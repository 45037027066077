import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { UserMutationPropsInterface } from './interfaces/UserMutationProps.interface';
import { ApiOperation } from '../../../api';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useUserNotFoundErrorHandler from '../../error-handlers/user/useUserNotFoundError.handler';
import useOnError from '../../error-handlers/useOnError';
import { queryClient } from '../../../queryClient';
import { getUsersQueryKey } from '../../queries/queryKeys';
import { useAlert } from '../../../alert/Alert.provider';
import { UserInterface } from '../../../../types/users/User.interface';

const useDeleteUserMutation = ({ setOtherError }: UserMutationPropsInterface = {}) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.DeleteUser);

  const notFoundErrorHandler = useUserNotFoundErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([notFoundErrorHandler], generalErrorHandler);

  return useMutation(
    async ({ userNumber }: UserInterface) => {
      const res = await request({ urlParams: { id: userNumber } });

      return res.data;
    },
    {
      onSuccess: (_, { fullName: name, email }: UserInterface) => {
        queryClient.invalidateQueries(getUsersQueryKey);

        showAlert(
          <Trans
            i18nKey="settings.sections.permission.subsections.user.actions.delete.success"
            values={{ name, email }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useDeleteUserMutation;
