import { useTranslation } from 'react-i18next';
import { ApiError } from '@datahub/api-client';
import {
  CommonValidationErrorHandlerInterface,
  handleCommonErrorMessage,
} from '../../useErrorHandler';
import ErrorHandled from '../../ErrorHandled.enum';
import { InvoiceErrorCodeTypeTranslationMap } from '../../../../../types/upload-hub/invoice/InvoiceErrorCode.type';
import { InvoiceErrorCodeEnum } from '../../../../../enums/upload-hub/InvoiceErrorCode.enum';

const errorFieldPrefix = 'InvoiceUpdateData';
const errorItemsFieldPrefix = 'InvoiceItemsUpdateData';

interface VerificationFormFieldErrorInterface {
  isParsed: boolean;
  errors: string[];
  field?: string;
  index?: number;
  isArray?: boolean;
}

const getVerificationErrorItemIndex = (field: string): number | undefined => {
  const myRegexp = /\[(\d+)]/;
  const match = myRegexp.exec(field);

  if (!match || (match.length < 2 && !match[1])) {
    return undefined;
  }

  return Number(match[1]);
};

const parseVerificationFormErrorEntry = ([field, errors]: [
  string,
  string[],
]): VerificationFormFieldErrorInterface => {
  const fieldParts = field.split('.');

  if (
    fieldParts.length !== 3 ||
    (fieldParts.length === 3 &&
      !fieldParts[0].includes(errorFieldPrefix) &&
      !fieldParts[0].includes(errorItemsFieldPrefix))
  ) {
    return {
      isParsed: false,
      errors,
    };
  }

  const isArrayField = fieldParts[0].includes(errorItemsFieldPrefix);
  const arrayIndex = getVerificationErrorItemIndex(fieldParts[0]);
  const isValidArray = isArrayField && arrayIndex !== undefined;

  return {
    isParsed: !isArrayField || isValidArray,
    errors,
    field: fieldParts[1],
    index: isValidArray ? arrayIndex : undefined,
    isArray: isValidArray,
  };
};

const useVerificationFormErrorHandler = ({
  setError,
  options,
}: CommonValidationErrorHandlerInterface) => {
  const { t } = useTranslation();
  const setErrorHandler = (field: string) => (message: string) =>
    setError(field, { message }, options);

  return (error: ApiError<any>) => {
    let isHandled = ErrorHandled.NOT_HANDLED;
    const responseModel = error.response?.data.responseModel;

    if (!responseModel) {
      return ErrorHandled.NOT_HANDLED;
    }

    Object.entries(responseModel).forEach((errorEntry) => {
      const { field, isArray, index, errors } = parseVerificationFormErrorEntry(
        errorEntry as [string, string[]],
      );

      if (!field) {
        return ErrorHandled.NOT_HANDLED;
      }

      const isFieldHandled = handleCommonErrorMessage({
        message: Array.isArray(errors) ? errors[0] : errors,
        setError: setErrorHandler(isArray ? `items.${index}.${field}` : field),
        // @ts-ignore
        errorMessage: t(InvoiceErrorCodeTypeTranslationMap[errors[0] as InvoiceErrorCodeEnum]),
        errorCode: Array.isArray(errors) ? errors[0] : errors,
      });

      if (isFieldHandled) {
        isHandled = ErrorHandled.HANDLED;
      }

      return isFieldHandled;
    });

    return isHandled;
  };
};

export default useVerificationFormErrorHandler;
