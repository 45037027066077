import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../api';
import { queryClient } from '../../../queryClient';
import { getDataSourcesForGeneralTypeQuery, getDataSourcesQueryKey } from '../../queries/queryKeys';
import DataSourcePayloadInterface from '../../../../types/data-source/DataSourcePayload.interface';
import useOnError from '../../error-handlers/useOnError';
import useDataSourceUniqueNameErrorHandler from '../../error-handlers/data-source/useDataSourceUniqueNameError.handler';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import { DataSourceMutationWithValidationPropsInterface } from './DataSourceMutationProps.interface';
import { useAlert } from '../../../alert/Alert.provider';

const useAddDataSourceMutation = (
  { setValidationError, setOtherError }: DataSourceMutationWithValidationPropsInterface = {
    setValidationError: () => {},
  },
) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.AddDataSource);

  const uniqueNameErrorHandler = useDataSourceUniqueNameErrorHandler(setValidationError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([uniqueNameErrorHandler], generalErrorHandler);

  return useMutation(
    async (payload: DataSourcePayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return res.data;
    },
    {
      onSuccess: (_, { name }: DataSourcePayloadInterface) => {
        queryClient.invalidateQueries(getDataSourcesQueryKey);
        queryClient.invalidateQueries(getDataSourcesForGeneralTypeQuery);

        showAlert(
          <Trans
            i18nKey="dataSourcesSettings.sections.dataSources.subsections.supplierList.addDataSourceModal.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useAddDataSourceMutation;
