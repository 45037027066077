import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { BulkDeleteInvoicePropsInterface } from '../interfaces/DeleteInvoiceProps.interface';
import useBulkDeleteInvoiceMutation from '../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/useBulkDeleteInvoice.mutation';
import DeleteModalComponent from '../../../../../../components/modal/delete-modal/DeleteModal.component';

const BulkDeleteInvoicesView: FC<BulkDeleteInvoicePropsInterface> = ({
  invoiceIds,
  close,
  reloadQuery,
  onMutate,
}) => {
  const {
    mutate: bulkDeleteInvoices,
    mutateAsync: bulkDeleteInvoicesAsync,
    isLoading,
    isSuccess,
  } = useBulkDeleteInvoiceMutation(reloadQuery, {});

  const onMutateHandler = (): void => {
    if (onMutate) {
      onMutate(bulkDeleteInvoicesAsync(invoiceIds));
    } else {
      bulkDeleteInvoices(invoiceIds);
    }
  };

  const selectedRowsTotal = invoiceIds.length;

  return (
    <DeleteModalComponent
      text={<Trans i18nKey="invoices.bulkDeleteInvoices.message" values={{ selectedRowsTotal }} />}
      close={close}
      isLoading={isLoading}
      isSuccess={isSuccess}
      mutate={onMutateHandler}
    />
  );
};

export default BulkDeleteInvoicesView;
