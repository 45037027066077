import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { UpdateOcrProcessedPagesLimitMutationPropsInterface } from './interfaces/UpdateOcrProcessedPagesLimitMutationProps.interface';
import { ApiOperation } from '../../../../../api';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { UpdateOcrProcessedPagesLimitPayloadInterface } from './interfaces/UpdateOcrProcessedPagesLimitPayload.interface';
import { queryClient } from '../../../../../queryClient';
import { getUploadHubSettingsQueryKey } from '../../../../queries/queryKeys';
import useDebouncedMutation from '../../../useDebounced.mutation';
import { useAlert } from '../../../../../alert/Alert.provider';

const useUpdateOcrProcessedPagesLimitMutation = ({
  setOtherError,
}: UpdateOcrProcessedPagesLimitMutationPropsInterface = {}) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateOcrProcessedPagesLimit);

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const { onError } = useOnError([], generalErrorHandler);

  const { mutate, ...rest } = useMutation(
    async (payload: UpdateOcrProcessedPagesLimitPayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return {
        data: res.data,
        payload,
      };
    },
    {
      onSuccess: (_, { ocrProcessedPagesLimit }: UpdateOcrProcessedPagesLimitPayloadInterface) => {
        queryClient.invalidateQueries(getUploadHubSettingsQueryKey);

        showAlert(
          <Trans
            i18nKey="pluginSettings.sections.uploadHub.subsections.general.controls.ocrProcessedPagesLimit.success"
            values={{ ocrProcessedPagesLimit }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );

  const { mutateDebounced } =
    useDebouncedMutation<UpdateOcrProcessedPagesLimitPayloadInterface>(mutate);

  return {
    mutate,
    mutateDebounced,
    ...rest,
  };
};

export default useUpdateOcrProcessedPagesLimitMutation;
