import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { InvoiceStateResponseType } from '../../../../../types/upload-hub/invoice/state/InvoiceStateResponse.type';
import { ApiOperation } from '../../../../api';
import { getInvoiceStateQueryKey } from '../../queryKeys';

const useGetInvoiceStateQuery = (id: string) => {
  const { request } = useRequest<InvoiceStateResponseType>(ApiOperation.GetInvoiceState);

  return useQuery(
    [getInvoiceStateQueryKey, id],
    async () => {
      const res = await request({ urlParams: { id } });

      return res.data.responseModel;
    },
    {
      enabled: false,
    },
  );
};

export default useGetInvoiceStateQuery;
