import React, { ChangeEvent, FC, memo, ReactElement, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import './PdfPreview.component.scss';
import classNames from 'classnames';
import Pagination from '../../../material/pagination/Pagination.component';
import { PdfPreviewPropsInterface } from './interfaces/PdfPreviewProps.interface';
import useCanvasDraw from '../../../../../utils/hooks/canvas/useCanvasDraw.hook';
import ZoomComponent from '../zoom/Zoom.component';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const resolutionScale = 1.5;

const PdfPreviewComponent: FC<PdfPreviewPropsInterface> = ({ path, stroke }): ReactElement => {
  const [numPages, setNumPages] = useState(0);
  const [page, setPage] = useState(1);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { draw, scale, calculateCanvas, zoomIn, zoomOut, mouseZoomActive } = useCanvasDraw();

  // TODO: resolve PDFDocumentProxy build issue
  const onDocumentLoadSuccessHandler = (pdf: any): void => {
    if (pdf.numPages) {
      setNumPages(pdf.numPages);
    }
  };

  const onPageRenderSuccessHandler = (): void => {
    if (stroke) {
      draw(canvasRef.current, stroke, page, resolutionScale);
    }

    calculateCanvas(canvasRef.current);
  };

  const onPageChangeHandler = (event: ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  return (
    <div
      className={classNames('pdf-preview', {
        'pdf-preview--mouse-zoom': mouseZoomActive,
      })}
      data-cy="pdf-preview"
    >
      <Document file={path} onLoadSuccess={onDocumentLoadSuccessHandler} options={options} key={1}>
        <Page
          key={page}
          pageNumber={page}
          canvasRef={canvasRef}
          onRenderSuccess={onPageRenderSuccessHandler}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          scale={resolutionScale}
        />
      </Document>

      <div className="pdf-preview__toolbar" data-cy="pdf-preview-toolbar">
        {numPages > 1 && (
          <Pagination
            count={numPages}
            page={page}
            onChange={onPageChangeHandler}
            shape="rounded"
            className="toolbar__paginator"
          />
        )}

        <ZoomComponent scale={scale} zoomIn={zoomIn} zoomOut={zoomOut} className="toolbar__zoom" />
      </div>
    </div>
  );
};

const PdfPreview = memo(PdfPreviewComponent);

export default PdfPreview;
