import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useNylasSendAuthCodeMutation from '../../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/synchronized-inboxes/nylas/useNylasSendAuthCode.mutation';
import { getUrlParams, joinUrlParams } from '../../../../../../../utils/Url.util';
import { useAlert } from '../../../../../../../services/alert/Alert.provider';

type NylasAuthErrorCodeType =
  | 'unknown'
  | 'invalid_request'
  | 'access_denied'
  | 'unauthorized_client'
  | 'unsupported_response_type'
  | 'invalid_scope'
  | 'server_error'
  | 'temporarily_unavailable';

interface NylasAuthErrorInterface {
  error: NylasAuthErrorCodeType;
  error_description: string;
}

interface NylasAuthUrlParams extends NylasAuthErrorInterface {
  code: string;
}

const useNylasAuth = () => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: sendNylasAuthCode, isLoading: isNylasAuthLoading } =
    useNylasSendAuthCodeMutation();

  useEffect(() => {
    (async () => {
      if (window.location.search) {
        const params = getUrlParams<NylasAuthUrlParams>(window.location.search);

        if (params.error) {
          showAlert(
            params.error_description || t('global.errors.general'),
            params.error === 'temporarily_unavailable' ? 'warning' : 'error',
          );
        }

        if (params.code) {
          await sendNylasAuthCode({
            authorizationCode: params.code,
          });
        }

        Object.keys(params).forEach((paramKey: string): void => {
          if (searchParams.has(paramKey)) {
            searchParams.delete(paramKey);
            setSearchParams(searchParams);
          }
        });
      }
    })();
  }, [searchParams, sendNylasAuthCode, setSearchParams, showAlert, t]);

  const initNylasAuth = (authUrl?: string) => {
    if (authUrl) {
      window.open(authUrl, '_self');
    } else if (process.env.NYLAS_CLIENT_SECRET) {
      const params = {
        client_id: process.env.NYLAS_CLIENT_SECRET,
        redirect_uri: `${window.location.origin}/settings/plugin`,
        response_type: 'code',
        scopes: process.env.REACT_APP_NYLAS_API_SCOPES,
      };

      window.open(
        `${process.env.REACT_APP_NYLAS_API_URL}oauth/authorize?${joinUrlParams(params)}`,
        '_self',
      );
    } else {
      throw new Error('Unknown authorization URL and Nylas client secret');
    }
  };

  return { initNylasAuth, isNylasAuthLoading };
};

export default useNylasAuth;
