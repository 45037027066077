import { DateFormatConfigInterface } from './interfaces/DateFormatConfig.interface';

const defaultDateFormatConfig: DateFormatConfigInterface = {
  locale: 'en-GB',
  dateSeparator: '/',
  timeSeparator: ':',
  utc: false,
  time: false,
};

const getDateFormatConfig = (config: DateFormatConfigInterface | undefined) => {
  return { ...defaultDateFormatConfig, ...config };
};

export default getDateFormatConfig;
