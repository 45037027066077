import { useRequest } from '@datahub/api-client';
import { useQuery } from 'react-query';
import { InvoiceHistoryResponseType } from '../../../../../types/upload-hub/invoice/history/InvoiceHistoryResponse.type';
import { ApiOperation } from '../../../../api';
import { getInvoiceHistoryQueryKey } from '../../queryKeys';

const useGetInvoiceHistoryQuery = (id: string) => {
  const { request } = useRequest<InvoiceHistoryResponseType>(ApiOperation.GetInvoiceHistory);

  return useQuery([getInvoiceHistoryQueryKey, id], async () => {
    const res = await request({ urlParams: { id } });

    return res.data.responseModel;
  });
};

export default useGetInvoiceHistoryQuery;
