import React, { MouseEventHandler } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Badge } from '@mui/material';
import classnames from 'classnames';
import classes from './NotificationBell.module.scss';
import { PropsInterface } from '../../../../../interfaces/Props.interface';
import { IconButton } from '../../../material';

interface NotificationBellComponentPropsInterface extends PropsInterface {
  newNotifications?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const NotificationBellComponent = ({
  newNotifications = false,
  onClick,
  className,
}: NotificationBellComponentPropsInterface) => {
  return (
    <Badge
      variant="dot"
      badgeContent=" "
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      invisible={!newNotifications}
      componentsProps={{
        badge: { className: classes['notification-bell__badge'] },
        root: { className: classnames(classes['notification-bell'], className) },
      }}
    >
      <IconButton
        onClick={onClick}
        className={classes['notification-bell__icon-button']}
        color="secondary"
        disableRipple
      >
        <NotificationsNoneIcon />
      </IconButton>
    </Badge>
  );
};

export default NotificationBellComponent;
