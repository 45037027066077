import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SupplierMatchModalPropsInterface } from './interfaces/SupplierMatchModalProps.interface';
import ModalComponent from '../../../../../../../components/modal/modal/Modal.component';
import SupplierMatchView from './SupplierMatch.view';

const SupplierMatchModalComponent: FC<SupplierMatchModalPropsInterface> = ({
  id,
  fileName,
  fileLocalization,
  open,
  setOpen,
  variant = 'assign',
  supplier,
  submitCallback,
}): ReactElement => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t(`invoices.tabs.toVerify.supplierMatchModal.title.${variant}`)}
      open={open}
      handleClose={handleClose}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
      content={
        <SupplierMatchView
          invoiceId={id}
          fileName={fileName}
          fileLocalization={fileLocalization}
          submitCallback={submitCallback}
          close={handleClose}
          variant={variant}
          supplier={supplier}
        />
      }
      required
      closeOnlyOnButton
      actions={variant === 'assign'}
    />
  );
};

export default SupplierMatchModalComponent;
