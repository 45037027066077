import React, { FC, ReactElement } from 'react';
import { Grid } from '@mui/material';
import classes from '../Landing-container.module.scss';
import LoginFormComponent from './components/login-form/LoginForm.component';
import ExternalLoginComponent from '../external-login/ExternalLogin.component';
import FormContainerComponent from '../../../components/form-container/FormContainer.component';

const LoginSectionComponent: FC = (): ReactElement => {
  return (
    <FormContainerComponent className={classes['landing-container']}>
      <Grid container direction="row">
        <Grid className={classes['landing-container__form']} item xs={12} lg={8}>
          <LoginFormComponent />
        </Grid>
        <Grid className={classes['landing-container__external']} item xs={12} lg={4}>
          <ExternalLoginComponent />
        </Grid>
      </Grid>
    </FormContainerComponent>
  );
};

export default LoginSectionComponent;
