import { useCallback, useMemo } from 'react';
import { isValid, parse } from 'date-fns';
import { DateFormatConfigInterface } from './interfaces/DateFormatConfig.interface';
import getDateFormatConfig from './getDateFormatConfig';
import { LocaleDateFormatsMap } from '../../../types/Locale.type';

const leftPad = (value: number) => (value < 10 ? `0${value}` : value);

const useDate = (customConfig?: DateFormatConfigInterface) => {
  const config = useMemo(() => getDateFormatConfig(customConfig), [customConfig]);
  const dateFormats = useMemo(
    () => (config.locale ? LocaleDateFormatsMap[config.locale] : []),
    [config.locale],
  );

  const parseDate = useCallback(
    (date: string): Date => {
      let parsedDate;

      dateFormats.some((dateFormat: string) => {
        const parsedDateInFormat = parse(date, dateFormat, new Date());
        const isParsedDateValid = isValid(parsedDateInFormat);

        if (isParsedDateValid) {
          parsedDate = parsedDateInFormat;
        }

        return isParsedDateValid;
      });

      return parsedDate ? new Date(parsedDate) : new Date(date);
    },
    [dateFormats],
  );

  const formatDate = useCallback(
    (date: Date | string | undefined | null): string => {
      if (date === undefined || date === null) {
        return '';
      }

      const d = typeof date === 'string' ? new Date(date) : date;

      if (Number.isNaN(Date.parse(d.toUTCString()))) {
        return String(date);
      }

      const dateDay = leftPad(config.utc ? d.getUTCDate() : d.getDate());
      const month = leftPad((config.utc ? d.getUTCMonth() : d.getMonth()) + 1);
      const year = config.utc ? d.getUTCFullYear() : d.getFullYear();

      const datePart = `${dateDay}${config.dateSeparator}${month}${config.dateSeparator}${year}`;
      let timePart = '';

      if (config.time) {
        const hours = leftPad(config.utc ? d.getUTCHours() : d.getHours());
        const minutes = leftPad(config.utc ? d.getUTCMinutes() : d.getMinutes());

        timePart = ` ${hours}${config.timeSeparator}${minutes}`;
      }

      return `${datePart}${timePart}`;
    },
    [config],
  );

  return {
    parse: parseDate,
    format: formatDate,
  };
};

export default useDate;
