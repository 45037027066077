import { useTranslation } from 'react-i18next';
import React, { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';
import AutocompleteSelect from '../../../../../../../../../../../components/material/autocomplete-select/AutocompleteSelect.component';
import { ResidentPropertyInterface } from '../../../../../../../../../../../../types/upload-hub/resident/property/ResidentProperty.interface';
import { FormControl } from '../../../../../../../../../../../components/material';
import useGetResidentBankAccountsQuery from '../../../../../../../../../../../../services/react-query-hooks/queries/upload-hub/resident/useGetResidentBankAccounts.query';
import { ResidentBankAccountInterface } from '../../../../../../../../../../../../types/upload-hub/resident/bank-account/ResidentBankAccount.interface';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';

// @ts-ignore
const BankAccountsControllerComponent = ({ name, error, disabled = false }) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<VerifyInvoiceDataFormInterface>();

  const property = watch(String(ResidentInvoiceDataEnum.PROPERTY));
  const { data: bankAccounts, isLoading } = useGetResidentBankAccountsQuery(
    property ? (property as ResidentPropertyInterface).value : '',
  );

  const bankAccountsController = useMemo(
    () => (
      <Controller
        name={name}
        control={control}
        render={({ field: fieldProps }) => (
          <AutocompleteSelect
            {...fieldProps}
            value={fieldProps.value as ResidentBankAccountInterface}
            data={bankAccounts?.map((ba: ResidentBankAccountInterface) => {
              if (ba.isDefault && !ba.text.includes('(default)')) {
                return {
                  ...ba,
                  text: `${ba.text} (default)`,
                };
              }
              return ba;
            })}
            isLoading={isLoading}
            placeholder={t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.bankAccount.label',
            )}
            error={!!error}
            disabled={disabled || property === null}
            data-cy="bank-account-input"
          />
        )}
      />
    ),
    [name, control, bankAccounts, isLoading, t, error, disabled, property],
  );

  return (
    <FormControl error={!!error} style={{ width: '95%' }} data-cy="bank-account-controller">
      {bankAccountsController}
      <span role="alert">{(error as any)?.message}</span>
    </FormControl>
  );
};

const BankAccountsController = memo(BankAccountsControllerComponent);

export default BankAccountsController;
