import React, { useMemo, useState } from 'react';
import { UserPayloadWithId } from '../../../../../../../../../../../types/users/UserPayload.interface';
import UpdateUserModalComponent from '../UpdateUserModal.component';
import { RoleInterface } from '../../../../../../../../../../../types/roles/Role.interface';

const useUpdateUser = (roles: RoleInterface[] | undefined) => {
  const [updateUserOpen, setUpdateUserOpen] = useState(false);
  const [updateUserData, setUpdateUserData] = useState<UserPayloadWithId | undefined>(undefined);

  const updateUserModal = useMemo(() => {
    if (!updateUserData) {
      return <> </>;
    }

    return (
      <UpdateUserModalComponent
        open={updateUserOpen}
        availableRoles={roles}
        setOpen={setUpdateUserOpen}
        {...updateUserData}
      />
    );
  }, [roles, updateUserData, updateUserOpen]);

  return { updateUserModal, setUpdateUserOpen, setUpdateUserData };
};

export default useUpdateUser;
