import React, { FC, ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsSectionItemInterface } from '../../../home-page/components/settings/components/settings-section/interfaces/SettingsSectionItem.interface';
import SynchronizedInboxesComponent from './components/synchronized-inboxes/SynchronizedInboxes.component';
import InvoiceReceiver from './components/invoice-receiver/InvoiceReceiver.component';
import SettingsSection from '../../../home-page/components/settings/components/settings-section/SettingsSection.component';
import useGetUploadHubSettingsQuery from '../../../../services/react-query-hooks/queries/upload-hub/settings/useGetUploadHubSettings.query';
import GeneralSettings from './components/general/GeneralSettings.component';
import ResidentSettings from './components/resident-settings/ResidentSettings.component';
import { InvoiceReceiversMap } from '../../../../types/upload-hub/invoice-receiver/InvoiceReceivers.type';
import { useUserPermissions } from '../../../../services/user/User.provider';
import { PermissionTypeEnum } from '../../../../enums/permission/PermissionType.enum';
import NoPermissionsView from '../../../components/no-permissions/NoPermissions.view';
import LoadingComponent from '../../../components/loading/Loading.component';
import classes from './UploadHub.module.scss';

const UploadHubComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { data: settings, isLoading } = useGetUploadHubSettingsQuery();
  const { hasPermission } = useUserPermissions();

  const hasUploadHubSettingsAccess = [
    PermissionTypeEnum.ADMIN,
    PermissionTypeEnum.UPLOAD_HUB_ADMIN,
  ].some(hasPermission);

  const uploadHubSections: SettingsSectionItemInterface[] = useMemo(
    () => [
      {
        title: t('pluginSettings.sections.uploadHub.title'),
        children: [
          {
            title: t('pluginSettings.sections.uploadHub.subsections.general.title'),
            element: (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {settings && (
                  <GeneralSettings
                    autoSendInvoicesToIntegrationService={
                      settings.automaticallySendInvoicesToIntegrationService
                    }
                    autoSendInvoicesToOCR={settings.automaticallySendInvoicesToOCR}
                    ocrProcessedPagesLimit={settings.ocrProcessedPagesLimit}
                    allowUsersToSeeOnlyInvoicesUploadedByThem={
                      settings.allowUsersToSeeOnlyInvoicesUploadedByThem
                    }
                  />
                )}
              </>
            ),
            isLoading,
          },
          {
            title: t('pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.title'),
            element: settings && (
              <SynchronizedInboxesComponent
                provider={settings.emailProvider}
                nylasAuthorizationUrl={settings.nylasAuthorizationUrl}
              />
            ),
          },
          {
            title: t('pluginSettings.sections.uploadHub.subsections.invoiceReceiver.title'),
            element: (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {settings && (
                  <InvoiceReceiver
                    invoiceReceiver={settings.invoiceReceiver}
                    primaryDataSources={settings.primaryDataSources}
                  />
                )}
              </>
            ),
            isLoading,
            required: true,
          },
          {
            title: t('pluginSettings.sections.uploadHub.subsections.resident.title'),
            element: (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>{settings && <ResidentSettings useItemGrossAmount={settings.doNotUseVat} />}</>
            ),
            isLoading,
            disabled: !settings || settings.invoiceReceiver !== InvoiceReceiversMap.resident,
          },
        ],
      },
    ],
    [isLoading, settings, t],
  );

  const isUploadHubSectionEnabled = useCallback(
    (section: SettingsSectionItemInterface) => !section.disabled,
    [],
  );

  const uploadHubSubSection = useCallback(
    (subSection: SettingsSectionItemInterface) => (
      <SettingsSection
        key={subSection.title}
        title={subSection.title}
        isLoading={subSection.isLoading}
        subsection
        required={subSection.required}
      >
        {subSection.element}
      </SettingsSection>
    ),
    [],
  );

  const uploadHubSection = useCallback(
    (section: SettingsSectionItemInterface) => (
      <div key={section.title}>
        <SettingsSection>
          {section.children?.filter(isUploadHubSectionEnabled).map(uploadHubSubSection)}
        </SettingsSection>
      </div>
    ),
    [isUploadHubSectionEnabled, uploadHubSubSection],
  );

  if (isLoading) {
    return (
      <div className={classes['upload-hub-settings__loading']}>
        <LoadingComponent size="sm" variant="relative" />
      </div>
    );
  }

  if (!hasUploadHubSettingsAccess) {
    return (
      <NoPermissionsView content={t('pluginSettings.sections.uploadHub.noPermissions.content')} />
    );
  }

  return <>{uploadHubSections.map(uploadHubSection)}</>;
};

export default UploadHubComponent;
