import React, { FC, ReactElement, useCallback } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import TableComponent from '../../../../../components/table/Table.component';
import { TableColumnInterface } from '../../../../../components/table/interfaces/TableColumn.interface';
import { AlignEnum } from '../../../../../../enums/Align.enum';
import { TableColumnTypeEnum } from '../../../../../components/table/enums/TableColumnType.enum';
import useTableFilters from '../../../../../components/table/hooks/TableFilters.hook';
import useGetToVerifyInvoicesListQuery from '../../../../../../services/react-query-hooks/queries/upload-hub/invoice/to-verify/useGetToVerifyInvoicesList.query';
import { TableRowInterface } from '../../../../../components/table/interfaces/TableRow.interface';
import { ToVerifyInvoiceInterface } from '../../../../../../types/upload-hub/invoice/to-verify/ToVerifyInvoice.interface';
import useDate from '../../../../../../utils/hooks/date-format/useDate';
import { StatusEnum } from '../../../../../../enums/Status.enum';
import { TableActionInterface } from '../../../../../components/table/interfaces/TableProps.interface';
import { TableActionNameEnum } from '../../../../../components/table/enums/TableActionName.enum';
import { ToVerifyInvoiceActionNameEnum } from './enums/ToVerifyInvoiceActionName.enum';
import { useInvoicesContext } from '../../Invoices.component';
import { InvoiceStatusEnum } from '../../../../../../enums/upload-hub/InvoiceStatus.enum';
import { InvoiceStatusMap } from '../../../../../../types/upload-hub/invoice/InvoiceStatus.type';
import { getToVerifyInvoicesQueryKey } from '../../../../../../services/react-query-hooks/queries/queryKeys';
import useFile from '../../../../../../utils/hooks/file/useFile.hook';
import useInvoiceVerification from './components/verification/hooks/useInvoiceVerification.hook';
import useSupplierMatch from './components/supplier-match/hooks/useSupplierMatch.hook';
import useDeleteInvoice from '../delete/hooks/useDeleteInvoice.hook';
import { Chip, Tooltip } from '../../../../../components/material';
import { useUploadHub } from '../../providers/UploadHub.provider';

const ToVerifyInvoicesComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { format } = useDate();
  const { download } = useFile();
  const { isProcessingEnabled } = useUploadHub();
  const { filters, dispatchFilters } = useTableFilters({
    OrderBy: 'fileName asc',
  });
  const { data, pagination, isLoading, isFetching } = useGetToVerifyInvoicesListQuery(filters);
  const getInvoice = (id: string): ToVerifyInvoiceInterface | undefined =>
    data?.find((item: ToVerifyInvoiceInterface) => item.invoiceId === id);

  const { verificationModal, setIsVerificationModalOpen, setVerifyInvoiceData } =
    useInvoiceVerification();

  const [
    ,
    setHistoryInvoiceData,
    setIsHistoryModalOpen,
    setDuplicatesInvoiceDataId,
    setIsDuplicatesModalOpen,
  ] = useInvoicesContext();

  const {
    deleteModal,
    setDeleteInvoiceOpen,
    setDeleteInvoiceData,
    setBulkDeleteInvoiceData,
    setBulkDeleteInvoiceOpen,
    deleteMutationPromise,
  } = useDeleteInvoice(getToVerifyInvoicesQueryKey);
  const { supplierMatchModal, setMatchSupplierInvoiceOpen, setMatchSupplierInvoiceData } =
    useSupplierMatch();

  const toVerifyInvoicesColumns: TableColumnInterface[] = [
    {
      name: 'invoiceStatus',
      label: t('invoices.tabs.toVerify.columns.status'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      width: '9rem',
    },
    {
      name: 'invoiceNote',
      label: t('invoices.tabs.toVerify.columns.note'),
      align: AlignEnum.CENTER,
      type: TableColumnTypeEnum.DEFAULT,
      width: '5rem',
    },
    {
      name: 'fileName',
      label: t('invoices.tabs.toVerify.columns.fileName'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '17rem',
    },
    {
      name: 'supplierName',
      label: t('invoices.tabs.toVerify.columns.supplier'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '11rem',
    },
    {
      name: 'invoiceReference',
      label: t('invoices.tabs.toVerify.columns.invoiceReference'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '13rem',
    },
    {
      name: 'invoiceDate',
      label: t('invoices.tabs.toVerify.columns.invoiceDate'),
      align: AlignEnum.CENTER,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '12rem',
    },
    {
      name: 'totalAmount',
      label: t('invoices.tabs.toVerify.columns.amountDue'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '10rem',
    },
    {
      name: 'propertyName',
      label: t('invoices.tabs.toVerify.columns.block'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '15rem',
    },
    {
      name: 'actions',
      label: t('invoices.tabs.toVerify.columns.action'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.ACTIONS,
      width: '4rem',
    },
  ];

  const renderInvoiceStatus = useCallback(
    (invoice: ToVerifyInvoiceInterface) => {
      const statusKey = InvoiceStatusMap[invoice.status];
      // TODO: resolve typing enum map issue
      // @ts-ignore
      const statusLabel = String(t(`invoices.statuses.${statusKey}`));

      switch (invoice.status) {
        case InvoiceStatusEnum.SUPPLIER_MATCHING_IN_PROGRESS:
          return <Chip label={statusLabel} color="info" variant="outlined" />;

        case InvoiceStatusEnum.NO_SUPPLIER:
          return <Chip label={statusLabel} color="warning" />;

        case InvoiceStatusEnum.MATCHING_IN_PROGRESS:
          return <Chip label={statusLabel} color="info" variant="outlined" />;

        case InvoiceStatusEnum.NO_MATCHING:
          return <Chip label={statusLabel} color="warning-secondary" />;

        case InvoiceStatusEnum.NO_DATA:
          return <Chip label={statusLabel} color="warning-tertiary" />;

        case InvoiceStatusEnum.DUPLICATES_DETECTION_IN_PROGRESS:
          return <Chip label={statusLabel} color="info" variant="outlined" />;

        case InvoiceStatusEnum.DUPLICATES:
          return <Chip label={statusLabel} color="black" />;

        default:
          return <Chip label={statusLabel} color="transparent" variant="outlined" />;
      }
    },
    [t],
  );

  const mapToVerifyInvoiceToRow = (invoice: ToVerifyInvoiceInterface): TableRowInterface => {
    return {
      ...invoice,
      id: invoice.invoiceId,
      status: StatusEnum.NULL,
      invoiceNote: invoice.note ? (
        <Tooltip title={invoice.note}>
          <StickyNote2OutlinedIcon />
        </Tooltip>
      ) : (
        ''
      ),
      invoiceStatus: renderInvoiceStatus(invoice),
      invoiceDate: invoice.invoiceDate ? format(invoice.invoiceDate) : '',
      totalAmount: invoice.amountTotal,
      propertyName: invoice.propertyName,
      disabled:
        invoice.status === InvoiceStatusEnum.MATCHING_IN_PROGRESS ||
        invoice.status === InvoiceStatusEnum.DUPLICATES_DETECTION_IN_PROGRESS,
    };
  };

  const actions: TableActionInterface<ToVerifyInvoiceActionNameEnum>[] = [
    {
      name: TableActionNameEnum.EDIT,
      icon: <EditOutlinedIcon />,
      label: t('invoices.tabs.toVerify.actions.edit.title'),
      active: isProcessingEnabled,
    },
    {
      name: TableActionNameEnum.DOWNLOAD,
      icon: <FileDownloadOutlinedIcon />,
      label: t('global.download'),
    },
    {
      name: ToVerifyInvoiceActionNameEnum.HISTORY,
      icon: <TimelineIcon />,
      label: t('invoices.tabs.toVerify.actions.history.title'),
    },
    {
      name: TableActionNameEnum.DELETE,
      icon: <ClearOutlinedIcon />,
      label: t('global.delete'),
      multiselect: true,
      active: isProcessingEnabled,
    },
  ];

  const openInvoiceVerification = (invoice: ToVerifyInvoiceInterface) => {
    switch (invoice.status) {
      case InvoiceStatusEnum.DUPLICATES: {
        setDuplicatesInvoiceDataId(invoice.invoiceId);
        setIsDuplicatesModalOpen(true);

        break;
      }

      case InvoiceStatusEnum.NO_SUPPLIER: {
        setMatchSupplierInvoiceData({
          id: invoice.invoiceId,
          fileName: invoice.fileName,
          fileLocalization: invoice.fileLocalization,
          supplier: null,
        });
        setMatchSupplierInvoiceOpen(true);

        break;
      }

      case InvoiceStatusEnum.NO_MATCHING: {
        setVerifyInvoiceData({
          id: invoice.invoiceId,
          fileName: invoice.fileName,
          invoiceReference: invoice.invoiceReference,
          step: 'matching',
        });
        setIsVerificationModalOpen(true);

        break;
      }

      case InvoiceStatusEnum.NO_DATA: {
        setVerifyInvoiceData({
          id: invoice.invoiceId,
          fileName: invoice.fileName,
          invoiceReference: invoice.invoiceReference,
          step: 'verification',
        });
        setIsVerificationModalOpen(true);

        break;
      }

      default:
    }
  };

  const onInvoiceClickHandler = (id: string | number): void => {
    const invoice = getInvoice(String(id));

    if (invoice) {
      openInvoiceVerification(invoice);
    }
  };

  const onActionSelectedHandler = (
    actionName: ToVerifyInvoiceActionNameEnum | TableActionNameEnum,
    rowId: string[] | number[] | string | number,
  ) => {
    const invoice = getInvoice(String(rowId));
    const isMultiple = Array.isArray(rowId) && !invoice;
    const isSingle = invoice && !isMultiple;

    switch (actionName) {
      case TableActionNameEnum.EDIT: {
        if (isSingle) {
          openInvoiceVerification(invoice);
        }

        break;
      }

      case ToVerifyInvoiceActionNameEnum.HISTORY: {
        if (isSingle) {
          setHistoryInvoiceData({ id: invoice.invoiceId });
          setIsHistoryModalOpen(true);
        }

        break;
      }

      case TableActionNameEnum.DOWNLOAD: {
        if (isSingle) {
          download(invoice.fileLocalization, invoice.fileName);
        }

        break;
      }

      case TableActionNameEnum.DELETE: {
        if (isSingle) {
          setDeleteInvoiceData(invoice);
          setDeleteInvoiceOpen(true);
        }

        if (isMultiple) {
          setBulkDeleteInvoiceData(rowId as string[]);
          setBulkDeleteInvoiceOpen(true);
        }

        break;
      }

      default:
    }
  };

  return (
    <Grid>
      <TableComponent
        table={{
          rows: data ? [...data.map(mapToVerifyInvoiceToRow)] : [],
          columns: toVerifyInvoicesColumns,
        }}
        emptyLabel={t('invoices.tabs.toVerify.emptyTable')}
        isLoading={isLoading || isFetching}
        multiselect
        actions={actions}
        onRowClick={isProcessingEnabled ? onInvoiceClickHandler : undefined}
        onActionSelected={onActionSelectedHandler}
        onActionCompleted={deleteMutationPromise}
        filters={filters}
        onChangeFilters={dispatchFilters}
        pagination={pagination}
      />

      {supplierMatchModal}
      {verificationModal}
      {deleteModal}
    </Grid>
  );
};

export default ToVerifyInvoicesComponent;
