import classnames from 'classnames';
import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { PropsInterface } from '../../interfaces/Props.interface';

const ContainerComponent: FC<PropsWithChildren<PropsInterface>> = ({
  children,
  className,
}): ReactElement => {
  return <div className={classnames('container', 'container-fluid', className)}>{children}</div>;
};

export default ContainerComponent;
