import React, { FC, useCallback, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import MergeIcon from '@mui/icons-material/Merge';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseFieldArrayInsert, UseFieldArrayRemove } from 'react-hook-form/dist/types/fieldArray';
import { VerifyInvoiceDataSectionPropsInterface } from './interfaces/VerifyInvoiceDataSectionProps.interface';
import VerifyInvoiceDataField from '../verify-invoice-data-field/VerifyInvoiceDataField.component';
import { InvoicePropertyInterface } from '../../../../../../../../../../../../types/upload-hub/invoice/InvoiceProperty.interface';
import classes from '../../../match-invoice-fields/components/match-section/MatchSection.module.scss';
import MatchSectionPaginatorComponent from '../../../match-invoice-fields/components/match-section/components/MatchSectionPaginator.component';
import VerifyInvoiceItemsFormComponent from '../verify-invoice-items-form/VerifyInvoiceItemsForm.component';
import { IconButton } from '../../../../../../../../../../../components/material';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';
import { emptyInvoicePropertyFormObject } from '../../../../../../../../../../../../services/react-query-hooks/queries/upload-hub/invoice/useGetInvoice.query';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';
import ConfirmModalComponent from '../../../../../../../../../../../components/modal/confirm-modal/ConfirmModal.component';

const VerifyInvoiceDataSectionComponent: FC<VerifyInvoiceDataSectionPropsInterface> = ({
  label,
  fields,
  setSupplierEditModalOpen,
  approval,
  error,
}) => {
  const { t } = useTranslation();
  const isMultiPageSection = fields.length > 0 && Array.isArray(fields[0]);
  const [page, setPage] = useState(0);
  const [pageAmount, setPageAmount] = useState(fields.length);
  const [confirmMergeItemsModalOpen, setConfirmMergeItemsModalOpen] = useState(false);

  const onPreviousPageClickHandler = () => {
    setPage(page - 1);
  };

  const onNextPageClickHandler = () => {
    setPage(page + 1);
  };

  const { setValue, watch, getValues, clearErrors, formState } =
    useFormContext<VerifyInvoiceDataFormInterface>();
  const items = watch('items');

  const onPropertyChangeHandler = useCallback(() => {
    items.forEach((_, index) => {
      setValue(`items[${index}].${ResidentInvoiceDataEnum.ITEM_CATEGORY}`, null as any);
      setValue(`items[${index}].${ResidentInvoiceDataEnum.ITEM_SCHEDULE}`, null as any);
    });
  }, [items, setValue]);

  const dataFieldComponent = useCallback(
    (field: InvoicePropertyInterface) => (
      <VerifyInvoiceDataField
        key={field.residentFieldName}
        field={field}
        onPropertyChange={onPropertyChangeHandler}
        setSupplierEditModalOpen={setSupplierEditModalOpen}
      />
    ),
    [onPropertyChangeHandler, setSupplierEditModalOpen],
  );

  const removeItemCallbackRef = useRef<(index: number | number[]) => void>();
  const registerRemoveItemCallback = (callback: UseFieldArrayRemove) => {
    removeItemCallbackRef.current = callback;
  };

  const onRemoveItemHandler = () => {
    if (removeItemCallbackRef.current) {
      if (page === pageAmount - 1) {
        setPage(page - 1);
      }

      if (page > 0 || (page === 0 && pageAmount > 1)) {
        setPageAmount((prevPageAmount: number): number => prevPageAmount - 1);
      }

      removeItemCallbackRef.current(page);
    }
  };

  const onMergeItemHandler = () => {
    if (removeItemCallbackRef.current && pageAmount > 1) {
      const totalAmount = getValues(String(ResidentInvoiceDataEnum.TOTAL_AMOUNT));
      const itemsIndexes = [...Array(pageAmount).keys()];

      itemsIndexes.shift();
      setPageAmount(1);
      removeItemCallbackRef.current(itemsIndexes);
      setValue(`items[0].${ResidentInvoiceDataEnum.ITEM_AMOUNT}`, totalAmount as any);

      if (!formState.errors[String(ResidentInvoiceDataEnum.TOTAL_AMOUNT)]) {
        clearErrors(`items[0].${ResidentInvoiceDataEnum.ITEM_AMOUNT}`);
      }

      setValue(`items[0].${ResidentInvoiceDataEnum.ITEM_VAT}`, '0%' as any);
      clearErrors(`items[0].${ResidentInvoiceDataEnum.ITEM_VAT}`);
      setPage(0);
      setConfirmMergeItemsModalOpen(false);
    }
  };

  const addItemCallbackRef =
    useRef<(index: number, value: Partial<VerifyInvoiceDataFormInterface>) => void>();
  const registerAddItemCallback = (callback: UseFieldArrayInsert<any, 'items'>) => {
    addItemCallbackRef.current = callback;
  };

  const onAddItemHandler = () => {
    if (addItemCallbackRef.current) {
      setPageAmount((prevPageAmount: number): number => prevPageAmount + 1);
      addItemCallbackRef.current(page + 1, emptyInvoicePropertyFormObject);
      setPage(page + 1);
    }
  };

  return (
    <div
      className={classNames(classes['match-section'], classes['match-section--form'], {
        [classes['match-section--error']]: error,
      })}
    >
      <div className={classes['match-section__title-container']}>
        <h6 className={classes['title-container__title']}>{label}</h6>
        {isMultiPageSection && (
          <>
            <MatchSectionPaginatorComponent
              page={page}
              pageAmount={pageAmount}
              onPreviousPageClick={onPreviousPageClickHandler}
              onNextPageClick={onNextPageClickHandler}
              error={error}
            />

            {!approval && (
              <>
                <div className={classes['title-container__actions']}>
                  <IconButton
                    size="medium"
                    aria-label={t(
                      'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.actions.add.title',
                    )}
                    onClick={onAddItemHandler}
                    className={classes.actions__button}
                  >
                    <AddOutlinedIcon />
                  </IconButton>
                  <IconButton
                    size="medium"
                    aria-label={t(
                      'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.actions.remove.title',
                    )}
                    disabled={pageAmount <= 1}
                    onClick={onRemoveItemHandler}
                    className={classes.actions__button}
                  >
                    <RemoveIcon />
                  </IconButton>
                </div>

                <div className={classes['title-container__actions']}>
                  <IconButton
                    size="medium"
                    aria-label={t(
                      'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.actions.merge.title',
                    )}
                    disabled={pageAmount <= 1}
                    onClick={() => setConfirmMergeItemsModalOpen(true)}
                    className={classes.actions__button}
                  >
                    <MergeIcon />
                  </IconButton>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <br />

      {!isMultiPageSection && (
        <Grid container direction="column" rowSpacing={1}>
          {fields.map((field) => dataFieldComponent(field as InvoicePropertyInterface))}
        </Grid>
      )}

      {isMultiPageSection && (
        <VerifyInvoiceItemsFormComponent
          page={page}
          itemsFields={fields as InvoicePropertyInterface[][]}
          registerRemoveItemCallback={registerRemoveItemCallback}
          registerAddItemCallback={registerAddItemCallback}
        />
      )}

      <ConfirmModalComponent
        title={t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.actions.merge.confirmModal.title',
        )}
        text={t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.actions.merge.confirmModal.content',
        )}
        open={confirmMergeItemsModalOpen}
        close={() => setConfirmMergeItemsModalOpen(false)}
        confirm={onMergeItemHandler}
        variant="warning"
        closeOnlyOnButton={false}
      />
    </div>
  );
};

export default VerifyInvoiceDataSectionComponent;
