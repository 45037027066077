import { useTranslation } from 'react-i18next';
import React, { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';
import AutocompleteSelect from '../../../../../../../../../../../components/material/autocomplete-select/AutocompleteSelect.component';
import { FormControl } from '../../../../../../../../../../../components/material';
import { ResidentScheduleInterface } from '../../../../../../../../../../../../types/upload-hub/resident/schedule/ResidentSchedule.interface';
import { ResidentCategoryInterface } from '../../../../../../../../../../../../types/upload-hub/resident/category/ResidentCategory.interface';
import useGetResidentCategoriesQuery from '../../../../../../../../../../../../services/react-query-hooks/queries/upload-hub/resident/useGetResidentCategories.query';
import { ResidentPropertyInterface } from '../../../../../../../../../../../../types/upload-hub/resident/property/ResidentProperty.interface';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';

const mapCategory = (category: ResidentCategoryInterface): ResidentCategoryInterface => ({
  ...category,
  icon: category.isInSchedule ? <EventAvailableIcon /> : undefined,
  accent: category.isInSchedule,
});

// @ts-ignore
const CategoriesControllerComponent = ({
  // @ts-ignore
  name,
  // @ts-ignore
  error,
  // @ts-ignore
  index,
  disabled = false,
  'data-cy': dataCy = '',
}) => {
  const { t } = useTranslation();
  const { control, getValues, watch } = useFormContext<VerifyInvoiceDataFormInterface>();
  const schedule = watch(`items[${index}].${ResidentInvoiceDataEnum.ITEM_SCHEDULE}`);
  const propertyValue = getValues(String(ResidentInvoiceDataEnum.PROPERTY));

  const { data: categories, isLoading } = useGetResidentCategoriesQuery(
    propertyValue ? (propertyValue as ResidentPropertyInterface).value : '',
    // @ts-ignore
    schedule ? (schedule as ResidentScheduleInterface).value : '',
  );

  const categoriesWithSchedule: ResidentScheduleInterface[] = useMemo(
    () => (categories ? categories.map(mapCategory) : []),
    [categories],
  );

  const categoriesController = useMemo(
    () => (
      <Controller
        name={name}
        control={control}
        render={({ field: fieldProps }) => (
          <AutocompleteSelect
            {...fieldProps}
            value={fieldProps.value as ResidentCategoryInterface}
            data={categoriesWithSchedule}
            isLoading={isLoading}
            placeholder={t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.category.label',
            )}
            error={!!error}
            disabled={disabled || schedule === null}
            data-cy={`${dataCy || 'category'}-input`}
          />
        )}
      />
    ),
    [name, control, categoriesWithSchedule, isLoading, t, error, disabled, schedule, dataCy],
  );

  return (
    <FormControl
      error={!!error}
      style={{ width: '50%' }}
      data-cy={`${dataCy || 'category'}-controller`}
    >
      {categoriesController}
      <span role="alert">{(error as any)?.message}</span>
    </FormControl>
  );
};

const CategoriesController = memo(CategoriesControllerComponent);

export default CategoriesController;
