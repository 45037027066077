/// <reference path="./../../../types.d.ts" />

import React, { StrictMode } from 'react';
import './styles/main.scss';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { UserProvider } from './services/user/User.provider';
import { AuthProvider } from './services/auth/Auth.provider';
import { queryClient } from './services/queryClient';
import { SubscriptionProvider } from './services/subscription/Subscription.provider';
import { clients, initApiClients } from './services/api';

const container = document.getElementById('root');
const isCypress = Boolean(window.Cypress);

initApiClients(clients);

const appContent = (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <SubscriptionProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </SubscriptionProvider>
      </UserProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

if (container) {
  const root = createRoot(container);

  if (isCypress) {
    root.render(appContent);
  } else {
    root.render(<StrictMode>{appContent}</StrictMode>);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
