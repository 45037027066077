import React, { useMemo, useState } from 'react';
import { SmtpSynchronizedInboxInterface } from '../../../../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';
import UpdateSynchronizedInboxModalComponent from '../UpdateSynchronizedInboxModal.component';

const useUpdateSynchronizedInbox = () => {
  const [updateEmailOpen, setUpdateEmailOpen] = useState(false);
  const [updateEmailData, setUpdateEmailData] = useState<
    SmtpSynchronizedInboxInterface | undefined
  >(undefined);

  const updateSynchronizedInboxModal = useMemo(() => {
    if (!updateEmailData) {
      return null;
    }

    return (
      <UpdateSynchronizedInboxModalComponent
        id={updateEmailData.uniqueNumber}
        {...updateEmailData}
        open={updateEmailOpen}
        setOpen={setUpdateEmailOpen}
      />
    );
  }, [updateEmailData, updateEmailOpen]);

  return { updateSynchronizedInboxModal, setUpdateEmailOpen, setUpdateEmailData };
};

export default useUpdateSynchronizedInbox;
