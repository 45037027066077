import React, { FC, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VerificationModalPropsInterface } from './interfaces/VerificationModalProps.interface';
import ModalComponent from '../../../../../../../components/modal/modal/Modal.component';
import VerificationView from './Verification.view';
import './VerificationModal.component.scss';
import ConfirmModalComponent from '../../../../../../../components/modal/confirm-modal/ConfirmModal.component';
import { useVerificationFormState } from './services/VerificationForm.provider';

const VerificationModalComponent: FC<VerificationModalPropsInterface> = ({
  open,
}): ReactElement => {
  const { t } = useTranslation();
  const {
    id,
    currentStep,
    closeHandler,
    confirmCloseModalOpen,
    setConfirmCloseModalOpen,
    confirmCloseHandler,
    rejectModal,
  } = useVerificationFormState();

  const verificationView = useMemo(
    () => currentStep && <VerificationView id={id} step={currentStep} />,
    [currentStep, id],
  );

  const modalContent = useMemo(
    () => (
      <ModalComponent
        open={open}
        handleClose={closeHandler}
        title={currentStep?.title || ''}
        content={verificationView}
        closeOnlyOnButton
        dialogProps={{
          fullWidth: true,
          maxWidth: 'xl',
        }}
        className="verification-modal"
        actions
        fixedContent
      />
    ),
    [closeHandler, currentStep?.title, open, verificationView],
  );

  if (currentStep) {
    return (
      <>
        {modalContent}
        {rejectModal}

        <ConfirmModalComponent
          title={t('invoices.tabs.toVerify.confirmModal.title')}
          text={t('invoices.tabs.toVerify.confirmModal.content')}
          open={confirmCloseModalOpen}
          close={() => setConfirmCloseModalOpen(false)}
          confirm={confirmCloseHandler}
          variant="warning"
        />
      </>
    );
  }

  return <ModalComponent open={false} handleClose={() => {}} title="" content="" />;
};

export default VerificationModalComponent;
