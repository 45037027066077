import { useEffect, useState } from 'react';

const useDelay = (time: number = 0) => {
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAvailable(true);
    }, time);
    return () => clearTimeout(timer);
  }, [time]);

  return {
    isAvailable,
  };
};

export default useDelay;
