import { useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

const useVirtualListRef = (
  itemCount: number,
  itemSize: number,
  selectedItemIndex: number | undefined,
) => {
  const ref = useRef<VariableSizeList>(null);

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [itemCount]);

  useEffect(() => {
    if (ref?.current && selectedItemIndex) {
      if (selectedItemIndex >= itemCount - 1) {
        ref.current.scrollTo(itemSize * itemCount);
      } else {
        ref.current.scrollToItem(selectedItemIndex, 'smart');
      }
    }
  }, [ref, itemCount, itemSize, selectedItemIndex]);

  return {
    ref,
  };
};

export default useVirtualListRef;
