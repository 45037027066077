import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { InvitationStatusEnum } from '../../../../../enums/user/InvitationStatus.enum';
import useGuidHook from '../../../../../utils/hooks/useGuid.hook';
import useGetUserInvitationStatusQuery from '../../../../../services/react-query-hooks/queries/user/useGetUserInvitationStatus.query';

const useVerifyInvitation = (id: string | undefined) => {
  const navigate = useNavigate();
  const { guid: invitationId, isValid } = useGuidHook(id || '');
  const { data, isError, isLoading } = useGetUserInvitationStatusQuery(invitationId, isValid);

  useEffect(() => {
    if (isError || data?.status === InvitationStatusEnum.NOT_INVITED) {
      navigate('/');
    }
  }, [navigate, data, isError]);

  return {
    isProcessing: !isValid ? false : isLoading,
    status: isValid && data ? data.status : InvitationStatusEnum.NOT_INVITED,
    invitationEmail: isValid && data ? data.invitationEmail : undefined,
    invitationId,
  };
};

export default useVerifyInvitation;
