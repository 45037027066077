import { useTranslation } from 'react-i18next';
import { useCommonErrorHandler } from '../../useErrorHandler';
import CommonSetErrorType from '../../CommonSetError.type';

const useIncorrectItemVatErrorHandler = (setError: CommonSetErrorType) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'WRONG_VAT_VALUE',
    errorMessage: t(
      'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.vat.errors.incorrect',
    ),
  });

  return handler;
};

export default useIncorrectItemVatErrorHandler;
