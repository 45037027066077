import React, { FC, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './NotificationsFooter.module.scss';
import TablePagination from '../../../table/components/table-pagination/TablePagination.component';
import { useNotification } from '../../../../../services/notification/Notification.provider';
import useTablePagination from '../../../table/hooks/TablePagination.hook';

const NotificationsFooterComponent: FC = (): ReactElement => {
  const { t } = useTranslation();

  const {
    notifications,
    notificationsPageSize,
    pagination,
    filters,
    dispatchNotificationsFilters,
    selectedNotificationTabIndex,
  } = useNotification();

  const { paginator: notificationsPaginator, setPage } = useTablePagination({
    rowsCount: notifications?.length || 0,
    rowsPerPageOptions: [notificationsPageSize],
    pagination,
    filters,
    onChangeFilters: dispatchNotificationsFilters,
    showRowsLabel: false,
  });

  useEffect(() => {
    if (pagination && pagination.CurrentPage === 1) {
      setPage(0);
    }
  }, [pagination, setPage]);

  return (
    <footer className={classes['notifications-footer']}>
      {selectedNotificationTabIndex === 0 && (
        <TablePagination
          rowsPerPageOptions={[10]}
          count={0}
          rowsPerPage={10}
          page={0}
          onPageChange={() => {}}
          labelRowsPerPage={t('table.pagination.rowsPerPage')}
          showFirstButton
          showLastButton
          showRowsLabel={false}
        />
      )}

      {selectedNotificationTabIndex === 1 && notificationsPaginator}
    </footer>
  );
};

export default NotificationsFooterComponent;
