import React, { FC, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import UserFormInterface, { defaultValues } from '../user-form/UserForm.interface';
import useAddUserMutation from '../../../../../../../../../../services/react-query-hooks/mutations/user/useAddUserMutation';
import UserPayloadInterface from '../../../../../../../../../../types/users/UserPayload.interface';
import FormModalComponent from '../../../../../../../../../components/modal/form-modal/FormModal.component';
import UserFormComponent from '../user-form/UserForm.component';
import { RoleInterface } from '../../../../../../../../../../types/roles/Role.interface';

interface AddUserPropsInterface {
  roles: RoleInterface[] | undefined;
  close: () => void;
}

const AddUserComponent: FC<AddUserPropsInterface> = ({ roles, close }): ReactElement => {
  const methods = useForm<UserFormInterface>({
    mode: 'onBlur',
    defaultValues,
  });

  const {
    mutate: addUser,
    isLoading,
    isSuccess,
  } = useAddUserMutation({
    setValidationError: methods.setError,
  });

  const onSubmitHandler = (data: UserPayloadInterface) => addUser(data);

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmitHandler)}
      isSuccess={isSuccess}
      isLoading={isLoading}
    >
      <UserFormComponent roles={roles} />
    </FormModalComponent>
  );
};

export default AddUserComponent;
