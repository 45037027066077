import React, { FC, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceHistoryModalPropsInterface } from './interfaces/InvoiceHistoryModalProps.interface';
import ModalComponent from '../../../../../components/modal/modal/Modal.component';
import InvoiceHistoryView from './InvoiceHistory.view';
import useGetInvoiceHistoryQuery from '../../../../../../services/react-query-hooks/queries/upload-hub/invoice/useGetInvoiceHistory.query';

const InvoiceHistoryModalComponent: FC<InvoiceHistoryModalPropsInterface> = ({
  id,
  open,
  setOpen,
}): ReactElement => {
  const { t } = useTranslation();
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const { data, isLoading } = useGetInvoiceHistoryQuery(id);

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      title={t('invoices.history.title')}
      content={
        data &&
        !isLoading && (
          <InvoiceHistoryView historyEntries={data?.historyEntries ? data.historyEntries : []} />
        )
      }
      isLoading={isLoading}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
      className="invoice-history-modal"
    />
  );
};

export default InvoiceHistoryModalComponent;
