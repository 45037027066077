import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { LinearProgress } from '@mui/material';
import classNames from 'classnames';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Trans, useTranslation } from 'react-i18next';
import classes from './ProcessedPages.module.scss';
import { PropsInterface } from '../../../../../../interfaces/Props.interface';
import useGetOcrProcessedPagesQuery from '../../../../../../services/react-query-hooks/queries/upload-hub/processed-pages/useGetOcrProcessedPages.query';
import { Tooltip } from '../../../../../components/material';

const lowWarningThreshold = 60;
const midWarningThreshold = 40;
const warningThreshold = 25;
const errorThreshold = 10;

const ProcessedPagesComponent: FC<PropsInterface> = ({ className }): ReactElement => {
  const { t } = useTranslation();
  const [value, setValue] = useState(100);
  const { data: processedPages, isLoading } = useGetOcrProcessedPagesQuery();

  useEffect(() => {
    if (processedPages) {
      const remainingPagesInPercentage =
        processedPages.latestActiveSubscriptionBoughtPagesNumber === 0
          ? 0
          : (1 -
              processedPages.pagesAlreadyUsedForCurrentPackage /
                processedPages.latestActiveSubscriptionBoughtPagesNumber) *
            100;

      if (remainingPagesInPercentage < 0) {
        setValue(0);
      } else if (remainingPagesInPercentage > 100) {
        setValue(100);
      } else {
        setValue(remainingPagesInPercentage);
      }
    }
  }, [processedPages]);

  const warningClass = useMemo(() => {
    if (value < errorThreshold) {
      return classes['processed-pages__progress--critical'];
    }

    if (value < warningThreshold) {
      return classes['processed-pages__progress--warning'];
    }

    if (value < midWarningThreshold) {
      return classes['processed-pages__progress--mid'];
    }

    if (value < lowWarningThreshold) {
      return classes['processed-pages__progress--low'];
    }

    return '';
  }, [value]);

  const tooltipVariant = useMemo(() => {
    if (value < errorThreshold) {
      return 'error';
    }

    if (value < warningThreshold) {
      return 'warning';
    }

    if (value < midWarningThreshold) {
      return 'warning-mid';
    }

    if (value < lowWarningThreshold) {
      return 'warning-low';
    }

    return 'primary';
  }, [value]);

  if (isLoading || !processedPages) {
    return <div className={classNames(classes['processed-pages'], className)} />;
  }

  const {
    pagesAlreadyUsedForCurrentPackage,
    latestActiveSubscriptionBoughtPagesNumber,
    pagesLeftFromPreviousPackages,
  } = processedPages;

  return (
    <div className={classNames(classes['processed-pages'], className)}>
      <Tooltip
        title={
          <div className={classes['processed-pages__tooltip-content']}>
            <p>
              <Trans
                i18nKey="invoices.processedPages.fromLatestActivePackage"
                values={{
                  pagesAlreadyUsedForCurrentPackage,
                  latestActiveSubscriptionBoughtPagesNumber,
                }}
                components={{ italic: <i />, bold: <strong /> }}
              />
            </p>
            {pagesLeftFromPreviousPackages > 0 && (
              <p>
                <Trans
                  i18nKey="invoices.processedPages.fromPreviouslyBoughtPackages"
                  values={{ pagesLeftFromPreviousPackages }}
                  components={{ italic: <i />, bold: <strong /> }}
                />
              </p>
            )}
          </div>
        }
        size="lg"
        variant={tooltipVariant}
      >
        <LinearProgress
          className={classNames(classes['processed-pages__progress'], warningClass)}
          variant="determinate"
          value={value}
        />
      </Tooltip>

      {value <= 10 && (
        <div className={classNames(classes['processed-pages__text-warning'])}>
          <WarningAmberRoundedIcon />

          <p>
            {t('invoices.processedPages.criticalWarning1')}
            <a href="mailto:accounting@resident.uk.com">accounting@resident.uk.com</a>
            {t('invoices.processedPages.criticalWarning2')}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProcessedPagesComponent;
