import { NotificationOriginEnum } from '../../enums/notification/NotificationOrigin.enum';
import { NotificationTypeEnum } from '../../enums/notification/NotificationType.enum';

export const NotificationOriginTypes = Object.values(NotificationOriginEnum);

export type NotificationOriginType = (typeof NotificationOriginTypes)[number];

export const NotificationOriginTypeGroupsMap: {
  [key in NotificationOriginType]: NotificationTypeEnum[];
} = {
  [NotificationOriginEnum.SYSTEM]: [NotificationTypeEnum.ADMIN_NOTIFICATION],
  [NotificationOriginEnum.UPLOAD_HUB]: [
    NotificationTypeEnum.INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT,
    NotificationTypeEnum.APPROVED_INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT,
    NotificationTypeEnum.OCR_FAILED_TO_PROCESS_INVOICE,
    NotificationTypeEnum.MAILBOX_PROCESSING_ERROR,
    NotificationTypeEnum.LESS_THAN_HUNDRED_PAGES_LEFT,
    NotificationTypeEnum.INVOICES_WAITING_TO_BE_VERIFIED,
    NotificationTypeEnum.INVOICES_WAITING_FOR_FINANCIAL_APPROVAL,
  ],
  [NotificationOriginEnum.DATA_SOURCE]: [
    NotificationTypeEnum.PRIMARY_DATA_SOURCE_REFRESHED,
    NotificationTypeEnum.DATA_SOURCE_SYNCHRONIZATION_FAILED,
  ],
  [NotificationOriginEnum.INTERNAL]: [NotificationTypeEnum.INVOICE_OCR_RECOGNITION_FINISHED],
};
