export enum InvoiceStatusEnum {
  // IN_PROGRESS
  OCR_IN_PROGRESS = 0,
  OCR_FAILED = 1,
  NO_OCR = 2,

  // TO_VERIFY
  SUPPLIER_MATCHING_IN_PROGRESS = 20,
  NO_SUPPLIER = 21,
  MATCHING_IN_PROGRESS = 22,
  NO_MATCHING = 23,
  NO_DATA = 24,
  DUPLICATES_DETECTION_IN_PROGRESS = 25,
  DUPLICATES = 26,

  // TO_APPROVE
  TO_APPROVE = 27,

  // PROCESSED
  TO_BE_SEND_TO_RESIDENT = 40,
  SENT_TO_RESIDENT = 41,
  NOT_SEND_TO_RESIDENT = 42,
}
