import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../../../../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../../../../../../../components/modal/types/Props.interface';
import UpdateUserComponent from './UpdateUser.component';
import { RoleInterface } from '../../../../../../../../../../types/roles/Role.interface';
import { UserPayloadWithId } from '../../../../../../../../../../types/users/UserPayload.interface';

interface UpdateUserModalComponentPropsInterface
  extends ModalControlPropsInterface,
    UserPayloadWithId {
  availableRoles: RoleInterface[] | undefined;
}

const UpdateUserModalComponent: FC<UpdateUserModalComponentPropsInterface> = ({
  id,
  email,
  fullName,
  roles,
  availableRoles,
  open,
  setOpen,
}): ReactElement => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      title={t('settings.sections.permission.subsections.user.updateModal.title')}
      content={
        <UpdateUserComponent
          availableRoles={availableRoles}
          close={handleClose}
          id={id}
          email={email}
          fullName={fullName}
          roles={roles}
        />
      }
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xs',
      }}
      actions
    />
  );
};

export default UpdateUserModalComponent;
