import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './LandingHeader.module.scss';
import { PropsInterface } from '../../../../interfaces/Props.interface';
import { ReactComponent as LogoDataHubBlack } from '../../../../assets/logo/logoDataHubBlack.svg';

const LandingHeaderComponent: FC<PropsInterface> = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={classes['landing-header']}>
      <div className={classes['landing-header__container']}>
        <h3>{t('landingPage.welcome')}</h3>
        <LogoDataHubBlack className={classes.logo} />
      </div>
    </div>
  );
};

export default LandingHeaderComponent;
