export const InvoiceReceivers = ['resident'] as const;

export type InvoiceReceiversType = (typeof InvoiceReceivers)[number];

export const InvoiceReceiversMap: { [key in InvoiceReceiversType]: number } = {
  resident: 1,
};

export const InvoiceReceiversTranslationMap: { [key in number]: string } = {
  1: 'pluginSettings.sections.uploadHub.subsections.invoiceReceiver.receivers.resident',
};
