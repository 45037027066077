import React, { FC, memo, ReactElement, useMemo } from 'react';
import { Fade, Grid } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import MatchSectionComponent from '../match-section/MatchSection.component';
import { MatchInvoiceFieldsFormPropsInterface } from './interfaces/MatchInvoiceFieldsFormProps.interface';
import { InvoiceItemInterface } from '../../../../../../../../../../../../types/upload-hub/invoice/InvoiceItem.interface';
import { useVerificationFormState } from '../../../../services/VerificationForm.provider';
import useMatchInvoiceFields from '../../hooks/useMatchInvoiceFields.hook';
import PortalComponent from '../../../../../../../../../../../components/portal/Portal.component';
import useDelay from '../../../../../../../../../../../../utils/hooks/useDelay.hook';
import classes from '../../../../Verification.module.scss';

const containerId = 'tags-container';

const MatchInvoiceFieldsForm: FC<MatchInvoiceFieldsFormPropsInterface> = ({
  invoice,
}): ReactElement => {
  const { t } = useTranslation();
  const { isAvailable: areNavButtonsReady } = useDelay();
  const { navigationButtons, verificationContainerRef } = useVerificationFormState();

  const { matchMap, droppableContainer, getTag, onDragEndHandler } = useMatchInvoiceFields(
    invoice,
    containerId,
  );

  const formContent = useMemo(
    () => (
      <Grid
        item
        xs={6}
        className={classes.verification__col}
        data-cy="match-invoice-fields-content"
      >
        <Grid container columnSpacing={0} className={classes.col__inner}>
          <DragDropContext onDragEnd={onDragEndHandler}>
            <Grid item xs={8}>
              <MatchSectionComponent
                fields={invoice.fields}
                title={t(
                  'invoices.tabs.toVerify.verificationModal.steps.matching.sections.invoiceData.title',
                )}
                matchMap={matchMap}
                getTag={getTag}
                data-cy="invoice-data-match-section"
              />

              {invoice.items.length > 0 && (
                <MatchSectionComponent
                  fields={invoice.items.map((item: InvoiceItemInterface) => [...item.fields])}
                  title={t(
                    'invoices.tabs.toVerify.verificationModal.steps.matching.sections.items.title',
                  )}
                  matchMap={matchMap}
                  getTag={getTag}
                  data-cy="invoice-items-match-section"
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  minHeight: '418px',
                  width: '18.86rem',
                }}
              >
                <h6 style={{ marginBottom: '0.6rem' }}>
                  {t(
                    'invoices.tabs.toVerify.verificationModal.steps.matching.sections.tagsContainer.title',
                  )}
                </h6>
                <p style={{ fontSize: '0.7rem', fontStyle: 'italic' }}>
                  {t(
                    'invoices.tabs.toVerify.verificationModal.steps.matching.sections.tagsContainer.subtitle',
                  )}
                </p>
                <Droppable droppableId={containerId}>{droppableContainer}</Droppable>
              </div>
            </Grid>
          </DragDropContext>
        </Grid>
      </Grid>
    ),
    [droppableContainer, getTag, invoice.fields, invoice.items, matchMap, onDragEndHandler, t],
  );

  return (
    <>
      {formContent}

      {areNavButtonsReady && (
        <PortalComponent id="modalActions" anchorEl={verificationContainerRef.current}>
          {navigationButtons && (
            <Fade in={areNavButtonsReady}>
              <Grid item xs={12} width="100%">
                {navigationButtons({
                  step: 'matching',
                  payload: matchMap,
                })}
              </Grid>
            </Fade>
          )}
        </PortalComponent>
      )}
    </>
  );
};

const MatchInvoiceFieldsFormComponent = memo(MatchInvoiceFieldsForm);

export default MatchInvoiceFieldsFormComponent;
