import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import React from 'react';
import { useRequest } from '@datahub/api-client';
import {
  getSynchronizedInboxesQueryKey,
  getUploadHubSettingsQueryKey,
} from '../../../../queries/queryKeys';
import { queryClient } from '../../../../../queryClient';
import { ApiOperation } from '../../../../../api';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { SynchronizedInboxMutationPropsInterface } from './SynchronizedInboxMutationProps.interface';
import useSynchronizedInboxNotFoundErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/useSynchronizedInboxNotFoundError.handler';
import {
  NylasSynchronizedInboxInterface,
  SmtpSynchronizedInboxInterface,
} from '../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';
import { useAlert } from '../../../../../alert/Alert.provider';
import { EmailProviderEnum } from '../../../../../../enums/upload-hub/settings/EmailProvider.enum';
import useNylasAccountNotFoundErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/nylas/useNylasAccountNotFoundError.handler';

const useDeleteSynchronizedInboxMutation = (
  { setOtherError, provider }: SynchronizedInboxMutationPropsInterface = {
    provider: EmailProviderEnum.SMTP,
  },
) => {
  const { showAlert } = useAlert();

  const { request: smtpDelete } = useRequest(ApiOperation.DeleteSynchronizedInbox);
  const { request: nylasDelete } = useRequest(ApiOperation.NylasDeleteAccount);

  const request = provider === EmailProviderEnum.SMTP ? smtpDelete : nylasDelete;

  const notFoundSmtpErrorHandler = useSynchronizedInboxNotFoundErrorHandler(setOtherError);
  const notFoundNylasErrorHandler = useNylasAccountNotFoundErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError(
    [notFoundSmtpErrorHandler, notFoundNylasErrorHandler],
    generalErrorHandler,
  );

  return useMutation(
    async (syncInbox: SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface) => {
      const params =
        provider === EmailProviderEnum.SMTP
          ? {
              urlParams: {
                id: (syncInbox as SmtpSynchronizedInboxInterface).uniqueNumber,
              },
            }
          : {
              data: {
                nylasAccountUniqueNumber: (syncInbox as NylasSynchronizedInboxInterface)
                  .accountUniqueNumber,
              },
            };

      const res = await request(params);

      return res.data;
    },
    {
      onSuccess: (
        _,
        { email }: SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface,
      ) => {
        queryClient.invalidateQueries(getUploadHubSettingsQueryKey);
        queryClient.invalidateQueries(getSynchronizedInboxesQueryKey);

        showAlert(
          <Trans
            i18nKey="pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.deleteSynchronizedInboxesModal.success"
            values={{ email }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useDeleteSynchronizedInboxMutation;
