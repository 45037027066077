import { useRequest } from '@datahub/api-client';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import React from 'react';
import { NylasAuthCodeMutationPropsInterface } from './NylasAuthCodeMutationProps.interface';
import { ApiOperation } from '../../../../../../api';
import useOnError from '../../../../../error-handlers/useOnError';
import useGeneralErrorHandler from '../../../../../error-handlers/useGeneralErrorHandler';
import { NylasAuthCodePayloadInterface } from '../../../../../../../types/upload-hub/synchronized-inboxes/nylas/NylasAuthCodePayload.interface';
import { useAlert } from '../../../../../../alert/Alert.provider';
import { queryClient } from '../../../../../../queryClient';
import {
  getSynchronizedInboxesQueryKey,
  getUploadHubSettingsQueryKey,
} from '../../../../../queries/queryKeys';
import useNylasAccountUniqueErrorHandler from '../../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/nylas/useNylasAccountUniqueError.handler';
import useNylasNotValidAuthorizationCodeErrorHandler from '../../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/nylas/useNylasNotValidAuthorizationCodeError.handler';

const useNylasSendAuthCodeMutation = ({
  setOtherError,
}: NylasAuthCodeMutationPropsInterface = {}) => {
  const { showAlert } = useAlert();
  const { request } = useRequest(ApiOperation.NylasSendAuthCode);

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const nylasAccountUniqueError = useNylasAccountUniqueErrorHandler(setOtherError);
  const nylasNotValidAuthCodeError = useNylasNotValidAuthorizationCodeErrorHandler(setOtherError);

  const { onError } = useOnError(
    [nylasAccountUniqueError, nylasNotValidAuthCodeError],
    generalErrorHandler,
  );

  return useMutation(
    async (payload: NylasAuthCodePayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return res.data;
    },
    {
      onSuccess: (_, { email }: NylasAuthCodePayloadInterface) => {
        queryClient.invalidateQueries(getUploadHubSettingsQueryKey);
        queryClient.invalidateQueries(getSynchronizedInboxesQueryKey);

        showAlert(
          <Trans
            i18nKey="pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.addSynchronizedInboxesModal.success"
            values={{ email }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useNylasSendAuthCodeMutation;
