import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import { getResidentWorkOrdersQueryKey } from '../../queryKeys';
import { ResidentWorkOrdersResponseType } from '../../../../../types/upload-hub/resident/work-orders/ResidentWorkOrdersResponse.type';

const useGetResidentWorkOrdersQuery = (supplierId: string = '', propertyId: string = '') => {
  const { request } = useRequest<ResidentWorkOrdersResponseType>(
    ApiOperation.GetResidentWorkOrders,
  );

  return useQuery(
    [getResidentWorkOrdersQueryKey, propertyId],
    async () => {
      const res = await request({
        params: {
          PropertyId: propertyId,
          SupplierId: supplierId,
        },
      });

      return res.data.responseModel.residentWorkOrders;
    },
    {
      enabled: false,
    },
  );
};

export default useGetResidentWorkOrdersQuery;
