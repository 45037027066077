import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import { getUploadHubSettingsQueryKey } from '../../queryKeys';
import { UploadHubSettingsResponseType } from '../../../../../types/upload-hub/settings/UploadHubSettingsResponse.type';

const useGetUploadHubSettingsQuery = () => {
  const { request } = useRequest<UploadHubSettingsResponseType>(ApiOperation.GetUploadHubSettings);

  return useQuery(getUploadHubSettingsQueryKey, async () => {
    const res = await request();

    return res.data.responseModel;
  });
};

export default useGetUploadHubSettingsQuery;
