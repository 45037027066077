import React from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { UserMutationPropsInterface } from './interfaces/UserMutationProps.interface';
import { ApiOperation } from '../../../api';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useAgencyNotFoundErrorHandler from '../../error-handlers/user/useAgencyNotFoundError.handler';
import useOnError from '../../error-handlers/useOnError';
import { UserInvitePayloadInterface } from '../../../../types/users/UserInvitePayload.interface';
import { useAlert } from '../../../alert/Alert.provider';
import { UserInterface } from '../../../../types/users/User.interface';

interface InviteUserPayloadInterface {
  payload: UserInvitePayloadInterface;
  user?: UserInterface;
}

const useInviteUserMutation = ({ setOtherError }: UserMutationPropsInterface = {}) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.InviteUser);

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const agencyNotFoundErrorHandler = useAgencyNotFoundErrorHandler(setOtherError);

  const { onError } = useOnError([agencyNotFoundErrorHandler], generalErrorHandler);

  return useMutation(
    async ({ payload }: InviteUserPayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return res.data;
    },
    {
      onSuccess: (_, { user }) => {
        // TODO: invalidate whole user list or single user row when invitations status is available in API
        // queryClient.invalidateQueries(getUsersQueryKey);

        if (user) {
          const { fullName: name, email } = user;

          showAlert(
            <Trans
              i18nKey="settings.sections.permission.subsections.user.actions.invite.success.single"
              values={{ name, email }}
              components={{ bold: <strong /> }}
            />,
            'success',
          );
        } else {
          showAlert(
            t('settings.sections.permission.subsections.user.actions.invite.success.multi'),
            'success',
          );
        }
      },
      onError,
    },
  );
};

export default useInviteUserMutation;
