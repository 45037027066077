import React, { useCallback, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import CurrencyType from '../../../types/Currency.type';
import getCurrencyConfig from './getCurrencyConfig';

const useCurrency = (cur?: CurrencyType | undefined) => {
  const currency = cur || ('pound' as CurrencyType | undefined);

  const config = useMemo(() => getCurrencyConfig(currency), [currency]);

  const renderCurrency = useCallback(
    (value: number, explicitCurrency?: CurrencyType | undefined) => {
      let currencySymbolProps = {};
      const renderConfig = explicitCurrency ? getCurrencyConfig(explicitCurrency) : config;

      const whitespace = renderConfig.whitespace ? ' ' : '';

      if (renderConfig.symbol) {
        currencySymbolProps = {
          ...(renderConfig.symbolPosition === 'start'
            ? { prefix: `${renderConfig.symbol}${whitespace}` }
            : { suffix: `${whitespace}${renderConfig.symbol}` }),
        };
      }
      return (
        <NumericFormat
          value={value}
          displayType="text"
          fixedDecimalScale
          decimalScale={2}
          decimalSeparator={renderConfig.decimalSeparator}
          thousandSeparator={renderConfig.thousandSeparator}
          {...currencySymbolProps}
        />
      );
    },
    [config],
  );

  return { renderCurrency, config };
};

export default useCurrency;
