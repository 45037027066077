import { ApiError } from '@datahub/api-client';
import ErrorHandled from './ErrorHandled.enum';
import ErrorHandlerType from './ErrorHandler.type';

const useOnError = (handlers: ErrorHandlerType[], defaultHandler: ErrorHandlerType) => {
  const onError = (error: ApiError<any>) => {
    const handled = handlers.some((handler: ErrorHandlerType) => {
      return handler(error) === ErrorHandled.HANDLED;
    });

    if (!handled) {
      defaultHandler(error);
    }
  };

  return { onError };
};

export default useOnError;
