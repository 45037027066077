import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import styles from './Main.module.scss';
import useAuth from '../services/auth/Auth.provider';
import HeaderComponent from './components/header/Header.component';
import RouterOutlet from './components/router/Router.outlet';
import { useSubscription } from '../services/subscription/Subscription.provider';
import { SubscriptionStatusEnum } from '../enums/subscription/SubscriptionStatus.enum';
import { useUserPermissions } from '../services/user/User.provider';

const MainAbstract: FC = (): ReactElement => {
  const { isAuthenticated } = useAuth();
  const { configuration, status } = useSubscription();
  const { hasAnyPermission, permissions } = useUserPermissions();

  const isSubscriptionLoaded = isAuthenticated && configuration;
  const isPermissionsLoaded = isAuthenticated && permissions;

  const isHeaderActive =
    isSubscriptionLoaded &&
    status === SubscriptionStatusEnum.ACTIVE &&
    isPermissionsLoaded &&
    hasAnyPermission;

  const isAppDisabled =
    (isSubscriptionLoaded &&
      [
        SubscriptionStatusEnum.NOT_FOUND,
        SubscriptionStatusEnum.DISABLED,
        SubscriptionStatusEnum.EXPIRED,
        SubscriptionStatusEnum.ERROR,
      ].includes(status)) ||
    (isPermissionsLoaded && !hasAnyPermission);

  return (
    <div className={styles.app}>
      {isHeaderActive && <HeaderComponent className={styles.app__header} />}
      <main
        className={classNames(styles.app__main, isAppDisabled ? styles['app__main--disabled'] : '')}
      >
        <RouterOutlet />
      </main>
      <footer className={styles.app__footer} />
    </div>
  );
};

export default MainAbstract;
