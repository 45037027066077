import React, { memo, useMemo } from 'react';
import { FieldValues, Controller, useFormContext } from 'react-hook-form';
import { FormControl } from '../../../../../../../../../../../components/material';
import DatePicker from '../../../../../../../../../../../components/material/date-picker/DatePicker.component';

// @ts-ignore
const DateControllerComponent = <TForm extends FieldValues>({
  // @ts-ignore
  name,
  // @ts-ignore
  error,
  required = false,
  label = '',
  disabled = false,
  disablePast = false,
  // @ts-ignore
  'data-cy': dataCy = '',
}) => {
  const { control } = useFormContext<TForm>();

  const dateController = useMemo(
    () => (
      <Controller
        name={name}
        control={control}
        render={({ field: fieldProps }) => (
          <DatePicker
            {...fieldProps}
            id={name}
            value={fieldProps.value ? (fieldProps.value as Date) : null}
            error={!!error}
            format="dd/MM/yyyy"
            label={label}
            required={required}
            disabled={disabled}
            disablePast={disablePast}
            data-cy={`${dataCy}-input`}
          />
        )}
      />
    ),
    [control, dataCy, disablePast, disabled, error, label, name, required],
  );

  return (
    <FormControl error={!!error} data-cy={`${dataCy}-controller`}>
      {dateController}
      <span role="alert">{(error as any)?.message}</span>
    </FormControl>
  );
};

const DateController = memo(DateControllerComponent);

export default DateController;
