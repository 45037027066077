import React, { useCallback, useMemo, useState } from 'react';
import DeleteInvoiceModalComponent from '../delete-invoice/DeleteInvoiceModal.component';
import BulkDeleteInvoicesModalComponent from '../bulk-delete-invoices/BulkDeleteInvoicesModal.component';
import { BaseProcessedInvoiceInterface } from '../../../../../../../types/upload-hub/invoice/common/BaseProcessedInvoice.interface';
import { InvoiceFileInterface } from '../../../../../../../types/upload-hub/invoice/InvoiceFile.interface';

const useDeleteInvoice = (reloadQuery: string) => {
  const [deleteInvoiceOpen, setDeleteInvoiceOpen] = useState(false);
  const [bulkDeleteInvoiceOpen, setBulkDeleteInvoiceOpen] = useState(false);
  const [deleteInvoiceData, setDeleteInvoiceData] = useState<
    BaseProcessedInvoiceInterface | InvoiceFileInterface | undefined
  >(undefined);
  const [bulkDeleteInvoiceData, setBulkDeleteInvoiceData] = useState<string[]>([]);
  const [deleteMutationPromise, setDeleteMutationPromise] = useState<
    Promise<unknown> | undefined
  >();

  const onDeleteMutateHandler = useCallback((p: Promise<unknown>): void => {
    setDeleteMutationPromise(p);
  }, []);

  const deleteModal = useMemo(() => {
    if (!deleteInvoiceData && !bulkDeleteInvoiceData) {
      return <> </>;
    }

    return (
      <>
        <DeleteInvoiceModalComponent
          open={deleteInvoiceOpen}
          setOpen={setDeleteInvoiceOpen}
          invoice={deleteInvoiceData}
          reloadQuery={reloadQuery}
          onMutate={onDeleteMutateHandler}
        />

        <BulkDeleteInvoicesModalComponent
          open={bulkDeleteInvoiceOpen}
          setOpen={setBulkDeleteInvoiceOpen}
          invoiceIds={bulkDeleteInvoiceData}
          reloadQuery={reloadQuery}
          onMutate={onDeleteMutateHandler}
        />
      </>
    );
  }, [
    deleteInvoiceData,
    bulkDeleteInvoiceData,
    deleteInvoiceOpen,
    bulkDeleteInvoiceOpen,
    reloadQuery,
    onDeleteMutateHandler,
  ]);

  return {
    deleteModal,
    setDeleteInvoiceOpen,
    setDeleteInvoiceData,
    setBulkDeleteInvoiceData,
    setBulkDeleteInvoiceOpen,
    deleteMutationPromise,
  };
};

export default useDeleteInvoice;
