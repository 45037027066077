import React, { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, Input } from '../../../../../../../../../../../components/material';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';

// @ts-ignore
const InputControllerComponent = ({
  // @ts-ignore
  name,
  // @ts-ignore
  error,
  disabled = false,
  width = '100%',
  // @ts-ignore
  'data-cy': dataCy = '',
}) => {
  const { control } = useFormContext<VerifyInvoiceDataFormInterface>();

  const inputController = useMemo(
    () => (
      <Controller
        name={name}
        control={control}
        render={({ field: fieldProps }) => (
          <Input
            {...fieldProps}
            error={!!error}
            fullWidth
            disabled={disabled}
            data-cy={`${dataCy}-input`}
          />
        )}
      />
    ),
    [control, dataCy, disabled, error, name],
  );

  return (
    <FormControl error={!!error} style={{ width }} data-cy={`${dataCy}-controller`}>
      {inputController}
      <span role="alert">{(error as any)?.message}</span>
    </FormControl>
  );
};

const InputController = memo(InputControllerComponent);

export default InputController;
