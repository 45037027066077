import React, {
  createContext,
  FC,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { formatISO, isDate } from 'date-fns';
import { UseFormSetError } from 'react-hook-form';
import useUpdateInvoiceMatchingMutation from '../../../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/useUpdateInvoiceMatching.mutation';
import useUpdateInvoiceMutation from '../../../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/useUpdateInvoice.mutation';
import useStepsNavigationHook from '../../../../../../../../../utils/hooks/steps-navigation/useStepsNavigation.hook';
import { ModalStepInterface } from '../../../../../../../../components/modal/interfaces/ModalStep.interface';
import MatchInvoiceFieldsComponent from '../components/match-invoice-fields/MatchInvoiceFields.component';
import VerifyInvoiceDataComponent from '../components/verify-invoice-data/VerifyInvoiceData.component';
import { VerificationFormProviderPropsInterface } from './interfaces/VerificationFormProviderProps.interface';
import { VerificationFormPayloadInterface } from './interfaces/VerificationFormPayload.interface';
import { VerifyInvoiceDataFormInterface } from '../components/verify-invoice-data/interfaces/VerifyInvoiceDataForm.interface';
import useGetInvoiceStateQuery from '../../../../../../../../../services/react-query-hooks/queries/upload-hub/invoice/useGetInvoiceState.query';
import { useInvoicesContext } from '../../../../../Invoices.component';
import { InvoiceStatusEnum } from '../../../../../../../../../enums/upload-hub/InvoiceStatus.enum';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';
import useApprovalButtons from '../../../../to-approve/hooks/approval-buttons/useApprovalButtons.hook';
import useRejectInvoice from '../../../../reject/hooks/useRejectInvoice.hook';
import { InvoiceRejectType } from '../../../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/reject/interfaces/InvoiceRejectMutationProps.interface';
import { InvoiceUpdateItemInterface } from '../../../../../../../../../types/upload-hub/invoice/verification/InvoiceUpdatePayload.interface';
import useApproveInvoiceMutation from '../../../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/useApproveInvoice.mutation';
import { queryClient } from '../../../../../../../../../services/queryClient';
import { getToApproveInvoicesQueryKey } from '../../../../../../../../../services/react-query-hooks/queries/queryKeys';
import useVerificationButtons from '../components/verify-invoice-data/hooks/useVerificationButtons.hook';
import { useThresholdVerification } from './hooks/ThresholdVerification.hook';
import { useUploadHub } from '../../../../../providers/UploadHub.provider';

// TODO: typing
const VerificationFormContext = createContext<any>({});

const joinChar = (val: string, char: string): string => {
  return val.includes(char) ? val : `${val}${char}`;
};

const mapVerificationFormData = (
  formData: UseFormReturn<VerifyInvoiceDataFormInterface>,
): {
  invoiceUpdateData: InvoiceUpdateItemInterface[];
  invoiceItemsUpdateData: InvoiceUpdateItemInterface[][];
} => {
  const verificationFormObj = (
    formData as UseFormReturn<VerifyInvoiceDataFormInterface>
  ).getValues();

  const invoiceDataTypes = [
    ResidentInvoiceDataEnum.INVOICE_REFERENCE,
    ResidentInvoiceDataEnum.PROPERTY,
    ResidentInvoiceDataEnum.BANK_ACCOUNT,
    ResidentInvoiceDataEnum.INVOICE_DATE,
    ResidentInvoiceDataEnum.PAYMENT_DUE_DATE,
    ResidentInvoiceDataEnum.PERIOD_START,
    ResidentInvoiceDataEnum.PERIOD_END,
    ResidentInvoiceDataEnum.WORKS_ORDER,
    ResidentInvoiceDataEnum.TOTAL_AMOUNT,
  ];
  const invoiceDataItemTypes = [
    ResidentInvoiceDataEnum.ITEM_DESCRIPTION,
    ResidentInvoiceDataEnum.ITEM_CATEGORY,
    ResidentInvoiceDataEnum.ITEM_AMOUNT,
    ResidentInvoiceDataEnum.ITEM_VAT,
    ResidentInvoiceDataEnum.ITEM_TYPE,
    ResidentInvoiceDataEnum.ITEM_SCHEDULE,
  ];
  const mapEntryToPayloadItem = (entry: any) => {
    const val = entry[1]?.value !== undefined ? entry[1].value : entry[1];
    const formatVal = (v: any) => {
      if (!isDate(v)) {
        return val;
      }

      const dateISOParts = formatISO(v).split('T');

      if (dateISOParts.length < 2) {
        return dateISOParts[0] || val;
      }

      if (dateISOParts[1].includes('+')) {
        return joinChar([dateISOParts[0], dateISOParts[1].split('+')[0]].join('T'), 'Z');
      }

      if (dateISOParts[1].includes('-')) {
        return joinChar([dateISOParts[0], dateISOParts[1].split('-')[0]].join('T'), 'Z');
      }

      return joinChar(dateISOParts.join('T'), 'Z');
    };

    return {
      residentFieldName: Number(entry[0]),
      value: val === null ? '' : formatVal(val),
    };
  };

  const formEntries = Object.entries(verificationFormObj);
  const invoiceData = formEntries
    .filter((entry) => invoiceDataTypes.includes(Number(entry[0])) && !!entry[1])
    .map(mapEntryToPayloadItem);
  const invoiceDataItems = verificationFormObj.items.map((item: any) => {
    return Object.entries(item)
      .filter((entry) => invoiceDataItemTypes.includes(Number(entry[0])) && !!entry[1])
      .map(mapEntryToPayloadItem);
  });

  return {
    invoiceUpdateData: invoiceData,
    invoiceItemsUpdateData: invoiceDataItems,
  };
};

export const VerificationFormProvider: FC<
  PropsWithChildren<VerificationFormProviderPropsInterface>
> = ({ id, fileName, invoiceReference, step, setOpen, children }): ReactElement => {
  const { t } = useTranslation();
  const { getThresholdData } = useThresholdVerification();
  const { isResident } = useUploadHub();
  const verificationContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [isFormDirty, setIsFormDirty] = useState(false);

  const closeMainModalHandler = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const [confirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false);

  const closeHandler = useCallback(() => {
    if (confirmCloseModalOpen || !isFormDirty) {
      closeMainModalHandler();
    } else {
      setConfirmCloseModalOpen(true);
    }
  }, [confirmCloseModalOpen, isFormDirty, closeMainModalHandler]);
  const confirmCloseHandler = useCallback(() => {
    closeMainModalHandler();
    setConfirmCloseModalOpen(false);
  }, [closeMainModalHandler]);

  const [error, setError] = useState('');
  const [validationErrorSetter, setValidationErrorSetter] = useState<
    UseFormSetError<VerifyInvoiceDataFormInterface> | (() => void)
  >(() => {});

  const { mutateAsync: updateInvoiceMatching, isLoading: isUpdateMatchingLoading } =
    useUpdateInvoiceMatchingMutation({
      setOtherError: setError,
    });

  const {
    mutateAsync: updateInvoiceVerification,
    isLoading: isUpdateInvoiceVerificationLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdateInvoiceMutation({
    setValidationError: validationErrorSetter,
    setOtherError: setError,
  });

  const { refetch: fetchInvoiceState } = useGetInvoiceStateQuery(id);
  const [, , , setDuplicatesInvoiceDataId, setIsDuplicatesModalOpen] = useInvoicesContext();

  const {
    mutate: approveInvoice,
    isLoading: isApproveLoading,
    isSuccess: isApproveSuccess,
  } = useApproveInvoiceMutation({
    setOtherError: setError,
  });
  const {
    rejectModal,
    setRejectInvoiceOpen,
    setRejectInvoiceData,
    error: rejectError,
    isSuccess: isRejectSuccess,
    isLoading: isRejectLoading,
  } = useRejectInvoice();

  const verificationSteps: ModalStepInterface[] = [
    {
      name: 'matching',
      title: t('invoices.tabs.toVerify.verificationModal.steps.matching.title'),
      content: <MatchInvoiceFieldsComponent id={id} />,
      nextStepButtonVariant: 'contained',
      initial: step === 'matching',
    },
    {
      name: 'verification',
      title: t('invoices.tabs.toVerify.verificationModal.steps.verification.title'),
      content: (
        <VerifyInvoiceDataComponent
          id={id}
          onClose={closeMainModalHandler}
          variant="verification"
        />
      ),
      previousStepButtonLabel: t(
        'invoices.tabs.toVerify.verificationModal.steps.verification.rematch.button',
      ),
      initial: step === 'verification',
      isLastStep: true,
    },
    {
      name: 'approval',
      title: t('invoices.tabs.toVerify.verificationModal.steps.approval.title'),
      content: (
        <VerifyInvoiceDataComponent id={id} onClose={closeMainModalHandler} variant="approval" />
      ),
      initial: step === 'approval',
      isFirstStep: true,
    },
  ];

  const onFormSubmitHandler = useCallback(
    (payload?: VerificationFormPayloadInterface): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (!payload) {
          reject();
          return;
        }

        setError('');
        const { step: currentStep, payload: formData, skipApprovalFlow } = payload;

        if (currentStep === 'matching') {
          updateInvoiceMatching({
            invoiceId: id,
            fileName,
            matchingResult: Object.fromEntries(formData as Map<string, number>),
          }).then(() => {
            resolve();
            setIsFormDirty(false);
          });
        }

        if (currentStep === 'verification') {
          (formData as UseFormReturn<VerifyInvoiceDataFormInterface>).handleSubmit(
            () => {
              const { invoiceUpdateData, invoiceItemsUpdateData } = mapVerificationFormData(
                formData as UseFormReturn<VerifyInvoiceDataFormInterface>,
              );

              if (isResident) {
                const { amount: invoiceAmount, property: invoiceProperty } =
                  getThresholdData(invoiceUpdateData);

                if (
                  invoiceAmount !== undefined &&
                  invoiceAmount > 0 &&
                  invoiceProperty?.threshold &&
                  invoiceProperty.threshold < invoiceAmount &&
                  skipApprovalFlow
                ) {
                  validationErrorSetter(
                    `${ResidentInvoiceDataEnum.TOTAL_AMOUNT}`,
                    {
                      message: t(
                        'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.totalAmount.errors.thresholdExceeded',
                      ),
                    },
                    { shouldFocus: true },
                  );
                  reject();
                  return;
                }
              }

              updateInvoiceVerification({
                invoiceId: id,
                skipApprovalFlow,
                invoiceUpdateData,
                invoiceItemsUpdateData,
              })
                .then(async () => {
                  queryClient.invalidateQueries(getToApproveInvoicesQueryKey).then();

                  const invoiceState = await fetchInvoiceState();

                  if (invoiceState.data?.state === InvoiceStatusEnum.DUPLICATES) {
                    setDuplicatesInvoiceDataId(id);
                    setIsDuplicatesModalOpen(true);
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            },
            (errors) => {
              reject(errors);
              reject();
            },
          )();
        }

        if (currentStep === 'approval') {
          (formData as UseFormReturn<VerifyInvoiceDataFormInterface>).handleSubmit(
            () => {
              const { invoiceUpdateData, invoiceItemsUpdateData } = mapVerificationFormData(
                formData as UseFormReturn<VerifyInvoiceDataFormInterface>,
              );

              const approveInvoiceHandler = () => {
                approveInvoice({
                  invoiceId: id,
                  invoiceReference: invoiceReference || fileName || '',
                });

                resolve();
              };

              if (isFormDirty) {
                updateInvoiceVerification({
                  invoiceId: id,
                  skipApprovalFlow,
                  invoiceUpdateData,
                  invoiceItemsUpdateData,
                })
                  .then(() => {
                    approveInvoiceHandler();
                  })
                  .catch(() => {
                    reject();
                  });
              } else {
                approveInvoiceHandler();
              }
            },
            (errors) => {
              reject(errors);
            },
          )();
        }
      });
    },
    [
      updateInvoiceMatching,
      id,
      fileName,
      getThresholdData,
      isResident,
      updateInvoiceVerification,
      validationErrorSetter,
      t,
      fetchInvoiceState,
      setDuplicatesInvoiceDataId,
      setIsDuplicatesModalOpen,
      isFormDirty,
      approveInvoice,
      invoiceReference,
    ],
  );

  const { currentStep, navigationButtons, onPreviousStepClick } = useStepsNavigationHook<
    ModalStepInterface,
    VerificationFormPayloadInterface
  >({
    isLoading: isUpdateMatchingLoading,
    steps: verificationSteps,
    onClose: closeHandler,
    onSubmit: onFormSubmitHandler,
    onNextStepClick: onFormSubmitHandler,
  });

  const { verificationButtons } = useVerificationButtons({
    id,
    isLoading: isUpdateInvoiceVerificationLoading,
    onClose: closeHandler,
    onSubmit: onFormSubmitHandler,
    onPreviousStep: onPreviousStepClick,
  });

  const onRejectHandler = useCallback(
    (variant: InvoiceRejectType) => {
      setRejectInvoiceData({
        invoiceId: id,
        invoiceReference: invoiceReference || fileName || '',
        variant,
      });

      setRejectInvoiceOpen(true);
    },
    [fileName, id, invoiceReference, setRejectInvoiceData, setRejectInvoiceOpen],
  );

  const { approvalButtons } = useApprovalButtons<VerificationFormPayloadInterface>({
    id,
    isLoading: isApproveLoading || isUpdateInvoiceVerificationLoading,
    onClose: closeHandler,
    onSubmit: onFormSubmitHandler,
    onReject: onRejectHandler,
  });

  useEffect(() => {
    switch (step) {
      case 'approval': {
        if (isApproveSuccess || isRejectSuccess) {
          closeMainModalHandler();
        }

        break;
      }

      default: {
        if (isUpdateSuccess) {
          closeMainModalHandler();
        }
      }
    }
  }, [closeMainModalHandler, isUpdateSuccess, isRejectSuccess, step, isApproveSuccess]);

  const memoizedVerificationFormState = useMemo(
    () => ({
      id,
      fileName,
      verificationContainerRef,
      currentStep,
      navigationButtons,
      verificationButtons,
      approvalButtons,
      onFormSubmitHandler,
      setIsFormDirty,
      setValidationErrorSetter,
      error: error || rejectError,
      isLoading:
        isUpdateMatchingLoading ||
        isUpdateInvoiceVerificationLoading ||
        isRejectLoading ||
        isApproveLoading,
      closeHandler,
      confirmCloseHandler,
      confirmCloseModalOpen,
      setConfirmCloseModalOpen,
      rejectModal,
    }),
    [
      id,
      fileName,
      currentStep,
      navigationButtons,
      verificationButtons,
      approvalButtons,
      onFormSubmitHandler,
      error,
      rejectError,
      isUpdateMatchingLoading,
      isUpdateInvoiceVerificationLoading,
      isRejectLoading,
      isApproveLoading,
      closeHandler,
      confirmCloseHandler,
      confirmCloseModalOpen,
      rejectModal,
    ],
  );

  return (
    <VerificationFormContext.Provider value={memoizedVerificationFormState}>
      {children}
    </VerificationFormContext.Provider>
  );
};

export function useVerificationFormState() {
  return useContext(VerificationFormContext);
}
