import React, { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListItem } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavigationLinkInterface } from '../../interfaces/NavigationLink.interface';
import { IconButton, MenuItem } from '../../../material';
import classes from './NavigationLinks.module.scss';
import { NavigationLinksProps } from './interfaces/NavigationLinksProps.interface';
import { useSubscription } from '../../../../../services/subscription/Subscription.provider';
import { PluginInterface } from '../../../../../types/plugin/Plugin.interface';
import { pathTo } from '../../../router/Router.outlet';
import { useUserPermissions } from '../../../../../services/user/User.provider';

const NavigationLinks: FC<NavigationLinksProps> = ({
  className,
  variant = 'just-icons',
}): ReactElement => {
  const { hasPlugins, pluginsTypes } = useSubscription();
  const { hasPermission } = useUserPermissions();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!hasPlugins) {
    return <> </>;
  }

  const navigationLinks = pluginsTypes
    .map((plugin: PluginInterface): NavigationLinkInterface => {
      return {
        // @ts-ignore
        name: t(`navigation.${plugin.label}`),
        href: pathTo([plugin.basePath]),
        icon: plugin.icon,
        disabled: !plugin.allowedPermissions.some(hasPermission),
      };
    })
    .filter((navLink: NavigationLinkInterface) => !navLink.disabled);

  return (
    <List className={classNames(classes['navigation-links'], className)}>
      {navigationLinks.map((item: NavigationLinkInterface) => {
        return variant === 'just-icons' ? (
          <ListItem
            key={item.name}
            onClick={() => navigate(item.href)}
            className={classes['MuiListItem-root']}
          >
            <IconButton size="large" aria-label={item.name}>
              {item.icon}
            </IconButton>
          </ListItem>
        ) : (
          <MenuItem key={item.name} onClick={() => navigate(item.href)}>
            {item.icon}
            <span>{item.name}</span>
          </MenuItem>
        );
      })}
    </List>
  );
};

export default NavigationLinks;
