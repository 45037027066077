import React, { FC, ReactElement } from 'react';
import { FormGroup, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import EmailInputComponent from '../../../../EmailInput.component';
import { FormControl, Button } from '../../../../../../../components/material';
import { ForgotPasswordFormDataInterface } from '../../interfaces/ForgotPasswordFormData.interface';
import useAuth from '../../../../../../../../services/auth/Auth.provider';
import { ForgotPasswordPropsInterface } from '../../interfaces/ForgotPasswordProps.interface';
import { useEmailValidator } from '../../../../validators/Email.validator';
import { LoginLoadingEnum } from '../../../../../../../../services/auth/enums/LoginLoading.enum';

const ResetPasswordComponent: FC<ForgotPasswordPropsInterface> = ({ onSubmit }): ReactElement => {
  const { loading } = useAuth();
  const { t } = useTranslation();
  const { options } = useEmailValidator();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormDataInterface>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Grid container direction="column" rowSpacing={4}>
          <Grid item>
            <FormControl>
              <EmailInputComponent error={!!errors.email} {...register('email', options)} />
              <span role="alert">{errors.email?.message}</span>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              loading={loading === LoginLoadingEnum.RESET}
              fullWidth
            >
              {t('landingPage.loginForm.resetEmail')}
            </Button>
          </Grid>
        </Grid>
      </FormGroup>
    </form>
  );
};

export default ResetPasswordComponent;
