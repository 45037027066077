import { Fade, Grid } from '@mui/material';
import React, { MutableRefObject, PropsWithChildren, useEffect, useRef } from 'react';
import { FieldValues, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useSubmitButtonsHook from '../../../../utils/hooks/useSubmitButtons.hook';
import PortalComponent from '../../portal/Portal.component';
import useDelay from '../../../../utils/hooks/useDelay.hook';
import classes from './FormModal.module.scss';
import { FormModalPropsInterface } from './interfaces/FormModalProps.interface';

const FormModalComponent = <T extends FieldValues>({
  children,
  close,
  submit,
  submitText,
  submitIcon,
  methods,
  isSuccess,
  isLoading,
  disablePortal,
  ...rest
}: PropsWithChildren<FormModalPropsInterface<T>>) => {
  const { t } = useTranslation();
  const { isAvailable: areButtonsReady } = useDelay();
  const formModalContainerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [close, isSuccess]);

  const { buttons } = useSubmitButtonsHook({
    isLoading,
    close,
    handleSubmit: submit,
    submitText: submitText || t('global.save'),
    submitIcon,
  });

  return (
    <form
      className={classNames(classes['form-modal'], {
        [classes['form-modal--no-fade']]: disablePortal,
      })}
      data-cy={rest['data-cy']}
    >
      <Grid container ref={formModalContainerRef}>
        <FormProvider {...methods}>{children}</FormProvider>
        {disablePortal && (
          <Grid item xs={12} width="100%" className={classes['form-modal__buttons']}>
            {buttons()}
          </Grid>
        )}

        {!disablePortal && areButtonsReady && (
          <PortalComponent id="modalActions" anchorEl={formModalContainerRef.current}>
            <Fade in={areButtonsReady}>
              <Grid item xs={12} width="100%" className={classes['form-modal__buttons']}>
                {buttons()}
              </Grid>
            </Fade>
          </PortalComponent>
        )}
      </Grid>
    </form>
  );
};

export default FormModalComponent;
