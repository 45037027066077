import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../api';
import { UsersResponseType } from '../../../../types/users/UsersResponse.type';
import { getUsersQueryKey } from '../queryKeys';
import { TableFiltersInterface } from '../../../../views/components/table/interfaces/TableFilters.interface';
import usePaginatedListQuery from '../../pagination/usePaginatedList.query';

const useGetUsersListQuery = (filters?: TableFiltersInterface) => {
  const { request } = useRequest<UsersResponseType>(ApiOperation.GetUsers);
  const { data, ...rest } = usePaginatedListQuery<UsersResponseType>(
    request,
    getUsersQueryKey,
    filters,
  );

  return {
    data: data?.responseModel,
    ...rest,
  };
};

export default useGetUsersListQuery;
