import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import useInvoiceUploadMutation from '../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/upload/useInvoiceUpload.mutation';
import InvoiceFileInterface from '../../../../../../../types/invoice-upload/InvoiceFile.interface';
import InvoiceUploadStatusType from '../../../../../../../types/invoice-upload/InvoiceUploadStatus.type';
import ConfirmModalComponent from '../../../../../../components/modal/confirm-modal/ConfirmModal.component';
import ProgressModalComponent from '../components/progress-modal/ProgressModal.component';
import { queryClient } from '../../../../../../../services/queryClient';
import {
  getInProgressInvoicesQueryKey,
  getToApproveInvoicesQueryKey,
  getToVerifyInvoicesQueryKey,
} from '../../../../../../../services/react-query-hooks/queries/queryKeys';

const useUploadInvoicesHook = () => {
  const { t } = useTranslation();
  const { mutateAsync } = useInvoiceUploadMutation();

  const [modalOpen, setModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [files, setFiles] = useState<InvoiceFileInterface[]>([]);

  const modalOpenRef = useRef(modalOpen);
  const filesRef = useRef(files);
  modalOpenRef.current = modalOpen;
  filesRef.current = files;

  useEffect(() => {
    modalOpenRef.current = modalOpen;
    filesRef.current = files;
  }, [files, modalOpen]);

  const inProgress = files.some(
    (file: InvoiceFileInterface) => file.status === 'queue' || file.status === 'uploading',
  );

  const setNewFileStatus =
    (uuid: string, status: InvoiceUploadStatusType) => (oldFiles: InvoiceFileInterface[]) => {
      const index = oldFiles.findIndex((file: InvoiceFileInterface) => file.uuid === uuid);
      const newFiles = [...oldFiles];

      if (index !== -1) {
        newFiles[index].status = status;
      }

      return newFiles;
    };

  const uploadFiles = useCallback(
    async (filesToUpload: InvoiceFileInterface[]) => {
      setFiles(filesToUpload);
      setModalOpen(true);
      modalOpenRef.current = true;
      filesRef.current = filesToUpload;
      // eslint-disable-next-line no-restricted-syntax
      for await (const file of filesToUpload) {
        if (modalOpenRef.current && filesRef.current.includes(file)) {
          setFiles(setNewFileStatus(file.uuid, 'uploading'));
          await mutateAsync(file.file)
            .then(() => {
              setFiles(setNewFileStatus(file.uuid, 'done'));
            })
            .catch(() => {
              setFiles(setNewFileStatus(file.uuid, 'error'));
            });
        }
      }
    },
    [mutateAsync],
  );

  const close = useCallback(() => {
    queryClient.invalidateQueries(getInProgressInvoicesQueryKey);
    queryClient.invalidateQueries(getToVerifyInvoicesQueryKey);
    queryClient.invalidateQueries(getToApproveInvoicesQueryKey);

    setModalOpen(false);
    setCloseModalOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    if (!inProgress) {
      close();
    } else {
      setCloseModalOpen(true);
    }
  }, [close, inProgress]);

  const upload = useCallback(
    (filesToUpload: File[]) => {
      const queue = filesToUpload.map((file: File) => ({
        uuid: uuidv4(),
        file,
        status: 'queue' as InvoiceUploadStatusType,
      }));
      uploadFiles(queue);
    },
    [uploadFiles],
  );

  const modals = useMemo(
    () => (
      <>
        <ProgressModalComponent
          open={modalOpen}
          handleClose={handleClose}
          files={files}
          inProgress={inProgress}
        />
        <ConfirmModalComponent
          text={t('invoices.invoiceUpload.modal.confirmModal.content')}
          title={t('invoices.invoiceUpload.modal.confirmModal.title')}
          open={closeModalOpen}
          close={() => setCloseModalOpen(false)}
          confirm={close}
          variant="warning"
        />
      </>
    ),
    [close, closeModalOpen, files, handleClose, inProgress, modalOpen, t],
  );

  return { modals, upload };
};

export default useUploadInvoicesHook;
