import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useDeleteSynchronizedInboxMutation from '../../../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/synchronized-inboxes/useDeleteSynchronizedInbox.mutation';
import DeleteModalComponent from '../../../../../../../components/modal/delete-modal/DeleteModal.component';
import {
  NylasSynchronizedInboxInterface,
  SmtpSynchronizedInboxInterface,
} from '../../../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';
import { EmailProviderEnum } from '../../../../../../../../enums/upload-hub/settings/EmailProvider.enum';

interface DeleteSynchronizedInboxPropsInterface {
  syncInbox: SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface;
  provider: EmailProviderEnum;
  close: () => void;
}

const DeleteSynchronizedInbox: FC<DeleteSynchronizedInboxPropsInterface> = ({
  syncInbox,
  provider,
  close,
}) => {
  const { t } = useTranslation();
  const {
    mutate: deleteSyncInbox,
    isLoading,
    isSuccess,
  } = useDeleteSynchronizedInboxMutation({ provider });

  return (
    <DeleteModalComponent
      text={t(
        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.deleteSynchronizedInboxesModal.message',
      )}
      close={close}
      isLoading={isLoading}
      isSuccess={isSuccess}
      mutate={() => deleteSyncInbox(syncInbox)}
    />
  );
};

export default DeleteSynchronizedInbox;
