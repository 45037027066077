import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../api';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { queryClient } from '../../../../../queryClient';
import {
  getProcessedInvoicesQueryKey,
  getToApproveInvoicesQueryKey,
  getToVerifyInvoicesQueryKey,
} from '../../../../queries/queryKeys';
import { rejectInvoiceMutationKey } from '../../../mutationKeys';
import { InvoiceMutationPropsInterface } from '../InvoiceMutationProps.interface';
import { useAlert } from '../../../../../alert/Alert.provider';
import { InvoiceRejectPayloadInterface } from './interfaces/InvoiceRejectPayload.interface';

const useRejectInvoiceMutation = ({ setOtherError }: InvoiceMutationPropsInterface) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.RejectInvoice);

  // TODO: handle other errors if needed
  // const uniqueNameErrorHandler = useRoleUniqueNameErrorHandler(setValidationError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async (payload: InvoiceRejectPayloadInterface) => {
      const { invoiceId: id } = payload;
      const res = await request({ data: { ...payload }, urlParams: { id } });

      return res.data;
    },
    {
      mutationKey: rejectInvoiceMutationKey,
      onSuccess: (_, { invoiceReference: name }: InvoiceRejectPayloadInterface) => {
        queryClient.invalidateQueries(getToVerifyInvoicesQueryKey);
        queryClient.invalidateQueries(getToApproveInvoicesQueryKey);
        queryClient.invalidateQueries(getProcessedInvoicesQueryKey);

        showAlert(
          <Trans
            i18nKey="invoices.rejectInvoice.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useRejectInvoiceMutation;
