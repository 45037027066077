import React, { FC, ReactElement } from 'react';
import { AccordionSummary as MatAccordionSummary, AccordionSummaryProps } from '@mui/material';
import classnames from 'classnames';
import './AccordionSummary.component.scss';

const AccordionSummary: FC<AccordionSummaryProps> = ({
  className,
  children,
  ...rest
}): ReactElement => {
  return (
    <MatAccordionSummary {...rest} className={classnames('data-hub-accordion-summary', className)}>
      {children}
    </MatAccordionSummary>
  );
};

export { AccordionSummary };
