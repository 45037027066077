import CurrencyType from '../../../../types/Currency.type';

export enum ISO4217CodeEnum {
  GBP = 'GBP',
  PLN = 'PLN',
}

export const currencyISOToTypeMap: { [key in ISO4217CodeEnum]: CurrencyType | undefined } = {
  [ISO4217CodeEnum.GBP]: 'pound',
  [ISO4217CodeEnum.PLN]: undefined,
};
