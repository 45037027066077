import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SupplierMatchPropsInterface } from './interfaces/SupplierMatchProps.interface';
import FormModalComponent from '../../../../../../../components/modal/form-modal/FormModal.component';
import { SupplierMatchFormInterface } from './components/supplier-match-form/interfaces/SupplierMatchForm.interface';
import useSupplierMatchMutation from '../../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/useSupplierMatch.mutation';
import SupplierMatchFormComponent from './components/supplier-match-form/SupplierMatchForm.component';

const SupplierMatchView: FC<SupplierMatchPropsInterface> = ({
  invoiceId,
  fileName,
  fileLocalization,
  close,
  variant,
  supplier,
  submitCallback,
}) => {
  const { t } = useTranslation();
  const methods = useForm<SupplierMatchFormInterface>({
    mode: 'onSubmit',
    defaultValues: {
      invoiceId,
      supplier,
    },
  });
  const {
    mutate: matchSupplier,
    isSuccess,
    isLoading,
  } = useSupplierMatchMutation({
    setValidationError: methods.setError,
  });

  const onSubmit = (data: SupplierMatchFormInterface) => {
    if (!supplier || (data.supplier && supplier.value !== data.supplier.value)) {
      matchSupplier({
        id: data.invoiceId,
        payload: {
          invoiceId: data.invoiceId,
          supplierId: data.supplier ? data.supplier.value : '',
        },
        supplierName: data.supplier ? data.supplier.text : '',
        fileName,
      });
    } else {
      close();
    }
  };

  useEffect(() => {
    if (isSuccess && submitCallback) {
      submitCallback();
    }
  }, [isSuccess, submitCallback]);

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmit)}
      isSuccess={isSuccess}
      isLoading={isLoading}
      submitText={variant === 'edit' ? t('global.edit') : t('global.save')}
      submitIcon={variant === 'edit' ? <EditOutlinedIcon /> : undefined}
      disablePortal={variant === 'edit'}
      data-cy="supplier-match-form"
    >
      <SupplierMatchFormComponent
        fileName={fileName}
        fileLocalization={fileLocalization}
        variant={variant}
      />
    </FormModalComponent>
  );
};

export default SupplierMatchView;
