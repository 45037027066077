import React, { ReactElement, FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoHWhite } from '../../../../../assets/logo/logoHWhite.svg';
import classes from './PublicLogo.module.scss';

interface PublicLogoComponentPropsInterface {
  svgElement?: ReactElement;
}

const PublicLogoComponent: FC<PublicLogoComponentPropsInterface> = ({
  svgElement = <LogoHWhite className={classes.logo} />,
}): ReactElement => {
  return <Link to="/">{svgElement}</Link>;
};

export default PublicLogoComponent;
