import React, { memo, useCallback } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AutocompleteSelect from '../../../../../../../../../../../components/material/autocomplete-select/AutocompleteSelect.component';
import { SupplierMatchFormInterface } from '../../interfaces/SupplierMatchForm.interface';
import useGetResidentSuppliersQuery from '../../../../../../../../../../../../services/react-query-hooks/queries/upload-hub/resident/useGetResidentSuppliers.query';
import { FormControl } from '../../../../../../../../../../../components/material';

const SupplierControllerComponent = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<SupplierMatchFormInterface>();

  const { data } = useGetResidentSuppliersQuery();

  const autocompleteInput = useCallback(
    ({ field: fieldProps }: FieldValues) => (
      <AutocompleteSelect
        {...fieldProps}
        data={data}
        placeholder={t('invoices.tabs.toVerify.supplierMatchModal.controls.supplier.label')}
        error={!!errors.supplier}
        data-cy="supplier-input"
        disablePortal
      />
    ),
    [data, errors.supplier, t],
  );

  return (
    <FormControl error={!!errors.supplier}>
      <Controller
        name="supplier"
        control={control}
        rules={{
          required: t(
            'invoices.tabs.toVerify.supplierMatchModal.controls.supplier.errors.required',
          ),
        }}
        render={autocompleteInput}
      />
      <span role="alert">{(errors.supplier as any)?.message}</span>
    </FormControl>
  );
};

const SupplierController = memo(SupplierControllerComponent);

export default SupplierController;
