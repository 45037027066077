import { InvoiceHistoryEventTypeEnum } from '../../../../enums/upload-hub/InvoiceHistoryEventType.enum';

export const InvoiceHistoryEventTypes = Object.values(InvoiceHistoryEventTypeEnum);

export type InvoiceHistoryEventType = (typeof InvoiceHistoryEventTypes)[number];

export const InvoiceHistoryEventTypeMap: {
  [key in InvoiceHistoryEventType]: number;
} = {
  [InvoiceHistoryEventTypeEnum.NEW_INVOICE_FROM_EMAIL_UPLOADED]: 0,
  [InvoiceHistoryEventTypeEnum.NEW_MANUAL_INVOICE_UPLOADED]: 1,
  [InvoiceHistoryEventTypeEnum.INVOICE_DATA_UPDATED]: 2,
  [InvoiceHistoryEventTypeEnum.INVOICE_DELETED]: 3,
  [InvoiceHistoryEventTypeEnum.INVOICE_AUTO_MATCHING_FAILED]: 4,
  [InvoiceHistoryEventTypeEnum.INVOICE_DUPLICATES_FOUND]: 5,
  [InvoiceHistoryEventTypeEnum.INVOICE_DUPLICATES_NOT_FOUND]: 6,
  [InvoiceHistoryEventTypeEnum.INVOICE_DUPLICATES_SKIPPED]: 7,
  [InvoiceHistoryEventTypeEnum.INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT]: 8,
  [InvoiceHistoryEventTypeEnum.INVOICE_FAILED_TO_BE_UPDATED_IN_RESIDENT]: 9,
  [InvoiceHistoryEventTypeEnum.INVOICE_MATCHING_AUTO_MATCHED]: 10,
  [InvoiceHistoryEventTypeEnum.INVOICE_MATCHING_SET_MANUALLY]: 11,
  [InvoiceHistoryEventTypeEnum.INVOICE_MOVED_BACK_TO_REVIEW]: 12,
  [InvoiceHistoryEventTypeEnum.INVOICE_OCR_RECOGNITION_FAILED]: 13,
  [InvoiceHistoryEventTypeEnum.INVOICE_OCR_RECOGNITION_SUCCEEDED]: 14,
  [InvoiceHistoryEventTypeEnum.INVOICE_REJECTED]: 15,
  [InvoiceHistoryEventTypeEnum.INVOICE_SEND_TO_RESIDENT]: 16,
  [InvoiceHistoryEventTypeEnum.INVOICE_SUPPLIER_AUTO_MATCHED]: 17,
  [InvoiceHistoryEventTypeEnum.INVOICE_SUPPLIER_COULD_NOT_BE_AUTO_MATCHED]: 18,
  [InvoiceHistoryEventTypeEnum.INVOICE_SUPPLIER_SET_MANUALLY]: 19,
  [InvoiceHistoryEventTypeEnum.INVOICE_UPDATED_IN_RESIDENT]: 20,
  [InvoiceHistoryEventTypeEnum.INVOICE_PROPERTIES_AUTO_MATCHED]: 21,
  [InvoiceHistoryEventTypeEnum.INVOICE_MATCHING_SET_DEFAULT]: 22,
  [InvoiceHistoryEventTypeEnum.INVOICE_APPROVED]: 23,
  [InvoiceHistoryEventTypeEnum.INVOICE_REJECTED_TO_AMEND]: 24,
};
