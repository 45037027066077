import React, { FC, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { TableColumnInterface } from '../../../../../components/table/interfaces/TableColumn.interface';
import { AlignEnum } from '../../../../../../enums/Align.enum';
import { TableColumnTypeEnum } from '../../../../../components/table/enums/TableColumnType.enum';
import TableComponent from '../../../../../components/table/Table.component';
import useTableFilters from '../../../../../components/table/hooks/TableFilters.hook';
import useGetToApproveInvoicesListQuery from '../../../../../../services/react-query-hooks/queries/upload-hub/invoice/to-approve/useGetToApproveInvoicesList.query';
import { TableRowInterface } from '../../../../../components/table/interfaces/TableRow.interface';
import { StatusEnum } from '../../../../../../enums/Status.enum';
import { InvoiceStatusEnum } from '../../../../../../enums/upload-hub/InvoiceStatus.enum';
import { ToApproveInvoiceInterface } from '../../../../../../types/upload-hub/invoice/to-approve/ToApproveInvoice.interface';
import useDate from '../../../../../../utils/hooks/date-format/useDate';
import { InvoiceStatusMap } from '../../../../../../types/upload-hub/invoice/InvoiceStatus.type';
import { Chip } from '../../../../../components/material';
import { TableActionInterface } from '../../../../../components/table/interfaces/TableProps.interface';
import { ToApproveInvoiceActionNameEnum } from './enums/ToApproveInvoiceActionName.enum';
import { useInvoicesContext } from '../../Invoices.component';
import { TableActionNameEnum } from '../../../../../components/table/enums/TableActionName.enum';
import useDeleteInvoice from '../delete/hooks/useDeleteInvoice.hook';
import { getToApproveInvoicesQueryKey } from '../../../../../../services/react-query-hooks/queries/queryKeys';
import useFile from '../../../../../../utils/hooks/file/useFile.hook';
import { useUploadHub } from '../../providers/UploadHub.provider';
import useApproveInvoiceMutation from '../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/useApproveInvoice.mutation';
import { queryClient } from '../../../../../../services/queryClient';
import {
  approveInvoiceMutationKey,
  rejectInvoiceMutationKey,
  rejectToAmendInvoiceMutationKey,
} from '../../../../../../services/react-query-hooks/mutations/mutationKeys';
import useRejectInvoice from '../reject/hooks/useRejectInvoice.hook';
import useInvoiceVerification from '../to-verify/components/verification/hooks/useInvoiceVerification.hook';

const ToApproveInvoicesComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { format } = useDate();
  const { download } = useFile();
  const { isProcessingEnabled, isApprovalEnabled } = useUploadHub();
  const { filters, dispatchFilters } = useTableFilters({
    OrderBy: 'fileName asc',
  });
  const { data, pagination, isLoading, isFetching } = useGetToApproveInvoicesListQuery(filters);
  const getInvoice = (id: string): ToApproveInvoiceInterface | undefined =>
    data?.find((item: ToApproveInvoiceInterface) => item.invoiceId === id);

  const { verificationModal, setIsVerificationModalOpen, setVerifyInvoiceData } =
    useInvoiceVerification();

  const { rejectModal, setRejectInvoiceOpen, setRejectInvoiceData } = useRejectInvoice();

  const [, setHistoryInvoiceData, setIsHistoryModalOpen] = useInvoicesContext();

  const { deleteModal, setDeleteInvoiceOpen, setDeleteInvoiceData } = useDeleteInvoice(
    getToApproveInvoicesQueryKey,
  );

  const { mutate: approveInvoice } = useApproveInvoiceMutation();

  const toApproveInvoicesColumns: TableColumnInterface[] = [
    {
      name: 'invoiceStatus',
      label: t('invoices.tabs.toApprove.columns.status'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      width: '9rem',
    },
    {
      name: 'fileName',
      label: t('invoices.tabs.toApprove.columns.fileName'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '20rem',
    },
    {
      name: 'supplierName',
      label: t('invoices.tabs.toApprove.columns.supplier'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '13rem',
    },
    {
      name: 'invoiceReference',
      label: t('invoices.tabs.toApprove.columns.invoiceReference'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '13rem',
    },
    {
      name: 'invoiceDate',
      label: t('invoices.tabs.toApprove.columns.invoiceDate'),
      align: AlignEnum.CENTER,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '12rem',
    },
    {
      name: 'totalAmount',
      label: t('invoices.tabs.toApprove.columns.amountDue'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '11rem',
    },
    {
      name: 'propertyName',
      label: t('invoices.tabs.toApprove.columns.block'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '15rem',
    },
    {
      name: 'actions',
      label: t('invoices.tabs.toApprove.columns.action'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.ACTIONS,
      width: '4rem',
    },
  ];

  // TODO: unify and extract render invoice status for all invoice tabs
  const renderInvoiceStatus = useCallback(
    (invoice: ToApproveInvoiceInterface): ReactElement => {
      const statusKey = InvoiceStatusMap[invoice.status];
      // TODO: resolve typing enum map issue
      // @ts-ignore
      const statusLabel = String(t(`invoices.statuses.${statusKey}`));

      switch (invoice.status) {
        case InvoiceStatusEnum.TO_APPROVE:
          return <Chip label={statusLabel} color="info" />;

        default:
          return <Chip label={statusLabel} color="transparent" variant="outlined" />;
      }
    },
    [t],
  );

  const mapToApproveInvoiceToRow = (invoice: ToApproveInvoiceInterface): TableRowInterface => {
    return {
      ...invoice,
      id: invoice.invoiceId,
      status: StatusEnum.NULL,
      invoiceStatus: renderInvoiceStatus(invoice),
      invoiceDate: invoice.invoiceDate ? format(invoice.invoiceDate) : '',
      totalAmount: invoice.amountTotal,
      propertyName: invoice.propertyName,
      disabled:
        invoice.status === InvoiceStatusEnum.MATCHING_IN_PROGRESS ||
        invoice.status === InvoiceStatusEnum.DUPLICATES_DETECTION_IN_PROGRESS,
      loading:
        queryClient.isMutating({
          predicate: (m) =>
            [
              approveInvoiceMutationKey,
              rejectInvoiceMutationKey,
              rejectToAmendInvoiceMutationKey,
            ].includes(String(m.options.mutationKey)) &&
            m.options.variables.invoiceId === invoice.invoiceId,
        }) > 0,
    };
  };

  const actions: TableActionInterface<ToApproveInvoiceActionNameEnum>[] = [
    {
      name: ToApproveInvoiceActionNameEnum.APPROVE,
      icon: <DoneOutlinedIcon />,
      label: t('invoices.tabs.toApprove.actions.approve.title'),
    },
    {
      name: TableActionNameEnum.EDIT,
      icon: <EditOutlinedIcon />,
      label: t('invoices.tabs.toApprove.actions.edit.title'),
    },
    {
      name: ToApproveInvoiceActionNameEnum.REJECT,
      icon: <RemoveCircleOutlineOutlinedIcon />,
      label: t('invoices.tabs.toApprove.actions.reject.title'),
    },
    {
      name: ToApproveInvoiceActionNameEnum.REJECT_TO_AMEND,
      icon: <BuildCircleOutlinedIcon />,
      label: t('invoices.tabs.toApprove.actions.rejectToAmend.title'),
    },
    {
      name: TableActionNameEnum.DOWNLOAD,
      icon: <FileDownloadOutlinedIcon />,
      label: t('global.download'),
    },
    {
      name: ToApproveInvoiceActionNameEnum.HISTORY,
      icon: <TimelineIcon />,
      label: t('invoices.tabs.toApprove.actions.history.title'),
    },
    {
      name: TableActionNameEnum.DELETE,
      icon: <ClearOutlinedIcon />,
      label: t('global.delete'),
      multiselect: false,
      active: isProcessingEnabled,
    },
  ];

  const onInvoiceClickHandler = (id: string | number): void => {
    if (!isApprovalEnabled) {
      return;
    }

    const invoice = getInvoice(String(id));

    if (invoice) {
      setVerifyInvoiceData({
        id: invoice.invoiceId,
        fileName: invoice.fileName,
        invoiceReference: invoice.invoiceReference,
        step: 'approval',
      });
      setIsVerificationModalOpen(true);
    }
  };

  const onActionSelectedHandler = (
    actionName: ToApproveInvoiceActionNameEnum | TableActionNameEnum,
    rowId: string[] | number[] | string | number,
  ): void => {
    const invoice = getInvoice(String(rowId));
    const isMultiple = Array.isArray(rowId);

    if (!invoice) {
      return;
    }

    switch (actionName) {
      case ToApproveInvoiceActionNameEnum.APPROVE: {
        if (!isMultiple) {
          approveInvoice(invoice);
        }

        break;
      }

      case TableActionNameEnum.EDIT: {
        if (!isMultiple) {
          onInvoiceClickHandler(invoice.invoiceId);
        }

        break;
      }

      case ToApproveInvoiceActionNameEnum.REJECT: {
        if (!isMultiple) {
          setRejectInvoiceData({
            invoiceId: invoice.invoiceId,
            invoiceReference: invoice.invoiceReference,
            variant: 'reject',
          });
          setRejectInvoiceOpen(true);
        }

        break;
      }

      case ToApproveInvoiceActionNameEnum.REJECT_TO_AMEND: {
        if (!isMultiple) {
          setRejectInvoiceData({
            invoiceId: invoice.invoiceId,
            invoiceReference: invoice.invoiceReference,
            variant: 'rejectToAmend',
          });
          setRejectInvoiceOpen(true);
        }

        break;
      }

      case ToApproveInvoiceActionNameEnum.HISTORY: {
        if (!isMultiple) {
          setHistoryInvoiceData({ id: invoice.invoiceId });
          setIsHistoryModalOpen(true);
        }

        break;
      }

      case TableActionNameEnum.DOWNLOAD: {
        download(invoice.fileLocalization, invoice.fileName);

        break;
      }

      case TableActionNameEnum.DELETE: {
        if (!isMultiple) {
          setDeleteInvoiceData(invoice);
          setDeleteInvoiceOpen(true);
        }

        break;
      }

      default:
    }
  };

  return (
    <Grid>
      <TableComponent
        table={{
          rows: data ? [...data.map(mapToApproveInvoiceToRow)] : [],
          columns: toApproveInvoicesColumns,
        }}
        emptyLabel={t('invoices.tabs.toApprove.emptyTable')}
        isLoading={isLoading || isFetching}
        multiselect={false}
        actions={actions}
        onRowClick={onInvoiceClickHandler}
        onActionSelected={onActionSelectedHandler}
        filters={filters}
        onChangeFilters={dispatchFilters}
        pagination={pagination}
      />

      {verificationModal}
      {rejectModal}
      {deleteModal}
    </Grid>
  );
};

export default ToApproveInvoicesComponent;
