import { useTranslation } from 'react-i18next';
import ErrorHandled from './ErrorHandled.enum';
import { useAlert } from '../../alert/Alert.provider';
import CommonSetErrorType from './CommonSetError.type';

const useGeneralErrorHandler = (setError?: CommonSetErrorType, errorMessage?: string) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  return () => {
    const message = errorMessage || t('global.errors.general');

    if (setError) {
      setError(message);
    }

    showAlert(message, 'error');

    return ErrorHandled.HANDLED;
  };
};

export default useGeneralErrorHandler;
