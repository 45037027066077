import React, { FC, ReactElement, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldArrayWithId } from 'react-hook-form/dist/types/fieldArray';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';
import { VerifyInvoiceItemsFormPropsInterface } from './interfaces/VerifyInvoiceItemsFormProps.interface';
import VerifyInvoiceItemComponent from '../verify-invoice-item/VerifyInvoiceItem.component';
import { FormControl } from '../../../../../../../../../../../components/material';

const VerifyInvoiceItemsFormComponent: FC<VerifyInvoiceItemsFormPropsInterface> = ({
  page,
  itemsFields: itemsProperties,
  registerRemoveItemCallback,
  registerAddItemCallback,
}): ReactElement => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<VerifyInvoiceDataFormInterface>();

  const {
    fields: itemsFields,
    remove,
    insert,
  } = useFieldArray({
    control,
    name: 'items',
  });

  registerRemoveItemCallback(remove);
  registerAddItemCallback(insert);

  const itemPart = useCallback(
    (item: FieldArrayWithId<any, any, any>, index: number) => (
      <Grid
        container
        direction="column"
        rowSpacing={1}
        key={item.id}
        style={{ display: index === page ? 'initial' : 'none' }}
      >
        <VerifyInvoiceItemComponent item={item} fields={itemsProperties[page]} index={index} />
      </Grid>
    ),
    [itemsProperties, page],
  );

  return (
    <>
      {itemsFields.map(itemPart)}
      <br />
      {errors.items && (
        <FormControl error>
          <span role="alert">
            {t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.errors.general',
            )}
          </span>
        </FormControl>
      )}
    </>
  );
};

export default VerifyInvoiceItemsFormComponent;
