import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../api';
import { queryClient } from '../../../../../queryClient';
import {
  getSynchronizedInboxesQueryKey,
  getUploadHubSettingsQueryKey,
} from '../../../../queries/queryKeys';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { SynchronizedInboxMutationWithValidationPropsInterface } from './SynchronizedInboxMutationProps.interface';
import useSynchronizedInboxUniqueEmailErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/useSynchronizedInboxUniqueEmailError.handler';
import useSynchronizedInboxNotFoundErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/useSynchronizedInboxNotFoundError.handler';
import SynchronizedInboxPayloadInterface from '../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInboxPayload.interface';
import { useAlert } from '../../../../../alert/Alert.provider';

interface UpdateSynchronizedInboxesPayloadInterface {
  id: string;
  payload: SynchronizedInboxPayloadInterface;
}

const useUpdateSynchronizedInboxMutation = (
  { setValidationError, setOtherError }: SynchronizedInboxMutationWithValidationPropsInterface = {
    setValidationError: () => {},
  },
) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateSynchronizedInbox);

  const uniqueEmailErrorHandler = useSynchronizedInboxUniqueEmailErrorHandler(setValidationError);
  const notFoundErrorHandler = useSynchronizedInboxNotFoundErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError(
    [uniqueEmailErrorHandler, notFoundErrorHandler],
    generalErrorHandler,
  );

  return useMutation(
    async ({ id, payload }: UpdateSynchronizedInboxesPayloadInterface) => {
      const res = await request({ data: { ...payload }, urlParams: { id } });

      return res.data;
    },
    {
      onSuccess: (_, { payload: { email } }: UpdateSynchronizedInboxesPayloadInterface) => {
        queryClient.invalidateQueries(getSynchronizedInboxesQueryKey);
        queryClient.invalidateQueries(getUploadHubSettingsQueryKey);

        showAlert(
          <Trans
            i18nKey="pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.updateSynchronizedInboxesModal.success"
            values={{ email }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useUpdateSynchronizedInboxMutation;
