import { useTranslation } from 'react-i18next';
import CommonSetErrorType from '../../CommonSetError.type';
import { useCommonErrorHandler } from '../../useErrorHandler';

const useInvalidTotalItemsAmountErrorHandler = (setError: CommonSetErrorType) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'INVALID_TOTAL_ITEMS_AMOUNT',
    errorMessage: t(
      'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.errors.invalidTotalItemsAmount',
    ),
  });

  return handler;
};

export default useInvalidTotalItemsAmountErrorHandler;
