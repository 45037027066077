import React, { FC, memo } from 'react';
import classNames from 'classnames';
import classes from '../../SettingsSection.module.scss';
import { SettingsSectionPropsInterface } from '../../interfaces/SettingsSectionProps.interface';

const SettingsSectionHeaderComponent: FC<SettingsSectionPropsInterface> = ({
  title,
  subsection,
  required,
}) => {
  const headerClassName = classNames(
    classes['settings-section__title'],
    required ? classes['settings-section__title--required'] : '',
    [subsection ? '' : classes['settings-section--main__title']],
  );

  return subsection ? (
    <h6 className={headerClassName}>{title}</h6>
  ) : (
    <h5 className={headerClassName}>{title}</h5>
  );
};

const SettingsSectionHeader = memo(SettingsSectionHeaderComponent);

export default SettingsSectionHeader;
