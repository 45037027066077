import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  DataSourceTypes,
  DataSourceTypesMap,
} from '../../../../types/data-source/DataSourceType.type';
import {
  DataSourceOrigins,
  DataSourceOriginsMap,
} from '../../../../types/data-source/DataSourceOrigin.type';
import { Input, FormControl, MenuItem } from '../../../components/material';
import Select from '../../../components/material/select/Select.component';
import FormContainerComponent from '../../../components/form-container/FormContainer.component';
import DataSourceFormInterface from './DataSourceForm.interface';
import classes from './DataSourceForm.module.scss';

interface DataSourceFormComponentPropsInterface {
  mode: 'add' | 'update';
}

const DataSourceFormComponent: FC<DataSourceFormComponentPropsInterface> = ({ mode }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<DataSourceFormInterface>();

  // TODO: check performance, maybe we should move controllers to callbacks
  return (
    <FormContainerComponent className={classes['data-source-form-section']} fullWidth>
      <Grid container direction="row">
        <Grid className={classes['data-source-form-section__form']} item xs={12}>
          <Grid container direction="column" rowSpacing={4}>
            <Grid item>
              <FormControl error={!!errors.name}>
                <Input
                  id="name"
                  fullWidth
                  label={t(
                    'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.name.label',
                  )}
                  {...register('name', {
                    required: t(
                      'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.name.errors.required',
                    ),
                  })}
                  required
                />
                <span role="alert">{errors.name?.message}</span>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <Controller
                  name="type"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t(
                      'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.type.errors.required',
                    ),
                  }}
                  render={(renderProps) => (
                    <Select
                      disabled={mode === 'update'}
                      fullWidth
                      label={t(
                        'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.type.label',
                      )}
                      error={!!errors.type}
                      {...renderProps.field}
                      required
                    >
                      {DataSourceTypes.map((type) => (
                        <MenuItem value={DataSourceTypesMap[type]} key={DataSourceTypesMap[type]}>
                          {t(
                            `dataSourcesSettings.sections.dataSources.subsections.supplierList.types.${type}`,
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <span role="alert">{errors.type?.message}</span>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <Controller
                  name="origin"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t(
                      'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.origin.errors.required',
                    ),
                  }}
                  render={(renderProps) => (
                    <Select
                      disabled={mode === 'update'}
                      fullWidth
                      label={t(
                        'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.origin.label',
                      )}
                      error={!!errors.origin}
                      {...renderProps.field}
                      required
                    >
                      {Object.values(DataSourceOrigins).map((origin) => (
                        <MenuItem
                          value={DataSourceOriginsMap[origin]}
                          key={DataSourceOriginsMap[origin]}
                        >
                          {t(
                            `dataSourcesSettings.sections.dataSources.subsections.supplierList.origins.${origin}`,
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <span role="alert">{errors.origin?.message}</span>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <Controller
                  name="autoSync"
                  control={control}
                  defaultValue={false}
                  render={(renderProps) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={renderProps.field.value}
                          onChange={renderProps.field.onChange}
                        />
                      }
                      label={t(
                        'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.autoSync.label',
                      )}
                    />
                  )}
                />
                <span role="alert">{errors.autoSync?.message}</span>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormContainerComponent>
  );
};

export default DataSourceFormComponent;
