import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useRequest } from '@datahub/api-client';
import { PermissionInterface } from '../../types/permission/Permission.interface';
import { UserContextTypeInterface } from './interfaces/UserContextType.interface';
import { ApiOperation } from '../api';
import { ConfirmInvitationPayloadInterface } from './interfaces/ConfirmInvitationPayload.interface';
import { PermissionTypeEnum } from '../../enums/permission/PermissionType.enum';
import { UserPermissionsContextTypeInterface } from './interfaces/UserPermissionsContextType.interface';

const hasPermissionType = (permissionType: PermissionTypeEnum) => (p: PermissionInterface) =>
  p.permissionId === permissionType;

const UserPermissionsContext = createContext<UserPermissionsContextTypeInterface>({
  hasPermission: () => false,
  hasAnyPermission: false,
});
const UserContext = createContext<UserContextTypeInterface>({} as UserContextTypeInterface);

export const UserProvider: FC<PropsWithChildren> = ({ children }): ReactElement => {
  const [error, setError] = useState(false);
  const [permissions, setPermissions] = useState<PermissionInterface[] | undefined>(undefined);
  const { request: getUserRequest } = useRequest<{
    responseModel: { permissions: PermissionInterface[] };
  }>(ApiOperation.GetUser);
  const { request: confirmInvitationRequest } = useRequest(ApiOperation.ConfirmUserInvitation);

  const retrievePermissions = useCallback(() => {
    return getUserRequest()
      .then((data) => {
        const newPermissions = data.data.responseModel.permissions;
        setPermissions(newPermissions);

        return newPermissions;
      })
      .catch((e) => {
        setError(true);
        throw e;
      });
  }, [getUserRequest]);

  const confirmInvitation = useCallback(
    (payload: ConfirmInvitationPayloadInterface) => {
      return confirmInvitationRequest({
        data: { ...payload },
      })
        .then(() => {
          // TODO: clear localStorage for invitation

          return true;
        })
        .catch((e) => {
          setError(e);
          throw e;
        });
    },
    [confirmInvitationRequest],
  );

  const resetState = () => {
    setError(false);
    setPermissions(undefined);
  };

  const memoizedUserState = useMemo(
    () => ({
      retrievePermissions,
      confirmInvitation,
      error,
      resetState,
    }),
    [error, retrievePermissions, confirmInvitation],
  );

  const hasPermission = useCallback(
    (permissionType: PermissionTypeEnum): boolean =>
      Boolean(permissions?.find(hasPermissionType(permissionType))),
    [permissions],
  );

  const memoizedPermissions = useMemo(
    () => ({
      permissions,
      hasPermission,
      hasAnyPermission: permissions !== undefined && permissions.length > 0,
    }),
    [permissions, hasPermission],
  );

  return (
    <UserContext.Provider value={memoizedUserState}>
      <UserPermissionsContext.Provider value={memoizedPermissions}>
        {children}
      </UserPermissionsContext.Provider>
    </UserContext.Provider>
  );
};

export function useUserRetrievePermissions() {
  return useContext(UserContext);
}

export function useUserPermissions() {
  return useContext(UserPermissionsContext);
}
