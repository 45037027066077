import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { PropsInterface } from '../../../../../interfaces/Props.interface';
import { UploadHubContextTypeInterface } from './interfaces/UploadHubContextType.interface';
import useGetUploadHubSettingsQuery from '../../../../../services/react-query-hooks/queries/upload-hub/settings/useGetUploadHubSettings.query';
import { UploadHubRoleEnum } from '../../../../../enums/upload-hub/UploadHubRole.enum';
import { InvoiceReceiversMap } from '../../../../../types/upload-hub/invoice-receiver/InvoiceReceivers.type';
import { ResidentInvoiceDataEnum } from '../../../../../enums/upload-hub/ResidentInvoiceData.enum';
import { PermissionTypeEnum } from '../../../../../enums/permission/PermissionType.enum';
import { useUserPermissions } from '../../../../../services/user/User.provider';

const UploadHubContext = createContext<UploadHubContextTypeInterface>(
  {} as UploadHubContextTypeInterface,
);

export const UploadHubProvider: FC<PropsWithChildren<PropsInterface>> = ({ children }) => {
  const { data: settings, isLoading } = useGetUploadHubSettingsQuery();
  const { hasPermission } = useUserPermissions();

  const approvalEditFields = useMemo(
    () => [ResidentInvoiceDataEnum.ITEM_CATEGORY, ResidentInvoiceDataEnum.ITEM_SCHEDULE],
    [],
  );

  const isFieldEnabledForApproval = useCallback(
    (field: ResidentInvoiceDataEnum): boolean =>
      (settings && settings.invoiceReceiver !== InvoiceReceiversMap.resident) ||
      approvalEditFields.includes(field),
    [approvalEditFields, settings],
  );

  const isAllowUsersToSeeOnlyInvoicesUploadedByThemEnabled =
    settings && settings.allowUsersToSeeOnlyInvoicesUploadedByThem;

  const isAdmin = [PermissionTypeEnum.ADMIN, PermissionTypeEnum.UPLOAD_HUB_ADMIN].some(
    hasPermission,
  );

  const isResident = useMemo(
    () => Boolean(settings && settings.invoiceReceiver === InvoiceReceiversMap.resident),
    [settings],
  );

  // TODO: refactor in case of multiple integrations with different roles
  const hasUserAccess = useCallback(
    (uploadHubRoles: UploadHubRoleEnum[]): boolean => {
      if (!hasPermission(PermissionTypeEnum.UPLOAD_HUB) || !settings) {
        return false;
      }

      return (
        settings.invoiceReceiver !== InvoiceReceiversMap.resident ||
        uploadHubRoles.includes(settings.activeUploadHubRole)
      );
    },
    [hasPermission, settings],
  );

  const memoizedUploadHubState = useMemo(
    () => ({
      settings,
      isLoading,
      isConfigured: settings
        ? Number.isInteger(settings.invoiceReceiver) &&
          settings.invoiceReceiver > 0 &&
          settings.primaryDataSources.length > 0
        : false,
      isSyncMailboxesEnabled: settings ? settings.amountOfConfiguredMailboxes > 0 : false,
      hasPermissions:
        isAdmin ||
        isAllowUsersToSeeOnlyInvoicesUploadedByThemEnabled ||
        hasUserAccess([
          UploadHubRoleEnum.ADMIN,
          UploadHubRoleEnum.FINANCIAL_MANAGER,
          UploadHubRoleEnum.PROPERTY_MANAGER,
        ]),
      isApprovalEnabled:
        isAdmin ||
        isAllowUsersToSeeOnlyInvoicesUploadedByThemEnabled ||
        hasUserAccess([UploadHubRoleEnum.ADMIN, UploadHubRoleEnum.FINANCIAL_MANAGER]),
      isFieldEnabledForApproval,
      isProcessingEnabled:
        isAdmin ||
        isAllowUsersToSeeOnlyInvoicesUploadedByThemEnabled ||
        hasUserAccess([UploadHubRoleEnum.ADMIN, UploadHubRoleEnum.PROPERTY_MANAGER]),
      isResident,
    }),
    [
      settings,
      isLoading,
      isAdmin,
      hasUserAccess,
      isFieldEnabledForApproval,
      isResident,
      isAllowUsersToSeeOnlyInvoicesUploadedByThemEnabled,
    ],
  );

  return (
    <UploadHubContext.Provider value={memoizedUploadHubState}>{children}</UploadHubContext.Provider>
  );
};

export default UploadHubProvider;

export function useUploadHub() {
  return useContext(UploadHubContext);
}
