import React from 'react';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { PluginTypeEnum } from '../../enums/plugin/PluginType.enum';
import { PathEnum } from '../../views/components/router/enums/Path.enum';
import { PluginInterface } from './Plugin.interface';
import UploadHubComponent from '../../views/plugin-settings/components/upload-hub/UploadHub.component';
import { PermissionTypeEnum } from '../../enums/permission/PermissionType.enum';

export const Plugins = Object.values(PluginTypeEnum);

export type PluginType = (typeof Plugins)[number];

// TODO: create plugins hook and move it there + translate labels
export const PluginsModelMap: { [key in PluginType]: PluginInterface } = {
  [PluginTypeEnum.UPLOAD_HUB]: {
    type: PluginTypeEnum.UPLOAD_HUB,
    name: 'uploadHub',
    label: 'invoices',
    basePath: PathEnum.INVOICES,
    icon: <UploadOutlinedIcon />,
    settings: {
      name: 'uploadHub',
      label: 'pluginSettings.sections.uploadHub.title',
      element: <UploadHubComponent />,
    },
    allowedPermissions: [
      PermissionTypeEnum.ADMIN,
      PermissionTypeEnum.UPLOAD_HUB_ADMIN,
      PermissionTypeEnum.UPLOAD_HUB,
    ],
  },
};
