import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { getAllRolesQueryKey, getRolesQueryKey } from '../../queries/queryKeys';
import { ApiOperation } from '../../../api';
import { queryClient } from '../../../queryClient';
import useOnError from '../../error-handlers/useOnError';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import { RoleMutationWithValidationPropsInterface } from './RoleMutationProps.interface';
import useRoleUniqueNameErrorHandler from '../../error-handlers/role/useRoleUniqueNameError.handler';
import RolePayloadInterface from '../../../../types/roles/RolePayload.interface';
import { useAlert } from '../../../alert/Alert.provider';

const useAddRoleMutation = (
  { setValidationError, setOtherError }: RoleMutationWithValidationPropsInterface = {
    setValidationError: () => {},
  },
) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.AddRole);

  const uniqueNameErrorHandler = useRoleUniqueNameErrorHandler(setValidationError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([uniqueNameErrorHandler], generalErrorHandler);

  return useMutation(
    async (payload: RolePayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return res.data;
    },
    {
      onSuccess: (_, { roleName: name }: RolePayloadInterface) => {
        queryClient.invalidateQueries(getRolesQueryKey);
        queryClient.invalidateQueries(getAllRolesQueryKey);

        showAlert(
          <Trans
            i18nKey="settings.sections.permission.subsections.role.addModal.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useAddRoleMutation;
