import React from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../../api';
import { UpdateUseItemGrossAmountPayloadInterface } from './interfaces/UpdateUseItemGrossAmountPayload.interface';
import { UpdateUseItemGrossAmountMutationPropsInterface } from './interfaces/UpdateUseItemGrossAmountMutationProps.interface';
import useGeneralErrorHandler from '../../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../../error-handlers/useOnError';
import { queryClient } from '../../../../../../queryClient';
import { getUploadHubSettingsQueryKey } from '../../../../../queries/queryKeys';
import useDebouncedMutation from '../../../../useDebounced.mutation';
import { useAlert } from '../../../../../../alert/Alert.provider';

const useUpdateUseItemGrossAmountMutation = ({
  setOtherError,
}: UpdateUseItemGrossAmountMutationPropsInterface = {}) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateUsIteGrossAmount);

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const { onError } = useOnError([], generalErrorHandler);

  const { mutate, ...rest } = useMutation(
    async (payload: UpdateUseItemGrossAmountPayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return {
        data: res.data,
        payload,
      };
    },
    {
      onSuccess: (_, { doNotUseVat }: UpdateUseItemGrossAmountPayloadInterface) => {
        queryClient.invalidateQueries(getUploadHubSettingsQueryKey);

        showAlert(
          <Trans
            i18nKey="pluginSettings.sections.uploadHub.subsections.resident.controls.useItemGrossAmount.success"
            values={{
              status: doNotUseVat ? t('global.enabled') : t('global.disabled'),
            }}
            components={{ bold: <strong /> }}
          />,
          doNotUseVat ? 'success' : 'info',
        );
      },
      onError,
    },
  );

  const { mutateDebounced } =
    useDebouncedMutation<UpdateUseItemGrossAmountPayloadInterface>(mutate);

  return {
    mutate,
    mutateDebounced,
    ...rest,
  };
};

export default useUpdateUseItemGrossAmountMutation;
