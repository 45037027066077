export const updateUserMutationKey = 'updateUserMutationKey';

export const updateRoleMutationKey = 'updateRoleMutationKey';
export const deleteRoleMutationKey = 'deleteRoleMutationKey';

export const syncDataSourceMutationKey = 'syncDataSourceMutationKey';

export const updateSynchronizedInboxesAutoSyncMutationKey =
  'updateSynchronizedInboxesAutoSyncMutationKey';
export const useSyncMailboxMutationKey = 'useSyncMailboxMutationKey';

export const sendToOcrMutationKey = 'sendToOcrMutationKey';
export const sendToIntegrationServiceMutationKey = 'sendToIntegrationServiceMutationKey';
export const updateInvoiceMutationKey = 'updateInvoiceMutationKey';
export const skipInvoiceDuplicatesMutationKey = 'skipInvoiceDuplicatesMutationKey';
export const approveInvoiceMutationKey = 'approveInvoiceMutationKey';
export const rejectInvoiceMutationKey = 'rejectInvoiceMutationKey';
export const rejectToAmendInvoiceMutationKey = 'rejectToAmendInvoiceMutationKey';
export const backInvoiceToReviewMutationKey = 'backInvoiceToReviewMutationKey';
