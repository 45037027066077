import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsSection from '../settings-section/SettingsSection.component';
import { SettingsSectionItemInterface } from '../settings-section/interfaces/SettingsSectionItem.interface';
import LogoUpload from './components/logo-upload/LogoUpload.component';
import UserPermission from './components/user-permission/UserPermission.component';
import RolePermission from './components/role-permission/RolePermission.component';
import { RoleInterface } from '../../../../../../types/roles/Role.interface';
import classes from './GlobalSettings.module.scss';
import useGetAllRolesListQuery from '../../../../../../services/react-query-hooks/queries/role/useGetAllRolesListQuery';

const GlobalSettingsComponent: FC = () => {
  const { t } = useTranslation();
  const { data: roles, isLoading, isFetching } = useGetAllRolesListQuery();

  const getRole = useCallback(
    (roleId: string | number) => roles?.find((role: RoleInterface) => role.roleNumber === roleId),
    [roles],
  );

  const globalSettingsSections: SettingsSectionItemInterface[] = useMemo(
    () => [
      {
        title: t('settings.sections.global.title'),
        children: [
          {
            title: t('settings.sections.global.subsections.logo.title'),
            element: <LogoUpload />,
          },
        ],
        isLoading: false,
      },
      {
        title: t('settings.sections.permission.title'),
        children: [
          {
            title: t('settings.sections.permission.subsections.user.title'),
            element: <UserPermission roles={roles} getRole={getRole} />,
          },
          {
            title: t('settings.sections.permission.subsections.role.title'),
            element: <RolePermission roles={roles} getRole={getRole} />,
          },
        ],
        isLoading: isLoading || isFetching,
      },
    ],
    [getRole, isFetching, isLoading, roles, t],
  );

  const globalSettingsSubSection = useCallback(
    (subSection: SettingsSectionItemInterface) => (
      <SettingsSection key={subSection.title} title={subSection.title} subsection>
        {subSection.element}
      </SettingsSection>
    ),
    [],
  );

  const globalSettingsSection = useCallback(
    (section: SettingsSectionItemInterface, index: number) => (
      <div key={section.title} className={classes['global-settings']}>
        <SettingsSection title={section.title} isLoading={section.isLoading}>
          {section.children?.map(globalSettingsSubSection)}
        </SettingsSection>
        {index < globalSettingsSections.length - 1 && (
          <div className={classes['global-settings__separator']} />
        )}
      </div>
    ),
    [globalSettingsSections.length, globalSettingsSubSection],
  );

  return <>{globalSettingsSections.map(globalSettingsSection)}</>;
};

export default GlobalSettingsComponent;
