import React, { useMemo, useState } from 'react';
import DeleteSynchronizedInboxModalComponent from '../DeleteSynchronizedInboxModal.component';
import {
  NylasSynchronizedInboxInterface,
  SmtpSynchronizedInboxInterface,
} from '../../../../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';
import { EmailProviderEnum } from '../../../../../../../../../enums/upload-hub/settings/EmailProvider.enum';

const useDeleteSynchronizedInbox = (provider: EmailProviderEnum) => {
  const [deleteEmailOpen, setDeleteEmailOpen] = useState(false);
  const [deleteEmailData, setDeleteEmailData] = useState<
    SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface | undefined
  >(undefined);

  const deleteSynchronizedInboxModal = useMemo(() => {
    if (!deleteEmailData) {
      return <> </>;
    }

    return (
      <DeleteSynchronizedInboxModalComponent
        syncInbox={deleteEmailData}
        provider={provider}
        open={deleteEmailOpen}
        setOpen={setDeleteEmailOpen}
      />
    );
  }, [deleteEmailData, deleteEmailOpen, provider]);

  return { deleteSynchronizedInboxModal, setDeleteEmailOpen, setDeleteEmailData };
};

export default useDeleteSynchronizedInbox;
