import React, { useMemo, useState } from 'react';
import VerificationModalComponent from '../VerificationModal.component';
import { VerificationFormProvider } from '../services/VerificationForm.provider';
import { VerificationModalStep } from '../services/interfaces/VerificationFormProviderProps.interface';

const useInvoiceVerification = () => {
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  // TODO: update typing to invoice interface when data structure is ready
  const [verifyInvoiceData, setVerifyInvoiceData] = useState<
    | {
        id: string;
        fileName: string;
        invoiceReference?: string | undefined;
        step: VerificationModalStep;
      }
    | undefined
  >(undefined);

  const verificationModal = useMemo(() => {
    if (!verifyInvoiceData) {
      return <> </>;
    }

    return (
      <VerificationFormProvider
        id={verifyInvoiceData.id}
        fileName={verifyInvoiceData.fileName}
        invoiceReference={verifyInvoiceData.invoiceReference}
        step={verifyInvoiceData.step}
        setOpen={(isOpen: boolean) => {
          if (!isOpen) {
            setVerifyInvoiceData(undefined);
            setIsVerificationModalOpen(false);
          }
        }}
      >
        <VerificationModalComponent open={isVerificationModalOpen} />
      </VerificationFormProvider>
    );
  }, [isVerificationModalOpen, verifyInvoiceData]);

  return { verificationModal, setIsVerificationModalOpen, setVerifyInvoiceData };
};

export default useInvoiceVerification;
