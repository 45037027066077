import classnames from 'classnames';
import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { LayoutPropsInterface } from './interfaces/LayoutProps.interface';

const RowComponent: FC<PropsWithChildren<LayoutPropsInterface>> = ({
  children,
  className,
  align,
}): ReactElement => {
  return (
    <div className={classnames('row', { [`row--${align}`]: align }, className)}>{children}</div>
  );
};

export default RowComponent;
