import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TablePagination from '../components/table-pagination/TablePagination.component';
import { TableFilterAction } from './TableFilters.hook';
import { TablePaginationPropsInterface } from '../interfaces/TablePaginationProps.interface';

const useTablePagination = ({
  rowsCount,
  rowsPerPageOptions,
  pagination,
  filters,
  onChangeFilters,
  showRowsLabel = true,
  className,
}: TablePaginationPropsInterface) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions ? rowsPerPageOptions[0] : 20);

  const changePageHandler = useCallback(
    (event: unknown, newPageNumber: number) => {
      setPage(newPageNumber);

      if (onChangeFilters && pagination && filters?.PageNumber !== undefined) {
        onChangeFilters({
          type: TableFilterAction.UPDATE_FILTERS,
          value: {
            ...filters,
            PageNumber: newPageNumber + 1,
          },
        });
      }
    },
    [filters, onChangeFilters, pagination],
  );

  const changeRowsPerPageHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newPageSize = parseInt(event.target.value, 10);

      setRowsPerPage(newPageSize);
      setPage(0);

      if (onChangeFilters && pagination && filters?.PageSize !== undefined) {
        onChangeFilters({
          type: TableFilterAction.UPDATE_FILTERS,
          value: {
            ...filters,
            PageSize: newPageSize,
            PageNumber: 1,
          },
        });
      }
    },
    [filters, onChangeFilters, pagination],
  );

  const paginator = useMemo(() => {
    return (
      <TablePagination
        className={className}
        rowsPerPageOptions={rowsPerPageOptions || [20, 40, 60, 80, 100]}
        count={pagination && typeof pagination === 'object' ? pagination.TotalCount : rowsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={changePageHandler}
        onRowsPerPageChange={changeRowsPerPageHandler}
        labelRowsPerPage={t('table.pagination.rowsPerPage')}
        showFirstButton
        showLastButton
        showRowsLabel={showRowsLabel}
      />
    );
  }, [
    changePageHandler,
    changeRowsPerPageHandler,
    className,
    page,
    pagination,
    rowsCount,
    rowsPerPage,
    rowsPerPageOptions,
    showRowsLabel,
    t,
  ]);

  return {
    paginator,
    page,
    setPage,
    rowsPerPage,
  };
};

export default useTablePagination;
