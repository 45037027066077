export const DataSourceTypes = [
  'residentSuppliers',
  'residentProperties',
  'residentBankAccounts',
  'residentWorkOrders',
  'residentCategories',
] as const;

export type DataSourceTypesType = (typeof DataSourceTypes)[number];

export const DataSourceTypesMap: { [key in DataSourceTypesType]: number } = {
  residentSuppliers: 1,
  residentProperties: 2,
  residentBankAccounts: 3,
  residentWorkOrders: 4,
  residentCategories: 5,
};
