import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import useGeneralErrorHandler from '../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../error-handlers/useOnError';
import { queryClient } from '../../../../queryClient';
import { getToVerifyInvoicesQueryKey } from '../../../queries/queryKeys';
import { InvoiceSupplierMatchMutationWithValidationPropsInterface } from './upload/InvoiceSupplierMatchMutationProps.interface';
import { SupplierMatchPayloadInterface } from '../../../../../types/upload-hub/invoice/supplier-match/SupplierMatchPayload.interface';
import { useAlert } from '../../../../alert/Alert.provider';

const useSupplierMatchMutation = (
  { setOtherError }: InvoiceSupplierMatchMutationWithValidationPropsInterface = {
    setValidationError: () => {},
  },
) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateInvoiceSupplierMatching);

  // TODO: handle not found supplier or something if needed
  // console.log(setValidationError);
  // const uniqueNameErrorHandler = useRoleUniqueNameErrorHandler(setValidationError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async ({ id, payload }: SupplierMatchPayloadInterface) => {
      const res = await request({ data: { ...payload }, urlParams: { id } });

      return { data: res.data, id, payload };
    },
    {
      onSuccess: (_, { supplierName, fileName: invoiceName }: SupplierMatchPayloadInterface) => {
        queryClient.invalidateQueries(getToVerifyInvoicesQueryKey);

        showAlert(
          <Trans
            i18nKey="invoices.tabs.toVerify.supplierMatchModal.success"
            values={{ supplierName, invoiceName }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useSupplierMatchMutation;
