export enum ResidentInvoiceDataEnum {
  INVOICE_REFERENCE,
  PROPERTY,
  BANK_ACCOUNT,
  INVOICE_DATE,
  SUPPLIER,
  PAYMENT_DUE_DATE,
  PERIOD_START,
  PERIOD_END,
  WORKS_ORDER,
  ITEM_DESCRIPTION,
  ITEM_CATEGORY,
  ITEM_AMOUNT,
  ITEM_VAT,
  ITEM_TYPE,
  ITEM_SCHEDULE,
  TOTAL_AMOUNT,
}
