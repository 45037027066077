import { useMutation } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../api';

const useInvoiceUploadMutation = () => {
  const { request } = useRequest(ApiOperation.UploadInvoice);

  return useMutation(async (file: File) => {
    const data = new FormData();
    data.append('Invoice', file);
    data.append('EmailAddress', '');
    const res = await request({ data });

    return res.data;
  });
};

export default useInvoiceUploadMutation;
