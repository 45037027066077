import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewPropsInterface } from './interfaces/FilePreviewProps.interface';
import { FileMimeTypeEnum } from './enums/FileMimeTypeEnum';
import PdfPreview from './components/pdf-preview/PdfPreview.component';
import ImagePreview from './components/image-preview/ImagePreview.component';
import useFile from '../../../utils/hooks/file/useFile.hook';

export const maxScale = 2;
export const minScale = 0.75;

const FilePreviewComponent: FC<FilePreviewPropsInterface> = ({
  fileName,
  path,
  stroke,
}): ReactElement => {
  const { t } = useTranslation();
  const { fileType } = useFile('', fileName);

  if (fileType === FileMimeTypeEnum.PDF) {
    return <PdfPreview path={path} stroke={stroke} />;
  }

  if (
    fileType === FileMimeTypeEnum.JPEG ||
    fileType === FileMimeTypeEnum.JPG ||
    fileType === FileMimeTypeEnum.PNG
  ) {
    return <ImagePreview path={path} stroke={stroke} />;
  }

  return (
    <div style={{ textAlign: 'center', padding: '1.25rem' }} data-cy="file-preview-error">
      {t('filePreview.errors.notFound')}
    </div>
  );
};

const FilePreview = memo(FilePreviewComponent);

export default FilePreview;
