import { useTranslation } from 'react-i18next';

export const useEmailValidator = () => {
  const { t } = useTranslation();

  return {
    options: {
      required: t('landingPage.loginForm.controls.email.errors.required'),
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: t('landingPage.loginForm.controls.email.errors.pattern'),
      },
    },
  };
};
