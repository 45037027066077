import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import { InvoiceFieldInterface } from '../../../../../../../../../../../types/upload-hub/invoice/InvoiceField.interface';
import { InvoiceItemInterface } from '../../../../../../../../../../../types/upload-hub/invoice/InvoiceItem.interface';
import { InvoiceMatchingInterface } from '../../../../../../../../../../../types/upload-hub/invoice/matching/InvoiceMatching.interface';
import { MatchTagInterface } from '../components/match-tag/interfaces/MatchTag.interface';
import { ResidentInvoiceDataTypeMap } from '../../../../../../../../../../../types/upload-hub/invoice/ResidentInvoiceData.type';
import MatchTagComponent from '../components/match-tag/MatchTag.component';
import { InvoiceTagInterface } from '../../../../../../../../../../../types/upload-hub/invoice/InvoiceTag.interface';
import { useVerificationFormState } from '../../../services/VerificationForm.provider';

const useMatchInvoiceFields = (invoice: InvoiceMatchingInterface, containerId: string) => {
  const { t } = useTranslation();
  const { setIsFormDirty } = useVerificationFormState();

  const mapInvoiceTag = useCallback(
    (tag: InvoiceTagInterface): MatchTagInterface => {
      const residentInvoiceDataType = ResidentInvoiceDataTypeMap[tag.tag];

      if (!residentInvoiceDataType) {
        return {
          id: '',
          label: '',
        };
      }

      return {
        id: String(tag.tag),
        // @ts-ignore
        label: t(`invoices.residentInvoiceDataTypes.${residentInvoiceDataType}`),
      };
    },
    [t],
  );

  const allFields = useMemo(
    () => [
      ...invoice.fields,
      ...invoice.items.reduce((acc: InvoiceFieldInterface[], item: InvoiceItemInterface) => {
        return [...acc, ...item.fields];
      }, []),
    ],
    [invoice.fields, invoice.items],
  );

  const isTagAvailable = useCallback(
    (tag: MatchTagInterface): boolean => {
      const matchedTag = Object.entries(invoice ? invoice.residentInvoiceDataMapping : {}).find(
        (matchDataItem: [string, number]) => tag.id === String(matchDataItem[1]),
      );

      return (
        !matchedTag ||
        !allFields.find(
          (field: InvoiceFieldInterface) => matchedTag && field.name === matchedTag[0],
        )
      );
    },
    [allFields, invoice],
  );

  const getTag = useCallback(
    (tagId: number | string | undefined): MatchTagInterface | undefined =>
      invoice.tags
        .map(mapInvoiceTag)
        .find((tag: MatchTagInterface) => tagId !== undefined && tag.id === String(tagId)),
    [invoice.tags, mapInvoiceTag],
  );

  const [tagsContainer, setTagsContainer] = useState(
    invoice.tags.map(mapInvoiceTag).filter(isTagAvailable),
  );

  const [matchMap, setMatchMap] = useState<Map<string, number>>(
    new Map(Object.entries(invoice ? invoice.residentInvoiceDataMapping : {})),
  );

  const onDragEndHandler = useCallback(
    (result: DropResult) => {
      if (!result.destination || !result.destination.droppableId) return;
      const { source, destination } = result;

      setIsFormDirty(true);

      if (source.droppableId !== destination.droppableId) {
        if (destination?.droppableId === containerId) {
          // SCENARIO 1: drag from field's slot to container
          const tagId = matchMap.get(source.droppableId);
          const tag = getTag(tagId);

          if (tag) {
            setMatchMap(() => {
              matchMap.delete(source.droppableId);
              return new Map(matchMap);
            });

            tagsContainer.splice(destination.index, 0, tag);
            setTagsContainer([...tagsContainer]);
          }
        } else if (source.droppableId === containerId) {
          // SCENARIO 2: drag from container to field's slot
          const copiedTags = [...tagsContainer];
          const [removedTypeItem] = copiedTags.splice(source.index, 1);

          if (removedTypeItem) {
            setMatchMap(() => {
              matchMap.forEach((value, key) => {
                if (value === Number(removedTypeItem.id)) {
                  matchMap.delete(key);
                }
              });

              return new Map(matchMap.set(destination.droppableId, Number(removedTypeItem.id)));
            });
            setTagsContainer(copiedTags);
          }
        } else {
          // SCENARIO 3: drag from field's slot to another field's slot
          const tagId = matchMap.get(source.droppableId);
          const tag = getTag(tagId);

          if (tag) {
            setMatchMap(() => {
              matchMap.delete(source.droppableId);
              matchMap.set(destination?.droppableId, Number(tag.id));

              return new Map(matchMap);
            });
          }
        }
      } else {
        const copiedTags = [...tagsContainer];
        const [removed] = copiedTags.splice(source.index, 1);

        copiedTags.splice(destination.index, 0, removed);
        setTagsContainer(copiedTags);
      }
    },
    [containerId, getTag, matchMap, tagsContainer],
  );

  const draggableItem = useCallback(
    (tag: MatchTagInterface) =>
      (
        { innerRef, draggableProps, dragHandleProps }: DraggableProvided,
        { isDragging }: DraggableStateSnapshot,
      ) =>
        (
          <div
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            style={{
              userSelect: 'none',
              margin: '0 0 8px 0',
              minHeight: '50px',
              borderColor: isDragging ? '#263B4A' : '#456C86',
              ...draggableProps.style,
            }}
            data-cy="match-tag"
          >
            <MatchTagComponent label={tag.label} />
          </div>
        ),
    [],
  );

  const droppableContainer = useCallback(
    (dropProvider: DroppableProvided, snapshot: DroppableStateSnapshot) => (
      <div
        ref={dropProvider.innerRef}
        data-cy="tags-container"
        style={{
          backgroundColor: snapshot.isDraggingOver ? '#edf8f7' : 'white',
          height: '100%',
          padding: '40px',
          borderRadius: '8px',
          border: snapshot.isDraggingOver ? '1px solid #309591' : '1px dashed #C4C4C4',
          transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }}
        {...dropProvider.droppableProps}
      >
        {tagsContainer.map((tag: MatchTagInterface, index: number) => (
          <Draggable draggableId={tag.id} index={index} key={tag.id}>
            {draggableItem(tag)}
          </Draggable>
        ))}

        {dropProvider.placeholder}
      </div>
    ),
    [draggableItem, tagsContainer],
  );

  return {
    matchMap,
    droppableContainer,
    getTag,
    onDragEndHandler,
  };
};

export default useMatchInvoiceFields;
