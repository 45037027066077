import React, { FC } from 'react';
import { Switch as MatSwitch, SwitchProps } from '@mui/material';
import classnames from 'classnames';
import './Switch.component.scss';

const Switch: FC<SwitchProps> = (props: SwitchProps) => {
  const { className } = props;

  return <MatSwitch {...props} className={classnames('data-hub-switch', className)} />;
};

export default Switch;
