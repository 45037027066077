import { useRequest } from '@datahub/api-client';
import { TableFiltersInterface } from '../../../../../../views/components/table/interfaces/TableFilters.interface';
import { ApiOperation } from '../../../../../api';
import { ToApproveInvoiceResponseType } from '../../../../../../types/upload-hub/invoice/to-approve/ToApproveInvoiceResponse.type';
import usePaginatedListQuery from '../../../../pagination/usePaginatedList.query';
import { getToApproveInvoicesQueryKey } from '../../../queryKeys';

const useGetToApproveInvoicesListQuery = (filters?: TableFiltersInterface) => {
  const { request } = useRequest<ToApproveInvoiceResponseType>(ApiOperation.GetToApproveInvoices);
  const { data, ...rest } = usePaginatedListQuery<ToApproveInvoiceResponseType>(
    request,
    getToApproveInvoicesQueryKey,
    filters,
  );

  return {
    data: data?.responseModel,
    ...rest,
  };
};

export default useGetToApproveInvoicesListQuery;
