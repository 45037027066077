import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../api';
import { InvoiceDetailsResponseType } from '../../../../../../types/upload-hub/invoice/details/InvoiceDetailsResponse.type';
import { getInvoiceDetailsQueryKey } from '../../../queryKeys';

const useGetInvoiceDetailsQuery = (id: string) => {
  const { request } = useRequest<InvoiceDetailsResponseType>(ApiOperation.GetInvoiceDetails);

  return useQuery([getInvoiceDetailsQueryKey, id], async () => {
    const res = await request({ urlParams: { id } });

    return res.data.responseModel;
  });
};

export default useGetInvoiceDetailsQuery;
