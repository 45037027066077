import React, {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Grid, InputAdornment, Table, TableBody, TableContainer } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';
import classes from './Table.module.scss';
import TableHeaderComponent from './components/table-header/TableHeader.component';
import { TableActionInterface, TablePropsInterface } from './interfaces/TableProps.interface';
import { TableRowInterface } from './interfaces/TableRow.interface';
import TableToolbarComponent from './components/table-toolbar/TableToolbar.component';
import { Input } from '../material';
import { TableFilterAction } from './hooks/TableFilters.hook';
import { TableActionNameEnum } from './enums/TableActionName.enum';
import TableEmptyComponent from './components/table-empty/TableEmpty.component';
import TableRowComponent from './components/table-row/TableRow.component';
import useTablePagination from './hooks/TablePagination.hook';

export type Order = 'asc' | 'desc';

const TableComponent = <T,>({
  table,
  onRowClick,
  actions,
  onActionSelected,
  onActionCompleted,
  isActionDisabled,
  multiselect,
  className,
  rowsPerPageOptions,
  filters,
  onChangeFilters,
  children,
  pagination,
  toolbar,
  isLoading = true,
  emptyLabel,
  expandable,
}: TablePropsInterface<T> & { children?: ReactNode }) => {
  const { t } = useTranslation();

  const {
    paginator: tablePaginator,
    page,
    rowsPerPage,
  } = useTablePagination({
    rowsCount: table.rows.length,
    rowsPerPageOptions,
    pagination,
    filters,
    onChangeFilters,
    className: classes.table__pagination,
  });

  const initOrderBy = useMemo((): string => {
    if (!filters || !filters.OrderBy) {
      return '';
    }

    const orderByParts = filters.OrderBy.split(' ');

    if (![1, 2].includes(orderByParts.length)) {
      return '';
    }

    return orderByParts[0];
  }, [filters]);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string | number>(initOrderBy);

  const onSortHandler = useCallback(
    (event: MouseEvent<unknown>, columnName: string | number) => {
      if (onChangeFilters && filters?.OrderBy !== undefined) {
        const newOrder = orderBy === columnName && order === 'asc' ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(columnName);

        onChangeFilters({
          type: TableFilterAction.UPDATE_FILTERS,
          value: {
            ...filters,
            OrderBy: `${columnName} ${newOrder}`.trim(),
          },
        });
      }
    },
    [filters, onChangeFilters, order, orderBy],
  );

  const totalPages = useMemo(
    () => (pagination && typeof pagination !== 'boolean' ? pagination.TotalPages : 1),
    [pagination],
  );
  const [selectedRows, setSelectedRows] = useState<string[][]>([[]]);

  const initSelectedRowsState = useMemo(
    () => Array.from(Array(totalPages).keys()).map((): string[] => []),
    [totalPages],
  );

  useEffect(() => {
    setSelectedRows(initSelectedRowsState);
  }, [initSelectedRowsState]);

  const isRowSelected = (rowId: string) => selectedRows[page]?.indexOf(rowId) !== -1;

  const assignSelectedRowsToPage = (rows: string[]): void => {
    setSelectedRows((currSelectedRows: string[][]) => {
      const newSelectedRows = [...currSelectedRows];
      newSelectedRows[page] = rows;

      return newSelectedRows;
    });
  };

  const onSelectRowHandler = (event: ChangeEvent<HTMLInputElement>, rowId: string): void => {
    const selectedIndex = selectedRows[page].indexOf(String(rowId));
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows[page], rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows[page].slice(1));
    } else if (selectedIndex === selectedRows[page].length - 1) {
      newSelected = newSelected.concat(selectedRows[page].slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows[page].slice(0, selectedIndex),
        selectedRows[page].slice(selectedIndex + 1),
      );
    }

    assignSelectedRowsToPage(newSelected);
  };

  const flattenedRows = useMemo(() => {
    return ([] as string[]).concat(...selectedRows);
  }, [selectedRows]);

  const onSelectAllRowsHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked && selectedRows[page].length === 0) {
      const newSelected = table.rows
        .filter((row: TableRowInterface) => !row.disabled)
        .map((row: TableRowInterface) => String(row.id));

      assignSelectedRowsToPage(newSelected);

      return;
    }

    assignSelectedRowsToPage([]);
  };

  const searchInputHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (value !== undefined && filters?.SearchKeyword !== undefined && onChangeFilters) {
        onChangeFilters({
          type: TableFilterAction.UPDATE_FILTERS,
          value: {
            ...filters,
            SearchKeyword: value.trim(),
          },
        });
      }
    },
    [filters, onChangeFilters],
  );

  const debouncedSearchInputHandler = useMemo(
    () => debounce(searchInputHandler, 500),
    [searchInputHandler],
  );

  const isMultiselectAction = (action: TableActionInterface<T>) => action.multiselect;

  const tableRows =
    pagination && typeof pagination === 'boolean'
      ? table.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : table.rows;

  const onActionSelectedHandler = (
    actionName: T | TableActionNameEnum,
    rowId: string[] | number[] | string | number,
    columnName?: string | number,
    state?: boolean,
  ) => {
    if (onActionSelected) {
      onActionSelected(actionName, rowId, columnName, state);
    }
  };

  useEffect(() => {
    if (onActionCompleted) {
      const actionCompletedPromises = Array.isArray(onActionCompleted)
        ? onActionCompleted
        : [onActionCompleted];

      Promise.all(actionCompletedPromises).then(() => {
        setSelectedRows(initSelectedRowsState);
      });
    }
  }, [initSelectedRowsState, onActionCompleted]);

  const hasActiveRows = table.rows.filter((row) => !row.disabled).length > 0;

  return (
    <div className={classNames([classes.table, className])}>
      {(filters?.SearchKeyword !== undefined || toolbar) && (
        <Grid container columnSpacing={4} rowSpacing={4} className={classes.table__toolbar}>
          {filters?.SearchKeyword !== undefined && (
            <Grid item xs={12} md={6}>
              <Input
                id="search"
                type="text"
                placeholder={t('global.search')}
                className={classes.toolbar__search}
                fullWidth
                onChange={debouncedSearchInputHandler}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon aria-label={t('global.search')} />
                  </InputAdornment>
                }
              />
            </Grid>
          )}

          {toolbar && (
            <Grid
              item
              xs={12}
              md={6}
              className={classNames(
                classes.toolbar__actions,
                filters?.SearchKeyword !== undefined
                  ? classes['toolbar__actions--with-search']
                  : '',
              )}
            >
              {toolbar}
            </Grid>
          )}
        </Grid>
      )}

      {children && <div className={classes['table__top-content']}>{children}</div>}

      <TableContainer className={classes['MuiTableContainer-root']}>
        {multiselect && flattenedRows.length > 0 && (
          <TableToolbarComponent
            onSelectAllRows={onSelectAllRowsHandler}
            rowsCount={table.rows.length}
            actions={actions ? actions.filter(isMultiselectAction) : []}
            onActionSelected={(action: TableActionInterface<T>) =>
              onActionSelectedHandler(action.name, flattenedRows)
            }
            pageNumber={page}
            selectedRows={selectedRows}
            multiselect={hasActiveRows}
          />
        )}

        <Table aria-label="data-hub-table">
          <TableHeaderComponent
            columns={table.columns}
            onSelectAllRows={onSelectAllRowsHandler}
            rowsCount={table.rows.length}
            selectedRowsCount={flattenedRows.length}
            multiselect={!!multiselect && hasActiveRows}
            order={order}
            orderBy={orderBy}
            onSort={onSortHandler}
            pageNumber={page}
            selectedRows={selectedRows}
          />

          <TableBody>
            {tableRows.map((row: TableRowInterface) => (
              <TableRowComponent
                key={row.id}
                row={row}
                isRowSelected={isRowSelected}
                onSelectRow={onSelectRowHandler}
                onClickRow={onRowClick}
                columns={table.columns}
                onActionSelected={onActionSelectedHandler}
                actions={actions || []}
                multiselect={multiselect}
                isActionDisabled={isActionDisabled}
                expandable={expandable}
                disabled={row.disabled}
                loading={row.loading}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!isLoading && tableRows.length <= 0 && <TableEmptyComponent label={emptyLabel} />}

      {pagination && tablePaginator}
    </div>
  );
};

export default TableComponent;
