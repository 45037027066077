import React, { useMemo, useState } from 'react';
import DeleteRoleModalComponent from '../DeleteRoleModal.component';
import { RoleInterface } from '../../../../../../../../../../types/roles/Role.interface';

const useDeleteRole = () => {
  const [deleteRoleOpen, setDeleteRoleOpen] = useState(false);
  const [deleteRoleData, setDeleteRoleData] = useState<RoleInterface | undefined>(undefined);

  const deleteRoleModal = useMemo(() => {
    if (!deleteRoleData) {
      return <> </>;
    }

    return (
      <DeleteRoleModalComponent
        open={deleteRoleOpen}
        setOpen={setDeleteRoleOpen}
        role={deleteRoleData}
      />
    );
  }, [deleteRoleData, deleteRoleOpen]);

  return { deleteRoleModal, setDeleteRoleOpen, setDeleteRoleData };
};

export default useDeleteRole;
