import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../../../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../../../../../../components/modal/types/Props.interface';
import DeleteRole from './DeleteRole.view';
import { RoleInterface } from '../../../../../../../../../types/roles/Role.interface';

interface DeleteRoleModalComponentPropsInterface extends ModalControlPropsInterface {
  role: RoleInterface | undefined;
}

const DeleteRoleModalComponent = ({
  role,
  open,
  setOpen,
}: DeleteRoleModalComponentPropsInterface) => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t('settings.sections.permission.subsections.role.deleteModal.title')}
      open={open}
      handleClose={handleClose}
      variant="warning"
      content={role && <DeleteRole role={role} close={handleClose} />}
    />
  );
};

export default DeleteRoleModalComponent;
