import { Grid } from '@mui/material';
import React, { FC, ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SyncIcon from '@mui/icons-material/Sync';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useGetDataSourcesListQuery from '../../services/react-query-hooks/queries/data-source/useGetDataSourcesList.query';
import { Button, Chip } from '../components/material';
import classes from './DataSources.module.scss';
import { TableColumnInterface } from '../components/table/interfaces/TableColumn.interface';
import TableComponent from '../components/table/Table.component';
import { DataSourceInterface } from '../../types/data-source/DataSource.interface';
import { TableRowInterface } from '../components/table/interfaces/TableRow.interface';
import { DataSourceTypes } from '../../types/data-source/DataSourceType.type';
import { DataSourceOrigins } from '../../types/data-source/DataSourceOrigin.type';
import useTableFilters from '../components/table/hooks/TableFilters.hook';
import { TableActionInterface } from '../components/table/interfaces/TableProps.interface';
import { TableColumnTypeEnum } from '../components/table/enums/TableColumnType.enum';
import { AlignEnum } from '../../enums/Align.enum';
import { TableActionNameEnum } from '../components/table/enums/TableActionName.enum';
import useDate from '../../utils/hooks/date-format/useDate';
import SettingsSection from '../home-page/components/settings/components/settings-section/SettingsSection.component';
import { DataSourceActionNameEnum } from './enums/DataSourceActionName.enum';
import useSyncDataSourceMutation from '../../services/react-query-hooks/mutations/data-source/useSyncDataSource.mutation';
import useAddDataSource from './components/add-data-source/hooks/useAddDataSource.hook';
import useDeleteDataSource from './components/delete-data-source/hooks/useDeleteDataSource.hook';
import useUpdateDataSource from './components/update-data-source/hooks/useUpdateDataSource.hook';
import { queryClient } from '../../services/queryClient';
import { syncDataSourceMutationKey } from '../../services/react-query-hooks/mutations/mutationKeys';

const DataSourcesComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { format } = useDate({ time: true });
  const { filters, dispatchFilters } = useTableFilters({
    OrderBy: 'name asc',
  });
  const { data, pagination } = useGetDataSourcesListQuery(filters);
  const getDataSource = useCallback(
    (dataSourceId: string | number) =>
      data?.find((dataSource: DataSourceInterface) => dataSource.uniqueNumber === dataSourceId),
    [data],
  );

  const { addDataSourceModal, setAddSourceOpen } = useAddDataSource();
  const { deleteDataSourceModal, setDeleteSourceOpen, setDeleteSourceData } = useDeleteDataSource();
  const { updateDataSourceModal, setUpdateSourceModalOpen, setUpdateDataSourceData } =
    useUpdateDataSource();

  const { mutate: syncDataSource } = useSyncDataSourceMutation();

  const dataSourcesColumns: TableColumnInterface[] = [
    {
      name: 'name',
      label: t('dataSourcesSettings.sections.dataSources.subsections.supplierList.columns.name'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '15rem',
    },
    {
      name: 'type',
      label: t('dataSourcesSettings.sections.dataSources.subsections.supplierList.columns.type'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: false,
      width: '10rem',
    },
    {
      name: 'origin',
      label: t('dataSourcesSettings.sections.dataSources.subsections.supplierList.columns.source'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: false,
      width: '5rem',
    },
    {
      name: 'autoSync',
      label: t(
        'dataSourcesSettings.sections.dataSources.subsections.supplierList.columns.autoSync',
      ),
      align: AlignEnum.CENTER,
      type: TableColumnTypeEnum.DEFAULT,
      width: '12.5rem',
    },
    {
      name: 'lastSyncDate',
      label: t(
        'dataSourcesSettings.sections.dataSources.subsections.supplierList.columns.lastSync',
      ),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.DEFAULT,
      width: '12.5rem',
      sortable: true,
    },
    {
      name: 'actions',
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.ACTIONS,
      width: '4rem',
    },
  ];

  const actions: TableActionInterface<DataSourceActionNameEnum>[] = [
    {
      name: DataSourceActionNameEnum.SYNC,
      icon: <SyncIcon />,
      label: t(
        'dataSourcesSettings.sections.dataSources.subsections.supplierList.actions.sync.title',
      ),
      // TODO: support multiselect?
      multiselect: false,
    },
    {
      name: TableActionNameEnum.EDIT,
      icon: <EditOutlinedIcon />,
      label: t('global.edit'),
    },
    // {
    //   name: TableActionNameEnum.DOWNLOAD,
    //   icon: <FileDownloadOutlinedIcon />,
    //   label: t('global.download'),
    //   multiselect: true,
    // },
    {
      name: TableActionNameEnum.DELETE,
      icon: <ClearOutlinedIcon />,
      label: t('global.delete'),
      // TODO: support multiselect
      multiselect: false,
    },
  ];

  const mapDataSourceToRow = (dataSource: DataSourceInterface): TableRowInterface => ({
    ...dataSource,
    id: dataSource.uniqueNumber,
    type: t(
      `dataSourcesSettings.sections.dataSources.subsections.supplierList.types.${
        DataSourceTypes[dataSource.type - 1]
      }`,
    ),
    origin: t(
      `dataSourcesSettings.sections.dataSources.subsections.supplierList.origins.${
        DataSourceOrigins[dataSource.origin - 1]
      }`,
    ),
    autoSync: dataSource.autoSync ? (
      <Chip label={t('global.on')} color="primary" uppercase variant="outlined" />
    ) : (
      ''
    ),
    lastSyncDate: dataSource.lastSync ? format(dataSource.lastSync) : '',
    loading:
      queryClient.isMutating({
        mutationKey: syncDataSourceMutationKey,
        predicate: (m) => m.options.variables?.uniqueNumber === dataSource.uniqueNumber,
      }) > 0,
  });

  const onActionSelectedHandler = (
    actionName: DataSourceActionNameEnum | TableActionNameEnum,
    rowId: string[] | number[] | string | number,
  ) => {
    const isMultiple = Array.isArray(rowId);

    switch (actionName) {
      case DataSourceActionNameEnum.SYNC: {
        if (!isMultiple) {
          const dataSource = getDataSource(rowId);

          if (dataSource) {
            syncDataSource(dataSource);
          }
        }

        break;
      }

      case TableActionNameEnum.DELETE: {
        if (!isMultiple) {
          const dataSource = getDataSource(rowId);

          if (dataSource) {
            setDeleteSourceData(dataSource);
            setDeleteSourceOpen(true);
          }
        }

        break;
      }

      case TableActionNameEnum.EDIT: {
        if (!isMultiple) {
          const dataSource = getDataSource(rowId);

          if (dataSource) {
            setUpdateDataSourceData({
              id: dataSource.uniqueNumber,
              name: dataSource.name,
              type: dataSource.type,
              origin: dataSource.origin,
              autoSync: dataSource.autoSync,
            });
            setUpdateSourceModalOpen(true);
          }
        }

        break;
      }

      default:
    }
  };

  const toolbarContent = useMemo(
    () => (
      <Button
        startIcon={<AddOutlinedIcon />}
        onClick={() => setAddSourceOpen(true)}
        variant="outlined"
      >
        {t(
          'dataSourcesSettings.sections.dataSources.subsections.supplierList.addDataSourceModal.button',
        )}
      </Button>
    ),
    [setAddSourceOpen, t],
  );

  return (
    <Grid className={classes['data-sources']}>
      <SettingsSection title={t('dataSourcesSettings.sections.dataSources.title')}>
        <SettingsSection
          title={t('dataSourcesSettings.sections.dataSources.subsections.supplierList.title')}
          subsection
        >
          <TableComponent
            table={{
              rows: data ? data.map(mapDataSourceToRow) : [],
              columns: dataSourcesColumns,
            }}
            className={classes['user-permission__table']}
            actions={actions}
            onActionSelected={onActionSelectedHandler}
            // TODO: support multiselect
            multiselect={false}
            filters={filters}
            onChangeFilters={dispatchFilters}
            toolbar={toolbarContent}
            pagination={pagination}
          />

          {addDataSourceModal}
          {deleteDataSourceModal}
          {updateDataSourceModal}
        </SettingsSection>
      </SettingsSection>
    </Grid>
  );
};

export default DataSourcesComponent;
