import {
  Fade,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import classNames from 'classnames';
import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AlignEnum } from '../../../../../../../../enums/Align.enum';
import InvoiceFileInterface from '../../../../../../../../types/invoice-upload/InvoiceFile.interface';
import { Button } from '../../../../../../../components/material';
import UploadStatusChipComponent from '../status-chip/UploadStatusChip.component';
import classes from './ProgressModalContent.module.scss';
import useDelay from '../../../../../../../../utils/hooks/useDelay.hook';
import PortalComponent from '../../../../../../../components/portal/Portal.component';

interface ProgressModalContentViewPropsInterface {
  files: InvoiceFileInterface[];
  handleClose: () => void;
  inProgress: boolean;
}

const ProgressModalContentView = ({
  files,
  handleClose,
  inProgress,
}: ProgressModalContentViewPropsInterface) => {
  const { t } = useTranslation();
  const { isAvailable: areButtonsReady } = useDelay();
  const modalContainerRef = useRef() as MutableRefObject<HTMLDivElement>;

  return (
    <Grid ref={modalContainerRef}>
      <TableContainer className={classes['table-container']}>
        <Table aria-label="progress-table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classNames(classes.cell, classes['cell--name'])}
                align={AlignEnum.CENTER}
              >
                {t('invoices.invoiceUpload.headers.fileName')}
              </TableCell>
              <TableCell
                className={classNames(classes.cell, classes.cell__progress)}
                align={AlignEnum.CENTER}
              >
                {t('invoices.invoiceUpload.headers.progress')}
              </TableCell>
              <TableCell
                className={classNames(classes.cell, classes['cell--status'])}
                align={AlignEnum.CENTER}
              >
                {t('invoices.invoiceUpload.headers.status')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file) => (
              <TableRow key={file.uuid}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classNames(classes.cell, classes['cell--row'], classes['cell--name'])}
                  align={AlignEnum.LEFT}
                >
                  {file.file.name}
                </TableCell>
                <TableCell
                  component="th"
                  className={classNames(classes.cell, classes['cell--row'])}
                  align={AlignEnum.CENTER}
                >
                  {file.status === 'uploading' && (
                    <div className={classes.progress}>
                      <LinearProgress className={classes.progress__bar} />
                    </div>
                  )}
                </TableCell>
                <TableCell
                  component="th"
                  className={classNames(
                    classes.cell,
                    classes['cell--row'],
                    classes['cell--status'],
                  )}
                  align={AlignEnum.CENTER}
                >
                  <UploadStatusChipComponent status={file.status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {areButtonsReady && (
        <PortalComponent id="modalActions" anchorEl={modalContainerRef.current}>
          <Fade in={areButtonsReady}>
            <div className={classes.buttons}>
              <Button onClick={handleClose} variant={inProgress ? 'text' : 'contained'}>
                {inProgress ? t('global.cancel') : t('global.done')}
              </Button>
            </div>
          </Fade>
        </PortalComponent>
      )}
    </Grid>
  );
};

export default ProgressModalContentView;
