import React, { FC, ReactElement } from 'react';
import { MatchInvoiceFieldsPropsInterface } from './interfaces/MatchInvoiceFieldsProps.interface';
import MatchInvoiceFieldsFormComponent from './components/match-invoice-fields-form/MatchInvoiceFieldsForm.component';
import useGetInvoiceMatchingQuery from '../../../../../../../../../../services/react-query-hooks/queries/upload-hub/invoice/useGetInvoiceMatching.query';
import LoadingGridContainerComponent from '../../../../../../../../../components/loading/LoadingGridContainer.component';

const VerifyInvoiceDataComponent: FC<MatchInvoiceFieldsPropsInterface> = ({ id }): ReactElement => {
  const { data: invoice, isLoading } = useGetInvoiceMatchingQuery(id);

  if (isLoading || !invoice) {
    return <LoadingGridContainerComponent />;
  }

  return <MatchInvoiceFieldsFormComponent invoice={invoice} />;
};

export default VerifyInvoiceDataComponent;
