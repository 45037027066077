import React, {
  createContext,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  useContext,
} from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import classNames from 'classnames';
import { MenuItem } from '../../menu-item/MenuItem.component';
import useVirtualListRef from './hooks/useVirtualListRef.hook';

const listBoxPadding = 8;

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref: ForwardedRef<HTMLDivElement>) => {
  const outerProps = useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

const listBoxItem = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top as number,
  };

  return (
    <MenuItem
      {...dataSet[0]}
      style={inlineStyle}
      key={dataSet[1].value}
      selected={dataSet[2]}
      className={classNames(
        'data-hub-autocomplete-select__option',
        dataSet[1].accent ? 'data-hub-autocomplete-select__option--accent' : '',
      )}
    >
      <div>
        {dataSet[1].icon || ''}
        {dataSet[1].text}
      </div>
    </MenuItem>
  );
};

const ListBoxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  (props: HTMLAttributes<HTMLElement>, ref: ForwardedRef<HTMLDivElement>) => {
    let selectedItemIndex: number | undefined;

    const { children, ...other } = props;
    const itemData: ReactElement[] = [];
    (children as ReactElement[]).forEach(
      (item: ReactElement & { children?: ReactElement[] }, index: number) => {
        if (Array.isArray(item) && item[2]) {
          selectedItemIndex = index;
        }

        itemData.push(item);
        itemData.push(...(item.children || []));
      },
    );
    const itemCount = itemData.length;
    const itemSize = 44 + listBoxPadding / 2;

    const { ref: listRef } = useVirtualListRef(itemCount, itemSize, selectedItemIndex);

    return (
      <div className="data-hub-autocomplete-list-box" ref={ref} data-cy="autocomplete-list-box">
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={250 + 2 * listBoxPadding}
            width="100%"
            ref={listRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {listBoxItem}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

export default ListBoxComponent;
