import React, { FC } from 'react';
import classNames from 'classnames';
import useDate from '../../../../../../../../../../utils/hooks/date-format/useDate';
import { InvoiceNotePropsInterface } from './interfaces/InvoiceNoteProps.interface';
import classes from './InvoiceNote.module.scss';

const InvoiceNoteComponent: FC<InvoiceNotePropsInterface> = ({ note, date, className }) => {
  const { format: dateFormat } = useDate({ time: true });

  return (
    <div className={classNames(classes['invoice-note'], className)}>
      <div className={classes['invoice-note__content']}>
        <p>{note}</p>
        {date && <p className={classes.content__timestamp}>{dateFormat(date)}</p>}
      </div>
    </div>
  );
};

export default InvoiceNoteComponent;
