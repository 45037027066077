import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../api';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { queryClient } from '../../../../../queryClient';
import { getInProgressInvoicesQueryKey } from '../../../../queries/queryKeys';
import useSynchronizedInboxNotFoundErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/useSynchronizedInboxNotFoundError.handler';
import { useAlert } from '../../../../../alert/Alert.provider';
import { SynchronizedInboxMutationPropsInterface } from './SynchronizedInboxMutationProps.interface';
import { EmailProviderEnum } from '../../../../../../enums/upload-hub/settings/EmailProvider.enum';

const useSyncAllSynchronizedInboxesMutation = (
  { setOtherError, provider }: SynchronizedInboxMutationPropsInterface = {
    provider: EmailProviderEnum.SMTP,
  },
) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request: smtpSync } = useRequest(ApiOperation.SyncMailboxes);
  const { request: nylasSync } = useRequest(ApiOperation.NylasScheduleAccountsMessagesProcessing);

  const request = provider === EmailProviderEnum.SMTP ? smtpSync : nylasSync;

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const notFoundErrorHandler = useSynchronizedInboxNotFoundErrorHandler(setOtherError);

  const { onError } = useOnError([notFoundErrorHandler], generalErrorHandler);

  return useMutation(
    async () => {
      const res = await request({ data: {} });

      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getInProgressInvoicesQueryKey);

        showAlert(t('invoices.tabs.inProgress.actions.sync.success'), 'success');
      },
      onError,
    },
  );
};

export default useSyncAllSynchronizedInboxesMutation;
