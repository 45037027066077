import { useRequest } from '@datahub/api-client';
import { getSynchronizedInboxesQueryKey } from '../../queryKeys';
import { ApiOperation } from '../../../../api';
import { SynchronizedInboxResponseType } from '../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInboxResponse.type';
import { TableFiltersInterface } from '../../../../../views/components/table/interfaces/TableFilters.interface';
import usePaginatedListQuery from '../../../pagination/usePaginatedList.query';
import { EmailProviderEnum } from '../../../../../enums/upload-hub/settings/EmailProvider.enum';

const useGetSynchronizedInboxesListQuery = (
  filters?: TableFiltersInterface,
  provider: EmailProviderEnum = EmailProviderEnum.SMTP,
) => {
  const { request: getSmtp } = useRequest<SynchronizedInboxResponseType>(
    ApiOperation.GetSynchronizedInboxes,
  );
  const { request: getNylas } = useRequest<SynchronizedInboxResponseType>(
    ApiOperation.NylasGetAccounts,
  );

  const request = provider === EmailProviderEnum.NYLAS ? getNylas : getSmtp;

  const { data, ...rest } = usePaginatedListQuery<SynchronizedInboxResponseType>(
    request,
    getSynchronizedInboxesQueryKey,
    filters,
  );

  return {
    data: data?.responseModel,
    ...rest,
  };
};

export default useGetSynchronizedInboxesListQuery;
