import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import { getResidentSchedulesQueryKey } from '../../queryKeys';
import { ResidentSchedulesResponseType } from '../../../../../types/upload-hub/resident/schedule/ResidentSchedulesResponse.type';

const useGetResidentSchedulesQuery = (propertyId: string = '') => {
  const { request } = useRequest<ResidentSchedulesResponseType>(ApiOperation.GetResidentSchedules);

  return useQuery(
    [getResidentSchedulesQueryKey, propertyId],
    async () => {
      const res = await request({
        params: {
          PropertyId: propertyId,
        },
      });

      return res.data.responseModel.residentSchedules;
    },
    {
      enabled: false,
    },
  );
};

export default useGetResidentSchedulesQuery;
