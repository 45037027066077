import React, { FC, ReactElement, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import FormContainerComponent from '../../../components/form-container/FormContainer.component';
import classes from '../Landing-container.module.scss';
import useVerifyInvitation from './hooks/VerifyInvitation.hook';
import { useLandingPageContext } from '../../LandingPage.view';
import { InvitationStatusEnum } from '../../../../enums/user/InvitationStatus.enum';
import InvitationCompletedConfirmationComponent from './components/invitation-completed-confirmation/InvitationCompletedConfirmation.component';
// import ConfirmInvitationFormComponent from './components/confirm-invitation-form/ConfirmInvitationForm.component';
import ExternalLoginComponent from '../external-login/ExternalLogin.component';

export const ConfirmInvitationComponent: FC = (): ReactElement => {
  const { id } = useParams();
  const { isProcessing, status, invitationEmail, invitationId } = useVerifyInvitation(id);
  const [, setIsLoading] = useLandingPageContext();

  useEffect(() => {
    setIsLoading(isProcessing);
  }, [isProcessing, setIsLoading]);

  if (isProcessing) {
    return <> </>;
  }

  return (
    <FormContainerComponent
      className={classNames(
        classes['landing-container'],
        classes['landing-container--single-column'],
      )}
    >
      {status === InvitationStatusEnum.INVITATION_COMPLETED && (
        <InvitationCompletedConfirmationComponent email={invitationEmail} />
      )}

      {[InvitationStatusEnum.EMAIL_SENT, InvitationStatusEnum.INVITATION_CREATED].includes(
        status,
      ) && (
        <Grid container direction="row" justifyContent="center">
          {/* RES-532: Disable sign up with username and password + google until new integration comes in */}
          {/* <Grid className={classes['landing-container__form']} item xs={12} lg={8}> */}
          {/* <ConfirmInvitationFormComponent email={invitationEmail} invitationId={invitationId} /> */}
          {/* </Grid> */}
          <Grid className={classes['landing-container__external']} item xs={12} lg={8}>
            <ExternalLoginComponent invitationId={invitationId} />
          </Grid>
        </Grid>
      )}
    </FormContainerComponent>
  );
};

export default ConfirmInvitationComponent;
