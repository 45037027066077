import React, { ChangeEvent, FC, memo, ReactElement, useState } from 'react';
import { FormControlLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useUpdateAllowUsersToSeeOnlyInvoicesUploadedByThemMutation from '../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/settings/useUpdateAllowUsersToSeeOnlyInvoicesUploadedByThem.mutation';
import Switch from '../../../../../components/material/switch/Switch.component';
import classes from './GeneralSettings.module.scss';
import { GeneralSettingsPropsInterface } from './interfaces/GeneralSettingsProps.interface';
import useUpdateAutoSendInvoiceToOCRMutation from '../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/settings/useUpdateAutoSendInvoiceToOCR.mutation';
import useUpdateAutoSendInvoiceToIntegrationServiceMutation from '../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/settings/useUpdateAutoSendInvoiceToIntegrationService.mutation';
import useUpdateOcrProcessedPagesLimitMutation from '../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/settings/useUpdateOcrProcessedPagesLimit.mutation';
import NumberInput from '../../../../../components/number-input/NumberInput.component';

const GeneralSettingsComponent: FC<GeneralSettingsPropsInterface> = ({
  autoSendInvoicesToOCR,
  autoSendInvoicesToIntegrationService,
  ocrProcessedPagesLimit,
  allowUsersToSeeOnlyInvoicesUploadedByThem,
}): ReactElement => {
  const { t } = useTranslation();

  const [isAutoSendInvoicesToOCR, setIsAutoSendInvoicesToOCR] = useState(autoSendInvoicesToOCR);
  const { mutateDebounced: updateAutoSendToOCRInvoice } = useUpdateAutoSendInvoiceToOCRMutation();

  const [
    isAllowUsersToSeeOnlyInvoicesUploadedByThem,
    setIsAllowUsersToSeeOnlyInvoicesUploadedByThem,
  ] = useState(allowUsersToSeeOnlyInvoicesUploadedByThem);

  const { mutateDebounced: updateAllowUsersToSeeOnlyInvoicesUploadedByThem } =
    useUpdateAllowUsersToSeeOnlyInvoicesUploadedByThemMutation();

  const [isAutoSendInvoicesToIntegrationService, setIsAutoSendInvoicesToIntegrationService] =
    useState(autoSendInvoicesToIntegrationService);
  const { mutateDebounced: updateAutoSendToIntegrationServiceInvoice } =
    useUpdateAutoSendInvoiceToIntegrationServiceMutation();

  const { mutate: updateOcrProcessedPagesLimit } = useUpdateOcrProcessedPagesLimitMutation();

  const autoSendInvoiceToOCRChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAutoSendInvoicesToOCR(event.target.checked);

    updateAutoSendToOCRInvoice({
      automaticallySendInvoiceToOcr: event.target.checked,
    });
  };

  const allowUsersToSeeOnlyInvoicesUploadedByThemChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setIsAllowUsersToSeeOnlyInvoicesUploadedByThem(event.target.checked);

    updateAllowUsersToSeeOnlyInvoicesUploadedByThem({
      allowUsersToSeeOnlyInvoicesUploadedByThem: event.target.checked,
    });
  };

  const autoSendInvoiceToIntegrationServiceChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setIsAutoSendInvoicesToIntegrationService(event.target.checked);

    updateAutoSendToIntegrationServiceInvoice({
      automaticallySendInvoiceToIntegrationService: event.target.checked,
    });
  };

  const ocrProcessedPagesLimitChangeHandler = (val: number | '') => {
    updateOcrProcessedPagesLimit({
      ocrProcessedPagesLimit: Number(val),
    });
  };

  return (
    <Grid container direction="column" rowSpacing={2}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={isAutoSendInvoicesToOCR}
              onChange={autoSendInvoiceToOCRChangeHandler}
            />
          }
          label={
            <span className={classes['general-settings__form-label']}>
              {t(
                'pluginSettings.sections.uploadHub.subsections.general.controls.autoSendInvoicesToOCR.label',
              )}
            </span>
          }
        />
      </Grid>

      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={isAllowUsersToSeeOnlyInvoicesUploadedByThem}
              onChange={allowUsersToSeeOnlyInvoicesUploadedByThemChangeHandler}
            />
          }
          label={
            <span className={classes['general-settings__form-label']}>
              {t(
                'pluginSettings.sections.uploadHub.subsections.general.controls.allowUsersToSeeOnlyInvoicesUploadedByThem.label',
              )}
            </span>
          }
        />
      </Grid>

      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={isAutoSendInvoicesToIntegrationService}
              onChange={autoSendInvoiceToIntegrationServiceChangeHandler}
            />
          }
          label={
            <span className={classes['general-settings__form-label']}>
              {t(
                'pluginSettings.sections.uploadHub.subsections.general.controls.autoSendInvoicesToIntegrationService.label',
              )}
            </span>
          }
        />
      </Grid>

      <Grid item>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={classes['general-settings__form-label']} style={{ marginRight: '2rem' }}>
            {t(
              'pluginSettings.sections.uploadHub.subsections.general.controls.ocrProcessedPagesLimit.label',
            )}
          </span>
          <NumberInput
            id="ocrProcessedPagesLimit"
            defaultValue={ocrProcessedPagesLimit}
            min={1}
            max={100}
            onChange={ocrProcessedPagesLimitChangeHandler}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const GeneralSettings = memo(GeneralSettingsComponent);

export default GeneralSettings;
