import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { array, date, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fade, Grid } from '@mui/material';
import { isValid } from 'date-fns';
import { VerifyInvoiceDataFormPropsInterface } from './interfaces/VerifyInvoiceDataFormProps.interface';
import {
  InvoiceFieldValueInterface,
  InvoicePropertyInterface,
} from '../../../../../../../../../../../../types/upload-hub/invoice/InvoiceProperty.interface';
import { InvoiceFieldValueTypeEnum } from '../../../../../../../../../../../../enums/upload-hub/InvoiceFieldValueType.enum';
import {
  defaultValues,
  VerifyInvoiceDataFormInterface,
} from '../../interfaces/VerifyInvoiceDataForm.interface';
import VerifyInvoiceDataSectionComponent from '../verify-invoice-data-section/VerifyInvoiceDataSection.component';
import { useVerificationFormState } from '../../../../services/VerificationForm.provider';
import { FormControl } from '../../../../../../../../../../../components/material';
import useDate from '../../../../../../../../../../../../utils/hooks/date-format/useDate';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';
import useSupplierMatch from '../../../../../supplier-match/hooks/useSupplierMatch.hook';
import useCurrency from '../../../../../../../../../../../../utils/hooks/currency/useCurrency.hook';
import { percentageRegex } from '../../../../../../../../../../../../utils/Regex';
import PortalComponent from '../../../../../../../../../../../components/portal/Portal.component';
import useDelay from '../../../../../../../../../../../../utils/hooks/useDelay.hook';
import classes from '../../../../Verification.module.scss';
import { useUploadHub } from '../../../../../../../../providers/UploadHub.provider';
import InvoiceNoteComponent from '../../../note/InvoiceNote.component';

const VerifyInvoiceDataFormComponent: FC<VerifyInvoiceDataFormPropsInterface> = ({
  invoice,
  onClose,
  variant,
}): ReactElement => {
  const { t } = useTranslation();
  const { parse } = useDate();
  const { config } = useCurrency();
  const { isAvailable: arePortalsReady } = useDelay();

  const {
    fileName,
    currentStep,
    error,
    verificationButtons,
    approvalButtons,
    verificationContainerRef,
    setIsFormDirty,
    setValidationErrorSetter,
  } = useVerificationFormState();

  const { isFieldEnabledForApproval } = useUploadHub();

  const supplier = useMemo((): InvoiceFieldValueInterface | null => {
    const supplierProp = invoice.invoiceProperties.find(
      (property: InvoicePropertyInterface) =>
        property.residentFieldName === ResidentInvoiceDataEnum.SUPPLIER,
    );

    if (!supplierProp) {
      return null;
    }

    return supplierProp.fieldValue || null;
  }, [invoice.invoiceProperties]);

  const { supplierMatchModal, setMatchSupplierInvoiceOpen, setMatchSupplierInvoiceData } =
    useSupplierMatch('edit', onClose);

  const selectDropdownsResidentFields = [
    ResidentInvoiceDataEnum.PROPERTY,
    ResidentInvoiceDataEnum.BANK_ACCOUNT,
    ResidentInvoiceDataEnum.SUPPLIER,
    ResidentInvoiceDataEnum.WORKS_ORDER,
    ResidentInvoiceDataEnum.ITEM_CATEGORY,
    ResidentInvoiceDataEnum.ITEM_SCHEDULE,
  ];

  const mapPropertyEnabledForApproval = useCallback(
    (field: InvoicePropertyInterface) => {
      return isFieldEnabledForApproval(field.residentFieldName)
        ? field
        : { ...field, disabled: true };
    },
    [isFieldEnabledForApproval],
  );

  const residentDataSection = useMemo(() => {
    const residentDataResidentFields = [
      ResidentInvoiceDataEnum.SUPPLIER,
      ResidentInvoiceDataEnum.PROPERTY,
      ResidentInvoiceDataEnum.BANK_ACCOUNT,
    ];

    const residentData = invoice.invoiceProperties.filter((field: InvoicePropertyInterface) =>
      residentDataResidentFields.includes(field.residentFieldName),
    );

    return currentStep.name === 'approval'
      ? residentData.map(mapPropertyEnabledForApproval)
      : residentData;
  }, [currentStep.name, invoice.invoiceProperties, mapPropertyEnabledForApproval]);

  const invoiceDataSection = useMemo(() => {
    const invoiceDataResidentFields = [
      ResidentInvoiceDataEnum.INVOICE_REFERENCE,
      ResidentInvoiceDataEnum.INVOICE_DATE,
      ResidentInvoiceDataEnum.PAYMENT_DUE_DATE,
      ResidentInvoiceDataEnum.PERIOD_START,
      ResidentInvoiceDataEnum.PERIOD_END,
      ResidentInvoiceDataEnum.WORKS_ORDER,
      ResidentInvoiceDataEnum.TOTAL_AMOUNT,
    ];

    const invoiceData = invoice.invoiceProperties.filter((field: InvoicePropertyInterface) =>
      invoiceDataResidentFields.includes(field.residentFieldName),
    );

    return currentStep.name === 'approval'
      ? invoiceData.map(mapPropertyEnabledForApproval)
      : invoiceData;
  }, [currentStep, invoice.invoiceProperties, mapPropertyEnabledForApproval]);

  const invoiceItemsDataSection = useMemo(() => {
    const mapFieldPropertiesEnabledForApproval = (
      fieldProperties: InvoicePropertyInterface[],
    ): InvoicePropertyInterface[] => fieldProperties.map(mapPropertyEnabledForApproval);

    return currentStep.name === 'approval'
      ? invoice.invoiceItemsProperties.map(mapFieldPropertiesEnabledForApproval)
      : invoice.invoiceItemsProperties;
  }, [currentStep.name, invoice.invoiceItemsProperties, mapPropertyEnabledForApproval]);

  const defaultFieldValue = (field: InvoicePropertyInterface) => {
    switch (field.fieldType) {
      case InvoiceFieldValueTypeEnum.STRING:
        if (selectDropdownsResidentFields.includes(field.residentFieldName)) {
          return field.fieldValue && field.fieldValue.value ? field.fieldValue : null;
        }

        return field.fieldValue ? field.fieldValue.value : '';

      case InvoiceFieldValueTypeEnum.DATE: {
        const parsedDate = field.fieldValue ? parse(field.fieldValue.value) : null;

        return field.fieldValue && field.fieldValue.value && isValid(parsedDate)
          ? parsedDate
          : null;
      }

      default:
        return field.fieldValue ? field.fieldValue.value : '';
    }
  };

  const defaultFieldsWithValue = (properties: InvoicePropertyInterface[]) =>
    properties.map((field: InvoicePropertyInterface) => [
      String(field.residentFieldName),
      defaultFieldValue(field),
    ]);

  // TODO: get mandatory data from response, it shouldn't be hardcoded
  const validationInvoiceDataSchema = object().shape({
    [ResidentInvoiceDataEnum.TOTAL_AMOUNT]: string()
      .required(
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.totalAmount.errors.required',
        ),
      )
      .matches(
        config.regexForNonZero || /(.*?)/,
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.totalAmount.errors.zero',
        ),
      )
      .matches(
        config.regexOptionalSymbol || /(.*?)/,
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.errors.invalidPriceFormat',
        ),
      ),
    [ResidentInvoiceDataEnum.INVOICE_REFERENCE]: string().required(
      t(
        'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.invoiceReference.errors.required',
      ),
    ),
    [ResidentInvoiceDataEnum.PROPERTY]: object()
      .nullable()
      .required(
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.property.errors.required',
        ),
      ),
    [ResidentInvoiceDataEnum.BANK_ACCOUNT]: object()
      .nullable()
      .required(
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.bankAccount.errors.required',
        ),
      ),
    [ResidentInvoiceDataEnum.INVOICE_DATE]: date()
      .nullable()
      .required(
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.invoiceDate.errors.required',
        ),
      )
      .typeError(
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.errors.invalidDateFormat',
        ),
      ),
    [ResidentInvoiceDataEnum.PAYMENT_DUE_DATE]: date()
      .nullable()
      .transform((_, val) => parse(val))
      .typeError(
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.errors.invalidDateFormat',
        ),
      ),
    [ResidentInvoiceDataEnum.PERIOD_START]: date()
      .nullable()
      .transform((_, val) => parse(val))
      .typeError(
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.errors.invalidDateFormat',
        ),
      ),
    [ResidentInvoiceDataEnum.PERIOD_END]: date()
      .nullable()
      .transform((_, val) => parse(val))
      .typeError(
        t(
          'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.errors.invalidDateFormat',
        ),
      ),
    items: array().of(
      object().shape({
        [ResidentInvoiceDataEnum.ITEM_DESCRIPTION]: string().required(
          t(
            'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.description.errors.required',
          ),
        ),
        [ResidentInvoiceDataEnum.ITEM_CATEGORY]: object()
          .nullable()
          .required(
            t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.category.errors.required',
            ),
          ),
        [ResidentInvoiceDataEnum.ITEM_AMOUNT]: string()
          .required(
            t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.amount.errors.required',
            ),
          )
          .matches(
            config.regexForNonZero || /(.*?)/,
            t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.amount.errors.zero',
            ),
          )
          .matches(
            config.regexOptionalSymbol || /(.*?)/,
            t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.errors.invalidPriceFormat',
            ),
          ),
        [ResidentInvoiceDataEnum.ITEM_VAT]: string().matches(
          new RegExp(
            `(^$)|(${String(percentageRegex).slice(1, -1)})|(${
              config.regexOptionalSymbolWithZero
                ? String(config.regexOptionalSymbolWithZero).slice(1, -1)
                : '.*?'
            })`,
          ),
          t(
            'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.errors.invalidVatFormat',
          ),
        ),
        [ResidentInvoiceDataEnum.ITEM_SCHEDULE]: object()
          .nullable()
          .required(
            t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.schedule.errors.required',
            ),
          ),
      }),
    ),
  });

  const methods = useForm<VerifyInvoiceDataFormInterface>({
    resolver: yupResolver(validationInvoiceDataSchema),
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      ...Object.fromEntries(defaultFieldsWithValue(invoice.invoiceProperties)),
      items: invoice.invoiceItemsProperties.map((item: InvoicePropertyInterface[]) => ({
        ...Object.fromEntries(defaultFieldsWithValue(item)),
      })),
    },
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      setIsFormDirty(true);
    }
  }, [methods.formState.isDirty, setIsFormDirty]);

  useEffect(() => {
    setValidationErrorSetter(() => methods.setError);
  }, [methods, setValidationErrorSetter]);

  const onOpenEditSupplierHandler = useCallback(
    (isOpen: boolean) => {
      setMatchSupplierInvoiceData({
        id: invoice.invoiceId,
        fileName,
        fileLocalization: invoice.fileLocalization,
        supplier,
      });
      setMatchSupplierInvoiceOpen(isOpen);
    },
    [fileName, invoice, setMatchSupplierInvoiceData, setMatchSupplierInvoiceOpen, supplier],
  );

  const formContent = useMemo(
    () => (
      <div className={classes.col__inner}>
        <form>
          <VerifyInvoiceDataSectionComponent
            label={t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.title',
            )}
            fields={residentDataSection}
            setSupplierEditModalOpen={supplier ? onOpenEditSupplierHandler : undefined}
            approval={currentStep.name === 'approval'}
          />

          <br />
          <br />
          <br />

          <VerifyInvoiceDataSectionComponent
            label={t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.invoiceData.title',
            )}
            fields={invoiceDataSection}
            approval={currentStep.name === 'approval'}
          />

          <br />
          <br />
          <br />

          <VerifyInvoiceDataSectionComponent
            label={t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.title',
            )}
            fields={invoiceItemsDataSection}
            approval={currentStep.name === 'approval'}
            error={Boolean(methods.formState.errors.items)}
          />
        </form>

        <br />
        <br />

        {error && (
          <FormControl error={!!error}>
            <span role="alert">{error}</span>
          </FormControl>
        )}
      </div>
    ),
    [
      currentStep.name,
      error,
      invoiceDataSection,
      invoiceItemsDataSection,
      methods.formState.errors.items,
      onOpenEditSupplierHandler,
      residentDataSection,
      supplier,
      t,
    ],
  );

  return (
    <>
      <Grid item xs={6} className={classes.verification__col}>
        <FormProvider {...methods}>{formContent}</FormProvider>
      </Grid>

      {arePortalsReady && (
        <PortalComponent id="modalActions" anchorEl={verificationContainerRef.current}>
          {variant === 'verification' && verificationButtons && (
            <Fade in={arePortalsReady}>
              <Grid item xs={12} width="100%">
                {verificationButtons({
                  step: 'verification',
                  payload: methods,
                })}
              </Grid>
            </Fade>
          )}

          {variant === 'approval' && approvalButtons && (
            <Fade in={arePortalsReady}>
              <Grid item xs={12} width="100%">
                {approvalButtons({
                  step: 'approval',
                  payload: methods,
                })}
              </Grid>
            </Fade>
          )}
        </PortalComponent>
      )}

      {arePortalsReady && invoice.note && (
        <PortalComponent id="invoiceNote" anchorEl={verificationContainerRef.current}>
          <Fade in={Boolean(arePortalsReady && invoice.note)}>
            <Grid item xs={12} width="100%">
              <h6 className={classes.verification__title}>
                {t('invoices.tabs.toVerify.verificationModal.steps.verification.preview.note')}
              </h6>
              <InvoiceNoteComponent
                note={invoice.note}
                date={invoice.noteDate || ''}
                className={classes.verification__note}
              />
            </Grid>
          </Fade>
        </PortalComponent>
      )}

      {supplierMatchModal}
    </>
  );
};

export default VerifyInvoiceDataFormComponent;
