import React, { FC, memo, useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import { isValid } from 'date-fns';
import classNames from 'classnames';
import { VerifyInvoiceDataFieldPropsInterface } from './interfaces/VerifyInvoiceDataFieldProps.interface';
import { ResidentInvoiceDataTypeMap } from '../../../../../../../../../../../../types/upload-hub/invoice/ResidentInvoiceData.type';
import { InvoiceFieldValueTypeEnum } from '../../../../../../../../../../../../enums/upload-hub/InvoiceFieldValueType.enum';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';
import './VerifyInvoiceDataField.component.scss';
import PropertiesController from '../controllers/PropertiesController.component';
import BankAccountsController from '../controllers/BankAccountsController.component';
import WorkOrdersController from '../controllers/WorkOrdersController.component';
import InputController from '../controllers/InputController.component';
import DateController from '../controllers/DateController.component';
import { ResidentPropertyInterface } from '../../../../../../../../../../../../types/upload-hub/resident/property/ResidentProperty.interface';
import { queryClient } from '../../../../../../../../../../../../services/queryClient';
import { getResidentBankAccountsQueryKey } from '../../../../../../../../../../../../services/react-query-hooks/queries/queryKeys';
import { IconButton, Tooltip } from '../../../../../../../../../../../components/material';
import useDate from '../../../../../../../../../../../../utils/hooks/date-format/useDate';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';
import EditFieldComponent from '../../../../../../../../../../../components/edit-field/EditField.component';
import { ThresholdInfoComponent } from '../threshold-info/ThresholdInfo.component';

const VerifyInvoiceDataFieldComponent: FC<VerifyInvoiceDataFieldPropsInterface> = ({
  field,
  onPropertyChange,
  setSupplierEditModalOpen,
}) => {
  const { t } = useTranslation();
  const { parse } = useDate();

  const getFieldLabel = useCallback(
    (id: ResidentInvoiceDataEnum): string => {
      if (ResidentInvoiceDataTypeMap[id]) {
        // @ts-ignore
        return t(`invoices.residentInvoiceDataTypes.${ResidentInvoiceDataTypeMap[id]}`);
      }

      return t('global.unknown');
    },
    [t],
  );

  const {
    setValue,
    formState: { errors },
  } = useFormContext<VerifyInvoiceDataFormInterface>();

  const selectDropdownsResidentFields = [
    ResidentInvoiceDataEnum.PROPERTY,
    ResidentInvoiceDataEnum.BANK_ACCOUNT,
    ResidentInvoiceDataEnum.SUPPLIER,
    ResidentInvoiceDataEnum.WORKS_ORDER,
    ResidentInvoiceDataEnum.ITEM_CATEGORY,
    ResidentInvoiceDataEnum.ITEM_SCHEDULE,
  ];

  const residentInputFields = [
    ResidentInvoiceDataEnum.TOTAL_AMOUNT,
    ResidentInvoiceDataEnum.INVOICE_REFERENCE,
  ];

  const onChangePropertyHandler = (value: ResidentPropertyInterface | null) => {
    setValue(String(ResidentInvoiceDataEnum.BANK_ACCOUNT), null);
    setValue(String(ResidentInvoiceDataEnum.WORKS_ORDER), null);

    if (onPropertyChange) {
      onPropertyChange();
    }

    if (value !== null) {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === getResidentBankAccountsQueryKey &&
            query.queryKey[1] === value.value
          );
        },
      });
    }
  };

  const onOpenSupplierEditHandler = () => {
    if (setSupplierEditModalOpen) {
      setSupplierEditModalOpen(true);
    }
  };

  const name = useMemo(() => String(field.residentFieldName), [field.residentFieldName]);
  const error = errors[String(field.residentFieldName)];
  const isParseDataError = useMemo(() => {
    return (
      field.fieldType === InvoiceFieldValueTypeEnum.DATE &&
      field.fieldValue &&
      field.fieldValue.value &&
      !isValid(parse(field.fieldValue.value))
    );
  }, [field.fieldType, field.fieldValue, parse]);

  return (
    <Grid item container direction="row" className="verify-invoice-data-field" columnSpacing={1}>
      <Grid item xs={1} className="verify-invoice-data-field__color-indicator">
        <div
          className="color-indicator__wrapper"
          style={{ backgroundColor: field.color || 'transparent' }}
        />
      </Grid>
      <Grid
        item
        xs={2}
        className={classNames(
          'verify-invoice-data-field__title',
          field.isMandatory ? 'verify-invoice-data-field__title--required' : '',
        )}
      >
        {getFieldLabel(field.residentFieldName)}
      </Grid>
      <Grid
        item
        xs={
          field.fieldType === InvoiceFieldValueTypeEnum.DATE ||
          residentInputFields.includes(field.residentFieldName)
            ? 2.25
            : 9
        }
        className="verify-invoice-data-field__field"
      >
        {field.fieldType === InvoiceFieldValueTypeEnum.STRING &&
          field.residentFieldName === ResidentInvoiceDataEnum.SUPPLIER && (
            <div style={{ width: '95%' }}>
              <EditFieldComponent
                value={field.fieldValue.text || ''}
                onEdit={onOpenSupplierEditHandler}
                disabled={field.disabled}
                data-cy={`invoice-data-${name}`}
              />
            </div>
          )}

        {field.fieldType === InvoiceFieldValueTypeEnum.DATE && (
          <DateController
            name={name}
            error={error}
            disabled={field.disabled}
            data-cy={`invoice-data-${name}`}
          />
        )}

        {field.fieldType === InvoiceFieldValueTypeEnum.STRING &&
          !selectDropdownsResidentFields.includes(field.residentFieldName) && (
            <InputController
              name={name}
              error={error}
              disabled={field.disabled}
              data-cy={`invoice-data-${name}`}
            />
          )}

        {field.fieldType === InvoiceFieldValueTypeEnum.STRING &&
          field.residentFieldName === ResidentInvoiceDataEnum.PROPERTY && (
            <PropertiesController
              name={name}
              error={error}
              onChangeProperty={onChangePropertyHandler}
              disabled={field.disabled}
            />
          )}

        {field.fieldType === InvoiceFieldValueTypeEnum.STRING &&
          field.residentFieldName === ResidentInvoiceDataEnum.BANK_ACCOUNT && (
            <BankAccountsController name={name} error={error} disabled={field.disabled} />
          )}

        {field.fieldType === InvoiceFieldValueTypeEnum.STRING &&
          field.residentFieldName === ResidentInvoiceDataEnum.WORKS_ORDER && (
            <WorkOrdersController name={name} error={error} disabled={field.disabled} />
          )}
      </Grid>

      {field.residentFieldName === ResidentInvoiceDataEnum.TOTAL_AMOUNT && (
        <ThresholdInfoComponent />
      )}

      {isParseDataError && (
        <Grid item xs={1}>
          <Tooltip
            title={
              <div className="verify-invoice-data-field__tooltip-content">
                <p className="tooltip-content__header">
                  {t('invoices.tabs.toVerify.verificationModal.steps.verification.errors.parse')}
                </p>
                <span className="tooltip-content__value">{field.fieldValue.value}</span>
              </div>
            }
          >
            <IconButton variant="outlined" className="verify-invoice-data-field__tooltip-button">
              <PriorityHighOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

const VerifyInvoiceDataField = memo(VerifyInvoiceDataFieldComponent);

export default VerifyInvoiceDataField;
