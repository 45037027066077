import React, { forwardRef, PropsWithChildren, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { Input, InputPropsInterface } from '../../../components/material';

const PasswordInputComponent = forwardRef((props: PropsWithChildren<InputPropsInterface>, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (): void => {
    setShowPassword(() => {
      return !showPassword;
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <Input
      id="password"
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
      fullWidth
      ref={ref}
      autoComplete="password"
      {...props}
    />
  );
});

export default PasswordInputComponent;
