import React, { forwardRef, PropsWithChildren, ReactElement } from 'react';
import './Input.component.scss';
import { Input as MatInput, InputProps as MatInputProps } from '@mui/material';
import classNames from 'classnames';
import { PropsInterface } from '../../../../interfaces/Props.interface';

export interface InputPropsInterface extends MatInputProps, PropsInterface {
  label?: string;
}

const Input = forwardRef((props: PropsWithChildren<InputPropsInterface>, ref): ReactElement => {
  const { label, className, ...rest } = props;

  return (
    <>
      {label && (
        <label
          htmlFor={props.name}
          className={classNames(
            'data-hub-input-label',
            props.required ? 'data-hub-input-label--required' : '',
          )}
        >
          {label}
        </label>
      )}
      <MatInput
        id={props.name}
        className={classNames(['data-hub-input', className])}
        ref={ref}
        {...rest}
      />
    </>
  );
});

export { Input };
