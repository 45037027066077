import { WebAuth } from 'auth0-js';

const webAuth = new WebAuth({
  clientID: process.env.REACT_APP_AUTH_API_CLIENT_ID || '',
  domain: process.env.REACT_APP_AUTH_API_URL || '',
  responseType: 'token id_token',
  audience: `${process.env.REACT_APP_AUTH_API_URL}/userinfo`,
  redirectUri: `${window.location.origin}`,
  scope: 'profile openid email',
});

export default webAuth;
