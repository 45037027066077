import React, { ChangeEvent, FC, memo, useState } from 'react';
import { FormControlLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Switch from '../../../../../components/material/switch/Switch.component';
import classes from './ResidentSettings.module.scss';
import { ResidentSettingsPropsInterface } from './interfaces/ResidentSettingsProps.interface';
import useUpdateUseItemGrossAmountMutation from '../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/settings/resident/useUpdateUseItemGrossAmount.mutation';

const ResidentSettingsComponent: FC<ResidentSettingsPropsInterface> = ({ useItemGrossAmount }) => {
  const { t } = useTranslation();

  const [isUseItemGrossAmount, setIsUseItemGrossAmount] = useState(useItemGrossAmount);
  const { mutateDebounced: updateUseItemGrossAmountMutation } =
    useUpdateUseItemGrossAmountMutation();

  const useItemGrossAmountChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setIsUseItemGrossAmount(event.target.checked);

    updateUseItemGrossAmountMutation({
      doNotUseVat: event.target.checked,
    });
  };

  return (
    <Grid container direction="column" rowSpacing={2}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch checked={isUseItemGrossAmount} onChange={useItemGrossAmountChangeHandler} />
          }
          label={
            <span className={classes['resident-settings__form-label']}>
              {t(
                'pluginSettings.sections.uploadHub.subsections.resident.controls.useItemGrossAmount.label',
              )}
            </span>
          }
        />
      </Grid>
    </Grid>
  );
};

const ResidentSettings = memo(ResidentSettingsComponent);

export default ResidentSettings;
