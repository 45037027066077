import React, { FC, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDuplicatesModalPropsInterface } from './interfaces/InvoiceDuplicatesModalProps.interface';
import InvoiceDuplicatesView from './InvoiceDuplicates.view';
import ModalComponent from '../../../../../components/modal/modal/Modal.component';
import useGetInvoiceDuplicatesQuery from '../../../../../../services/react-query-hooks/queries/upload-hub/invoice/useGetInvoiceDuplicates.query';

const InvoiceDuplicatesModalComponent: FC<InvoiceDuplicatesModalPropsInterface> = ({
  id,
  open,
  setOpen,
}): ReactElement => {
  const { t } = useTranslation();
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const { data, isLoading } = useGetInvoiceDuplicatesQuery(id);

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      title={t('invoices.duplicates.title')}
      content={data && <InvoiceDuplicatesView invoiceDuplicates={data} handleClose={handleClose} />}
      isLoading={isLoading}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
      }}
      className="invoice-duplicates-modal"
      actions
    />
  );
};

export default InvoiceDuplicatesModalComponent;
