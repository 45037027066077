import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleDataSourceObjectInterface } from '../../../../types/data-source/DataSource.interface';
import ModalComponent from '../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../components/modal/types/Props.interface';
import UpdateDataSourceView from './UpdateDataSource.view';

interface UpdateDataSourceModalComponentProps
  extends SimpleDataSourceObjectInterface,
    ModalControlPropsInterface {
  id: string;
}

const UpdateDataSourceModalComponent = ({
  id,
  open,
  setOpen,
  name,
  type,
  origin,
  autoSync,
}: UpdateDataSourceModalComponentProps) => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t(
        'dataSourcesSettings.sections.dataSources.subsections.supplierList.updateDataSourceModal.title',
      )}
      open={open}
      handleClose={handleClose}
      content={
        <UpdateDataSourceView
          id={id}
          close={handleClose}
          name={name}
          type={type}
          origin={origin}
          autoSync={autoSync}
        />
      }
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xs',
      }}
      actions
    />
  );
};

export default UpdateDataSourceModalComponent;
