import { PropsInterface } from '../../../interfaces/Props.interface';
import { BreakpointEnum } from '../../../enums/Breakpoint.enum';

export interface BreakpointPropsInterface extends PropsInterface {
  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
  xl?: number | string;
}

export const allBreakpointsList = [
  BreakpointEnum.XS,
  BreakpointEnum.SM,
  BreakpointEnum.MD,
  BreakpointEnum.LG,
  BreakpointEnum.XL,
];
