import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Auth0Error, Auth0UserProfile } from 'auth0-js';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { AuthCookieEnum } from './enums/AuthCookie.enum';
import { SocialConnectionEnum } from './enums/SocialConnection.enum';
import { AuthContextTypeInterface } from './interfaces/AuthContextType.interface';
import webAuth from './WebAuth.configuration';
import { isTokenAuthenticated } from './utils/Auth0Token.util';
import { endSession, startSession } from './utils/Auth0Session.util';
import { useUserRetrievePermissions } from '../user/User.provider';
import { LoginLoadingEnum } from './enums/LoginLoading.enum';
import { LocalStorageKeysEnum } from '../../enums/LocalStorageKeys.enum';
import { useSubscription } from '../subscription/Subscription.provider';

const AuthContext = createContext<AuthContextTypeInterface>({} as AuthContextTypeInterface);

export const AuthProvider: FC<PropsWithChildren> = ({ children }): ReactElement => {
  const [user, setUser] = useState<Auth0UserProfile>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<LoginLoadingEnum>(LoginLoadingEnum.NULL);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [resetPasswordSent, setResetPasswordSent] = useState<boolean>(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const {
    retrievePermissions,
    confirmInvitation,
    error: retrievePermissionsError,
    resetState: resetUserState,
  } = useUserRetrievePermissions();

  const { retrieveConfiguration } = useSubscription();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleLogin = useCallback(startSession, []);

  const { t } = useTranslation();

  const resetLoadingState = (e?: any) => {
    if (e) {
      console.log('e: ', e);
    }

    setLoading(LoginLoadingEnum.NULL);
  };

  const login = useCallback(
    async (email: string, password: string) => {
      setLoading(LoginLoadingEnum.REGULAR);
      setError(null);

      if (email?.trim() !== '' && password?.trim() !== '') {
        await webAuth.login(
          {
            username: email,
            password,
            scope: 'profile openid email',
            realm: 'Username-Password-Authentication',
            redirectUri: window.location.origin,
          },
          (err: Auth0Error | any) => {
            setError(err);
            setLoading(LoginLoadingEnum.NULL);
          },
        );
      } else {
        setError({
          error: 'validation',
          description: t('landingPage.loginForm.controls.errors.required'),
        });
        setLoading(LoginLoadingEnum.NULL);
      }
    },
    [t],
  );

  const socialLogin = (connection: SocialConnectionEnum, invitationId?: string): void => {
    if (invitationId) {
      localStorage.setItem(LocalStorageKeysEnum.INVITATION_ID, invitationId);
    }

    switch (connection) {
      case SocialConnectionEnum.FB:
        setLoading(LoginLoadingEnum.FB);
        break;
      case SocialConnectionEnum.GOOGLE:
        setLoading(LoginLoadingEnum.GOOGLE);
        break;
      default:
        setLoading(LoginLoadingEnum.RESIDENT);
    }
    setError(null);

    webAuth.authorize({
      connection,
      redirectUri: window.location.origin,
    });
  };

  const register = useCallback(
    async (email: string, password: string, invitationId: string) => {
      setLoading(LoginLoadingEnum.REGULAR);
      setError(null);

      if (email?.trim() !== '' && password?.trim() !== '' && invitationId) {
        localStorage.setItem(LocalStorageKeysEnum.INVITATION_ID, invitationId);

        await webAuth.redirect.signupAndLogin(
          {
            connection: 'Username-Password-Authentication',
            email,
            password,
          },
          (err: Auth0Error | any) => {
            setError(err);
            setLoading(LoginLoadingEnum.NULL);
          },
        );
      } else {
        setError({
          error: 'validation',
          description: t('landingPage.confirmInvitationForm.controls.errors.required'),
        });
        setLoading(LoginLoadingEnum.NULL);
      }
    },
    [t],
  );

  const getUser = async (accessToken: string): Promise<Auth0UserProfile> => {
    return new Promise((resolve, reject) => {
      webAuth.client.userInfo(
        accessToken,
        (userError: Auth0Error | null, userProfile: Auth0UserProfile) => {
          if (userError) {
            reject(userError);
          } else {
            resolve(userProfile);
          }
        },
      );
    });
  };

  const logout = useCallback((): void => {
    endSession();
    setIsAuthenticated(() => false);
  }, []);

  const resetPassword = (email: string): void => {
    setLoading(LoginLoadingEnum.RESET);
    setError(null);

    webAuth.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email,
      },
      (err: Auth0Error | null) => {
        if (err) {
          setError(err);
        } else {
          setResetPasswordSent(true);
        }

        setLoading(LoginLoadingEnum.NULL);
      },
    );
  };

  const clearAuthInfo = useCallback(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    resetLoadingState();
  }, []);

  useEffect(() => {
    const events = ['pagehide', 'pageshow'];

    events.forEach((event: string) => window.addEventListener(event, resetLoadingState));

    return () => {
      events.forEach((event: string) => window.removeEventListener(event, resetLoadingState));
    };
  }, []);

  useEffect(() => {
    if (retrievePermissionsError) {
      logout();
    }
  }, [retrievePermissionsError, logout, resetUserState]);

  useEffect(() => {
    if (pathname === '/login' && retrievePermissionsError) {
      setError({
        error: 'validation',
        description: t('landingPage.loginForm.controls.errors.access'),
      });
      resetUserState();
    }
  }, [pathname, resetUserState, retrievePermissionsError, t]);

  useEffect(() => {
    if (shouldRedirect) {
      setShouldRedirect(false);
      navigate('/');
    }
  }, [shouldRedirect, navigate]);

  useEffect(() => {
    (async () => {
      setLoadingInitial(true);

      if (window.location.hash) {
        await handleLogin(new URL(window.location.toString())).then(
          () => {
            setShouldRedirect(true);
          },
          (e: Auth0Error | string) => {
            setError(e);
            logout();
          },
        );
      }

      if (Cookie.get(AuthCookieEnum.ACCESS_TOKEN)) {
        try {
          if (
            Cookie.get(AuthCookieEnum.USER_ID) &&
            localStorage.getItem(LocalStorageKeysEnum.INVITATION_ID)
          ) {
            await confirmInvitation({
              invitationId: localStorage.getItem(LocalStorageKeysEnum.INVITATION_ID) || '',
              userAuthNumber: Cookie.get(AuthCookieEnum.USER_ID) || '',
            });
          }

          await retrievePermissions();
          await retrieveConfiguration();
          const userProfile = await getUser(String(Cookie.get(AuthCookieEnum.ACCESS_TOKEN)));
          setUser(userProfile);
        } catch (e) {
          setError(e);
        }
      }

      setIsAuthenticated(() =>
        isTokenAuthenticated(
          Cookie.get(AuthCookieEnum.ACCESS_TOKEN) as string,
          Cookie.get(AuthCookieEnum.ID_TOKEN) as string,
          Cookie.get(AuthCookieEnum.EXPIRES_IN) as string,
        ),
      );

      localStorage.removeItem(LocalStorageKeysEnum.INVITATION_ID);
      setLoadingInitial(false);
    })();
  }, [retrievePermissions, retrieveConfiguration, confirmInvitation, handleLogin, logout]);

  useEffect(() => {
    if (error && pathname !== '/login') {
      setError(null);
    }

    setResetPasswordSent(false);
  }, [error, pathname]);

  const memoizedAuthState = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      socialLogin,
      register,
      logout,
      isAuthenticated,
      resetPassword,
      resetPasswordSent,
      clearAuthInfo,
    }),
    [
      user,
      loading,
      error,
      login,
      register,
      logout,
      isAuthenticated,
      resetPasswordSent,
      clearAuthInfo,
    ],
  );

  return (
    <AuthContext.Provider value={memoizedAuthState}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
