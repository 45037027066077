import React, { FC, MouseEvent } from 'react';
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TableHeaderPropsInterface } from './interfaces/TableHeaderProps.interface';
import { TableColumnInterface } from '../../interfaces/TableColumn.interface';
import classes from './TableHeader.module.scss';
import { AlignEnum } from '../../../../../enums/Align.enum';

const TableHeaderComponent: FC<TableHeaderPropsInterface> = ({
  columns,
  onSelectAllRows,
  rowsCount,
  selectedRowsCount,
  multiselect,
  order,
  orderBy,
  onSort,
  pageNumber,
  selectedRows,
}) => {
  const onSortClickHandler = (columnName: string | number) => (event: MouseEvent<unknown>) => {
    onSort(event, columnName);
  };
  const { t } = useTranslation();

  return (
    <TableHead
      className={classNames(classes['table-header'], {
        [classes['table-header--hidden']]: selectedRowsCount > 0,
      })}
    >
      <TableRow>
        {multiselect && (
          <TableCell
            padding="checkbox"
            className={classNames(classes['MuiTableCell-root'], classes['table-header__cell'])}
          >
            <Checkbox
              color="primary"
              indeterminate={
                selectedRows[pageNumber]?.length > 0 && selectedRows[pageNumber]?.length < rowsCount
              }
              checked={rowsCount > 0 && selectedRows[pageNumber]?.length === rowsCount}
              onChange={onSelectAllRows}
              inputProps={{
                'aria-label': t('table.selectAllRows'),
              }}
            />
          </TableCell>
        )}

        {columns.map((column: TableColumnInterface) => (
          <TableCell
            key={column.name}
            align={column.align || AlignEnum.CENTER}
            className={classNames(classes['MuiTableCell-root'], classes['table-header__cell'], {
              [classes['table-header__cell--sortable']]: column.sortable,
              [classes['table-header__cell--center']]: column.align === AlignEnum.CENTER,
            })}
            sortDirection={orderBy === column.name ? order : false}
          >
            {column.sortable && (
              <TableSortLabel
                active={orderBy === column.name}
                direction={orderBy === column.name ? order : 'asc'}
                onClick={onSortClickHandler(column.name)}
                classes={{
                  root: classes['cell__sort-label'],
                }}
              >
                {column.label}
              </TableSortLabel>
            )}

            {!column?.sortable && column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaderComponent;
