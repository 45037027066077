import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceHistoryPropsInterface } from './interfaces/InvoiceHistoryProps.interface';
import classes from './InvoiceHistory.module.scss';
import { InvoiceHistoryEntryInterface } from '../../../../../../types/upload-hub/invoice/history/InvoiceHistoryEntry.interface';
import useDate from '../../../../../../utils/hooks/date-format/useDate';
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '../../../../../components/material';
import { InvoiceHistoryEventTypes } from '../../../../../../types/upload-hub/invoice/history/InvoiceHistoryEventType.type';

const InvoiceHistoryView: FC<InvoiceHistoryPropsInterface> = ({ historyEntries }): ReactElement => {
  const { t } = useTranslation();
  const { format } = useDate({ time: true });
  const [expandedEntry, setExpandedEntry] = useState<string | null>(null);

  const onChangeHandler = (entryId: string) => () => {
    setExpandedEntry((prevEntryId: string | null) => (prevEntryId === entryId ? null : entryId));
  };

  return (
    <div className={classes['invoice-history']}>
      {historyEntries.map((historyEntry: InvoiceHistoryEntryInterface) => (
        <div key={historyEntry.modificationDate}>
          <Accordion expanded={expandedEntry === historyEntry.modificationDate}>
            <AccordionSummary
              aria-controls={`history-entry-from-${historyEntry.modificationDate}-content`}
              id={historyEntry.modificationDate}
              className={classes['invoice-history__entry']}
            >
              <div className={classes.entry__container}>
                <div className={classes.container__info}>
                  <p className={classes.container__person}>{historyEntry.modifiedBy}</p>
                  <p className={classes.container__date}>{format(historyEntry.modificationDate)}</p>
                </div>
                <div className={classes.container__value}>
                  {t(`invoices.history.types.${InvoiceHistoryEventTypes[historyEntry.eventName]}`)}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              aria-controls={`history-entry-from-${historyEntry.modificationDate}-details`}
            >
              <pre>{JSON.stringify(JSON.parse(historyEntry.eventDetails), undefined, 2)}</pre>
            </AccordionDetails>
          </Accordion>
          <div className={classes['invoice-history__button']}>
            <Button
              variant="toggle"
              onClick={onChangeHandler(historyEntry.modificationDate)}
              disableRipple
            >
              {expandedEntry === historyEntry.modificationDate
                ? t('global.showLess')
                : t('global.showMore')}
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InvoiceHistoryView;
