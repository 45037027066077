import React, { FC, ReactElement, useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import classes from './SynchronizedInboxes.module.scss';
import TableComponent from '../../../../../components/table/Table.component';
import { Button } from '../../../../../components/material';
import useTableFilters from '../../../../../components/table/hooks/TableFilters.hook';
import useGetSynchronizedInboxesListQuery from '../../../../../../services/react-query-hooks/queries/upload-hub/synchronized-inboxes/useGetSynchronizedInboxesList.query';
import {
  NylasSynchronizedInboxInterface,
  SmtpSynchronizedInboxInterface,
} from '../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';
import { TableRowInterface } from '../../../../../components/table/interfaces/TableRow.interface';
import { TableColumnInterface } from '../../../../../components/table/interfaces/TableColumn.interface';
import { AlignEnum } from '../../../../../../enums/Align.enum';
import { TableColumnTypeEnum } from '../../../../../components/table/enums/TableColumnType.enum';
import { SynchronizedInboxesPropsInterface } from './interfaces/SynchronizedInboxesProps.interface';
import useUpdateSynchronizedInboxAutoSyncMutation from '../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/synchronized-inboxes/useUpdateSynchronizedInboxAutoSync.mutation';
import { TableActionNameEnum } from '../../../../../components/table/enums/TableActionName.enum';
import { TableActionInterface } from '../../../../../components/table/interfaces/TableProps.interface';
import { SynchronizedInboxesActionNameEnum } from './enums/SynchronizedInboxesActionName.enum';
import useSyncSynchronizedInboxMutation from '../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/synchronized-inboxes/useSyncSynchronizedInbox.mutation';
import useAddSynchronizedInbox from './components/add-synchronized-inbox/hooks/useAddSynchronizedInbox.hook';
import useUpdateSynchronizedInbox from './components/update-synchronized-inbox/hooks/useUpdateSynchronizedInbox.hook';
import useDeleteSynchronizedInbox from './components/delete-synchronized-inbox/hooks/useDeleteSynchronizedInbox.hook';
import { queryClient } from '../../../../../../services/queryClient';
import { updateSynchronizedInboxesAutoSyncMutationKey } from '../../../../../../services/react-query-hooks/mutations/mutationKeys';
import { EmailProviderEnum } from '../../../../../../enums/upload-hub/settings/EmailProvider.enum';
import useNylasAuth from './hooks/useNylasAuth.hook';
import { StatusEnum } from '../../../../../../enums/Status.enum';

const SynchronizedInboxesComponent: FC<SynchronizedInboxesPropsInterface> = ({
  provider: emailProvider,
  nylasAuthorizationUrl,
}): ReactElement => {
  const provider = emailProvider || EmailProviderEnum.SMTP;
  const { t } = useTranslation();
  const { initNylasAuth, isNylasAuthLoading } = useNylasAuth();

  const pluginSettingsColumns: (TableColumnInterface | boolean)[] = [
    provider === EmailProviderEnum.NYLAS && {
      name: 'status',
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.STATUS,
    },
    {
      name: 'name',
      label: t('pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.columns.email'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'type',
      label: t(
        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.columns.autoSync',
      ),
      align: AlignEnum.CENTER,
      type: TableColumnTypeEnum.CHECKBOX,
      width: '4rem',
    },
    {
      name: 'actions',
      label: t('pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.columns.action'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.ACTIONS,
      width: '4rem',
    },
  ];

  const { mutate: updateSynchronizedInboxesAutoSync } = useUpdateSynchronizedInboxAutoSyncMutation({
    provider,
  });

  const { filters, dispatchFilters } = useTableFilters({
    PageNumber: 1,
    PageSize: 20,
    OrderBy: 'name asc',
  });
  const { data, isLoading, pagination } = useGetSynchronizedInboxesListQuery(filters, provider);

  const getSyncInbox = useCallback(
    (id: string): SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface | undefined =>
      data?.find((item: SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface) => {
        return provider === EmailProviderEnum.SMTP
          ? (item as SmtpSynchronizedInboxInterface).uniqueNumber === id
          : (item as NylasSynchronizedInboxInterface).accountUniqueNumber === id;
      }),
    [data, provider],
  );

  const { mutate: syncMailbox } = useSyncSynchronizedInboxMutation({ provider });

  const { addSynchronizedInboxModal, setAddEmailOpen } = useAddSynchronizedInbox();
  const { updateSynchronizedInboxModal, setUpdateEmailOpen, setUpdateEmailData } =
    useUpdateSynchronizedInbox();
  const { deleteSynchronizedInboxModal, setDeleteEmailOpen, setDeleteEmailData } =
    useDeleteSynchronizedInbox(provider);

  const mapPluginSettingsRow = (
    syncInbox: SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface,
  ): TableRowInterface => {
    const syncInboxId =
      provider === EmailProviderEnum.SMTP
        ? (syncInbox as SmtpSynchronizedInboxInterface).uniqueNumber
        : (syncInbox as NylasSynchronizedInboxInterface).accountUniqueNumber;

    return {
      ...syncInbox,
      id: syncInboxId,
      type: syncInbox.autoSync,
      name: syncInbox.email,
      status:
        provider === EmailProviderEnum.NYLAS &&
        !(syncInbox as NylasSynchronizedInboxInterface).authorized
          ? StatusEnum.WARNING
          : StatusEnum.NULL,
      loading:
        queryClient.isMutating({
          mutationKey: updateSynchronizedInboxesAutoSyncMutationKey,
          predicate: (m) => m.options.variables?.id === syncInboxId,
        }) > 0,
    };
  };

  const actions: TableActionInterface<SynchronizedInboxesActionNameEnum>[] = [
    {
      name: SynchronizedInboxesActionNameEnum.RE_AUTH,
      icon: <LockResetOutlinedIcon />,
      label: t(
        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.actions.reAuth.title',
      ),
      multiselect: false,
      active: (row: TableRowInterface) => provider === EmailProviderEnum.NYLAS && !row.authorized,
    },
    {
      name: SynchronizedInboxesActionNameEnum.SYNC,
      icon: <SyncIcon />,
      label: t(
        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.actions.sync.title',
      ),
      multiselect: false,
      active: (row: TableRowInterface) => provider === EmailProviderEnum.SMTP || !!row.authorized,
    },
    {
      name: TableActionNameEnum.EDIT,
      icon: <EditOutlinedIcon />,
      label: t('global.edit'),
      active: provider === EmailProviderEnum.SMTP,
    },
    {
      name: TableActionNameEnum.DELETE,
      icon: <ClearOutlinedIcon />,
      label: t('global.delete'),
      multiselect: false,
    },
  ];

  const onActionSelectedHandler = (
    actionName: SynchronizedInboxesActionNameEnum | TableActionNameEnum,
    rowId: string[] | number[] | string | number,
    columnName?: string | number,
    state?: boolean,
  ) => {
    const syncInbox = getSyncInbox(String(rowId));
    const isMultiple = Array.isArray(rowId);

    if (!syncInbox) {
      return;
    }

    switch (actionName) {
      case SynchronizedInboxesActionNameEnum.SYNC: {
        if (!isMultiple) {
          syncMailbox(syncInbox);
        }

        break;
      }

      case SynchronizedInboxesActionNameEnum.RE_AUTH: {
        if (!isMultiple) {
          window.open((syncInbox as NylasSynchronizedInboxInterface).reauthorizationUrl, '_self');
        }

        break;
      }

      case TableActionNameEnum.EDIT: {
        if (!isMultiple) {
          setUpdateEmailData(syncInbox as SmtpSynchronizedInboxInterface);
          setUpdateEmailOpen(true);
        }

        break;
      }

      case TableActionNameEnum.DELETE: {
        if (!isMultiple) {
          setDeleteEmailData(syncInbox);
          setDeleteEmailOpen(true);
        }

        break;
      }

      case TableActionNameEnum.CHECKBOX: {
        if (!isMultiple && columnName) {
          updateSynchronizedInboxesAutoSync({
            id:
              provider === EmailProviderEnum.SMTP
                ? (syncInbox as SmtpSynchronizedInboxInterface).uniqueNumber
                : (syncInbox as NylasSynchronizedInboxInterface).accountUniqueNumber,
            autoSync: !!state,
            email: syncInbox.email,
          });
        }

        break;
      }

      default:
    }
  };

  const onAddEmailHandler = useCallback(() => {
    if (provider === EmailProviderEnum.SMTP) {
      setAddEmailOpen(true);
    } else {
      try {
        initNylasAuth(nylasAuthorizationUrl);
      } catch (e: any) {
        console.error(e);
      }
    }
  }, [initNylasAuth, nylasAuthorizationUrl, provider, setAddEmailOpen]);

  const toolbarContent = useMemo(
    () => (
      <div>
        <Button
          startIcon={<AddOutlinedIcon />}
          onClick={onAddEmailHandler}
          variant="outlined"
          loading={isNylasAuthLoading}
        >
          {t('pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.addButton')}
        </Button>
      </div>
    ),
    [isNylasAuthLoading, onAddEmailHandler, t],
  );

  return (
    <Grid className={classes['synchronized-inboxes']}>
      <TableComponent
        table={{
          rows: data ? data.map(mapPluginSettingsRow) : [],
          columns: pluginSettingsColumns.filter(Boolean) as TableColumnInterface[],
        }}
        filters={filters}
        actions={actions}
        onActionSelected={onActionSelectedHandler}
        onChangeFilters={dispatchFilters}
        toolbar={toolbarContent}
        pagination={pagination}
        isLoading={isLoading}
        className={classes['synchronized-inboxes__table']}
        emptyLabel={t(
          'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.emptyTable',
        )}
      />

      {addSynchronizedInboxModal}
      {updateSynchronizedInboxModal}
      {deleteSynchronizedInboxModal}
    </Grid>
  );
};

export default SynchronizedInboxesComponent;
