import { useCallback, useMemo } from 'react';
import { TableActionInterface } from '../../../interfaces/TableProps.interface';
import { TableRowInterface } from '../../../interfaces/TableRow.interface';

const useTableRowUtils = <T>(row?: TableRowInterface, actions?: TableActionInterface<T>[]) => {
  const isActionActive = useCallback(
    (action: TableActionInterface<T>) => {
      if (action.active === undefined || !row) {
        return true;
      }

      return typeof action.active === 'boolean' ? action.active : action.active(row);
    },
    [row],
  );

  const activeActions = useMemo(
    () => (actions ? actions.filter(isActionActive) : []),
    [actions, isActionActive],
  );

  return {
    isActionActive,
    activeActions,
  };
};

export default useTableRowUtils;
