import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import useAddSynchronizedInboxMutation from '../../../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/synchronized-inboxes/useAddSynchronizedInbox.mutation';
import FormModalComponent from '../../../../../../../components/modal/form-modal/FormModal.component';
import SynchronizedInboxesFormComponent from '../synchronized-inboxes-form/SynchronizedInboxesForm.component';
import SynchronizedInboxesFormInterface, {
  defaultValues,
} from '../synchronized-inboxes-form/SynchronizedInboxesForm.interface';

interface AddSynchronizedInboxPropsInterface {
  close: () => void;
}

const AddSynchronizedInbox: FC<AddSynchronizedInboxPropsInterface> = ({ close }) => {
  const methods = useForm<SynchronizedInboxesFormInterface>({
    mode: 'onBlur',
    defaultValues,
  });
  const {
    mutate: addSyncInbox,
    isLoading,
    isSuccess,
  } = useAddSynchronizedInboxMutation({
    setValidationError: methods.setError,
  });

  const onSubmitHandler = (data: SynchronizedInboxesFormInterface) =>
    addSyncInbox({
      ...data,
      imapPort: Number(data.imapPort),
    });

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmitHandler)}
      isSuccess={isSuccess}
      isLoading={isLoading}
    >
      <SynchronizedInboxesFormComponent mode="add" />
    </FormModalComponent>
  );
};

export default AddSynchronizedInbox;
