import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { InvoiceVerificationResponseType } from '../../../../../types/upload-hub/invoice/verification/InvoiceVerificationResponse.type';
import { ApiOperation } from '../../../../api';
import { getInvoiceQueryKey } from '../../queryKeys';
import { InvoicePropertyInterface } from '../../../../../types/upload-hub/invoice/InvoiceProperty.interface';
import { InvoiceFieldValueTypeEnum } from '../../../../../enums/upload-hub/InvoiceFieldValueType.enum';
import { getFieldColor } from '../../../../../utils/Colors';
import { ResidentInvoiceDataEnum } from '../../../../../enums/upload-hub/ResidentInvoiceData.enum';

const emptyInvoiceProperty: InvoicePropertyInterface[] = [
  {
    residentFieldName: ResidentInvoiceDataEnum.ITEM_DESCRIPTION,
    ocrFieldName: 'Description',
    fieldValue: {
      text: '',
      value: '',
    },
    fieldType: InvoiceFieldValueTypeEnum.STRING,
    isMandatory: true,
  },
  {
    residentFieldName: ResidentInvoiceDataEnum.ITEM_TYPE,
    ocrFieldName: '',
    fieldValue: {
      text: '',
      value: '',
    },
    fieldType: InvoiceFieldValueTypeEnum.STRING,
    isMandatory: false,
  },
  {
    residentFieldName: ResidentInvoiceDataEnum.ITEM_AMOUNT,
    ocrFieldName: 'Amount',
    fieldValue: {
      text: '',
      value: '',
    },
    fieldType: InvoiceFieldValueTypeEnum.STRING,
    isMandatory: true,
  },
  {
    residentFieldName: ResidentInvoiceDataEnum.ITEM_CATEGORY,
    ocrFieldName: '',
    fieldValue: {
      text: '',
      value: '',
    },
    fieldType: InvoiceFieldValueTypeEnum.STRING,
    isMandatory: true,
  },
  {
    residentFieldName: ResidentInvoiceDataEnum.ITEM_SCHEDULE,
    ocrFieldName: '',
    fieldValue: {
      text: '',
      value: '',
    },
    fieldType: InvoiceFieldValueTypeEnum.STRING,
    isMandatory: true,
  },
  {
    residentFieldName: ResidentInvoiceDataEnum.ITEM_VAT,
    ocrFieldName: 'Tax',
    fieldValue: {
      text: '',
      value: '',
    },
    fieldType: InvoiceFieldValueTypeEnum.STRING,
    isMandatory: false,
  },
];

const dropdownInvoiceItems = [
  ResidentInvoiceDataEnum.ITEM_CATEGORY,
  ResidentInvoiceDataEnum.ITEM_SCHEDULE,
];

const priceOrVarInvoiceProperties = [
  ResidentInvoiceDataEnum.TOTAL_AMOUNT,
  ResidentInvoiceDataEnum.ITEM_AMOUNT,
  ResidentInvoiceDataEnum.ITEM_VAT,
];

export const emptyInvoicePropertyFormObject = emptyInvoiceProperty.reduce(
  (acc: { [key: number]: string | null }, field: InvoicePropertyInterface) => {
    acc[field.residentFieldName] = dropdownInvoiceItems.includes(field.residentFieldName)
      ? null
      : field.fieldValue.value;

    return acc;
  },
  {},
);

const assignColorToProperty = (property: InvoicePropertyInterface): InvoicePropertyInterface => {
  return {
    ...property,
    color: property.fieldValue ? getFieldColor(property.ocrFieldName) : 'transparent',
  };
};

const assignColorToItemProperties = (item: InvoicePropertyInterface[]) =>
  item.map(assignColorToProperty);

const removeWhitespacesFromProperty = (
  property: InvoicePropertyInterface,
): InvoicePropertyInterface => ({
  ...property,
  fieldValue: {
    value: property.fieldValue.value ? property.fieldValue.value.replace(/ /g, '') : '',
    text: property.fieldValue.text ? property.fieldValue.text.replace(/ /g, '') : '',
  },
});

const removeTrailingDotsFromDateProperty = (
  property: InvoicePropertyInterface,
): InvoicePropertyInterface => {
  if (property.fieldType === InvoiceFieldValueTypeEnum.DATE) {
    return {
      ...property,
      fieldValue: {
        value: property.fieldValue.value ? property.fieldValue.value.replace(/\.+$/, '') : '',
        text: property.fieldValue.text ? property.fieldValue.text.replace(/\.+$/, '') : '',
      },
    };
  }

  return property;
};

const removeWhitespacesFromPriceOrVatProperty = (
  property: InvoicePropertyInterface,
): InvoicePropertyInterface => {
  return priceOrVarInvoiceProperties.includes(property.residentFieldName) &&
    property.fieldType === InvoiceFieldValueTypeEnum.STRING
    ? removeWhitespacesFromProperty(property)
    : property;
};

const removeWhitespacesFromPriceOrVatItemProperties = (item: InvoicePropertyInterface[]) =>
  item.map(removeWhitespacesFromPriceOrVatProperty);

const removeTrailingDotsFromItemDatesProperties = (item: InvoicePropertyInterface[]) =>
  item.map(removeTrailingDotsFromDateProperty);

const mapProperties = (properties: InvoicePropertyInterface[]) =>
  properties
    .map(assignColorToProperty)
    .map(removeWhitespacesFromProperty)
    .map(removeTrailingDotsFromDateProperty);

const mapItemsProperties = (itemsProperties: InvoicePropertyInterface[][]) =>
  itemsProperties
    .map(assignColorToItemProperties)
    .map(removeWhitespacesFromPriceOrVatItemProperties)
    .map(removeTrailingDotsFromItemDatesProperties);

const useGetInvoiceQuery = (id: string) => {
  const { request } = useRequest<InvoiceVerificationResponseType>(ApiOperation.GetInvoice);

  return useQuery([getInvoiceQueryKey, id], async () => {
    const res = await request({ urlParams: { id } });

    return {
      ...res.data.responseModel,
      invoiceProperties: res.data.responseModel
        ? mapProperties(res.data.responseModel.invoiceProperties)
        : [],
      invoiceItemsProperties:
        res.data.responseModel.invoiceItemsProperties.length > 0
          ? mapItemsProperties(res.data.responseModel.invoiceItemsProperties)
          : [emptyInvoiceProperty.map(assignColorToProperty)],
    };
  });
};

export default useGetInvoiceQuery;
