import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useSubmitButtonsHook from '../../../../utils/hooks/useSubmitButtons.hook';
import classes from './DeleteModal.module.scss';
import { DeleteModalPropsInterface } from './interfaces/DeleteModalProps.interface';

const DeleteModalComponent = ({
  text,
  isLoading,
  isSuccess,
  mutate,
  close,
}: DeleteModalPropsInterface) => {
  const { t } = useTranslation();

  const onDeleteHandler = (): void => {
    mutate();
  };

  const { buttons } = useSubmitButtonsHook({
    isLoading,
    close,
    handleSubmit: onDeleteHandler,
    submitText: t('global.delete'),
  });

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [close, isSuccess]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes['delete-modal-message']}>{text}</div>
      </Grid>

      {buttons()}
    </Grid>
  );
};

export default DeleteModalComponent;
