import React, { useMemo, useState } from 'react';
import InvoiceHistoryModalComponent from '../InvoiceHistoryModal.component';

const useInvoiceHistory = () => {
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  // TODO: update typing to invoice interface when data structure is ready ({ id: string })
  const [historyInvoiceData, setHistoryInvoiceData] = useState<{ id: string } | undefined>(
    undefined,
  );

  const historyModal = useMemo(() => {
    if (!historyInvoiceData) {
      return <> </>;
    }

    return (
      <InvoiceHistoryModalComponent
        id={historyInvoiceData.id}
        open={isHistoryModalOpen}
        setOpen={(isOpen: boolean) => {
          if (!isOpen) {
            setHistoryInvoiceData(undefined);
            setIsHistoryModalOpen(false);
          }
        }}
      />
    );
  }, [historyInvoiceData, isHistoryModalOpen]);

  return { historyModal, historyInvoiceData, setIsHistoryModalOpen, setHistoryInvoiceData };
};

export default useInvoiceHistory;
