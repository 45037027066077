export const getUsersQueryKey = 'getUsersQueryKey';
export const getUserInvitationStatusQueryKey = 'getUserInvitationStatusQueryKey';

export const getDataSourcesQueryKey = 'getDataSourcesQueryKey';
export const getDataSourcesForGeneralTypeQuery = 'getDataSourcesForGeneralTypeQuery';

export const getAllRolesQueryKey = 'getAllRolesQueryKey';
export const getRolesQueryKey = 'getRolesQueryKey';

export const getNotificationsQueryKey = 'getNotificationsQueryKey';

export const getPermissionsQueryKey = 'getPermissionsQueryKey';

export const getOcrProcessedPages = 'getOcrProcessedPages';
export const getInProgressInvoicesQueryKey = 'getInProgressInvoicesQueryKey';
export const getToVerifyInvoicesQueryKey = 'getToVerifyInvoicesQueryKey';
export const getToApproveInvoicesQueryKey = 'getToApproveInvoicesQueryKey';
export const getProcessedInvoicesQueryKey = 'getProcessedInvoicesQueryKey';
export const getInvoiceQueryKey = 'getInvoiceQueryKey';
export const getInvoiceStateQueryKey = 'getInvoiceStateQueryKey';
export const getInvoiceDetailsQueryKey = 'getInvoiceDetailsQueryKey';
export const getInvoiceMatchingQueryKey = 'getInvoiceMatchingQueryKey';
export const getInvoiceHistoryQueryKey = 'getInvoiceHistoryQueryKey';
export const getInvoiceDuplicatesQueryKey = 'getInvoiceDuplicatesQueryKey';

export const getUploadHubRolesQueryKey = 'getUploadHubRolesQueryKey';
export const getUploadHubSettingsQueryKey = 'getUploadHubSettingsQueryKey';
export const getSynchronizedInboxesQueryKey = 'getSynchronizedInboxes';

export const getConfigurationQueryKey = 'getConfigurationQueryKey';

export const getResidentPropertiesQueryKey = 'getResidentPropertiesQueryKey';
export const getResidentSuppliersQueryKey = 'getResidentSuppliersQueryKey';
export const getResidentBankAccountsQueryKey = 'getResidentBankAccountsQueryKey';
export const getResidentWorkOrdersQueryKey = 'getResidentWorkOrdersQueryKey';
export const getResidentSchedulesQueryKey = 'getResidentSchedulesQueryKey';
export const getResidentCategoriesQueryKey = 'getResidentCategoriesQueryKey';
