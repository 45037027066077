import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import classNames from 'classnames';
import TableComponent from '../../../../../components/table/Table.component';
import { TableColumnInterface } from '../../../../../components/table/interfaces/TableColumn.interface';
import { AlignEnum } from '../../../../../../enums/Align.enum';
import { TableColumnTypeEnum } from '../../../../../components/table/enums/TableColumnType.enum';
import useGetInProgressInvoicesListQuery from '../../../../../../services/react-query-hooks/queries/upload-hub/invoice/in-progress/useGetInProgressInvoicesList.query';
import useTableFilters from '../../../../../components/table/hooks/TableFilters.hook';
import { TableRowInterface } from '../../../../../components/table/interfaces/TableRow.interface';
import { InProgressInvoiceInterface } from '../../../../../../types/upload-hub/invoice/in-progress/InProgressInvoice.interface';
import useDate from '../../../../../../utils/hooks/date-format/useDate';
import { TableActionInterface } from '../../../../../components/table/interfaces/TableProps.interface';
import { TableActionNameEnum } from '../../../../../components/table/enums/TableActionName.enum';
import { InProgressInvoiceActionNameEnum } from './enums/InProgressInvoiceActionName.enum';
import { useInvoicesContext } from '../../Invoices.component';
import { Button, Chip } from '../../../../../components/material';
import { queryClient } from '../../../../../../services/queryClient';
import {
  getInProgressInvoicesQueryKey,
  getOcrProcessedPages,
} from '../../../../../../services/react-query-hooks/queries/queryKeys';
import { InvoiceStatusEnum } from '../../../../../../enums/upload-hub/InvoiceStatus.enum';
import useSendToOcrMutation from '../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/send-to-ocr/useSendToOcr.mutation';
import useFile from '../../../../../../utils/hooks/file/useFile.hook';
import useDeleteInvoice from '../delete/hooks/useDeleteInvoice.hook';
import { InvoiceStatusMap } from '../../../../../../types/upload-hub/invoice/InvoiceStatus.type';
import classes from './InProgressInvoices.module.scss';
import useSyncAllSynchronizedInboxesMutation from '../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/synchronized-inboxes/useSyncAllSynchronizedInboxes.mutation';
import { useUploadHub } from '../../providers/UploadHub.provider';
import { sendToOcrMutationKey } from '../../../../../../services/react-query-hooks/mutations/mutationKeys';
import { EmailProviderEnum } from '../../../../../../enums/upload-hub/settings/EmailProvider.enum';

const InProgressInvoicesComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { format } = useDate();
  const { download } = useFile();
  const { isSyncMailboxesEnabled, isProcessingEnabled, settings } = useUploadHub();
  const { filters, dispatchFilters } = useTableFilters();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { data, pagination, isLoading } = useGetInProgressInvoicesListQuery(filters);
  const getInvoice = (id: string): InProgressInvoiceInterface | undefined =>
    data?.find((item: InProgressInvoiceInterface) => item.invoiceId === id);

  const {
    deleteModal,
    setDeleteInvoiceOpen,
    setDeleteInvoiceData,
    setBulkDeleteInvoiceData,
    setBulkDeleteInvoiceOpen,
    deleteMutationPromise,
  } = useDeleteInvoice(getInProgressInvoicesQueryKey);

  const [, setHistoryInvoiceData, setIsHistoryModalOpen] = useInvoicesContext();

  const { mutate: sendToOcr } = useSendToOcrMutation();
  const { mutate: syncMailboxes, isLoading: isSyncMailboxesProcessing } =
    useSyncAllSynchronizedInboxesMutation({
      provider: settings ? settings.emailProvider : EmailProviderEnum.SMTP,
    });

  const inProgressInvoicesColumns: TableColumnInterface[] = [
    {
      name: 'invoiceStatus',
      label: t('invoices.tabs.inProgress.columns.status'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      width: '5rem',
    },
    {
      name: 'email',
      label: t('invoices.tabs.inProgress.columns.location'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '15rem',
    },
    {
      name: 'fileName',
      label: t('invoices.tabs.inProgress.columns.fileName'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '25rem',
    },
    {
      name: 'registrationDate',
      label: t('invoices.tabs.inProgress.columns.addDate'),
      align: AlignEnum.CENTER,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '10rem',
    },
    {
      name: 'actions',
      label: t('invoices.tabs.inProgress.columns.action'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.ACTIONS,
      width: '4rem',
    },
  ];

  const renderInvoiceStatus = useCallback(
    (invoice: InProgressInvoiceInterface) => {
      const statusKey = InvoiceStatusMap[invoice.state];
      // TODO: resolve typing enum map issue
      // @ts-ignore
      const statusLabel = String(t(`invoices.statuses.${statusKey}`));

      switch (invoice.state) {
        case InvoiceStatusEnum.NO_OCR:
          return <Chip label={statusLabel} color="secondary" />;

        case InvoiceStatusEnum.OCR_IN_PROGRESS:
          return <Chip label={statusLabel} color="info" variant="outlined" />;

        case InvoiceStatusEnum.OCR_FAILED:
          return <Chip label={statusLabel} color="error" />;

        default:
          return <Chip label={statusLabel} color="transparent" variant="outlined" />;
      }
    },
    [t],
  );

  const mapInProgressInvoiceToRow = (invoice: InProgressInvoiceInterface): TableRowInterface => ({
    ...invoice,
    id: invoice.invoiceId,
    invoiceStatus: renderInvoiceStatus(invoice),
    email: invoice.emailLocation || t('invoices.tabs.inProgress.manualLocation'),
    registrationDate: invoice.addDate ? format(invoice.addDate) : '',
    disabled: invoice.state === InvoiceStatusEnum.OCR_IN_PROGRESS,
    loading:
      queryClient.isMutating({
        mutationKey: sendToOcrMutationKey,
        predicate: (m) => m.options.variables?.payload.invoiceNumber === invoice.invoiceId,
      }) > 0,
  });

  const actions: TableActionInterface<InProgressInvoiceActionNameEnum>[] = [
    {
      name: InProgressInvoiceActionNameEnum.SEND_TO_OCR,
      icon: <FileUploadOutlinedIcon />,
      label: t('invoices.tabs.inProgress.actions.ocr.title'),
      // TODO: support multiselect
      multiselect: false,
      active: (row: TableRowInterface) =>
        row.state === InvoiceStatusEnum.NO_OCR && isProcessingEnabled,
    },
    {
      name: InProgressInvoiceActionNameEnum.SEND_TO_OCR,
      icon: <FileUploadOutlinedIcon />,
      label: t('invoices.tabs.inProgress.actions.ocr.resend'),
      active: (row: TableRowInterface) =>
        row.state === InvoiceStatusEnum.OCR_FAILED && isProcessingEnabled,
    },
    {
      name: TableActionNameEnum.DOWNLOAD,
      icon: <FileDownloadOutlinedIcon />,
      label: t('global.download'),
    },
    {
      name: InProgressInvoiceActionNameEnum.HISTORY,
      icon: <TimelineIcon />,
      label: t('invoices.tabs.inProgress.actions.history.title'),
    },
    {
      name: TableActionNameEnum.DELETE,
      icon: <ClearOutlinedIcon />,
      label: t('global.delete'),
      multiselect: true,
      active: isProcessingEnabled,
    },
  ];

  const onActionSelectedHandler = (
    actionName: InProgressInvoiceActionNameEnum | TableActionNameEnum,
    rowId: string[] | number[] | string | number,
  ) => {
    const invoice = getInvoice(String(rowId));
    const isMultiple = Array.isArray(rowId) && !invoice;
    const isSingle = invoice && !isMultiple;

    switch (actionName) {
      case InProgressInvoiceActionNameEnum.SEND_TO_OCR: {
        if (isSingle) {
          sendToOcr({
            fileName: invoice.fileName,
            payload: {
              invoiceNumber: invoice.invoiceId,
            },
          });
        }

        break;
      }

      case TableActionNameEnum.DOWNLOAD: {
        if (isSingle) {
          download(invoice.fileLocalization, invoice.fileName);
        }
        break;
      }

      case TableActionNameEnum.DELETE: {
        if (isSingle) {
          setDeleteInvoiceData(invoice);
          setDeleteInvoiceOpen(true);
        }

        if (isMultiple) {
          setBulkDeleteInvoiceData(rowId as string[]);
          setBulkDeleteInvoiceOpen(true);
        }
        break;
      }

      case InProgressInvoiceActionNameEnum.HISTORY: {
        if (isSingle) {
          setHistoryInvoiceData({ id: invoice.invoiceId });
          setIsHistoryModalOpen(true);
        }
        break;
      }

      default:
    }
  };

  const onRefreshHandler = () => {
    queryClient.invalidateQueries(getOcrProcessedPages);
    queryClient.invalidateQueries(getInProgressInvoicesQueryKey);
    setIsRefreshing(true);

    setTimeout(() => {
      setIsRefreshing(false);
    }, 500);
  };

  const onSyncHandler = useCallback(() => {
    syncMailboxes();
  }, [syncMailboxes]);

  const toolbarContent = useMemo(
    () => (
      <>
        <Button
          onClick={onRefreshHandler}
          variant="outlined"
          loading={isLoading || isRefreshing}
          startIcon={<RefreshOutlinedIcon />}
          className={classes.toolbar__button}
        >
          {t('invoices.tabs.inProgress.actions.refresh.title')}
        </Button>

        {isSyncMailboxesEnabled && isProcessingEnabled && (
          <Button
            onClick={onSyncHandler}
            variant="outlined"
            loading={isSyncMailboxesProcessing}
            startIcon={<CachedOutlinedIcon />}
            className={classNames(classes.toolbar__button, classes['toolbar__button--sync'])}
          >
            {t('invoices.tabs.inProgress.actions.sync.title')}
          </Button>
        )}
      </>
    ),
    [
      isLoading,
      isProcessingEnabled,
      isRefreshing,
      isSyncMailboxesEnabled,
      isSyncMailboxesProcessing,
      onSyncHandler,
      t,
    ],
  );

  return (
    <Grid className={classes['in-progress-invoices']}>
      <TableComponent
        table={{
          rows: data ? data.map(mapInProgressInvoiceToRow) : [],
          columns: inProgressInvoicesColumns,
        }}
        emptyLabel={t('invoices.tabs.inProgress.emptyTable')}
        isLoading={isLoading}
        multiselect
        actions={actions}
        onActionSelected={onActionSelectedHandler}
        onActionCompleted={deleteMutationPromise}
        filters={filters}
        onChangeFilters={dispatchFilters}
        toolbar={toolbarContent}
        pagination={pagination}
      />

      {deleteModal}
    </Grid>
  );
};

export default InProgressInvoicesComponent;
