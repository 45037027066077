import { useTranslation } from 'react-i18next';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { useValidationErrorHandler } from '../useErrorHandler';

const useIncorrectEmailErrorHandler = <T extends FieldValues>(
  setValidationError: UseFormSetError<T>,
  errorMessage?: string,
) => {
  const { t } = useTranslation();

  return useValidationErrorHandler({
    setError: setValidationError,
    errorCode: 'INCORRECT',
    errorMessage:
      errorMessage ||
      t('settings.sections.permission.subsections.user.controls.email.errors.incorrect'),
    field: 'email',
    options: { shouldFocus: true },
  });
};

export default useIncorrectEmailErrorHandler;
