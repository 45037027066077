import { OCRFieldsEnum } from '../../../enums/upload-hub/OCRFields.enum';

export const OCRInvoiceFieldTypes = Object.values(OCRFieldsEnum);

export type OCRInvoiceFieldType = (typeof OCRInvoiceFieldTypes)[number];

export const OCRInvoiceFieldColors: { [key in OCRInvoiceFieldType]: string } = {
  [OCRFieldsEnum.AMOUNT]: '#4b0082',
  [OCRFieldsEnum.DUE_DATE]: '#ff8c00',
  [OCRFieldsEnum.INVOICE_DATE]: '#228b22',
  [OCRFieldsEnum.INVOICE_ID]: '#7f0000',
  [OCRFieldsEnum.PRODUCT_CODE]: '#ffff00',
  [OCRFieldsEnum.SERVICE_END_DATE]: '#00ff00',
  [OCRFieldsEnum.SERVICE_START_DATE]: '#00ffff',
  [OCRFieldsEnum.TAX]: '#0000ff',
  [OCRFieldsEnum.INVOICE_TOTAL]: '#ff1493',
  [OCRFieldsEnum.VENDOR_ADDRESS]: '#9900ff',
  [OCRFieldsEnum.VENDOR_ADDRESS_RECIPIENT]: '#eee8aa',
  [OCRFieldsEnum.VENDOR_NAME]: '#6495ed',
  [OCRFieldsEnum.DATE]: '#6b8e23',
};

export const OCRInvoiceFieldColorsMap = new Map(Object.entries(OCRInvoiceFieldColors));
