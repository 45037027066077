import React, { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { BaseElementInterface } from '../../../../interfaces/Element.interface';
import { PathEnum } from '../enums/Path.enum';
import { pathTo } from '../Router.outlet';
import { useUploadHub } from '../../../home-page/components/invoices/providers/UploadHub.provider';

const ApprovalElement: FC<BaseElementInterface> = ({ element }): ReactElement => {
  const { isApprovalEnabled } = useUploadHub();

  if (!isApprovalEnabled) {
    return <Navigate to={pathTo([PathEnum.INVOICES, PathEnum.IN_PROGRESS])} />;
  }

  return element;
};

export default ApprovalElement;
