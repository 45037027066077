import React, { FC, ReactElement } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, SwipeableDrawer } from '@mui/material';
import classes from './DrawerMenu.module.scss';
import UserProfileMenuComponent from '../../../header/components/user-profile/UserProfileMenu.component';
import { IconButton } from '../../../material';
import NavigationLinks from '../navigation-links/NavigationLinks.component';
import { DrawerMenuPropsInterface } from './interfaces/DrawerMenuProps.interface';

const DrawerMenuComponent: FC<DrawerMenuPropsInterface> = (props): ReactElement => {
  const { isOpen, setIsOpen } = props;

  return (
    <SwipeableDrawer
      anchor="top"
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      className={classes['drawer-menu']}
    >
      <div className={classes['drawer-menu__header']}>
        <UserProfileMenuComponent />
        <IconButton size="large" aria-label="close" onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <NavigationLinks variant="full-button" />
    </SwipeableDrawer>
  );
};

export default DrawerMenuComponent;
