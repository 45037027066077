import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { InvoiceMutationPropsInterface } from '../InvoiceMutationProps.interface';
import { ApiOperation } from '../../../../../api';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { sendToOcrMutationKey } from '../../../mutationKeys';
import { useAlert } from '../../../../../alert/Alert.provider';
import { SendToOcrPayloadInterface } from './interfaces/SendToOcrPayload.interface';
import useThresholdOfAvailablePagesToProcessExceededErrorHandler from '../../../../error-handlers/upload-hub/invoice/useThresholdOfAvailablePagesToProcessExceededError.handler';

const useSendToOcrMutation = ({ setOtherError }: InvoiceMutationPropsInterface = {}) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.SendToOCR);

  const thresholdOfAvailablePagesToProcessExceededErrorHandler =
    useThresholdOfAvailablePagesToProcessExceededErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError(
    [thresholdOfAvailablePagesToProcessExceededErrorHandler],
    generalErrorHandler,
  );

  return useMutation(
    async ({ payload, fileName: name }: SendToOcrPayloadInterface) => {
      const res = await request({ data: { ...payload } });

      if (res.status === 200) {
        showAlert(
          <Trans
            i18nKey="invoices.tabs.inProgress.actions.ocr.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(res.data);
        }, 10000);
      });
    },
    {
      mutationKey: sendToOcrMutationKey,
      onError,
    },
  );
};

export default useSendToOcrMutation;
