import React, {
  FC,
  MutableRefObject,
  ReactElement,
  SyntheticEvent,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Badge } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { NotificationsListItemPropsInterface } from './interfaces/NotificationsListItemProps.interface';
import classes from './NotificationsListItem.module.scss';
import { Tooltip } from '../../../../../material';
import { NotificationsTypeOptions } from '../../../../../../../types/notification/Notification.type';
import useMarkNotificationAsReadMutation from '../../../../../../../services/react-query-hooks/mutations/notification/useMarkNotificationAsRead.mutation';
import { useNotification } from '../../../../../../../services/notification/Notification.provider';

const NotificationsListItemComponent: FC<NotificationsListItemPropsInterface> = ({
  notification,
  onClose,
}): ReactElement => {
  const { t } = useTranslation();
  const indicatorBadgeRef = useRef() as MutableRefObject<HTMLSpanElement>;
  const [isIndicatorHovered, setIsIndicatorHovered] = useState<boolean>(false);

  const {
    isLoading: isOverallNotificationsLoading,
    onNotificationClickHandler: onNotificationClickActionHandler,
  } = useNotification();

  const messageContent = useMemo(() => {
    if (notification.message) {
      return notification.message;
    }

    return String(
      t(
        // @ts-ignore
        `notification.types.${NotificationsTypeOptions[notification.type].translationKey}`,
      ),
    );
  }, [notification.message, notification.type, t]);

  const [hasBeenClicked, setHasBeenClicked] = useState<boolean>(false);
  const [error, setError] = useState('');

  const { mutate: markNotificationAsRead, isLoading } = useMarkNotificationAsReadMutation({
    setOtherError: setError,
  });

  const onNotificationClickHandler = (): void => {
    onMarkAsReadClickHandler();
    onNotificationClickActionHandler(notification);

    if (onClose) {
      onClose();
    }
  };

  const onMarkAsReadClickHandler = (event?: SyntheticEvent): void => {
    event?.stopPropagation();

    setHasBeenClicked(true);

    if (!notification.isRead && !isLoading) {
      markNotificationAsRead({
        notificationUniqueNumber: notification.notificationUniqueNumber,
      });
    }
  };

  const onNotificationLinkClickHandler = (event: SyntheticEvent): void => {
    event.stopPropagation();

    onMarkAsReadClickHandler();
  };

  return (
    <li>
      <div
        role="button"
        onClick={onNotificationClickHandler}
        onKeyDown={onNotificationClickHandler}
        tabIndex={0}
        className={classnames(
          classes['notifications-list-item'],
          notification.isRead ||
            (hasBeenClicked && !error) ||
            isLoading ||
            isOverallNotificationsLoading
            ? classes['notifications-list-item--read']
            : '',
        )}
      >
        <Tooltip
          title={t('notification.markAsRead.tooltip')}
          open={!notification.isRead && isIndicatorHovered}
          disableInteractive
          disableHoverListener
          disableFocusListener
          disableTouchListener
          PopperProps={{
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(
                  indicatorBadgeRef.current!.getBoundingClientRect().x,
                  indicatorBadgeRef.current!.getBoundingClientRect().y,
                  0,
                  0,
                );
              },
            },
          }}
        >
          <div
            className={classes['notifications-list-item__indicator']}
            role="button"
            onClick={onMarkAsReadClickHandler}
            onKeyDown={onMarkAsReadClickHandler}
            onMouseOver={() => setIsIndicatorHovered(true)}
            onMouseLeave={() => setIsIndicatorHovered(false)}
            onFocus={() => {}}
            tabIndex={0}
          >
            <Badge
              variant="dot"
              badgeContent=" "
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              componentsProps={{
                badge: { className: classes.badge },
                root: { className: classes['notifications-list-item__indicator'] },
              }}
              ref={indicatorBadgeRef}
            />
          </div>
        </Tooltip>
        <div className={classes['notifications-list-item__content']}>
          <p className={classes.content__message}>{messageContent}</p>
          {notification.url && (
            <a
              href={notification.url}
              target={notification.urlSelfOpen ? '_self' : '_blank'}
              className={classes.content__link}
              rel="noreferrer"
              onClick={onNotificationLinkClickHandler}
            >
              {notification.urlLabel ? t(notification.urlLabel) : notification.url}
            </a>
          )}
        </div>
      </div>
    </li>
  );
};

export default NotificationsListItemComponent;
