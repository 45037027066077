import React, { FC, ReactElement } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Google } from '@mui/icons-material';
import classNames from 'classnames';
import classes from './ExternalLogin.module.scss';
import { Button } from '../../../components/material';
import useAuth from '../../../../services/auth/Auth.provider';
import { SocialConnectionEnum } from '../../../../services/auth/enums/SocialConnection.enum';
import { LoginLoadingEnum } from '../../../../services/auth/enums/LoginLoading.enum';
import { ExternalLoginPropsInterface } from './interfaces/ExternalLoginProps.interface';

const ExternalLoginComponent: FC<ExternalLoginPropsInterface> = ({
  invitationId,
}): ReactElement => {
  const { t } = useTranslation();
  const { loading, socialLogin } = useAuth();

  return (
    <div
      className={classNames(
        classes['external-login'],
        invitationId ? classes['external-login--sign-up'] : '',
      )}
    >
      <h6 className={classes['external-login__header']}>
        {t(`landingPage.externalLogin.header.${invitationId ? 'signUp' : 'login'}`)}
      </h6>

      <Grid container direction="column" rowSpacing={2}>
        {/* RES-532: Disable sign up with username and password + google until new integration comes in */}
        {!invitationId && (
          <>
            {/* TODO: uncomment when FB social login is configured in auth0 */}
            {/* <Grid item> */}
            {/*  <Button */}
            {/*    fullWidth */}
            {/*    variant="contained" */}
            {/*    className="data-hub-button--fb" */}
            {/*    startIcon={<FacebookRounded />} */}
            {/*    type="button" */}
            {/*    loading={loading === LoginLoadingEnum.FB} */}
            {/*    disabled={loading !== LoginLoadingEnum.FB && loading !== LoginLoadingEnum.NULL} */}
            {/*    onClick={() => socialLogin(SocialConnectionEnum.FB, invitationId)} */}
            {/*  > */}
            {/*    <span>{t('landingPage.externalLogin.facebook')}</span> */}
            {/*  </Button> */}
            {/* </Grid> */}
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                className="data-hub-button--google"
                startIcon={<Google />}
                type="button"
                loading={loading === LoginLoadingEnum.GOOGLE}
                disabled={loading !== LoginLoadingEnum.GOOGLE && loading !== LoginLoadingEnum.NULL}
                onClick={() => socialLogin(SocialConnectionEnum.GOOGLE, invitationId)}
              >
                <span>{t('landingPage.externalLogin.google')}</span>
              </Button>
            </Grid>
          </>
        )}

        <Grid item>
          <Button
            fullWidth
            variant="contained"
            className="data-hub-button--resident"
            startIcon={<img src="/static/resident-logo.png" alt="logo" />}
            type="button"
            disabled={loading !== LoginLoadingEnum.RESIDENT && loading !== LoginLoadingEnum.NULL}
            loading={loading === LoginLoadingEnum.RESIDENT}
            onClick={() => socialLogin(SocialConnectionEnum.RESIDENT, invitationId)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ExternalLoginComponent;
