import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';
import { useValidationErrorHandler } from '../useErrorHandler';
import DataSourceFormInterface from '../../../../views/data-source/components/data-source-form/DataSourceForm.interface';

const useDataSourceCannotTurnOffAutoSyncErrorHandler = (
  setValidationError: UseFormSetError<DataSourceFormInterface>,
) => {
  const { t } = useTranslation();

  return useValidationErrorHandler({
    setError: setValidationError,
    errorCode: 'CANNOT_TURN_ON_DATA_SOURCE_AUTO_SYNC',
    errorMessage: t(
      'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.autoSync.errors.cannotTurnOn',
    ),
    field: 'autoSync',
  });
};

export default useDataSourceCannotTurnOffAutoSyncErrorHandler;
