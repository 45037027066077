import React, { useMemo, useState } from 'react';
import AddSynchronizedInboxModalComponent from '../AddSynchronizedInboxModal.component';

const useAddSynchronizedInbox = () => {
  const [addEmailOpen, setAddEmailOpen] = useState(false);

  const addSynchronizedInboxModal = useMemo(
    () => <AddSynchronizedInboxModalComponent open={addEmailOpen} setOpen={setAddEmailOpen} />,
    [addEmailOpen],
  );

  return { addSynchronizedInboxModal, setAddEmailOpen };
};

export default useAddSynchronizedInbox;
