import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import classes from './PrimaryDataSources.module.scss';
import {
  DataSourceTypes,
  DataSourceTypesMap,
} from '../../../../../../../../types/data-source/DataSourceType.type';
import useGetDataSourcesForGeneralTypeListQuery from '../../../../../../../../services/react-query-hooks/queries/data-source/useGetDataSourcesForGeneralTypeList.query';
import Select from '../../../../../../../components/material/select/Select.component';
import { FormControl, MenuItem } from '../../../../../../../components/material';
import { DataSourceInterface } from '../../../../../../../../types/data-source/DataSource.interface';
import { PrimaryDataSourcesPropsInterface } from './interfaces/PrimaryDataSourcesProps.interface';
import { InvoiceReceiverFormInterface } from '../../interfaces/InvoiceReceiverForm.interface';

const dataSourceAsMenuItem = ({ uniqueNumber, name }: DataSourceInterface) => (
  <MenuItem value={uniqueNumber} key={uniqueNumber}>
    {name}
  </MenuItem>
);

const PrimaryDataSourcesComponent: FC<PropsWithChildren<PrimaryDataSourcesPropsInterface>> = ({
  invoiceReceiver,
  isLoading,
  children,
}): ReactElement => {
  const { t } = useTranslation();
  const { data: dataSources, isLoading: isGeneralTypeDataSourcesLoading } =
    useGetDataSourcesForGeneralTypeListQuery(invoiceReceiver);

  const {
    formState: { errors },
    control,
  } = useFormContext<InvoiceReceiverFormInterface>();

  return (
    <>
      <h6 className={classes['primary-data-sources__header']}>
        {t(
          'pluginSettings.sections.uploadHub.subsections.invoiceReceiver.primaryDataSources.header',
        )}
      </h6>
      <Grid
        container
        direction="column"
        rowSpacing={2}
        className={classes['primary-data-sources__form']}
      >
        {/* TODO: move supplierList.types to common upload hub translation section */}
        {!isGeneralTypeDataSourcesLoading &&
          DataSourceTypes.map((type) => (
            <Grid item key={type}>
              <FormControl
                error={errors?.primaryDataSources ? !!errors?.primaryDataSources[type] : false}
              >
                <Controller
                  name={`primaryDataSources.${type}`}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t(
                      'pluginSettings.sections.uploadHub.subsections.invoiceReceiver.controls.primaryDataSource.errors.required',
                      {
                        primaryDataSource: t(
                          `dataSourcesSettings.sections.dataSources.subsections.supplierList.types.${type}`,
                        ),
                      },
                    ),
                  }}
                  render={(renderProps) => (
                    <Select
                      fullWidth
                      label={t(
                        `dataSourcesSettings.sections.dataSources.subsections.supplierList.types.${type}`,
                      )}
                      disabled={isLoading}
                      error={
                        errors?.primaryDataSources ? !!errors?.primaryDataSources[type] : false
                      }
                      grid={1}
                      {...renderProps.field}
                      required
                    >
                      {dataSources
                        ?.filter(
                          (dataSource: DataSourceInterface) =>
                            dataSource.type === DataSourceTypesMap[type],
                        )
                        .map(dataSourceAsMenuItem)}
                    </Select>
                  )}
                />
                <span role="alert">
                  {errors?.primaryDataSources ? errors?.primaryDataSources[type]?.message : ''}
                </span>
              </FormControl>
            </Grid>
          ))}
        {children}
      </Grid>
    </>
  );
};

export default PrimaryDataSourcesComponent;
