import React from 'react';
import { useForm } from 'react-hook-form';
import useUpdateDataSourceMutation from '../../../../services/react-query-hooks/mutations/data-source/useUpdateDataSource.mutation';
import { SimpleDataSourceObjectInterface } from '../../../../types/data-source/DataSource.interface';
import FormModalComponent from '../../../components/modal/form-modal/FormModal.component';
import DataSourceFormComponent from '../data-source-form/DataSourceForm.component';
import DataSourceFormInterface from '../data-source-form/DataSourceForm.interface';

interface UpdateDataSourcePropsInterface extends SimpleDataSourceObjectInterface {
  id: string;
  close: () => void;
}

const UpdateDataSourceView = ({
  id,
  name,
  type,
  origin,
  autoSync,
  close,
}: UpdateDataSourcePropsInterface) => {
  const methods = useForm<DataSourceFormInterface>({
    mode: 'onChange',
    defaultValues: {
      name,
      type,
      origin,
      autoSync,
    },
  });
  const {
    mutate: updateDataSource,
    isLoading,
    isSuccess,
  } = useUpdateDataSourceMutation({
    setValidationError: methods.setError,
  });

  const onSubmitHandler = (data: DataSourceFormInterface) =>
    updateDataSource({
      id,
      payload: {
        ...data,
        type: Number(data.type),
        origin: Number(data.origin),
      },
    });

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmitHandler)}
      isSuccess={isSuccess}
      isLoading={isLoading}
    >
      <DataSourceFormComponent mode="update" />
    </FormModalComponent>
  );
};

export default UpdateDataSourceView;
