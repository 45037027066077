import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../../../../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../../../../../../../components/modal/types/Props.interface';
import AddUserComponent from './AddUser.component';
import { RoleInterface } from '../../../../../../../../../../types/roles/Role.interface';

interface AddUserModalComponentPropsInterface extends ModalControlPropsInterface {
  roles: RoleInterface[] | undefined;
}

const AddUserModalComponent: FC<AddUserModalComponentPropsInterface> = ({
  roles,
  open,
  setOpen,
}): ReactElement => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      title={t('settings.sections.permission.subsections.user.addModal.title')}
      content={<AddUserComponent roles={roles} close={handleClose} />}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xs',
      }}
      actions
    />
  );
};

export default AddUserModalComponent;
