import React, { useMemo, useState } from 'react';
import DeleteUserModalComponent from '../DeleteUserModal.component';
import { UserInterface } from '../../../../../../../../../../../types/users/User.interface';

const useDeleteUser = () => {
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState<UserInterface | undefined>(undefined);

  const deleteUserModal = useMemo(() => {
    if (!deleteUserData) {
      return <> </>;
    }

    return (
      <DeleteUserModalComponent
        user={deleteUserData}
        open={deleteUserOpen}
        setOpen={setDeleteUserOpen}
      />
    );
  }, [deleteUserData, deleteUserOpen]);

  return { deleteUserModal, setDeleteUserOpen, setDeleteUserData };
};

export default useDeleteUser;
