import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalControlPropsInterface } from '../../../../../../../../../components/modal/types/Props.interface';
import ModalComponent from '../../../../../../../../../components/modal/modal/Modal.component';
import DeleteUserComponent from './DeleteUser.component';
import { UserInterface } from '../../../../../../../../../../types/users/User.interface';

interface DeleteUserModalComponentPropsInterface extends ModalControlPropsInterface {
  user: UserInterface | undefined;
}

const DeleteUserModalComponent: FC<DeleteUserModalComponentPropsInterface> = ({
  user,
  open,
  setOpen,
}): ReactElement => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t('settings.sections.permission.subsections.user.deleteModal.title')}
      open={open}
      handleClose={handleClose}
      variant="warning"
      content={user && <DeleteUserComponent user={user} close={handleClose} />}
    />
  );
};

export default DeleteUserModalComponent;
