import React, { FC, ReactElement, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { VerifyInvoiceItemPropsInterface } from './interfaces/VerifyInvoiceItemProps.interface';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';
// TODO: create shared scss for invoice field and invoice item
import '../verify-invoice-data-field/VerifyInvoiceDataField.component.scss';
import { ResidentInvoiceDataTypeMap } from '../../../../../../../../../../../../types/upload-hub/invoice/ResidentInvoiceData.type';
import SchedulesController from '../controllers/SchedulesController.component';
import CategoriesController from '../controllers/CategoriesController.component';
import InputController from '../controllers/InputController.component';
import { ResidentScheduleInterface } from '../../../../../../../../../../../../types/upload-hub/resident/schedule/ResidentSchedule.interface';
import { queryClient } from '../../../../../../../../../../../../services/queryClient';
import { getResidentCategoriesQueryKey } from '../../../../../../../../../../../../services/react-query-hooks/queries/queryKeys';
import { InvoicePropertyInterface } from '../../../../../../../../../../../../types/upload-hub/invoice/InvoiceProperty.interface';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';

const VerifyInvoiceItemComponent: FC<VerifyInvoiceItemPropsInterface> = ({
  fields,
  index,
}): ReactElement => {
  const { t } = useTranslation();

  const getField = useCallback(
    (id: ResidentInvoiceDataEnum) => {
      return fields?.find(
        (property: InvoicePropertyInterface) => property.residentFieldName === id,
      );
    },
    [fields],
  );

  const getFieldColor = useCallback(
    (id: ResidentInvoiceDataEnum) => {
      const field = getField(id);

      return field && field.color ? field.color : 'transparent';
    },
    [getField],
  );

  const getFieldLabel = useCallback(
    (id: ResidentInvoiceDataEnum): string => {
      if (ResidentInvoiceDataTypeMap[id]) {
        // @ts-ignore
        return t(`invoices.residentInvoiceDataTypes.${ResidentInvoiceDataTypeMap[id]}`);
      }

      return t('global.unknown');
    },
    [t],
  );

  const {
    setValue,
    formState: { errors },
  } = useFormContext<VerifyInvoiceDataFormInterface>();

  const onChangeScheduleHandler = (value: ResidentScheduleInterface | null) => {
    // @ts-ignore
    setValue(`items[${index}].${ResidentInvoiceDataEnum.ITEM_CATEGORY}`, null);

    if (value !== null) {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === getResidentCategoriesQueryKey && query.queryKey[1] === value.value
          );
        },
      });
    }
  };

  return (
    <>
      <Grid item container direction="row" className="verify-invoice-data-field">
        <Grid item xs={1} className="verify-invoice-data-field__color-indicator">
          <div
            className="color-indicator__wrapper"
            style={{
              backgroundColor: getFieldColor(ResidentInvoiceDataEnum.ITEM_DESCRIPTION),
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className={classNames(
            'verify-invoice-data-field__title',
            'verify-invoice-data-field__title--required',
          )}
        >
          {getFieldLabel(ResidentInvoiceDataEnum.ITEM_DESCRIPTION)}
        </Grid>
        <Grid item xs={9} className="verify-invoice-data-field__field">
          <InputController
            name={`items[${index}].${ResidentInvoiceDataEnum.ITEM_DESCRIPTION}`}
            error={errors.items?.[index]?.[String(ResidentInvoiceDataEnum.ITEM_DESCRIPTION)]}
            width="50%"
            disabled={getField(ResidentInvoiceDataEnum.ITEM_DESCRIPTION)?.disabled}
            data-cy={`invoice-item-description-${index}`}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" className="verify-invoice-data-field">
        <Grid item xs={1} className="verify-invoice-data-field__color-indicator">
          <div
            className="color-indicator__wrapper"
            style={{
              backgroundColor: getFieldColor(ResidentInvoiceDataEnum.ITEM_AMOUNT),
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className={classNames(
            'verify-invoice-data-field__title',
            'verify-invoice-data-field__title--required',
          )}
        >
          {getFieldLabel(ResidentInvoiceDataEnum.ITEM_AMOUNT)}
        </Grid>
        <Grid item xs={2.25} className="verify-invoice-data-field__field">
          <InputController
            name={`items[${index}].${ResidentInvoiceDataEnum.ITEM_AMOUNT}`}
            error={errors.items?.[index]?.[String(ResidentInvoiceDataEnum.ITEM_AMOUNT)]}
            disabled={getField(ResidentInvoiceDataEnum.ITEM_AMOUNT)?.disabled}
            data-cy={`invoice-item-amount-${index}`}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" className="verify-invoice-data-field">
        <Grid item xs={1} className="verify-invoice-data-field__color-indicator">
          <div
            className="color-indicator__wrapper"
            style={{ backgroundColor: getFieldColor(ResidentInvoiceDataEnum.ITEM_VAT) }}
          />
        </Grid>
        <Grid item xs={2} className={classNames('verify-invoice-data-field__title')}>
          {getFieldLabel(ResidentInvoiceDataEnum.ITEM_VAT)}
        </Grid>
        <Grid item xs={2.25} className="verify-invoice-data-field__field">
          <InputController
            name={`items[${index}].${ResidentInvoiceDataEnum.ITEM_VAT}`}
            error={errors.items?.[index]?.[String(ResidentInvoiceDataEnum.ITEM_VAT)]}
            disabled={getField(ResidentInvoiceDataEnum.ITEM_VAT)?.disabled}
            data-cy={`invoice-item-vat-${index}`}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" className="verify-invoice-data-field">
        <Grid item xs={1} className="verify-invoice-data-field__color-indicator">
          <div
            className="color-indicator__wrapper"
            style={{
              backgroundColor: getFieldColor(ResidentInvoiceDataEnum.ITEM_SCHEDULE),
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className={classNames(
            'verify-invoice-data-field__title',
            'verify-invoice-data-field__title--required',
          )}
        >
          {getFieldLabel(ResidentInvoiceDataEnum.ITEM_SCHEDULE)}
        </Grid>
        <SchedulesController
          name={`items[${index}].${ResidentInvoiceDataEnum.ITEM_SCHEDULE}`}
          error={errors.items?.[index]?.[String(ResidentInvoiceDataEnum.ITEM_SCHEDULE)]}
          onChangeSchedule={onChangeScheduleHandler}
          disabled={getField(ResidentInvoiceDataEnum.ITEM_SCHEDULE)?.disabled}
          data-cy={`schedule-${index}`}
        />
      </Grid>
      <Grid item container direction="row" className="verify-invoice-data-field">
        <Grid item xs={1} className="verify-invoice-data-field__color-indicator">
          <div
            className="color-indicator__wrapper"
            style={{
              backgroundColor: getFieldColor(ResidentInvoiceDataEnum.ITEM_CATEGORY),
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className={classNames(
            'verify-invoice-data-field__title',
            'verify-invoice-data-field__title--required',
          )}
        >
          {getFieldLabel(ResidentInvoiceDataEnum.ITEM_CATEGORY)}
        </Grid>
        <CategoriesController
          name={`items[${index}].${ResidentInvoiceDataEnum.ITEM_CATEGORY}`}
          error={errors.items?.[index]?.[String(ResidentInvoiceDataEnum.ITEM_CATEGORY)]}
          index={index}
          disabled={getField(ResidentInvoiceDataEnum.ITEM_CATEGORY)?.disabled}
          data-cy={`category-${index}`}
        />
      </Grid>
    </>
  );
};

export default VerifyInvoiceItemComponent;
