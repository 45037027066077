import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSubmitButtonsHook from '../../../../utils/hooks/useSubmitButtons.hook';
import ModalComponent from '../modal/Modal.component';
import classes from './ConfirmModal.module.scss';
import ModalVariantType from '../../../../types/ModalVariant.type';

interface ConfirmModalComponentProps {
  text: string;
  open: boolean;
  title: string;
  close: () => void;
  confirm: () => void;
  variant?: ModalVariantType;
  closeOnlyOnButton?: boolean;
}

const ConfirmModalComponent = ({
  text,
  open,
  title,
  close,
  confirm,
  variant,
  closeOnlyOnButton = true,
}: ConfirmModalComponentProps) => {
  const { t } = useTranslation();

  const { buttons } = useSubmitButtonsHook({
    isLoading: false,
    close,
    handleSubmit: confirm,
    submitText: t('global.confirm'),
  });

  return (
    <ModalComponent
      open={open}
      handleClose={close}
      title={title}
      variant={variant || 'info'}
      content={
        <Grid container>
          <Grid item xs={12}>
            <div className={classes['confirm-modal-message']}>{text}</div>
          </Grid>
          {buttons()}
        </Grid>
      }
      closeOnlyOnButton={closeOnlyOnButton}
    />
  );
};

export default ConfirmModalComponent;
