const useGuidHook = (guid: string) => {
  const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const regx = new RegExp(guidPattern);

  return {
    isValid: regx.test(guid),
    guid,
  };
};

export default useGuidHook;
