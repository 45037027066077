import React, { FC, memo, useCallback } from 'react';
import { SettingsOutlined } from '@mui/icons-material';
import PermDataSettingOutlinedIcon from '@mui/icons-material/PermDataSettingOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { MenuItem } from '../material';
import { SettingsMenuPropsInterface } from './interfaces/SettingsMenuProps.interface';
import { pathTo } from '../router/Router.outlet';
import { PathEnum } from '../router/enums/Path.enum';
import { useUserPermissions } from '../../../services/user/User.provider';
import { PermissionTypeEnum } from '../../../enums/permission/PermissionType.enum';

const settingsPathTo = (path: PathEnum): string => {
  return pathTo([PathEnum.SETTINGS, path]);
};

const SettingsMenuComponent: FC<SettingsMenuPropsInterface> = ({
  navigateToMenuItem,
  popupState,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { hasPermission } = useUserPermissions();

  const isMenuItemActive = useCallback(
    (path: string): boolean => {
      return !!matchPath({ path }, pathname);
    },
    [pathname],
  );

  return (
    <>
      {hasPermission(PermissionTypeEnum.ADMIN) && (
        <>
          <MenuItem
            selected={isMenuItemActive(settingsPathTo(PathEnum.GLOBAL))}
            onClick={() => navigateToMenuItem(settingsPathTo(PathEnum.GLOBAL), popupState)}
          >
            <SettingsOutlined />
            <span>{t('settings.title')}</span>
          </MenuItem>
          <MenuItem
            selected={isMenuItemActive(settingsPathTo(PathEnum.DATA_SRC))}
            onClick={() => navigateToMenuItem(settingsPathTo(PathEnum.DATA_SRC), popupState)}
          >
            <PermDataSettingOutlinedIcon />
            <span>{t('dataSourcesSettings.title')}</span>
          </MenuItem>
        </>
      )}

      <MenuItem
        selected={isMenuItemActive(settingsPathTo(PathEnum.PLUGIN))}
        onClick={() => navigateToMenuItem(settingsPathTo(PathEnum.PLUGIN), popupState)}
      >
        <SettingsApplicationsOutlinedIcon />
        <span>{t('pluginSettings.title')}</span>
      </MenuItem>
    </>
  );
};

const SettingsMenu = memo(SettingsMenuComponent);

export default SettingsMenu;
