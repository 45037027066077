import React, { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { BaseElementInterface } from '../../../../interfaces/Element.interface';
import { PathEnum } from '../enums/Path.enum';
import { pathTo } from '../Router.outlet';
import { useUploadHub } from '../../../home-page/components/invoices/providers/UploadHub.provider';

const ProcessingElement: FC<BaseElementInterface> = ({ element }): ReactElement => {
  const { isApprovalEnabled, isProcessingEnabled } = useUploadHub();

  if (!isProcessingEnabled && isApprovalEnabled) {
    return <Navigate to={pathTo([PathEnum.INVOICES, PathEnum.TO_APPROVE])} />;
  }
  if (!isProcessingEnabled) {
    return <Navigate to={pathTo([PathEnum.INVOICES, PathEnum.PROCESSED])} />;
  }

  return element;
};

export default ProcessingElement;
