import React from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceFileInterface from '../../../../../../../../types/invoice-upload/InvoiceFile.interface';
import ModalComponent from '../../../../../../../components/modal/modal/Modal.component';
import ProgressModalContentView from './ProgressModalContent.view';

interface ProgressModalComponentPropsInterface {
  open: boolean;
  handleClose: () => void;
  files: InvoiceFileInterface[];
  inProgress: boolean;
}

const ProgressModalComponent = ({
  open,
  handleClose,
  files,
  inProgress,
}: ProgressModalComponentPropsInterface) => {
  const { t } = useTranslation();

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      title={t('invoices.invoiceUpload.modal.title')}
      content={
        <ProgressModalContentView files={files} handleClose={handleClose} inProgress={inProgress} />
      }
      closeOnlyOnButton
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
      }}
      actions
    />
  );
};

export default ProgressModalComponent;
