import React from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../api';
import { queryClient } from '../../../../../queryClient';
import { getSynchronizedInboxesQueryKey } from '../../../../queries/queryKeys';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import useSynchronizedInboxNotFoundErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/useSynchronizedInboxNotFoundError.handler';
import { SynchronizedInboxMutationPropsInterface } from './SynchronizedInboxMutationProps.interface';
import { updateSynchronizedInboxesAutoSyncMutationKey } from '../../../mutationKeys';
import { useAlert } from '../../../../../alert/Alert.provider';
import { EmailProviderEnum } from '../../../../../../enums/upload-hub/settings/EmailProvider.enum';
import useNylasAccountNotFoundErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/nylas/useNylasAccountNotFoundError.handler';
import useNylasAccountNotAuthorizedErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/nylas/useNylasAccountNotAuthorizedError.handler';

interface UpdateSynchronizedInboxesAutoSyncPayloadInterface {
  id: string;
  autoSync: boolean;
  email: string;
}

const useUpdateSynchronizedInboxAutoSyncMutation = (
  { setOtherError, provider }: SynchronizedInboxMutationPropsInterface = {
    provider: EmailProviderEnum.SMTP,
  },
) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request: smtpUpdateAutoSync } = useRequest(ApiOperation.UpdateSynchronizedInboxAutoSync);
  const { request: nylasUpdateAutoSync } = useRequest(ApiOperation.NylasSetAccountAutoSync);
  const request = provider === EmailProviderEnum.SMTP ? smtpUpdateAutoSync : nylasUpdateAutoSync;

  const notFoundSmtpErrorHandler = useSynchronizedInboxNotFoundErrorHandler(setOtherError);
  const notFoundNylasErrorHandler = useNylasAccountNotFoundErrorHandler(setOtherError);
  const notAuthorizedNylasErrorHandler = useNylasAccountNotAuthorizedErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError(
    [notFoundSmtpErrorHandler, notFoundNylasErrorHandler, notAuthorizedNylasErrorHandler],
    generalErrorHandler,
  );

  return useMutation(
    async ({ id, autoSync }: UpdateSynchronizedInboxesAutoSyncPayloadInterface) => {
      let urlParams = {};

      if (provider === EmailProviderEnum.SMTP) {
        urlParams = { id };
      }

      const res = await request({ data: { autoSync, accountUniqueNumber: id }, urlParams });

      return res.data;
    },
    {
      mutationKey: updateSynchronizedInboxesAutoSyncMutationKey,
      onSuccess: (_, { autoSync, email }: UpdateSynchronizedInboxesAutoSyncPayloadInterface) => {
        queryClient.invalidateQueries(getSynchronizedInboxesQueryKey);

        showAlert(
          <Trans
            i18nKey="pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.actions.autoSync.success"
            values={{ email, status: autoSync ? t('global.enabled') : t('global.disabled') }}
            components={{ bold: <strong /> }}
          />,
          autoSync ? 'success' : 'info',
        );
      },
      onError,
    },
  );
};

export default useUpdateSynchronizedInboxAutoSyncMutation;
