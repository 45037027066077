import { Grid } from '@mui/material';
import React, { FC } from 'react';
import classnames from 'classnames';
import LoadingComponent from './Loading.component';
import classes from './LoadingGridContainer.module.scss';
import { PropsInterface } from '../../../interfaces/Props.interface';

const LoadingGridContainerComponent: FC<PropsInterface> = ({ className }) => {
  return (
    <Grid item xs={6} className={classnames(classes['loading-grid-container'], className)}>
      <LoadingComponent variant="relative" size="sm" />
    </Grid>
  );
};

export default LoadingGridContainerComponent;
