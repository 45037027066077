import React, { FC, ReactElement } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import classnames from 'classnames';
import { EditFieldPropsInterface } from './interfaces/EditFieldProps.interface';
import classes from './EditField.module.scss';

const EditFieldComponent: FC<EditFieldPropsInterface> = ({
  value,
  onEdit,
  disabled = false,
  'data-cy': dataCy = '',
}): ReactElement => {
  const onClickHandler = () => {
    if (onEdit && !disabled) {
      onEdit();
    }
  };

  return (
    <div
      role="button"
      className={classnames(classes['edit-field'], disabled ? classes['edit-field--disabled'] : '')}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      tabIndex={0}
      data-cy={dataCy}
      aria-disabled={disabled}
    >
      <span className={classes['edit-field__text']}>{value}</span>
      {!disabled && <EditOutlinedIcon />}
    </div>
  );
};

export default EditFieldComponent;
