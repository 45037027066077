import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Snackbar, SnackbarCloseReason } from '@mui/material';
import { SnackbarOrigin } from '@mui/material/Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';
import { PropsInterface } from '../../interfaces/Props.interface';
import { Alert } from '../../views/components/material';
import { AlertVariant, SnackbarAlertInterface } from './interfaces/SnackbarAlert.interface';
import { AlertContextTypeInterface } from './interfaces/AlertContextType.interface';

const preemptionTime = 1000;
const autoHideDuration = 4500;

const AlertContext = createContext<AlertContextTypeInterface>({} as AlertContextTypeInterface);

export const AlertProvider: FC<PropsWithChildren<PropsInterface>> = ({ children }) => {
  const { t } = useTranslation();
  const [snackbarAlertsStack, setSnackbarAlertsStack] = useState<readonly SnackbarAlertInterface[]>(
    [],
  );
  const [open, setOpen] = useState(false);
  const [activeSnackbarAlert, setActiveSnackbarAlert] = useState<
    SnackbarAlertInterface | undefined
  >(undefined);
  const [areIncomingAlertsBlocked, setAreIncomingAlertsBlocked] = useState<boolean>(false);
  const [preemptionTimer, setPreemptionTimer] = useState<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  );

  useEffect(() => {
    if (areIncomingAlertsBlocked && !preemptionTimer) {
      setPreemptionTimer(
        setTimeout(() => {
          setPreemptionTimer(undefined);
          setAreIncomingAlertsBlocked(false);
        }, preemptionTime),
      );
    }

    return () => {
      if (preemptionTimer !== undefined) {
        clearTimeout(preemptionTimer);
        setPreemptionTimer(undefined);
      }
    };
  }, [preemptionTimer, areIncomingAlertsBlocked]);

  useEffect(() => {
    if (snackbarAlertsStack.length && !activeSnackbarAlert) {
      setActiveSnackbarAlert({ ...snackbarAlertsStack[0] });
      setSnackbarAlertsStack((prev: readonly SnackbarAlertInterface[]) => prev.slice(1));
      setOpen(true);
    } else if (snackbarAlertsStack.length && activeSnackbarAlert && open) {
      if (areIncomingAlertsBlocked) {
        setSnackbarAlertsStack([]);
      } else {
        setOpen(false);
      }
    }
  }, [snackbarAlertsStack, activeSnackbarAlert, open, areIncomingAlertsBlocked]);

  const showAlert = (
    message: string | ReactElement,
    variant: AlertVariant = 'info',
    origin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' },
    preemption: boolean = false,
    onClick: (() => void) | undefined = undefined,
  ): void => {
    if (preemption) {
      setAreIncomingAlertsBlocked(true);
    }

    setSnackbarAlertsStack((prev: readonly SnackbarAlertInterface[]) => [
      ...prev,
      {
        message,
        key: new Date().getTime(),
        variant,
        origin,
        preemption,
        onClick,
      },
    ]);
  };

  const onCloseHandler = (event: SyntheticEvent | Event, reason?: SnackbarCloseReason): void => {
    if (event) {
      event.stopPropagation();
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onExitedHandler = (): void => {
    setActiveSnackbarAlert(undefined);
  };

  const memoizedAlertState = useMemo(
    () => ({
      showAlert,
    }),
    [],
  );

  const onClickHandler = (): void => {
    if (activeSnackbarAlert && activeSnackbarAlert.onClick) {
      activeSnackbarAlert.onClick();
      setOpen(false);
    }
  };

  return (
    <AlertContext.Provider value={memoizedAlertState}>
      {children}

      <Snackbar
        key={activeSnackbarAlert?.key}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onCloseHandler}
        TransitionProps={{ onExited: onExitedHandler }}
        anchorOrigin={{
          vertical: activeSnackbarAlert ? activeSnackbarAlert.origin.vertical : 'bottom',
          horizontal: activeSnackbarAlert ? activeSnackbarAlert.origin.horizontal : 'right',
        }}
      >
        <Alert
          onClose={onCloseHandler}
          severity={activeSnackbarAlert?.variant || 'info'}
          variant="standard"
          sx={{ width: '100%' }}
          elevation={6}
          onClick={onClickHandler}
          clickable={activeSnackbarAlert?.onClick !== undefined}
        >
          {activeSnackbarAlert ? activeSnackbarAlert.message : t('alert.fallbackMessage')}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export function useAlert() {
  return useContext(AlertContext);
}
