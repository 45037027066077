import React from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../api';
import { queryClient } from '../../../../../queryClient';
import {
  getSynchronizedInboxesQueryKey,
  getUploadHubSettingsQueryKey,
} from '../../../../queries/queryKeys';
import useOnError from '../../../../error-handlers/useOnError';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import { SynchronizedInboxMutationWithValidationPropsInterface } from './SynchronizedInboxMutationProps.interface';
import SynchronizedInboxPayloadInterface from '../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInboxPayload.interface';
import useSynchronizedInboxUniqueEmailErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/useSynchronizedInboxUniqueEmailError.handler';
import useSynchronizedInboxCannotConnectErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/useSynchronizedInboxCannotConnectError.handler';
import useIncorrectEmailErrorHandler from '../../../../error-handlers/common/useIncorrectEmailError.handler';
import SynchronizedInboxesFormInterface from '../../../../../../views/plugin-settings/components/upload-hub/components/synchronized-inboxes/components/synchronized-inboxes-form/SynchronizedInboxesForm.interface';
import { useAlert } from '../../../../../alert/Alert.provider';

const useAddSynchronizedInboxMutation = (
  { setValidationError, setOtherError }: SynchronizedInboxMutationWithValidationPropsInterface = {
    setValidationError: () => {},
  },
) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.AddSynchronizedInbox);

  const uniqueEmailErrorHandler = useSynchronizedInboxUniqueEmailErrorHandler(setValidationError);
  const incorrectEmailErrorHandler =
    useIncorrectEmailErrorHandler<SynchronizedInboxesFormInterface>(
      setValidationError,
      t(
        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.email.errors.incorrect',
      ),
    );
  const cannotConnectToMailboxErrorHandler =
    useSynchronizedInboxCannotConnectErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError(
    [uniqueEmailErrorHandler, incorrectEmailErrorHandler, cannotConnectToMailboxErrorHandler],
    generalErrorHandler,
  );

  return useMutation(
    async (payload: SynchronizedInboxPayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return res.data;
    },
    {
      onSuccess: (_, { email }: SynchronizedInboxPayloadInterface) => {
        queryClient.invalidateQueries(getUploadHubSettingsQueryKey);
        queryClient.invalidateQueries(getSynchronizedInboxesQueryKey);

        showAlert(
          <Trans
            i18nKey="pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.addSynchronizedInboxesModal.success"
            values={{ email }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useAddSynchronizedInboxMutation;
