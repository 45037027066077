import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import useSubmitButtonsHook from '../../../../../../../../utils/hooks/useSubmitButtons.hook';
import classes from './ApprovalButtons.module.scss';
import { Button } from '../../../../../../../components/material';
import { RejectInvoiceVariant } from '../../../reject/hooks/useRejectInvoice.hook';
import { queryClient } from '../../../../../../../../services/queryClient';
import {
  rejectInvoiceMutationKey,
  rejectToAmendInvoiceMutationKey,
} from '../../../../../../../../services/react-query-hooks/mutations/mutationKeys';

export interface ApprovalButtonsInterface<P> {
  id: string;
  isLoading?: boolean;
  onClose?: () => void;
  onSubmit?: (payload?: P) => Promise<void>;
  onReject?: (variant: RejectInvoiceVariant) => void;
}

const useApprovalButtons = <P extends {}>({
  id,
  isLoading,
  onClose,
  onSubmit,
  onReject,
}: ApprovalButtonsInterface<P>) => {
  const { t } = useTranslation();

  const isRejectToAmendLoading =
    queryClient.isMutating({
      mutationKey: rejectToAmendInvoiceMutationKey,
      predicate: (m) => m.options.variables.invoiceId === id,
    }) > 0;

  const isRejectLoading =
    queryClient.isMutating({
      mutationKey: rejectInvoiceMutationKey,
      predicate: (m) => m.options.variables.invoiceId === id,
    }) > 0;

  const { buttons } = useSubmitButtonsHook({
    isLoading: !!isLoading,
    disabled: isRejectToAmendLoading || isRejectLoading,
    close: onClose || (() => {}),
    handleSubmit: onSubmit || (() => {}),
    submitText: t('invoices.tabs.toApprove.actions.approve.title'),
  });

  const onRejectHandler = useCallback(
    (variant: RejectInvoiceVariant): void => {
      if (onReject) {
        onReject(variant);
      }
    },
    [onReject],
  );

  const approvalButtons = useCallback(
    (payload?: P): ReactNode | void => {
      return (
        <Grid
          container
          columnSpacing={4}
          rowSpacing={2}
          className={classes['approval-buttons']}
          data-cy="approval-buttons"
        >
          <Grid
            item
            xs={4}
            className={classNames(
              classes['approval-buttons__button'],
              classes['approval-buttons__button--first'],
            )}
          >
            <Button
              variant="text"
              onClick={() => onRejectHandler('rejectToAmend')}
              disabled={isLoading || isRejectLoading}
              loading={isRejectToAmendLoading}
            >
              {t('invoices.tabs.toApprove.actions.rejectToAmend.title')}
            </Button>
          </Grid>

          <Grid item xs={4} className={classes['approval-buttons__button']}>
            {buttons(payload)}
          </Grid>

          <Grid
            item
            xs={4}
            className={classNames(
              classes['approval-buttons__button'],
              classes['approval-buttons__button--last'],
            )}
          >
            <Button
              variant="text"
              onClick={() => onRejectHandler('reject')}
              disabled={isLoading || isRejectToAmendLoading}
              loading={isRejectLoading}
            >
              {t('invoices.tabs.toApprove.actions.reject.title')}
            </Button>
          </Grid>
        </Grid>
      );
    },
    [buttons, isLoading, isRejectLoading, isRejectToAmendLoading, onRejectHandler, t],
  );

  return {
    approvalButtons,
  };
};

export default useApprovalButtons;
