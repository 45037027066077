import { useRequest } from '@datahub/api-client';
import { getRolesQueryKey } from '../queryKeys';
import { ApiOperation } from '../../../api';
import { RolesResponseType } from '../../../../types/roles/RolesResponse.type';
import { TableFiltersInterface } from '../../../../views/components/table/interfaces/TableFilters.interface';
import usePaginatedListQuery from '../../pagination/usePaginatedList.query';

const useGetRolesListQuery = (filters?: TableFiltersInterface) => {
  const { request } = useRequest<RolesResponseType>(ApiOperation.GetRoles);
  const { data, ...rest } = usePaginatedListQuery<RolesResponseType>(
    request,
    getRolesQueryKey,
    filters,
  );

  return {
    data: data?.responseModel,
    ...rest,
  };
};

export default useGetRolesListQuery;
