import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import useAddDataSourceMutation from '../../../../services/react-query-hooks/mutations/data-source/useAddDataSource.mutation';
import FormModalComponent from '../../../components/modal/form-modal/FormModal.component';
import DataSourceFormComponent from '../data-source-form/DataSourceForm.component';
import DataSourceFormInterface, {
  defaultValues,
} from '../data-source-form/DataSourceForm.interface';

interface AddDataSourcePropsInterface {
  close: () => void;
}

const AddDataSourceView: FC<AddDataSourcePropsInterface> = ({ close }) => {
  const methods = useForm<DataSourceFormInterface>({
    mode: 'onChange',
    defaultValues,
  });
  const {
    mutate: addDataSource,
    isLoading,
    isSuccess,
  } = useAddDataSourceMutation({
    setValidationError: methods.setError,
  });

  const onSubmitHandler = (data: DataSourceFormInterface) =>
    addDataSource({
      ...data,
      type: Number(data.type),
      origin: Number(data.origin),
    });

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmitHandler)}
      isSuccess={isSuccess}
      isLoading={isLoading}
    >
      <DataSourceFormComponent mode="add" />
    </FormModalComponent>
  );
};

export default AddDataSourceView;
