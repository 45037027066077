import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../../../../../components/modal/types/Props.interface';
import AddSynchronizedInbox from './AddSynchronizedInbox.view';

interface AddRoleModalComponentPropsInterface extends ModalControlPropsInterface {}

const AddSynchronizedInboxModalComponent = ({
  open,
  setOpen,
}: AddRoleModalComponentPropsInterface) => {
  const { t } = useTranslation();

  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t(
        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.addSynchronizedInboxesModal.title',
      )}
      open={open}
      handleClose={handleClose}
      content={<AddSynchronizedInbox close={handleClose} />}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
      actions
    />
  );
};

export default AddSynchronizedInboxModalComponent;
