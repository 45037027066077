export type LocaleType = 'en-GB';

export const Locales = ['en-GB'] as const;

export type LocalesType = (typeof Locales)[number];

export const LocaleDateFormatsMap: { [key in LocalesType]: string[] } = {
  'en-GB': [
    'dd/MM/yy',
    'dd/MM/yyyy',
    'dd MMM yy',
    'dd MMM yyyy',
    'dd MMMM yy',
    'dd MMMM yyyy',
    'do MMMM yy',
    'do MMMM yyyy',
    'do MMM yy',
    'do MMM yyyy',
    'dd MMM yy',
    'dd MMM yyyy',
    'dd.MM.yy',
    'dd.MM.yyyy',
    'dd-MM-yy',
    'dd-MM-yyyy',
    'MMMM yy',
    'MMMM yyyy',
    'MMM dd, yy',
    'MMM dd, yyyy',
  ],
};
