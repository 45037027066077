import React, { FC, memo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import classes from './SettingsSection.module.scss';
import { SettingsSectionPropsInterface } from './interfaces/SettingsSectionProps.interface';
import LoadingComponent from '../../../../../components/loading/Loading.component';
import SettingsSectionHeader from './components/header/SettingsSectionHeader.component';

const SettingsSectionComponent: FC<PropsWithChildren<SettingsSectionPropsInterface>> = ({
  children,
  title,
  subsection,
  className,
  isLoading,
  required,
}) => {
  return (
    <div
      className={classNames(
        classes['settings-section'],
        subsection ? '' : classes['settings-section--main'],
        className,
      )}
    >
      {title && <SettingsSectionHeader title={title} subsection={subsection} required={required} />}
      {isLoading && (
        <div className={classes['settings-section__loading']}>
          <LoadingComponent size="sm" variant="relative" />
        </div>
      )}
      {!isLoading && children}
    </div>
  );
};

const SettingsSection = memo(SettingsSectionComponent);

export default SettingsSection;
