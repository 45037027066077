import { useTranslation } from 'react-i18next';
import CommonSetErrorType from '../../../CommonSetError.type';
import { useCommonErrorHandler } from '../../../useErrorHandler';

const useSynchronizedInboxNotFoundErrorHandler = (setError?: CommonSetErrorType) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'UPLOAD_HUB_EMAIL_SETTING_NOT_FOUND',
    errorMessage: t(
      'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.errors.notFound',
    ),
  });

  return handler;
};

export default useSynchronizedInboxNotFoundErrorHandler;
