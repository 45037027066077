import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../../../../../components/modal/types/Props.interface';
import DeleteSynchronizedInbox from './DeleteSynchronizedInbox.view';
import {
  NylasSynchronizedInboxInterface,
  SmtpSynchronizedInboxInterface,
} from '../../../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';
import { EmailProviderEnum } from '../../../../../../../../enums/upload-hub/settings/EmailProvider.enum';

interface DeleteSynchronizedInboxModalComponentPropsInterface extends ModalControlPropsInterface {
  syncInbox: SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface | undefined;
  provider: EmailProviderEnum;
}

const DeleteSynchronizedInboxModalComponent = ({
  syncInbox,
  provider,
  open,
  setOpen,
}: DeleteSynchronizedInboxModalComponentPropsInterface) => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t(
        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.deleteSynchronizedInboxesModal.title',
      )}
      open={open}
      handleClose={handleClose}
      variant="warning"
      content={
        syncInbox && (
          <DeleteSynchronizedInbox syncInbox={syncInbox} provider={provider} close={handleClose} />
        )
      }
    />
  );
};

export default DeleteSynchronizedInboxModalComponent;
