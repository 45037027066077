import React, { FC, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import UserFormInterface from './UserForm.interface';
import FormContainerComponent from '../../../../../../../../../components/form-container/FormContainer.component';
import classes from './UserForm.module.scss';
import { FormControl, Input, MenuItem } from '../../../../../../../../../components/material';
import EmailInputComponent from '../../../../../../../../../landing-page/components/form/EmailInput.component';
import { useEmailValidator } from '../../../../../../../../../landing-page/components/form/validators/Email.validator';
import { RoleInterface } from '../../../../../../../../../../types/roles/Role.interface';
import Select from '../../../../../../../../../components/material/select/Select.component';

interface UserFormComponentPropsInterface {
  roles: RoleInterface[] | undefined;
}

const UserFormComponent: FC<UserFormComponentPropsInterface> = ({ roles }): ReactElement => {
  const { t } = useTranslation();
  const { options } = useEmailValidator();
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<UserFormInterface>();

  // TODO: check performance, maybe we should move controllers to callbacks
  return (
    <FormContainerComponent className={classes['user-form']} fullWidth>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container direction="column" rowSpacing={4}>
            <Grid item>
              <FormControl error={!!errors.fullName}>
                <Input
                  id="name"
                  fullWidth
                  label={t('settings.sections.permission.subsections.user.controls.fullName.label')}
                  {...register('fullName', {
                    required: t(
                      'settings.sections.permission.subsections.user.controls.fullName.errors.required',
                    ),
                  })}
                  required
                />
                <span role="alert">{errors.fullName?.message}</span>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl error={!!errors.email}>
                <EmailInputComponent
                  error={!!errors.email}
                  {...register('email', options)}
                  placeholder={t(
                    'settings.sections.permission.subsections.user.controls.email.label',
                  )}
                  label={t('settings.sections.permission.subsections.user.controls.email.label')}
                  required
                />
                <span role="alert">{errors.email?.message}</span>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <Controller
                  name="roles"
                  control={control}
                  defaultValue={[]}
                  render={(renderProps) => (
                    <Select
                      fullWidth
                      multiple
                      label={t(
                        'settings.sections.permission.subsections.user.controls.roles.label',
                      )}
                      {...renderProps.field}
                    >
                      {roles?.map((role: RoleInterface) => (
                        <MenuItem value={role.roleNumber} key={role.roleNumber}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormContainerComponent>
  );
};

export default UserFormComponent;
