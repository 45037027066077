import React, { FC, PropsWithChildren, ReactElement } from 'react';
import classnames from 'classnames';
import { PropsInterface } from '../../../interfaces/Props.interface';
import classes from './FormContainer.module.scss';

interface FormContainerPropsInterface extends PropsInterface {
  fullWidth?: boolean;
}

const FormContainerComponent: FC<PropsWithChildren<FormContainerPropsInterface>> = ({
  children,
  className,
  fullWidth = false,
}): ReactElement => {
  return (
    <div
      className={classnames(
        classes['form-container'],
        [fullWidth ? classes['form-container--full-width'] : ''],
        className,
      )}
      data-cy="form-container"
    >
      {children}
    </div>
  );
};

export default FormContainerComponent;
