import { FC, PropsWithChildren, ReactPortal, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { PortalPropsInterface } from './interfaces/PortalProps.interface';

const PortalComponent: FC<PropsWithChildren<PortalPropsInterface>> = ({
  id,
  children,
  anchorEl,
}): ReactPortal | null => {
  const el = useRef(document.getElementById(id) || document.createElement('div'));
  const [hasParentEl] = useState(!!el.current.parentElement);

  useEffect(() => {
    const currentEl = el.current;

    if (!hasParentEl) {
      currentEl.id = id;

      if (anchorEl) {
        anchorEl.appendChild(el.current);
      } else {
        document.body.appendChild(el.current);
      }
    }

    return () => {
      if (!hasParentEl && currentEl.parentElement) {
        currentEl.parentElement.removeChild(currentEl);
      }
    };
  }, [anchorEl, hasParentEl, id]);

  return el ? createPortal(children, el.current) : null;
};

export default PortalComponent;
