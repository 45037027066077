import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { InvoiceMatchingMutationPropsInterface } from './InvoiceMatchingMutationProps.interface';
import { ApiOperation } from '../../../../api';
import useGeneralErrorHandler from '../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../error-handlers/useOnError';
import { queryClient } from '../../../../queryClient';
import {
  getInvoiceMatchingQueryKey,
  getInvoiceQueryKey,
  getToVerifyInvoicesQueryKey,
} from '../../../queries/queryKeys';
import { InvoiceMatchingPayloadInterface } from '../../../../../types/upload-hub/invoice/matching/InvoiceMatchingPayload.interface';
import { useAlert } from '../../../../alert/Alert.provider';

const useUpdateInvoiceMatchingMutation = ({
  setOtherError,
}: InvoiceMatchingMutationPropsInterface) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateInvoiceMatching);

  // TODO: other specific exceptions if needed
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async (payload: InvoiceMatchingPayloadInterface) => {
      const res = await request({ data: { ...payload }, urlParams: { id: payload.invoiceId } });

      return res.data;
    },
    {
      onSuccess: (_, { invoiceId, fileName: name }: InvoiceMatchingPayloadInterface) => {
        queryClient.invalidateQueries(getToVerifyInvoicesQueryKey);
        queryClient.invalidateQueries([getInvoiceMatchingQueryKey, invoiceId]);
        queryClient.invalidateQueries([getInvoiceQueryKey, invoiceId]);

        showAlert(
          <Trans
            i18nKey="invoices.tabs.toVerify.verificationModal.steps.matching.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useUpdateInvoiceMatchingMutation;
