import React from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../api';
import { UpdateAutoSendInvoiceToIntegrationServicePayloadInterface } from './interfaces/UpdateAutoSendInvoiceToIntegrationServicePayload.interface';
import { UpdateUploadHubSettingsMutationPropsInterface } from './interfaces/UpdateUploadHubSettingsMutationProps.interface';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { queryClient } from '../../../../../queryClient';
import { getUploadHubSettingsQueryKey } from '../../../../queries/queryKeys';
import useDebouncedMutation from '../../../useDebounced.mutation';
import { useAlert } from '../../../../../alert/Alert.provider';

const useUpdateAutoSendInvoiceToIntegrationServiceMutation = ({
  setOtherError,
}: UpdateUploadHubSettingsMutationPropsInterface = {}) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateAutoSendInvoiceToIntegrationService);

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const { onError } = useOnError([], generalErrorHandler);

  const { mutate, ...rest } = useMutation(
    async (payload: UpdateAutoSendInvoiceToIntegrationServicePayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return {
        data: res.data,
        payload,
      };
    },
    {
      onSuccess: (
        _,
        {
          automaticallySendInvoiceToIntegrationService,
        }: UpdateAutoSendInvoiceToIntegrationServicePayloadInterface,
      ) => {
        queryClient.invalidateQueries(getUploadHubSettingsQueryKey);

        showAlert(
          <Trans
            i18nKey="pluginSettings.sections.uploadHub.subsections.general.controls.autoSendInvoicesToIntegrationService.success"
            values={{
              status: automaticallySendInvoiceToIntegrationService
                ? t('global.enabled')
                : t('global.disabled'),
            }}
            components={{ bold: <strong /> }}
          />,
          automaticallySendInvoiceToIntegrationService ? 'success' : 'info',
        );
      },
      onError,
    },
  );

  const { mutateDebounced } =
    useDebouncedMutation<UpdateAutoSendInvoiceToIntegrationServicePayloadInterface>(mutate);

  return {
    mutate,
    mutateDebounced,
    ...rest,
  };
};

export default useUpdateAutoSendInvoiceToIntegrationServiceMutation;
