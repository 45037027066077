import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../../../../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../../../../../../components/modal/types/Props.interface';
import AddRole from './AddRole.view';

interface AddRoleModalComponentPropsInterface extends ModalControlPropsInterface {}

const AddRoleModalComponent = ({ open, setOpen }: AddRoleModalComponentPropsInterface) => {
  const { t } = useTranslation();

  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t('settings.sections.permission.subsections.role.addModal.title')}
      open={open}
      handleClose={handleClose}
      content={<AddRole close={handleClose} />}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xs',
      }}
      actions
    />
  );
};

export default AddRoleModalComponent;
