import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { getPermissionsQueryKey } from '../queryKeys';
import { ApiOperation } from '../../../api';
import { PermissionsResponseType } from '../../../../types/permission/PermissionsResponse.type';

const useGetPermissionsListQuery = () => {
  const { request } = useRequest<PermissionsResponseType>(ApiOperation.GetPermissions);

  return useQuery(getPermissionsQueryKey, async () => {
    const res = await request();

    return res.data.responseModel;
  });
};

export default useGetPermissionsListQuery;
