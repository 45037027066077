import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { getRolesQueryKey } from '../../queries/queryKeys';
import { ApiOperation } from '../../../api';
import { queryClient } from '../../../queryClient';
import useOnError from '../../error-handlers/useOnError';
import { RoleMutationPropsInterface } from './RoleMutationProps.interface';
import RolePayloadInterface from '../../../../types/roles/RolePayload.interface';
import useRoleNotFoundErrorHandler from '../../error-handlers/role/useRoleNotFoundError.handler';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useDebouncedMutation from '../useDebounced.mutation';
import { updateRoleMutationKey } from '../mutationKeys';
import { useAlert } from '../../../alert/Alert.provider';
import { PermissionInterface } from '../../../../types/permission/Permission.interface';

interface UpdateRolePayloadInterface {
  id: string;
  payload: RolePayloadInterface;
  permission: PermissionInterface;
  action: 'add' | 'remove';
}

const useUpdateRoleMutation = ({ setOtherError }: RoleMutationPropsInterface = {}) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateRole);

  const notFoundErrorHandler = useRoleNotFoundErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([notFoundErrorHandler], generalErrorHandler);

  const { mutate, ...rest } = useMutation(
    async ({ id, payload }: UpdateRolePayloadInterface) => {
      const res = await request({ data: { ...payload }, urlParams: { id } });

      return { data: res.data, id, payload };
    },
    {
      mutationKey: updateRoleMutationKey,
      onSuccess: (
        _,
        {
          payload: { roleName: name },
          permission: { permissionName },
          action,
        }: UpdateRolePayloadInterface,
      ) => {
        queryClient.invalidateQueries(getRolesQueryKey);

        showAlert(
          <Trans
            i18nKey={`settings.sections.permission.subsections.role.actions.update.success.${
              action === 'add' ? 'add' : 'delete'
            }`}
            values={{ name, permissionName }}
            components={{ bold: <strong /> }}
          />,
          action === 'add' ? 'success' : 'info',
        );
      },
      onError,
    },
  );

  const { mutateDebounced } = useDebouncedMutation<UpdateRolePayloadInterface>(mutate);

  return {
    mutate,
    mutateDebounced,
    ...rest,
  };
};

export default useUpdateRoleMutation;
