import React, { FC } from 'react';
import { Tooltip as MatTooltip } from '@mui/material';
import './Tooltip.component.scss';
import classNames from 'classnames';
import { TooltipPropsInterface } from './interfaces/TooltipProps.interface';

const Tooltip: FC<TooltipPropsInterface> = ({
  children,
  variant = 'primary',
  size = 'md',
  ...props
}) => {
  return (
    <MatTooltip
      {...props}
      classes={{
        tooltip: classNames(`MuiTooltip-tooltip--${variant}`, `MuiTooltip-tooltip--${size}`),
      }}
    >
      {children}
    </MatTooltip>
  );
};

export { Tooltip };
