import { useRequest } from '@datahub/api-client';
import { useQuery } from 'react-query';
import { OcrProcessedPagesResponseType } from '../../../../../types/upload-hub/processed-pages/OcrProcessedPagesResponse.type';
import { ApiOperation } from '../../../../api';
import { getOcrProcessedPages } from '../../queryKeys';

const useGetOcrProcessedPagesQuery = () => {
  const { request } = useRequest<OcrProcessedPagesResponseType>(ApiOperation.GetOcrProcessedPages);

  return useQuery(getOcrProcessedPages, async () => {
    const res = await request();

    return res.data.responseModel;
  });
};

export default useGetOcrProcessedPagesQuery;
