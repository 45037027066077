import { useMutation } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { MarkNotificationAsReadMutationPropsInterface } from './interfaces/MarkNotificationAsReadMutationProps.interface';
import { ApiOperation } from '../../../api';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../error-handlers/useOnError';
import { queryClient } from '../../../queryClient';
import { getNotificationsQueryKey } from '../../queries/queryKeys';
import { UpdateNotificationInterface } from '../../../../types/notification/UpdateNotification.interface';
import { useNotification } from '../../../notification/Notification.provider';

const useMarkNotificationAsReadMutation = ({
  setOtherError,
  disableSuccessCallback,
}: MarkNotificationAsReadMutationPropsInterface) => {
  const { request } = useRequest(ApiOperation.MarkNotificationAsRead);
  const { markNewNotificationAsRead, pagination } = useNotification();

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async (payload: UpdateNotificationInterface) => {
      const res = await request({ data: { ...payload } });

      return res.data;
    },
    {
      onSuccess: (_, payload: UpdateNotificationInterface) => {
        queryClient.invalidateQueries([
          getNotificationsQueryKey,
          {
            PageNumber: pagination?.CurrentPage,
          },
        ]);

        if (!disableSuccessCallback) {
          markNewNotificationAsRead(payload.notificationUniqueNumber);
        }
      },
      onError,
    },
  );
};

export default useMarkNotificationAsReadMutation;
