import React, { FC, ReactElement, useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TimelineIcon from '@mui/icons-material/Timeline';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import classNames from 'classnames';
import TableComponent from '../../../../../components/table/Table.component';
import { TableColumnInterface } from '../../../../../components/table/interfaces/TableColumn.interface';
import { AlignEnum } from '../../../../../../enums/Align.enum';
import { TableColumnTypeEnum } from '../../../../../components/table/enums/TableColumnType.enum';
import useTableFilters, {
  TableFilterAction,
} from '../../../../../components/table/hooks/TableFilters.hook';
import useDate from '../../../../../../utils/hooks/date-format/useDate';
import { TableRowInterface } from '../../../../../components/table/interfaces/TableRow.interface';
import { StatusEnum } from '../../../../../../enums/Status.enum';
import { ProcessedInvoiceInterface } from '../../../../../../types/upload-hub/invoice/processed/ProcessedInvoice.interface';
import { useGetProcessedInvoicesListQuery } from '../../../../../../services/react-query-hooks/queries/upload-hub/invoice/processed/useGetProcessedInvoicesList.query';
import { TableActionInterface } from '../../../../../components/table/interfaces/TableProps.interface';
import { TableActionNameEnum } from '../../../../../components/table/enums/TableActionName.enum';
import { ProcessedInvoiceActionNameEnum } from './enums/ProcessedInvoiceActionName.enum';
import { useInvoicesContext } from '../../Invoices.component';
import InvoiceDetailsComponent from './components/details/InvoiceDetails.component';
import { InvoiceStatusMap } from '../../../../../../types/upload-hub/invoice/InvoiceStatus.type';
import { InvoiceStatusEnum } from '../../../../../../enums/upload-hub/InvoiceStatus.enum';
import { getProcessedInvoicesQueryKey } from '../../../../../../services/react-query-hooks/queries/queryKeys';
import useFile from '../../../../../../utils/hooks/file/useFile.hook';
import useSendInvoiceToIntegrationServiceMutation from '../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/useSendInvoiceToIntegrationService.mutation';
import { Button, Chip, Tooltip } from '../../../../../components/material';
import useDeleteInvoice from '../delete/hooks/useDeleteInvoice.hook';
import useInvoicesReport from './components/report/hooks/useInvoicesReport.hook';
import classes from './ProcessedInvoices.module.scss';
import { queryClient } from '../../../../../../services/queryClient';
import {
  backInvoiceToReviewMutationKey,
  sendToIntegrationServiceMutationKey,
} from '../../../../../../services/react-query-hooks/mutations/mutationKeys';
import { useUploadHub } from '../../providers/UploadHub.provider';
import useBackInvoiceToReviewMutation from '../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/useBackInvoiceToReview.mutation';

const ProcessedInvoicesComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { format } = useDate();
  const { download } = useFile();
  const { isProcessingEnabled } = useUploadHub();
  const { filters, dispatchFilters } = useTableFilters({
    ShowRejectedItems: 'false',
  });
  const { data, pagination, isLoading } = useGetProcessedInvoicesListQuery(filters);
  const getInvoice = (id: string): ProcessedInvoiceInterface | undefined =>
    data?.find((item: ProcessedInvoiceInterface) => item.invoiceId === id);
  const [, setHistoryInvoiceData, setIsHistoryModalOpen] = useInvoicesContext();

  const {
    deleteModal,
    setDeleteInvoiceOpen,
    setDeleteInvoiceData,
    setBulkDeleteInvoiceData,
    setBulkDeleteInvoiceOpen,
    deleteMutationPromise,
  } = useDeleteInvoice(getProcessedInvoicesQueryKey);

  const { invoicesReportModal, setIsInvoicesReportModalOpen } = useInvoicesReport();

  const { mutate: sendInvoiceToIntegrationService } = useSendInvoiceToIntegrationServiceMutation();
  const { mutate: backInvoiceToReview } = useBackInvoiceToReviewMutation();

  const processedInvoicesColumns: TableColumnInterface[] = [
    {
      name: 'invoiceStatus',
      label: t('invoices.tabs.processed.columns.status'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      width: '10rem',
    },
    {
      name: 'invoiceNote',
      label: t('invoices.tabs.processed.columns.note'),
      align: AlignEnum.CENTER,
      type: TableColumnTypeEnum.DEFAULT,
      width: '5rem',
    },
    {
      name: 'supplierName',
      label: t('invoices.tabs.processed.columns.supplier'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '15rem',
    },
    {
      name: 'invoiceReference',
      label: t('invoices.tabs.processed.columns.invoiceReference'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '13rem',
    },
    {
      name: 'invoiceDate',
      label: t('invoices.tabs.processed.columns.invoiceDate'),
      align: AlignEnum.CENTER,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '12rem',
    },
    {
      name: 'totalAmountValue',
      label: t('invoices.tabs.processed.columns.amountDue'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '10rem',
    },
    {
      name: 'propertyName',
      label: t('invoices.tabs.processed.columns.block'),
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '15rem',
    },
    {
      name: 'actions',
      label: t('invoices.tabs.processed.columns.action'),
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.ACTIONS,
      width: '4rem',
    },
  ];

  const renderInvoiceStatus = useCallback(
    (invoice: ProcessedInvoiceInterface) => {
      if (invoice.isRejected) {
        return <Chip label={t('invoices.statuses.rejected')} color="black" variant="filled" />;
      }

      const statusKey = InvoiceStatusMap[invoice.status];
      // TODO: resolve typing enum map issue
      // @ts-ignore
      const statusLabel = String(t(`invoices.statuses.${statusKey}`));

      switch (invoice.status) {
        case InvoiceStatusEnum.TO_BE_SEND_TO_RESIDENT:
          return <Chip label={statusLabel} color="secondary" />;
        case InvoiceStatusEnum.SENT_TO_RESIDENT:
          return <Chip label={statusLabel} color="secondary" variant="outlined" />;

        case InvoiceStatusEnum.NOT_SEND_TO_RESIDENT:
          return <Chip label={statusLabel} color="error" />;

        default:
          return <Chip label={statusLabel} color="transparent" variant="outlined" />;
      }
    },
    [t],
  );

  const onDeleteModalOpenHandler = (invoice: ProcessedInvoiceInterface) => {
    setDeleteInvoiceData(invoice);
    setDeleteInvoiceOpen(true);
  };

  const onBulkDeleteModalOpenHandler = (invoiceIds: string[]) => {
    setBulkDeleteInvoiceData(invoiceIds);
    setBulkDeleteInvoiceOpen(true);
  };

  const isInvoiceLoading = (invoice: ProcessedInvoiceInterface): boolean =>
    queryClient.isMutating({
      predicate: (m) =>
        [sendToIntegrationServiceMutationKey, backInvoiceToReviewMutationKey].includes(
          String(m.options.mutationKey),
        ) && m.options.variables.invoiceId === invoice.invoiceId,
    }) > 0;

  const actions: TableActionInterface<ProcessedInvoiceActionNameEnum>[] = [
    {
      name: ProcessedInvoiceActionNameEnum.SEND,
      icon: <FileUploadOutlinedIcon />,
      label: t('invoices.tabs.processed.actions.send.title'),
      active: (row: TableRowInterface) =>
        row.initialStatus === InvoiceStatusEnum.TO_BE_SEND_TO_RESIDENT && isProcessingEnabled,
    },
    {
      name: ProcessedInvoiceActionNameEnum.RESEND,
      icon: <FileUploadOutlinedIcon />,
      label: t('invoices.tabs.processed.actions.resend.title'),
      active: (row: TableRowInterface) =>
        row.initialStatus === InvoiceStatusEnum.NOT_SEND_TO_RESIDENT && isProcessingEnabled,
    },
    {
      name: ProcessedInvoiceActionNameEnum.BACK_TO_REVIEW,
      icon: <UndoOutlinedIcon />,
      label: t('invoices.tabs.processed.actions.backToReview.title'),
      active: (row: TableRowInterface) => !!row.isRejected && isProcessingEnabled,
    },
    {
      name: TableActionNameEnum.DOWNLOAD,
      icon: <FileDownloadOutlinedIcon />,
      label: t('global.download'),
    },
    {
      name: ProcessedInvoiceActionNameEnum.HISTORY,
      icon: <TimelineIcon />,
      label: t('invoices.tabs.processed.actions.history.title'),
    },
    {
      name: TableActionNameEnum.DELETE,
      icon: <ClearOutlinedIcon />,
      label: t('global.delete'),
      multiselect: true,
      active: isProcessingEnabled,
    },
  ];

  const onActionSelectedHandler = (
    actionName: ProcessedInvoiceActionNameEnum | TableActionNameEnum,
    rowId: string[] | number[] | string | number,
  ) => {
    const invoice = getInvoice(String(rowId));
    const isMultiple = Array.isArray(rowId) && !invoice;
    const isSingle = invoice && !isMultiple;

    switch (actionName) {
      case ProcessedInvoiceActionNameEnum.SEND: {
        if (isSingle) {
          sendInvoiceToIntegrationService(invoice);
        }

        break;
      }

      case ProcessedInvoiceActionNameEnum.RESEND: {
        if (isSingle) {
          sendInvoiceToIntegrationService(invoice);
        }

        break;
      }

      case ProcessedInvoiceActionNameEnum.BACK_TO_REVIEW: {
        if (isSingle) {
          backInvoiceToReview(invoice);
        }

        break;
      }

      case ProcessedInvoiceActionNameEnum.HISTORY: {
        if (isSingle) {
          setHistoryInvoiceData({ id: invoice.invoiceId });
          setIsHistoryModalOpen(true);
        }

        break;
      }

      case TableActionNameEnum.DOWNLOAD: {
        if (isSingle) {
          download(invoice.fileLocalization);
        }
        break;
      }

      case TableActionNameEnum.DELETE: {
        if (isSingle) {
          onDeleteModalOpenHandler(invoice);
        }
        if (isMultiple) {
          onBulkDeleteModalOpenHandler(rowId as string[]);
          setBulkDeleteInvoiceOpen(true);
        }

        break;
      }

      default:
    }
  };

  const mapProcessedInvoiceToRow = (invoice: ProcessedInvoiceInterface): TableRowInterface => {
    return {
      ...invoice,
      id: invoice.invoiceId,
      status: StatusEnum.NULL,
      invoiceNote:
        invoice.isRejected && invoice.note ? (
          <Tooltip title={invoice.note}>
            <StickyNote2OutlinedIcon />
          </Tooltip>
        ) : (
          ''
        ),
      initialStatus: invoice.status,
      invoiceStatus: renderInvoiceStatus(invoice),
      invoiceDate: invoice.invoiceDate ? format(invoice.invoiceDate) : '',
      propertyName: invoice.propertyName,
      expandedElement: (
        <InvoiceDetailsComponent
          id={invoice.invoiceId}
          onDelete={() => onDeleteModalOpenHandler(invoice)}
          disabled={isInvoiceLoading(invoice)}
          actions={actions}
          row={{
            id: invoice.invoiceId,
            initialStatus: invoice.status,
            isRejected: invoice.isRejected,
            note: invoice.note,
            noteDate: invoice.noteDate,
          }}
          onActionSelected={onActionSelectedHandler}
        />
      ),
      totalAmountValue: invoice.amountTotal,
      loading: isInvoiceLoading(invoice),
    };
  };

  const isShowRejected = useMemo(
    (): boolean => filters.ShowRejectedItems !== undefined && filters.ShowRejectedItems === 'true',
    [filters],
  );

  const onShowRejectedHandler = useCallback(() => {
    dispatchFilters({
      type: TableFilterAction.UPDATE_FILTERS,
      value: {
        ...filters,
        ShowRejectedItems: isShowRejected ? 'false' : 'true',
      },
    });
  }, [dispatchFilters, filters, isShowRejected]);

  const toolbarContent = useMemo(() => {
    const rejectedToggleLabel = isShowRejected
      ? t('invoices.tabs.processed.actions.hideRejected.title')
      : t('invoices.tabs.processed.actions.showRejected.title');

    return (
      <>
        <Button
          variant="outlined"
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={() => setIsInvoicesReportModalOpen(true)}
          className={classes.toolbar__button}
        >
          {t('invoices.tabs.processed.actions.report.title')}
        </Button>

        <Button
          variant="outlined"
          startIcon={<DeleteOutlineIcon />}
          onClick={onShowRejectedHandler}
          className={classNames(classes.toolbar__button, classes['toolbar__button--show-rejected'])}
        >
          <span>{rejectedToggleLabel}</span>
        </Button>
      </>
    );
  }, [isShowRejected, onShowRejectedHandler, setIsInvoicesReportModalOpen, t]);
  return (
    <Grid className={classes['processed-invoices']}>
      <TableComponent
        table={{
          rows: data ? data.map(mapProcessedInvoiceToRow) : [],
          columns: processedInvoicesColumns,
        }}
        emptyLabel={t('invoices.tabs.processed.emptyTable')}
        isLoading={isLoading}
        multiselect
        actions={actions}
        onActionSelected={onActionSelectedHandler}
        onActionCompleted={deleteMutationPromise}
        filters={filters}
        onChangeFilters={dispatchFilters}
        toolbar={toolbarContent}
        pagination={pagination}
        expandable
      />

      {deleteModal}
      {invoicesReportModal}
    </Grid>
  );
};

export default ProcessedInvoicesComponent;
