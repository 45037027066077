import React, { FC, ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { date, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { InvoicesReportPropsInterface } from './interfaces/InvoicesReportProps.interface';
import { InvoicesReportFormInterface } from './components/invoices-report-form/interfaces/InvoicesReportForm.interface';
import FormModalComponent from '../../../../../../../components/modal/form-modal/FormModal.component';
import useGetInvoicesReportMutation from '../../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/report/useGetInvoicesReport.mutation';
import useFile from '../../../../../../../../utils/hooks/file/useFile.hook';
import InvoicesReportFormComponent from './components/invoices-report-form/InvoicesReportForm.component';

const InvoicesReportView: FC<InvoicesReportPropsInterface> = ({ close }): ReactElement => {
  const { t } = useTranslation();
  const { download } = useFile();
  const [error, setError] = useState('');

  const validationInvoicesReportFormSchema = object().shape({
    fromDate: date()
      .nullable()
      .required(t('invoices.report.controls.fromDate.errors.required'))
      .typeError(t('invoices.report.controls.errors.invalidDateFormat')),
    toDate: date()
      .nullable()
      .required(t('invoices.report.controls.toDate.errors.required'))
      .typeError(t('invoices.report.controls.errors.invalidDateFormat')),
  });

  const methods = useForm<InvoicesReportFormInterface>({
    resolver: yupResolver(validationInvoicesReportFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      fromDate: null,
      toDate: null,
    },
  });

  const {
    mutateAsync: getInvoicesReport,
    isSuccess,
    isLoading,
  } = useGetInvoicesReportMutation({
    setValidationError: methods.setError,
    setOtherError: setError,
  });

  const onSubmitHandler = async (data: InvoicesReportFormInterface) => {
    setError('');

    const fromDate = data.fromDate ? format(data.fromDate, 'yyyy-MM-dd') : '';
    const toDate = data.toDate ? format(data.toDate, 'yyyy-MM-dd') : '';

    const {
      data: { responseModel: response },
    } = await getInvoicesReport({
      FromDate: fromDate,
      ToDate: toDate,
    });

    download(
      `data:application/octec-stream;charset=utf-8;base64,${response.fileContent}`,
      `processed_invoices_${fromDate}_${toDate}.xlsx`,
    );
  };

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmitHandler)}
      submitText={t('global.download')}
      submitIcon={<FileDownloadOutlinedIcon />}
      isSuccess={isSuccess}
      isLoading={isLoading}
    >
      <InvoicesReportFormComponent error={error} />
    </FormModalComponent>
  );
};

export default InvoicesReportView;
