import React, { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl } from '../../../../../../../../../../../components/material';
import AutocompleteSelect from '../../../../../../../../../../../components/material/autocomplete-select/AutocompleteSelect.component';
import { ResidentPropertyInterface } from '../../../../../../../../../../../../types/upload-hub/resident/property/ResidentProperty.interface';
import useGetResidentPropertiesQuery from '../../../../../../../../../../../../services/react-query-hooks/queries/upload-hub/resident/useGetResidentProperties.query';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';

// @ts-ignore
const PropertiesControllerComponent = ({ name, error, onChangeProperty, disabled = false }) => {
  const { t } = useTranslation();
  const { data: properties, isLoading } = useGetResidentPropertiesQuery();
  const { control, watch } = useFormContext<VerifyInvoiceDataFormInterface>();

  const currentSelectedProperty = watch('1');

  const propertiesController = useMemo(
    () => (
      <Controller
        name={name}
        control={control}
        render={({ field: fieldProps }) => (
          <AutocompleteSelect
            {...fieldProps}
            value={fieldProps.value as ResidentPropertyInterface}
            data={properties}
            isLoading={isLoading}
            placeholder={t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.property.label',
            )}
            error={!!error}
            onChange={(value) => {
              fieldProps.onChange(value);

              if (
                onChangeProperty &&
                (value === null ||
                  (currentSelectedProperty as ResidentPropertyInterface)?.value !==
                    (value as ResidentPropertyInterface)?.value)
              ) {
                onChangeProperty(value as ResidentPropertyInterface);
              }
            }}
            disabled={disabled}
            data-cy="property-input"
          />
        )}
      />
    ),
    [
      name,
      control,
      properties,
      isLoading,
      t,
      error,
      disabled,
      onChangeProperty,
      currentSelectedProperty,
    ],
  );

  return (
    <FormControl error={!!error} style={{ width: '95%' }} data-cy="property-controller">
      {propertiesController}
      <span role="alert">{(error as any)?.message}</span>
    </FormControl>
  );
};

const PropertiesController = memo(PropertiesControllerComponent);

export default PropertiesController;
