import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { InvoiceMutationPropsInterface } from './InvoiceMutationProps.interface';
import { ApiOperation } from '../../../../api';
import useGeneralErrorHandler from '../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../error-handlers/useOnError';
import { queryClient } from '../../../../queryClient';
import { getProcessedInvoicesQueryKey } from '../../../queries/queryKeys';
import { sendToIntegrationServiceMutationKey } from '../../mutationKeys';
import { useAlert } from '../../../../alert/Alert.provider';
import { ProcessedInvoiceActionPayloadInterface } from './interfaces/ProcessedInvoiceActionPayload.interface';

const useSendInvoiceToIntegrationServiceMutation = ({
  setOtherError,
}: InvoiceMutationPropsInterface = {}) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.SendInvoiceToIntegrationService);

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async ({ invoiceId: id }: ProcessedInvoiceActionPayloadInterface) => {
      const res = await request({ urlParams: { id } });

      return res.data;
    },
    {
      mutationKey: sendToIntegrationServiceMutationKey,
      onSuccess: (_, { invoiceReference: name }: ProcessedInvoiceActionPayloadInterface) => {
        queryClient.invalidateQueries(getProcessedInvoicesQueryKey);

        showAlert(
          <Trans
            i18nKey="invoices.tabs.processed.actions.send.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useSendInvoiceToIntegrationServiceMutation;
