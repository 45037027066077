import React, { useMemo, useState } from 'react';
import AddDataSourceModalComponent from '../AddDataSourceModal.component';

const useAddDataSource = () => {
  const [addSourceOpen, setAddSourceOpen] = useState(false);

  const addDataSourceModal = useMemo(
    () => <AddDataSourceModalComponent open={addSourceOpen} setOpen={setAddSourceOpen} />,
    [addSourceOpen],
  );

  return { addDataSourceModal, setAddSourceOpen };
};

export default useAddDataSource;
