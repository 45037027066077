import React, { FC, PropsWithChildren, ReactElement } from 'react';
import {
  allBreakpointsList,
  BreakpointPropsInterface,
} from './interfaces/BreakpointProps.interface';
import { BreakpointEnum } from '../../enums/Breakpoint.enum';

const getColumnClass = (breakpointProps: BreakpointPropsInterface): string => {
  return allBreakpointsList
    .filter((breakpoint: BreakpointEnum) => breakpointProps[breakpoint])
    .map((breakpoint: BreakpointEnum): string => `col-${breakpoint}-${breakpointProps[breakpoint]}`)
    .join(' ');
};

const ColumnComponent: FC<PropsWithChildren<BreakpointPropsInterface>> = ({
  className,
  children,
  ...restProps
}): ReactElement => {
  const classes = `${getColumnClass(restProps)} ${className || ''}`;

  return <div className={classes}>{children}</div>;
};

export default ColumnComponent;
