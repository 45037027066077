import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';
import { useValidationErrorHandler } from '../useErrorHandler';
import DataSourceFormInterface from '../../../../views/data-source/components/data-source-form/DataSourceForm.interface';

const useDataSourceUniqueNameErrorHandler = (
  setValidationError: UseFormSetError<DataSourceFormInterface>,
) => {
  const { t } = useTranslation();

  return useValidationErrorHandler({
    setError: setValidationError,
    errorCode: 'DATA_SOURCE_WITH_GIVEN_NAME_ALREADY_EXIST',
    errorMessage: t(
      'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.name.errors.duplicate',
    ),
    field: 'name',
    options: { shouldFocus: true },
  });
};

export default useDataSourceUniqueNameErrorHandler;
