import React, {
  forwardRef,
  ReactElement,
  useRef,
  useState,
  useCallback,
  useEffect,
  memo,
  HTMLAttributes,
  ReactNode,
  SyntheticEvent,
} from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  CircularProgress,
} from '@mui/material';
import { AutocompleteValue } from '@mui/base';
import { useTranslation } from 'react-i18next';
import { AutocompleteSelectPropsInterface } from './interfaces/AutocompleteSelectProps.interface';
import './AutocompleteSelect.component.scss';
import { AutocompleteSelectItemInterface } from './interfaces/AutocompleteSelectItem.interface';
import TextField from '../text-field/TextField.component';
import ListBoxComponent from './components/ListBox.component';

const AutocompleteSelectComponent = forwardRef(
  (
    {
      data,
      isLoading,
      fetch,
      value,
      onBlur,
      onChange,
      placeholder,
      label,
      error,
      disabled,
      required,
      disablePortal,
      ...rest
    }: AutocompleteSelectPropsInterface,
    ref,
  ): ReactElement => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly AutocompleteSelectItemInterface[]>([]);

    const isFetching = fetch && open && options.length === 0;
    const [isFetched, setIsFetched] = useState(false);

    const inputRef = useRef<HTMLInputElement>();

    const fetchOptions = useCallback(async () => {
      let active = true;

      if (!isFetching) {
        return undefined;
      }

      if (fetch) {
        const response = await fetch();

        if (active) {
          setOptions(response.data || []);
          setIsFetched(true);
        }
      }

      return () => {
        active = false;
      };
    }, [isFetching, fetch]);

    useEffect(() => {
      if (fetch) {
        (async () => {
          await fetchOptions();
        })();
      } else if (data && data.length > 0) {
        setOptions(data);
      }
    }, [data, fetch, fetchOptions]);

    useEffect(() => {
      if (!value && !open && fetch && options.length > 0 && isFetched) {
        setOptions([]);
        setIsFetched(false);
      }
    }, [fetch, isFetched, open, options.length, value]);

    const textFieldInput = useCallback(
      (renderInput: AutocompleteRenderInputParams) => (
        <TextField
          {...renderInput}
          placeholder={placeholder || t('global.search')}
          label={label}
          error={!!error}
          InputProps={{
            ...renderInput.InputProps,
            endAdornment: (
              <>
                {isLoading || (isFetching && !isFetched) ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {renderInput.InputProps.endAdornment}
              </>
            ),
          }}
          value={value}
          InputLabelProps={{ shrink: false }}
          inputRef={inputRef}
          required={required}
        />
      ),
      [placeholder, t, label, error, isLoading, isFetching, isFetched, value, required],
    );

    return (
      <Autocomplete
        value={value}
        onBlur={() => {
          onBlur();
        }}
        onChange={(
          _: SyntheticEvent,
          item: AutocompleteValue<AutocompleteSelectItemInterface, undefined, undefined, undefined>,
        ) => {
          onChange(item);
          inputRef.current?.blur();
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        className="data-hub-autocomplete-select"
        options={options}
        loading={isLoading || (isFetching && !isFetched)}
        disabled={disabled}
        disableListWrap
        disablePortal={disablePortal}
        ListboxComponent={ListBoxComponent}
        getOptionLabel={(option: AutocompleteSelectItemInterface) => option.text}
        isOptionEqualToValue={(
          option: AutocompleteSelectItemInterface,
          val: AutocompleteSelectItemInterface,
        ) => option.value === val.value}
        renderOption={(
          props: HTMLAttributes<HTMLLIElement>,
          option: AutocompleteSelectItemInterface,
          { selected }: AutocompleteRenderOptionState,
        ) => [props, option, selected] as ReactNode}
        renderInput={textFieldInput}
        ref={ref}
        data-cy={rest['data-cy']}
      />
    );
  },
);

const AutocompleteSelect = memo(AutocompleteSelectComponent);

export default AutocompleteSelect;
