import React, { FC, ReactElement } from 'react';
import { CardContent as MatCardContent, CardMedia } from '@mui/material';
import { CardContentPropsInterface } from './interfaces/CardContentProps.interface';

const CardContent: FC<CardContentPropsInterface> = ({
  children,
  image,
  imageAlt,
}): ReactElement => {
  if (!image) {
    return <MatCardContent>{children}</MatCardContent>;
  }

  return (
    <>
      <CardMedia component="img" height="140" image={image} alt={imageAlt || 'Card image'} />
      <MatCardContent>{children}</MatCardContent>
    </>
  );
};

export { CardContent };
