import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import { getResidentCategoriesQueryKey } from '../../queryKeys';
import { ResidentCategoriesResponseType } from '../../../../../types/upload-hub/resident/category/ResidentCategoriesResponse.type';

const useGetResidentCategoriesQuery = (propertyId: string = '', scheduleId: string = '') => {
  const { request } = useRequest<ResidentCategoriesResponseType>(
    ApiOperation.GetResidentCategories,
  );

  return useQuery(
    [getResidentCategoriesQueryKey, propertyId, scheduleId],
    async () => {
      const res = await request({
        params: {
          PropertyId: propertyId,
          ScheduleId: scheduleId,
        },
      });

      return res.data.responseModel.residentCategories;
    },
    {
      enabled: !!scheduleId,
    },
  );
};

export default useGetResidentCategoriesQuery;
