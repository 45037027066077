import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';
import { useValidationErrorHandler } from '../../../useErrorHandler';
import SynchronizedInboxesFormInterface from '../../../../../../views/plugin-settings/components/upload-hub/components/synchronized-inboxes/components/synchronized-inboxes-form/SynchronizedInboxesForm.interface';

const useSynchronizedInboxUniqueEmailErrorHandler = (
  setValidationError: UseFormSetError<SynchronizedInboxesFormInterface>,
) => {
  const { t } = useTranslation();

  return useValidationErrorHandler({
    setError: setValidationError,
    errorCode: 'UPLOAD_HUB_EMAIL_SETTING_ALREADY_EXIST',
    errorMessage: t(
      'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.email.errors.duplicate',
    ),
    field: 'email',
    options: { shouldFocus: true },
  });
};

export default useSynchronizedInboxUniqueEmailErrorHandler;
