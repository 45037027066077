import React, { FC, ReactElement } from 'react';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import classes from './MatchItem.module.scss';
import { MatchTagPropsInterface } from './interfaces/MatchTagProps.interface';

const MatchTagComponent: FC<MatchTagPropsInterface> = ({ label }): ReactElement => {
  return (
    <div className={classes['match-tag']}>
      <span className={classes['match-tag__label']}>{label}</span>
      <MenuOutlinedIcon className={classes['match-tag__icon']} />
    </div>
  );
};

export default MatchTagComponent;
