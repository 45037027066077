import React, { useCallback, useMemo, useState } from 'react';
import RejectInvoiceModalComponent from '../RejectInvoiceModal.component';
import useRejectInvoiceMutation from '../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/reject/useRejectInvoice.mutation';
import useRejectToAmendInvoiceMutation from '../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/reject/useRejectToAmend.mutation';
import { InvoiceRejectPayloadInterface } from '../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/reject/interfaces/InvoiceRejectPayload.interface';

export type RejectInvoiceVariant = 'reject' | 'rejectToAmend';

const useRejectInvoice = () => {
  const [rejectInvoiceOpen, setRejectInvoiceOpen] = useState(false);
  const [rejectInvoiceData, setRejectInvoiceData] = useState<
    InvoiceRejectPayloadInterface | undefined
  >(undefined);

  const [error, setError] = useState('');
  const {
    mutate: rejectInvoice,
    isLoading: isRejectLoading,
    isSuccess: isRejectSuccess,
  } = useRejectInvoiceMutation({ setOtherError: setError });
  const {
    mutate: rejectToAmendInvoice,
    isLoading: isRejectToAmendLoading,
    isSuccess: isRejectToAmendSuccess,
  } = useRejectToAmendInvoiceMutation({ setOtherError: setError });

  const variant = useMemo<RejectInvoiceVariant>(
    () => rejectInvoiceData?.variant || 'reject',
    [rejectInvoiceData?.variant],
  );

  const rejectInvoiceHandler = useCallback(
    (payload?: InvoiceRejectPayloadInterface) => {
      setError('');

      const reqPayload = {
        ...payload,
        ...rejectInvoiceData,
        invoiceReference: rejectInvoiceData?.invoiceReference || '',
        invoiceId: rejectInvoiceData?.invoiceId || '',
      };

      if (variant === 'rejectToAmend') {
        rejectToAmendInvoice(reqPayload);
      } else if (rejectInvoiceData) {
        rejectInvoice(reqPayload);
      }
    },
    [rejectInvoice, rejectInvoiceData, rejectToAmendInvoice, variant],
  );

  const rejectModal = useMemo(() => {
    if (!rejectInvoiceData) {
      return <> </>;
    }

    return (
      <RejectInvoiceModalComponent
        open={rejectInvoiceOpen}
        setOpen={setRejectInvoiceOpen}
        onReject={rejectInvoiceHandler}
        variant={variant}
        note={rejectInvoiceData.note}
      />
    );
  }, [rejectInvoiceData, rejectInvoiceOpen, rejectInvoiceHandler, variant]);

  return {
    rejectModal,
    setRejectInvoiceOpen,
    setRejectInvoiceData,
    rejectInvoice: rejectInvoiceHandler,
    error,
    isLoading: isRejectLoading || isRejectToAmendLoading,
    isSuccess: isRejectSuccess || isRejectToAmendSuccess,
  };
};

export default useRejectInvoice;
