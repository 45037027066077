import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import useUpdateSynchronizedInboxMutation from '../../../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/synchronized-inboxes/useUpdateSynchronizedInbox.mutation';
import { SimpleSmtpSynchronizedInboxInterface } from '../../../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';
import FormModalComponent from '../../../../../../../components/modal/form-modal/FormModal.component';
import SynchronizedInboxesFormComponent from '../synchronized-inboxes-form/SynchronizedInboxesForm.component';
import SynchronizedInboxesFormInterface from '../synchronized-inboxes-form/SynchronizedInboxesForm.interface';

interface UpdateSynchronizedInboxPropsInterface extends SimpleSmtpSynchronizedInboxInterface {
  id: string;
  close: () => void;
}

const UpdateSynchronizedInboxView: FC<UpdateSynchronizedInboxPropsInterface> = ({
  id,
  close,
  ...formValues
}) => {
  const methods = useForm<SynchronizedInboxesFormInterface>({
    mode: 'onBlur',
    defaultValues: formValues,
  });
  const {
    mutate: updateSyncInbox,
    isLoading,
    isSuccess,
  } = useUpdateSynchronizedInboxMutation({
    setValidationError: methods.setError,
  });

  const onSubmitHandler = (data: SynchronizedInboxesFormInterface) =>
    updateSyncInbox({
      id,
      payload: {
        ...data,
        imapPort: Number(data.imapPort),
      },
    });

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmitHandler)}
      isSuccess={isSuccess}
      isLoading={isLoading}
    >
      <SynchronizedInboxesFormComponent mode="update" />
    </FormModalComponent>
  );
};

export default UpdateSynchronizedInboxView;
