import React, { ChangeEvent, FC, memo, ReactElement, useState, useEffect } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { NumberInputPropsInterface } from './interfaces/NumberInputProps.interface';
import { IconButton } from '../material';
import TextField from '../material/text-field/TextField.component';
import './NumberInput.component.scss';

const NumberInputComponent: FC<NumberInputPropsInterface> = ({
  id,
  value,
  defaultValue,
  min = 1,
  max = 100,
  onChange,
  buttons = true,
}): ReactElement => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<number | '' | undefined>(
    value || defaultValue || '',
  );

  useEffect(() => {
    if (value !== undefined && value !== inputValue) {
      setInputValue(value);
    }
  }, [inputValue, value]);

  const ocrChangeInputValueHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.target.value === '') {
      setInputValue('');
      return;
    }

    const val = Number(event.target.value);

    if (!Number.isInteger(val)) {
      return;
    }

    if (val > max) {
      setInputValue(max);
    } else if (val < min) {
      setInputValue(min);
    } else {
      setInputValue(val);
    }
  };

  const ocrBlurInputValueHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value === '') {
      setInputValue(1);

      if (value !== 1 && onChange) {
        onChange(1);
      }
    } else if (value !== Number(event.target.value) && onChange) {
      onChange(Number(event.target.value));
    }
  };

  const onDecreaseInputValueHandler = () => {
    if (inputValue !== '' && inputValue !== undefined && inputValue > min) {
      setInputValue(inputValue - 1);

      if (onChange) {
        onChange(inputValue - 1);
      }
    }
  };

  const onIncreaseInputValueHandler = () => {
    if (inputValue !== '' && inputValue !== undefined && inputValue < max) {
      setInputValue(inputValue + 1);

      if (onChange) {
        onChange(inputValue + 1);
      }
    }
  };

  return (
    <div className="number-input">
      {buttons && (
        <IconButton
          size="large"
          aria-label={t('global.decrease')}
          style={{ marginRight: '0.5rem' }}
          onClick={onDecreaseInputValueHandler}
          variant="input-group"
        >
          <RemoveIcon />
        </IconButton>
      )}

      <TextField
        type="number"
        InputProps={{
          inputProps: { id, min, max },
        }}
        value={inputValue}
        onChange={ocrChangeInputValueHandler}
        onBlur={ocrBlurInputValueHandler}
      />

      {buttons && (
        <IconButton
          size="large"
          aria-label={t('global.increase')}
          style={{ marginLeft: '0.5rem' }}
          onClick={onIncreaseInputValueHandler}
          variant="input-group"
        >
          <AddIcon />
        </IconButton>
      )}
    </div>
  );
};

const NumberInput = memo(NumberInputComponent);

export default NumberInput;
