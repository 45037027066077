import React, { FC, ReactElement } from 'react';
import { AccordionDetails as MatAccordionDetails, AccordionDetailsProps } from '@mui/material';
import classnames from 'classnames';
import './AccordionDetails.component.scss';

const AccordionDetails: FC<AccordionDetailsProps> = ({
  className,
  children,
  ...rest
}): ReactElement => {
  return (
    <MatAccordionDetails {...rest} className={classnames('data-hub-accordion-details', className)}>
      {children}
    </MatAccordionDetails>
  );
};

export { AccordionDetails };
