import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import InvoiceNoteFormInterface from './interfaces/InvoiceNoteForm.interface';
import FormContainerComponent from '../../../../../../../../../components/form-container/FormContainer.component';
import classes from './InvoiceNoteForm.module.scss';
import classesConfirmModal from '../../../../../../../../../components/modal/confirm-modal/ConfirmModal.module.scss';
import { FormControl } from '../../../../../../../../../components/material';

interface InvoiceNoteFormPropsInterface {
  message?: string;
  hidden?: boolean;
}
const InvoiceNoteFormComponent: FC<InvoiceNoteFormPropsInterface> = ({
  message,
  hidden = false,
}) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<InvoiceNoteFormInterface>();

  return (
    <FormContainerComponent className={classes['invoice-note-form-section']} fullWidth>
      <Grid container direction="row">
        {message && (
          <Grid
            item
            xs={12}
            className={classNames(
              classes['invoice-note-form-section__form'],
              classes['invoice-note-form-section__form--message'],
            )}
          >
            <div className={classesConfirmModal['confirm-modal-message']}>{message}</div>
          </Grid>
        )}
        {!hidden && (
          <Grid className={classes['invoice-note-form-section__form']} item xs={12}>
            <Grid container direction="column" rowSpacing={4}>
              <Grid item>
                <FormControl error={!!errors.note}>
                  <Controller
                    name="note"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t(
                        'invoices.tabs.toVerify.verificationModal.steps.verification.note.controls.note.errors.required',
                      ),
                    }}
                    render={({ field: fieldProps }) => (
                      <TextField
                        {...fieldProps}
                        label={t(
                          'invoices.tabs.toVerify.verificationModal.steps.verification.note.controls.note.label',
                        )}
                        error={!!errors.note}
                        multiline
                        rows={4}
                        required
                      />
                    )}
                  />
                  <span role="alert">{errors.note?.message}</span>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </FormContainerComponent>
  );
};

export default InvoiceNoteFormComponent;
