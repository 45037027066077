import { useRequest } from '@datahub/api-client';
import { TableFiltersInterface } from '../../../../views/components/table/interfaces/TableFilters.interface';
import { ApiOperation } from '../../../api';
import { NotificationsResponseType } from '../../../../types/notification/NotificationsResponse.type';
import usePaginatedListQuery from '../../pagination/usePaginatedList.query';
import { getNotificationsQueryKey } from '../queryKeys';
import useAuth from '../../../auth/Auth.provider';
import { useUserPermissions } from '../../../user/User.provider';
import { NotificationInterface } from '../../../../types/notification/Notification.interface';
import { NotificationsTypeOptions } from '../../../../types/notification/Notification.type';

const useGetNotificationsListQuery = (filters?: TableFiltersInterface) => {
  const { isAuthenticated } = useAuth();
  const { hasAnyPermission } = useUserPermissions();

  const { request } = useRequest<NotificationsResponseType>(ApiOperation.GetNotifications);

  const { data, ...rest } = usePaginatedListQuery<NotificationsResponseType>(
    request,
    getNotificationsQueryKey,
    filters,
    {},
    {
      enabled: isAuthenticated && hasAnyPermission,
      keepPreviousData: true,
    },
  );

  const assignNotificationUrlBehavior = (n: NotificationInterface) => {
    const { urlSelfOpen, urlTranslationKey } = NotificationsTypeOptions[n.type];

    return {
      ...n,
      urlSelfOpen,
      // @ts-ignore
      urlLabel: urlTranslationKey ? `notification.url.${urlTranslationKey}` : '',
    };
  };

  return {
    data: data ? data.responseModel.map(assignNotificationUrlBehavior) : [],
    ...rest,
  };
};

export default useGetNotificationsListQuery;
