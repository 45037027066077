import React, { FC, memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ImagePreviewPropsInterface } from './interfaces/ImagePreviewProps.interface';
import './ImagePreview.component.scss';
import useCanvasDraw from '../../../../../utils/hooks/canvas/useCanvasDraw.hook';
import ZoomComponent from '../zoom/Zoom.component';

const ImagePreviewComponent: FC<ImagePreviewPropsInterface> = ({ path, stroke }): ReactElement => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { draw, initialCanvas, setInitialCanvas, scale, zoomIn, zoomOut, mouseZoomActive } =
    useCanvasDraw();

  const convertImageToCanvas = useCallback(() => {
    if (path) {
      const img = new Image();

      img.src = path;
      img.onload = () => {
        setLoading(false);
        setError('');

        if (canvasRef.current && containerRef.current) {
          const canvasContext = canvasRef.current.getContext('2d');

          if (canvasContext) {
            const pattern = canvasContext.createPattern(img, 'no-repeat');

            if (pattern && canvasRef.current) {
              canvasContext.save();

              canvasRef.current.height = img.height;
              canvasRef.current.width = img.width;

              canvasContext.fillStyle = pattern;

              canvasContext.scale(scale, scale);
              canvasContext.drawImage(img, 0, 0, img.width, img.height);
              canvasContext.restore();

              if (stroke) {
                draw(canvasRef.current, stroke, 1, scale);
              }

              setInitialCanvas(canvasRef.current);
            }
          }
        }
      };

      img.onerror = () => {
        setLoading(false);
        setError(t('filePreview.image.errors.empty'));
      };
    } else {
      setLoading(false);
      setError(t('filePreview.image.errors.empty'));
    }
  }, [draw, path, scale, setInitialCanvas, stroke, t]);

  useEffect(() => {
    if (scale && !initialCanvas) {
      convertImageToCanvas();
    }
  }, [convertImageToCanvas, initialCanvas, scale]);

  return (
    <div
      className={classNames('image-preview', {
        'image-preview--mouse-zoom': mouseZoomActive,
      })}
      ref={containerRef}
      data-cy="image-preview"
    >
      {loading && (
        <div style={{ textAlign: 'center', padding: '1.25rem' }} data-cy="image-preview-loading">
          {t('filePreview.image.loading')}
        </div>
      )}

      {!loading && error && (
        <div style={{ textAlign: 'center', padding: '1.25rem' }} data-cy="image-preview-error">
          {error}
        </div>
      )}

      {!loading && !error && (
        <>
          <div className="image-preview__preview">
            <canvas ref={canvasRef} />
          </div>

          <div className="image-preview__toolbar" data-cy="image-preview-toolbar">
            <ZoomComponent
              scale={scale}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              className="toolbar__zoom"
            />
          </div>
        </>
      )}
    </div>
  );
};

const ImagePreview = memo(ImagePreviewComponent);

export default ImagePreview;
