import { createTheme } from '@mui/material/styles';

const brandPrimary = '#f2aa1e';
const brandSecondary = '#fcd57a';

const primaryTheme = createTheme({
  palette: {
    primary: {
      main: brandPrimary,
    },
    secondary: {
      main: brandSecondary,
    },
  },
});

export default primaryTheme;
