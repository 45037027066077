import { SimpleDataSourceObjectInterface } from '../../../../types/data-source/DataSource.interface';

export default interface DataSourceFormInterface
  extends Omit<SimpleDataSourceObjectInterface, 'type' | 'origin'> {
  type: number | '';
  origin: number | '';
}

export const defaultValues: DataSourceFormInterface = {
  name: '',
  origin: 1,
  type: '',
  autoSync: false,
};
