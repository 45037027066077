import React, { FC, ReactElement } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import classNames from 'classnames';
import { ZoomPropsInterface } from './interfaces/ZoomProps.interface';
import { IconButton } from '../../../material';
import './Zoom.component.scss';
import { maxScale, minScale } from '../../FilePreview.component';

const ZoomComponent: FC<ZoomPropsInterface> = ({
  scale,
  zoomIn,
  zoomOut,
  className,
}): ReactElement => {
  return (
    <div className={classNames('zoom', className)} data-cy="zoom">
      <IconButton
        onClick={zoomIn}
        className="zoom__button"
        size="small"
        disabled={scale >= maxScale}
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        onClick={zoomOut}
        className="zoom__button"
        size="small"
        disabled={scale <= minScale}
      >
        <ZoomOutIcon />
      </IconButton>
    </div>
  );
};

export default ZoomComponent;
