import { Grid } from '@mui/material';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../views/components/material';

interface useSubmitButtonsHookPropsInterface<T> {
  isLoading: boolean;
  close: () => void;
  handleSubmit: ((payload?: T) => void) | ((payload?: T) => Promise<void>);
  submitText: string;
  submitIcon?: ReactElement;
  disabled?: boolean;
}

const useSubmitButtonsHook = <T extends {}>({
  isLoading,
  disabled,
  close,
  handleSubmit,
  submitText,
  submitIcon,
}: useSubmitButtonsHookPropsInterface<T>) => {
  const { t } = useTranslation();

  const buttons = useCallback(
    (payload?: T) => (
      <Grid container justifyContent="center" alignContent="flex-end">
        <Grid item width="100%">
          <Grid container columnSpacing={4} rowSpacing={2} alignContent="flex-end">
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                disabled={disabled || isLoading}
                onClick={close}
                data-cy="cancel"
              >
                {t('global.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-start">
              <Button
                variant="contained"
                type="submit"
                disabled={disabled}
                loading={isLoading}
                onClick={payload ? () => handleSubmit(payload) : handleSubmit}
                startIcon={submitIcon}
                data-cy="submit"
              >
                {submitText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    [close, disabled, handleSubmit, isLoading, submitText, submitIcon, t],
  );

  return { buttons };
};

export default useSubmitButtonsHook;
