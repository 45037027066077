import React, { FC, ReactElement } from 'react';
import { Box, CircularProgress } from '@mui/material';
import classes from '../../Table.module.scss';

const TableLoadingCircleComponent: FC = (): ReactElement => {
  return (
    <Box className={classes['table__cell--loading-wrapper']}>
      <CircularProgress color="primary" size={21} />
    </Box>
  );
};

export default TableLoadingCircleComponent;
