import React, { useMemo, useState } from 'react';
import DeleteDataSourceModalComponent from '../DeleteDataSourceModal.component';
import { DataSourceInterface } from '../../../../../types/data-source/DataSource.interface';

const useDeleteDataSource = () => {
  const [deleteSourceOpen, setDeleteSourceOpen] = useState(false);
  const [deleteSourceData, setDeleteSourceData] = useState<DataSourceInterface | undefined>(
    undefined,
  );

  const deleteDataSourceModal = useMemo(() => {
    if (!deleteSourceData) {
      return <> </>;
    }

    return (
      <DeleteDataSourceModalComponent
        open={deleteSourceOpen}
        setOpen={setDeleteSourceOpen}
        dataSource={deleteSourceData}
      />
    );
  }, [deleteSourceData, deleteSourceOpen]);

  return { deleteDataSourceModal, setDeleteSourceOpen, setDeleteSourceData };
};

export default useDeleteDataSource;
