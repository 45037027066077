import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { isValid, isPast } from 'date-fns';
import { PropsInterface } from '../../interfaces/Props.interface';
import useGetConfigurationQuery from '../react-query-hooks/queries/configuration/useGetConfigurationQuery';
import { SubscriptionContextTypeInterface } from './interfaces/SubscriptionContextType.interface';
import { SubscriptionStatusEnum } from '../../enums/subscription/SubscriptionStatus.enum';
import { BasePluginInterface } from '../../types/plugin/BasePlugin.interface';
import { PluginsModelMap } from '../../types/plugin/Plugin.type';
import { PluginInterface } from '../../types/plugin/Plugin.interface';

const SubscriptionContext = createContext<SubscriptionContextTypeInterface>(
  {} as SubscriptionContextTypeInterface,
);

const getUniquePluginsByType = (plugins: BasePluginInterface[]) => {
  const uniquePlugins = new Set();

  return plugins.filter((element: BasePluginInterface) => {
    const isDuplicate = uniquePlugins.has(element.type);

    uniquePlugins.add(element.type);

    return !isDuplicate;
  });
};

export const SubscriptionProvider: FC<PropsWithChildren<PropsInterface>> = ({ children }) => {
  const {
    refetch: retrieveConfiguration,
    data: configuration,
    isLoading,
    isFetching,
    error,
  } = useGetConfigurationQuery();

  const mapAvailablePlugins = useCallback((plugins: BasePluginInterface[]): PluginInterface[] => {
    return plugins.reduce((pluginsAcc: PluginInterface[], plugin: BasePluginInterface) => {
      const pluginModel = PluginsModelMap[plugin.type] as PluginInterface;

      if (!pluginModel) {
        return pluginsAcc;
      }

      return [...pluginsAcc, pluginModel];
    }, []);
  }, []);

  const memoizedSubscriptionState = useMemo(() => {
    const basePlugins: BasePluginInterface[] =
      configuration &&
      configuration.activeSubscription &&
      configuration.activeSubscription.plugins &&
      configuration.activeSubscription.plugins.length > 0
        ? configuration.activeSubscription.plugins
        : [];

    const queryData = {
      retrieveConfiguration,
      configuration,
      isLoading,
      isFetching,
      hasPlugins: basePlugins.length > 0,
      plugins: mapAvailablePlugins(basePlugins),
      pluginsTypes: mapAvailablePlugins(getUniquePluginsByType(basePlugins)),
    };

    if (error) {
      return {
        ...queryData,
        status: SubscriptionStatusEnum.ERROR,
      };
    }

    if (!configuration?.activeSubscription) {
      return {
        ...queryData,
        status: SubscriptionStatusEnum.NOT_FOUND,
      };
    }

    const subscriptionExpirationDate = new Date(configuration.activeSubscription.endDate || '');

    if (!isValid(subscriptionExpirationDate) || isPast(subscriptionExpirationDate)) {
      return {
        ...queryData,
        status: SubscriptionStatusEnum.EXPIRED,
      };
    }

    return {
      ...queryData,
      status: SubscriptionStatusEnum.ACTIVE,
    };
  }, [configuration, retrieveConfiguration, isLoading, isFetching, mapAvailablePlugins, error]);

  return (
    <SubscriptionContext.Provider value={memoizedSubscriptionState}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export function useSubscription() {
  return useContext(SubscriptionContext);
}
