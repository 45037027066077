import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { DataSourceMutationWithValidationPropsInterface } from './DataSourceMutationProps.interface';
import { ApiOperation } from '../../../api';
import { queryClient } from '../../../queryClient';
import { getDataSourcesForGeneralTypeQuery, getDataSourcesQueryKey } from '../../queries/queryKeys';
import DataSourcePayloadInterface from '../../../../types/data-source/DataSourcePayload.interface';
import useDataSourceUniqueNameErrorHandler from '../../error-handlers/data-source/useDataSourceUniqueNameError.handler';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../error-handlers/useOnError';
import useDataSourceCannotTurnOffAutoSyncErrorHandler from '../../error-handlers/data-source/useDataSourceCannotTurnOffAutoSyncError.handler';
import useDataSourceNotFoundErrorHandler from '../../error-handlers/data-source/useDataSourceNotFoundError.handler';
import { useAlert } from '../../../alert/Alert.provider';

interface UpdateDataSourcePayloadInterface {
  id: string;
  payload: DataSourcePayloadInterface;
}

const useUpdateDataSourceMutation = (
  { setValidationError, setOtherError }: DataSourceMutationWithValidationPropsInterface = {
    setValidationError: () => {},
  },
) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateDataSource);

  const uniqueNameErrorHandler = useDataSourceUniqueNameErrorHandler(setValidationError);
  const autoSyncErrorHandler = useDataSourceCannotTurnOffAutoSyncErrorHandler(setValidationError);
  const notFoundErrorHandler = useDataSourceNotFoundErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError(
    [uniqueNameErrorHandler, autoSyncErrorHandler, notFoundErrorHandler],
    generalErrorHandler,
  );

  return useMutation(
    async ({ id, payload }: UpdateDataSourcePayloadInterface) => {
      const res = await request({ data: { ...payload }, urlParams: { id } });

      return res.data;
    },
    {
      onSuccess: (_, { payload: { name } }: UpdateDataSourcePayloadInterface) => {
        queryClient.invalidateQueries(getDataSourcesQueryKey);
        queryClient.invalidateQueries(getDataSourcesForGeneralTypeQuery);

        showAlert(
          <Trans
            i18nKey="dataSourcesSettings.sections.dataSources.subsections.supplierList.updateDataSourceModal.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useUpdateDataSourceMutation;
