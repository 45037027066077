import React, { ReactElement, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import classes from './InvoiceDetails.module.scss';
import { InvoiceDetailsPropsInterface } from './interfaces/InvoiceDetailsProps.interface';
import useGetInvoiceDetailsQuery from '../../../../../../../../services/react-query-hooks/queries/upload-hub/invoice/processed/useGetInvoiceDetails.query';
import LoadingComponent from '../../../../../../../components/loading/Loading.component';
import { AlignEnum } from '../../../../../../../../enums/Align.enum';
import useDate from '../../../../../../../../utils/hooks/date-format/useDate';
import { Button } from '../../../../../../../components/material';
import useFile from '../../../../../../../../utils/hooks/file/useFile.hook';
import { TableActionInterface } from '../../../../../../../components/table/interfaces/TableProps.interface';
import useTableRowUtils from '../../../../../../../components/table/components/table-row/hooks/TableRowUtils.hook';
import InvoiceNoteComponent from '../../../to-verify/components/verification/components/note/InvoiceNote.component';

const InvoiceDetailsComponent = <T,>({
  id,
  onDelete,
  disabled,
  row,
  actions,
  onActionSelected,
}: InvoiceDetailsPropsInterface<T>): ReactElement => {
  const { t } = useTranslation();
  const { format } = useDate();
  const { download } = useFile();
  const { data: invoice, isLoading } = useGetInvoiceDetailsQuery(id);
  const { activeActions } = useTableRowUtils(row, actions);

  const hasNote = useMemo(() => row && row.note && row.isRejected, [row]);

  return (
    <div
      className={classNames(
        classes['invoice-details'],
        disabled ? classes['invoice-details--disabled'] : '',
      )}
    >
      {isLoading && <LoadingComponent variant="relative" size="sm" />}
      {invoice && !isLoading && (
        <div className={classes['invoice-details__container']}>
          <TableContainer>
            <Table
              className={classes['invoice-details__table']}
              aria-label="invoice-duplicates-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classNames(classes.table__cell, classes['table__cell--header'])}
                    align={AlignEnum.LEFT}
                  >
                    {t('invoices.tabs.processed.details.columns.supplierName')}
                  </TableCell>
                  <TableCell
                    className={classNames(classes.table__cell, classes['table__cell--header'])}
                    align={AlignEnum.CENTER}
                  >
                    {t('invoices.tabs.processed.details.columns.invoiceReference')}
                  </TableCell>
                  <TableCell
                    className={classNames(classes.table__cell, classes['table__cell--header'])}
                    align={AlignEnum.CENTER}
                  >
                    {t('invoices.tabs.processed.details.columns.accountNumber')}
                  </TableCell>
                  <TableCell
                    className={classNames(classes.table__cell, classes['table__cell--header'])}
                    align={AlignEnum.CENTER}
                  >
                    {t('invoices.tabs.processed.details.columns.invoiceDate')}
                  </TableCell>
                  <TableCell
                    className={classNames(classes.table__cell, classes['table__cell--header'])}
                    align={AlignEnum.RIGHT}
                  >
                    {t('invoices.tabs.processed.details.columns.amount')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classNames(classes.table__cell)} align={AlignEnum.LEFT}>
                    <p>{invoice.supplierName}</p>
                  </TableCell>
                  <TableCell className={classNames(classes.table__cell)} align={AlignEnum.CENTER}>
                    {invoice.invoiceReference}
                  </TableCell>
                  <TableCell className={classNames(classes.table__cell)} align={AlignEnum.CENTER}>
                    {invoice.accountNumber}
                  </TableCell>
                  <TableCell className={classNames(classes.table__cell)} align={AlignEnum.CENTER}>
                    {format(invoice.invoiceDate)}
                  </TableCell>
                  <TableCell className={classNames(classes.table__cell)} align={AlignEnum.RIGHT}>
                    {invoice.totalAmount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div
            className={classNames(
              classes['invoice-details__more'],
              hasNote ? classes['invoice-details__more--with-note'] : '',
            )}
          >
            {hasNote && (
              <InvoiceNoteComponent
                note={String(row?.note)}
                date={(row?.noteDate as string | Date) || ''}
                className={classes.more__note}
              />
            )}
            <div className={classes.more__buttons}>
              {activeActions &&
                activeActions.map((action: TableActionInterface<T>, index: number) => (
                  <Button
                    key={String(action.name)}
                    startIcon={action.icon}
                    variant={index === activeActions.length - 1 ? 'outlined' : 'text'}
                    onClick={() => {
                      if (onActionSelected) {
                        onActionSelected(action.name, id);
                      }
                    }}
                    disabled={disabled}
                  >
                    {action.label}
                  </Button>
                ))}

              {!actions && (
                <>
                  <Button
                    startIcon={<FileDownloadOutlinedIcon />}
                    variant="text"
                    onClick={() => {
                      download(invoice?.fileLocalization);
                    }}
                    disabled={disabled}
                  >
                    {t('global.download')}
                  </Button>
                  <Button
                    startIcon={<ClearOutlinedIcon />}
                    variant="outlined"
                    onClick={() => onDelete(id)}
                    disabled={disabled}
                  >
                    {t('global.delete')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceDetailsComponent;
