import { useRequest } from '@datahub/api-client';
import { TableFiltersInterface } from '../../../../../../views/components/table/interfaces/TableFilters.interface';
import { ApiOperation } from '../../../../../api';
import { InProgressInvoiceResponseType } from '../../../../../../types/upload-hub/invoice/in-progress/InProgressInvoiceResponse.type';
import usePaginatedListQuery from '../../../../pagination/usePaginatedList.query';
import { getInProgressInvoicesQueryKey } from '../../../queryKeys';

const useGetInProgressInvoicesListQuery = (filters?: TableFiltersInterface) => {
  const { request } = useRequest<InProgressInvoiceResponseType>(ApiOperation.GetInProgressInvoices);

  const { data, ...rest } = usePaginatedListQuery<InProgressInvoiceResponseType>(
    request,
    getInProgressInvoicesQueryKey,
    filters,
  );

  return {
    data: data?.responseModel,
    ...rest,
  };
};

export default useGetInProgressInvoicesListQuery;
