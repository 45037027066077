import React, { FC, ReactElement } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import classnames from 'classnames';
import classes from './MatchSectionPaginator.module.scss';
import { IconButton } from '../../../../../../../../../../../../components/material';
import { MatchSectionPaginatorPropsInterface } from './interfaces/MatchSectionPaginatorProps.interface';

const MatchSectionPaginatorComponent: FC<MatchSectionPaginatorPropsInterface> = ({
  page,
  pageAmount,
  onNextPageClick,
  onPreviousPageClick,
  className,
  error,
}): ReactElement => {
  const onPreviousPageClickHandler = () => {
    if (onPreviousPageClick) {
      onPreviousPageClick();
    }
  };

  const onNextPageClickHandler = () => {
    if (onNextPageClick) {
      onNextPageClick();
    }
  };

  return (
    <div
      className={classnames(classes['match-section-paginator'], className, {
        [classes['match-section-paginator--error']]: error,
      })}
      data-cy="match-section-paginator"
    >
      <IconButton
        disabled={page === 0}
        onClick={onPreviousPageClickHandler}
        size="small"
        className={classes['match-section-paginator__previous-button']}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <h6 className={classes['match-section-paginator__pages']}>{`${page + 1} / ${pageAmount}`}</h6>
      <IconButton
        disabled={pageAmount - 1 === page}
        onClick={onNextPageClickHandler}
        size="small"
        className={classes['match-section-paginator__next-button']}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

export default MatchSectionPaginatorComponent;
