import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../components/modal/types/Props.interface';
import AddDataSourceView from './AddDataSource.view';

interface AddDataSourceModalComponentPropsInterface extends ModalControlPropsInterface {}

const AddDataSourceModalComponent = ({
  open,
  setOpen,
}: AddDataSourceModalComponentPropsInterface) => {
  const { t } = useTranslation();

  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t(
        'dataSourcesSettings.sections.dataSources.subsections.supplierList.addDataSourceModal.title',
      )}
      open={open}
      handleClose={handleClose}
      content={<AddDataSourceView close={handleClose} />}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xs',
      }}
      actions
    />
  );
};

export default AddDataSourceModalComponent;
