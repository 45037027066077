import Cookie from 'js-cookie';
import { Auth0Error } from 'auth0-js';
import jwtDecode from 'jwt-decode';
import { AuthCookieEnum } from '../enums/AuthCookie.enum';
import { getTokenExpDateFromNow } from './Auth0Token.util';
import { getUrlParams } from '../../../utils/Url.util';
import { HashParamsInterface } from '../interfaces/HashParams.interface';

const endSession = (): void => {
  Cookie.remove(AuthCookieEnum.ACCESS_TOKEN);
  Cookie.remove(AuthCookieEnum.ID_TOKEN);
  Cookie.remove(AuthCookieEnum.USER_ID);
  Cookie.remove(AuthCookieEnum.TOKEN_TYPE);
};

const startSession = async (url: URL): Promise<void> => {
  const params = getUrlParams<HashParamsInterface>(url.hash);

  if (params.error && params.error_description) {
    endSession();
    // eslint-disable-next-line no-throw-literal
    throw {
      error: params.error,
      description: params.error_description,
    } as Auth0Error;
  }

  try {
    if (params.access_token && params.id_token && params.expires_in && params.token_type) {
      const accessTokenExpires = getTokenExpDateFromNow(params.expires_in);
      // @ts-ignore
      const { sub: userId, exp } = jwtDecode(params.id_token);
      const expires = new Date(exp * 1000);

      // ACCESS TOKEN to Auth0 API
      Cookie.set(AuthCookieEnum.ACCESS_TOKEN, params.access_token, {
        expires: accessTokenExpires,
      });
      Cookie.set(AuthCookieEnum.EXPIRES_IN, params.expires_in, {
        expires: accessTokenExpires,
      });

      // ACCESS TOKEN to Client API
      Cookie.set(AuthCookieEnum.ID_TOKEN, params.id_token, { expires });
      Cookie.set(AuthCookieEnum.USER_ID, userId, { expires });
      Cookie.set(AuthCookieEnum.TOKEN_TYPE, params.token_type, { expires });
    }
  } catch (e: Auth0Error | any) {
    endSession();
    throw Error(e);
  }
};

export { startSession, endSession };
