import React, { ForwardedRef, forwardRef } from 'react';
import { TextField as MatTextField, TextFieldProps } from '@mui/material';
import classNames from 'classnames';
import './TextField.component.scss';

const TextField = forwardRef((props: TextFieldProps, ref: ForwardedRef<unknown>) => {
  const { label, className, placeholder, ...rest } = props;
  const inputId = `${props.name}_id`;

  return (
    <>
      {label && (
        <label
          htmlFor={inputId}
          className={classNames(
            'data-hub-text-field-label',
            props.required ? 'data-hub-text-field-label--required' : '',
          )}
        >
          {label}
        </label>
      )}
      <MatTextField
        id={inputId}
        className={classNames(['data-hub-text-field', className])}
        inputRef={ref}
        {...rest}
        placeholder={placeholder}
      />
    </>
  );
});

export default TextField;
