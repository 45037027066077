import { useRequest } from '@datahub/api-client';
import { getDataSourcesQueryKey } from '../queryKeys';
import { ApiOperation } from '../../../api';
import { DataSourcesResponseType } from '../../../../types/data-source/DataSourceResponse.type';
import { TableFiltersInterface } from '../../../../views/components/table/interfaces/TableFilters.interface';
import usePaginatedListQuery from '../../pagination/usePaginatedList.query';

const useGetDataSourcesListQuery = (filters?: TableFiltersInterface) => {
  const { request } = useRequest<DataSourcesResponseType>(ApiOperation.GetDataSources);
  const { data, ...rest } = usePaginatedListQuery<DataSourcesResponseType>(
    request,
    getDataSourcesQueryKey,
    filters,
  );

  return {
    data: data?.responseModel,
    ...rest,
  };
};

export default useGetDataSourcesListQuery;
