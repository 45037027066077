import { Popover } from '@mui/material';
import React, { FC, ReactElement, useMemo, MouseEvent } from 'react';
import NotificationBellComponent from './components/notification-bell/NotificationBell.component';
import NotificationsContentView from './components/notifications-content/NotificationsContent.view';
import { PropsInterface } from '../../../interfaces/Props.interface';
import { useNotification } from '../../../services/notification/Notification.provider';

const NotificationsComponent: FC<PropsInterface> = ({ className }): ReactElement => {
  const { areNewNotificationsReceived, anchorPopoverEl, setAnchorPopoverEl } = useNotification();

  const onOpenNotificationsHandler = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorPopoverEl(event.currentTarget);
  };

  const onCloseNotificationsHandler = () => {
    setAnchorPopoverEl(null);
  };

  const isOpen = useMemo(() => Boolean(anchorPopoverEl), [anchorPopoverEl]);

  return (
    <>
      <NotificationBellComponent
        onClick={onOpenNotificationsHandler}
        newNotifications={areNewNotificationsReceived}
        className={className}
      />
      <Popover
        open={isOpen}
        anchorEl={anchorPopoverEl}
        onClose={onCloseNotificationsHandler}
        anchorOrigin={{
          vertical: 50,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 380,
        }}
      >
        <NotificationsContentView onClose={onCloseNotificationsHandler} />
      </Popover>
    </>
  );
};

export default NotificationsComponent;
