import React, { FC, ReactElement } from 'react';
import useGetInvoiceQuery from '../../../../../../../../../../services/react-query-hooks/queries/upload-hub/invoice/useGetInvoice.query';
import { VerifyInvoiceDataPropsInterface } from './interfaces/VerifyInvoiceDataProps.interface';
import VerifyInvoiceDataFormComponent from './components/verify-invoice-data-form/VerifyInvoiceDataForm.component';
import LoadingGridContainerComponent from '../../../../../../../../../components/loading/LoadingGridContainer.component';

const VerifyInvoiceDataComponent: FC<VerifyInvoiceDataPropsInterface> = ({
  id,
  onClose,
  variant,
}): ReactElement => {
  const { data: invoice, isLoading, isFetching } = useGetInvoiceQuery(id);

  if (isLoading || isFetching || !invoice) {
    return <LoadingGridContainerComponent />;
  }

  return <VerifyInvoiceDataFormComponent invoice={invoice} onClose={onClose} variant={variant} />;
};

export default VerifyInvoiceDataComponent;
