import React, { ReactElement } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { PopupState as PopupStateInterface } from 'material-ui-popup-state/hooks';
import { Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableActionsPropsInterface } from './interfaces/TableActionsProps.interface';
import { IconButton, MenuItem } from '../../../material';
import { TableActionInterface } from '../../interfaces/TableProps.interface';

const TableActionsComponent = <T,>({
  row,
  actions,
  onActionSelected,
}: TableActionsPropsInterface<T>): ReactElement => {
  const onMenuItemClickHandler = (
    action: TableActionInterface<T>,
    popupState: PopupStateInterface,
  ) => {
    popupState.close();
    onActionSelected(action);
  };
  const { t } = useTranslation();

  return (
    <PopupState variant="popover" popupId={`table-actions-menu_${row.id}`}>
      {(popupState: PopupStateInterface) => (
        <>
          <IconButton
            aria-label={t('table.actions')}
            {...bindTrigger(popupState)}
            onClick={(event) => {
              event.stopPropagation();
              const { onClick } = bindTrigger(popupState);
              onClick(event);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            {actions.map((action: TableActionInterface<T>) => (
              <MenuItem
                key={String(action.name)}
                onClick={(event) => {
                  onMenuItemClickHandler(action, popupState);
                  event.stopPropagation();
                }}
              >
                {action.icon}
                <span>{action.label}</span>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default TableActionsComponent;
