import React, { forwardRef } from 'react';
import { Alert as MuiAlert, Box } from '@mui/material';
import classnames from 'classnames';
import './Alert.component.scss';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ErrorIcon from '@mui/icons-material/Error';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AlertPropsInterface } from './interfaces/AlertProps.interface';

const Alert = forwardRef<HTMLDivElement, AlertPropsInterface>(
  ({ className, severity, variant, children, clickable, ...rest }, ref) => {
    let topIcon;

    switch (severity) {
      case 'warning': {
        topIcon = <WarningAmberRoundedIcon />;
        break;
      }
      case 'error': {
        topIcon = <ErrorIcon />;
        break;
      }
      case 'success': {
        topIcon = <GppGoodOutlinedIcon />;
        break;
      }
      case 'info': {
        topIcon = <InfoOutlinedIcon />;
        break;
      }

      default:
    }

    const alert = (
      <MuiAlert
        elevation={6}
        ref={ref}
        icon={topIcon}
        severity={severity}
        variant={variant}
        className={classnames(
          className,
          'data-hub-alert',
          `data-hub-alert--${severity}`,
          clickable ? 'data-hub-alert--clickable' : '',
        )}
        data-cy="alert"
        {...rest}
      >
        <Box className="data-hub-alert__accent" />

        <p className="data-hub-alert__content">{children}</p>
      </MuiAlert>
    );

    if (variant === 'outlined') {
      return (
        <div
          className={classnames(
            'data-hub-alert-gradient-box',
            `data-hub-alert-gradient-box--${severity}`,
          )}
        >
          {alert}
        </div>
      );
    }

    return alert;
  },
);

export { Alert };
