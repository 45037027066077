import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { FormGroup, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PasswordInputComponent from '../../../form/PasswordInput.component';
import EmailInputComponent from '../../../form/EmailInput.component';
import useAuth from '../../../../../../services/auth/Auth.provider';
import { LoginFormDataInterface } from './interfaces/LoginFormData.interface';
import { Button, FormControl } from '../../../../../components/material';
import { useEmailValidator } from '../../../form/validators/Email.validator';
import classes from './LoginForm.module.scss';
import { LoginLoadingEnum } from '../../../../../../services/auth/enums/LoginLoading.enum';

const LoginFormComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { login, loading, error } = useAuth();
  const { options } = useEmailValidator();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginFormDataInterface>();
  const [showAuthError, setShowAuthError] = useState<boolean>(false);

  const onSubmit = async (data: LoginFormDataInterface): Promise<void> => {
    setShowAuthError(true);

    return new Promise(() => {
      login(data.email, data.password);
    });
  };

  const email = useRef({});
  const password = useRef({});
  email.current = watch('email', '');
  password.current = watch('password', '');

  useEffect(() => {
    const subscription = watch(() => setShowAuthError(false));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes['login-form']}>
      <FormGroup>
        <Grid container direction="column" rowSpacing={4}>
          <Grid item>
            <FormControl>
              <EmailInputComponent error={!!errors.email} {...register('email', options)} />
              <span role="alert">{errors.email?.message}</span>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <PasswordInputComponent
                error={!!errors.password}
                placeholder={t('landingPage.loginForm.controls.password.placeholder')}
                {...register('password', {
                  required: t('landingPage.loginForm.controls.password.errors.required'),
                })}
              />
              <span role="alert">{errors.password?.message}</span>
              {showAuthError && <span role="alert">{error?.description}</span>}
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={8}>
                <Button
                  component={Link}
                  to="/reset-password"
                  variant="text"
                  className={classes['login-form__button']}
                  disabled={loading !== LoginLoadingEnum.NULL}
                >
                  {t('landingPage.loginForm.forgotPassword.button')}
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  type="submit"
                  loading={loading === LoginLoadingEnum.REGULAR}
                  fullWidth
                  className={classes['login-form__button']}
                  disabled={
                    loading !== LoginLoadingEnum.REGULAR && loading !== LoginLoadingEnum.NULL
                  }
                >
                  {t('landingPage.loginForm.login')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormGroup>
    </form>
  );
};

export default LoginFormComponent;
