import React, { Dispatch, FC, ReactElement, SetStateAction } from 'react';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InvoiceUploadComponent from './components/invoice-upload/InvoiceUpload.component';
import classes from './Invoices.module.scss';
import TabsComponent from '../../../components/tabs/Tabs.component';
import { TabInterface } from '../../../components/tabs/interfaces/Tab.interface';
import { PathEnum } from '../../../components/router/enums/Path.enum';
import { pathTo } from '../../../components/router/Router.outlet';
import useInvoiceHistory from './components/history/hooks/useInvoiceHistory.hook';
import { useUploadHub } from './providers/UploadHub.provider';
import LoadingComponent from '../../../components/loading/Loading.component';
import UploadHubWelcomeComponent from './components/welcome/UploadHubWelcome.component';
import useInvoiceDuplicates from './components/duplicates/hooks/useInvoiceDuplicates.hook';
import ProcessedPagesComponent from './components/processed-pages/ProcessedPages.component';
import NoPermissionsView from '../../../components/no-permissions/NoPermissions.view';

// TODO: update typing to invoice interface when data structure is ready ({ id: string })
export type InvoicesContextType = [
  { id: string } | undefined,
  Dispatch<SetStateAction<{ id: string } | undefined>>,
  Dispatch<SetStateAction<boolean>>,
  Dispatch<SetStateAction<string | undefined>>,
  Dispatch<SetStateAction<boolean>>,
];

const InvoicesComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { isLoading, isConfigured, isApprovalEnabled, hasPermissions, isProcessingEnabled } =
    useUploadHub();
  const location = useLocation();
  const { historyModal, historyInvoiceData, setIsHistoryModalOpen, setHistoryInvoiceData } =
    useInvoiceHistory();
  const { duplicatesModal, setIsDuplicatesModalOpen, setDuplicatesInvoiceDataId } =
    useInvoiceDuplicates();

  if (isLoading) {
    return (
      <div className={classes.invoices}>
        <LoadingComponent variant="contained" size="sm" />
      </div>
    );
  }

  if (!hasPermissions) {
    return <NoPermissionsView content={t('invoices.noPermissions.content')} />;
  }

  if (!isConfigured) {
    return <UploadHubWelcomeComponent />;
  }

  const invoicesTabs: TabInterface[] = [
    {
      name: 'inProgress',
      label: t('invoices.tabs.inProgress.label'),
      path: pathTo([PathEnum.INVOICES, PathEnum.IN_PROGRESS]),
      disabled: !isProcessingEnabled,
      hidden: !isProcessingEnabled,
    },
    {
      name: 'toVerify',
      label: t('invoices.tabs.toVerify.label'),
      path: pathTo([PathEnum.INVOICES, PathEnum.TO_VERIFY]),
      disabled: !isProcessingEnabled,
      hidden: !isProcessingEnabled,
    },
    {
      name: 'toApprove',
      label: t('invoices.tabs.toApprove.label'),
      path: pathTo([PathEnum.INVOICES, PathEnum.TO_APPROVE]),
      disabled: !isApprovalEnabled,
      hidden: !isApprovalEnabled,
    },
    {
      name: 'processed',
      label: t('invoices.tabs.processed.label'),
      path: pathTo([PathEnum.INVOICES, PathEnum.PROCESSED]),
    },
  ];

  const areInvoicesTabsLoaded =
    location.pathname.includes(`${PathEnum.INVOICES}/`) &&
    location.pathname !== pathTo([PathEnum.INVOICES]) &&
    location.pathname !== `${pathTo([PathEnum.INVOICES])}/`;

  return (
    <div className={classes.invoices}>
      {isProcessingEnabled && (
        <>
          <InvoiceUploadComponent className={classes.invoices__upload} />
          <ProcessedPagesComponent className={classes['invoices__processed-pages']} />
        </>
      )}

      {areInvoicesTabsLoaded && (
        <TabsComponent
          tabs={invoicesTabs}
          variant="fullWidth"
          size="lg"
          withRouting
          classNames={{
            root: classes.invoices__tabs,
          }}
        />
      )}

      <Outlet
        context={[
          historyInvoiceData,
          setHistoryInvoiceData,
          setIsHistoryModalOpen,
          setDuplicatesInvoiceDataId,
          setIsDuplicatesModalOpen,
        ]}
      />

      {historyModal}
      {duplicatesModal}
    </div>
  );
};

export default InvoicesComponent;

export const useInvoicesContext = () => useOutletContext<InvoicesContextType>();
