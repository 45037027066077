import React, { FC, ReactElement } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import { CloseButtonPropsInterface } from './interfaces/CloseButtonProps.interface';
import classes from './CloseButton.module.scss';

const CloseButtonComponent: FC<CloseButtonPropsInterface> = ({
  onClose,
  classNames,
}): ReactElement => {
  return (
    <div className={classnames(classes['close-button'], classNames?.root || '')}>
      <div
        className={classnames(classes['close-button__wrapper'], classNames?.wrapper || '')}
        role="button"
        onClick={onClose}
        onKeyDown={onClose}
        tabIndex={0}
        data-cy="close-button"
      >
        <CloseIcon className={classnames(classes['close-button__icon'], classNames?.icon)} />
      </div>
    </div>
  );
};

export default CloseButtonComponent;
