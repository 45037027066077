import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { UpdateUploadHubSettingsMutationPropsInterface } from './interfaces/UpdateUploadHubSettingsMutationProps.interface';
import { ApiOperation } from '../../../../../api';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { queryClient } from '../../../../../queryClient';
import { getUploadHubSettingsQueryKey } from '../../../../queries/queryKeys';
import { UpdateInvoiceReceiverPayloadInterface } from './interfaces/UpdateInvoiceReceiverPayload.interface';
import { useAlert } from '../../../../../alert/Alert.provider';

const useUpdateInvoiceReceiverMutation = ({
  setOtherError,
}: UpdateUploadHubSettingsMutationPropsInterface = {}) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateInvoiceReceiver);

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async (payload: UpdateInvoiceReceiverPayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return {
        data: res.data,
        payload,
      };
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getUploadHubSettingsQueryKey);

        showAlert(
          t('pluginSettings.sections.uploadHub.subsections.invoiceReceiver.success'),
          'success',
        );
      },
      onError,
    },
  );
};

export default useUpdateInvoiceReceiverMutation;
