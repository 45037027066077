import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { getDataSourcesForGeneralTypeQuery } from '../queryKeys';
import { ApiOperation } from '../../../api';
import { DataSourcesResponseType } from '../../../../types/data-source/DataSourceResponse.type';

const useGetDataSourcesForGeneralTypeListQuery = (origin: string | number) => {
  const { request } = useRequest<DataSourcesResponseType>(
    ApiOperation.GetDataSourcesForGeneralType,
  );

  return useQuery([getDataSourcesForGeneralTypeQuery, origin], async () => {
    const res = await request({ urlParams: { id: String(origin) } });

    return res.data.responseModel;
  });
};

export default useGetDataSourcesForGeneralTypeListQuery;
