import { useMutation } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { MarkAllNotificationsAsReadMutationPropsInterface } from './interfaces/MarkAllNotificationsAsReadMutationProps.interface';
import { ApiOperation } from '../../../api';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../error-handlers/useOnError';
import { queryClient } from '../../../queryClient';
import { getNotificationsQueryKey } from '../../queries/queryKeys';
import { useNotification } from '../../../notification/Notification.provider';

const useMarkAllNotificationsAsReadMutation = ({
  setOtherError,
}: MarkAllNotificationsAsReadMutationPropsInterface) => {
  const { request } = useRequest(ApiOperation.MarkAllNotificationsAsRead);
  const { markAllNewNotificationsAsRead } = useNotification();

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async () => {
      const res = await request();

      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getNotificationsQueryKey);
        markAllNewNotificationsAsRead();
      },
      onError,
    },
  );
};

export default useMarkAllNotificationsAsReadMutation;
