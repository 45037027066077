import React, { FC, ReactElement } from 'react';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classes from './BackButton.module.scss';
import { Button } from '../../../components/material';

const BackButtonComponent: FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Button variant="text" component={Link} to="/" className={classes['back-button']}>
      <KeyboardArrowLeft className={classes['back-button__icon']} />
      <span className={classes['back-button__label']}>{t('global.back')}</span>
    </Button>
  );
};

export default BackButtonComponent;
