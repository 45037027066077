import React, { FC, ReactElement } from 'react';
import { FormControl as MatFormControl, FormControlProps } from '@mui/material';
import './FormControl.component.scss';
import classNames from 'classnames';

const FormControl: FC<FormControlProps> = (props): ReactElement => {
  const { children, className, ...rest } = props;

  return (
    <MatFormControl fullWidth className={classNames('data-hub-form-control', className)} {...rest}>
      {children}
    </MatFormControl>
  );
};

export { FormControl };
