import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './TableEmpty.module.scss';
import { TableEmptyPropsInterface } from './interfaces/TableEmptyProps.interface';

const TableEmptyComponent: FC<TableEmptyPropsInterface> = ({ label }): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={classes['table-empty']}>
      <p className={classes['table-empty__content']}>{label || t('table.empty')}</p>
    </div>
  );
};

export default TableEmptyComponent;
