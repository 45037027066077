import { ResidentInvoiceDataEnum } from '../../../enums/upload-hub/ResidentInvoiceData.enum';

export const ResidentInvoiceDataTypes = Object.values(ResidentInvoiceDataEnum);

export type ResidentInvoiceDataType = (typeof ResidentInvoiceDataTypes)[number];

export const ResidentInvoiceDataTypeMap: {
  [key in ResidentInvoiceDataType]: string;
} = {
  [ResidentInvoiceDataEnum.INVOICE_REFERENCE]: 'invoiceReference',
  [ResidentInvoiceDataEnum.PROPERTY]: 'property',
  [ResidentInvoiceDataEnum.BANK_ACCOUNT]: 'bankAccount',
  [ResidentInvoiceDataEnum.INVOICE_DATE]: 'invoiceDate',
  [ResidentInvoiceDataEnum.SUPPLIER]: 'supplier',
  [ResidentInvoiceDataEnum.PAYMENT_DUE_DATE]: 'paymentDueDate',
  [ResidentInvoiceDataEnum.PERIOD_START]: 'periodStart',
  [ResidentInvoiceDataEnum.PERIOD_END]: 'periodEnd',
  [ResidentInvoiceDataEnum.WORKS_ORDER]: 'worksOrder',
  [ResidentInvoiceDataEnum.ITEM_DESCRIPTION]: 'itemDescription',
  [ResidentInvoiceDataEnum.ITEM_CATEGORY]: 'itemCategory',
  [ResidentInvoiceDataEnum.ITEM_AMOUNT]: 'itemAmount',
  [ResidentInvoiceDataEnum.ITEM_VAT]: 'itemVat',
  [ResidentInvoiceDataEnum.ITEM_TYPE]: 'itemType',
  [ResidentInvoiceDataEnum.ITEM_SCHEDULE]: 'itemSchedule',
  [ResidentInvoiceDataEnum.TOTAL_AMOUNT]: 'totalAmount',
};
