import { InvoiceStatusEnum } from '../../../enums/upload-hub/InvoiceStatus.enum';

export const InvoiceStatuses = Object.values(InvoiceStatusEnum);

export type InvoiceStatusType = (typeof InvoiceStatuses)[number];

export const InvoiceStatusMap: { [key in InvoiceStatusType]: string } = {
  [InvoiceStatusEnum.OCR_IN_PROGRESS]: 'ocrInProgress',
  [InvoiceStatusEnum.OCR_FAILED]: 'ocrFailed',
  [InvoiceStatusEnum.NO_OCR]: 'noOcr',
  [InvoiceStatusEnum.SUPPLIER_MATCHING_IN_PROGRESS]: 'supplierMatchingInProgress',
  [InvoiceStatusEnum.NO_SUPPLIER]: 'noSupplier',
  [InvoiceStatusEnum.MATCHING_IN_PROGRESS]: 'matchingInProgress',
  [InvoiceStatusEnum.NO_MATCHING]: 'noMatching',
  [InvoiceStatusEnum.NO_DATA]: 'noData',
  [InvoiceStatusEnum.DUPLICATES_DETECTION_IN_PROGRESS]: 'duplicatesDetectionInProgress',
  [InvoiceStatusEnum.DUPLICATES]: 'duplicates',
  [InvoiceStatusEnum.TO_APPROVE]: 'toApprove',
  [InvoiceStatusEnum.TO_BE_SEND_TO_RESIDENT]: 'toBeSendToResident',
  [InvoiceStatusEnum.SENT_TO_RESIDENT]: 'sentToResident',
  [InvoiceStatusEnum.NOT_SEND_TO_RESIDENT]: 'notSendToResident',
};
