import { OCRInvoiceFieldColorsMap } from '../types/upload-hub/invoice/OCRFieldColor.type';

const getFieldColor = (name: string): string => {
  if (!name) {
    return 'transparent';
  }

  let color = OCRInvoiceFieldColorsMap.get(name) || localStorage.getItem(`${name}Color`);

  if (!color) {
    color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    localStorage.setItem(`${name}Color`, color);
  }

  return color;
};

export { getFieldColor };
