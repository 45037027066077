import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';
import { useValidationErrorHandler } from '../useErrorHandler';
import RoleFormInterface from '../../../../views/home-page/components/settings/components/global-settings/components/role-permission/role-form/RoleForm.interface';

const useRoleUniqueNameErrorHandler = (setValidationError: UseFormSetError<RoleFormInterface>) => {
  const { t } = useTranslation();

  return useValidationErrorHandler({
    setError: setValidationError,
    errorCode: 'ROLE_ALREADY_EXIST',
    errorMessage: t('settings.sections.permission.subsections.role.controls.name.errors.duplicate'),
    field: 'roleName',
    options: { shouldFocus: true },
  });
};

export default useRoleUniqueNameErrorHandler;
