import { useTranslation } from 'react-i18next';
import CommonSetErrorType from '../../../CommonSetError.type';
import { useCommonErrorHandler } from '../../../useErrorHandler';

const useSynchronizedInboxCannotConnectErrorHandler = (setError?: CommonSetErrorType) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'CANNOT_CONNECT_TO_MAILBOX',
    errorMessage: t(
      'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.errors.cannotConnect',
    ),
  });

  return handler;
};

export default useSynchronizedInboxCannotConnectErrorHandler;
