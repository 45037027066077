import React, { FC, ReactElement } from 'react';
import classnames from 'classnames';
import { TabPanelPropsInterface } from './interfaces/TabPanelProps.interface';
import classes from './TabPanel.module.scss';

const TabPanel: FC<TabPanelPropsInterface> = ({
  children,
  value,
  index,
  className,
  ...other
}): ReactElement => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-panel-${index}`}
      {...other}
      className={classnames(classes['tab-panel'], className)}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
