import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useDeleteDataSourceMutation from '../../../../services/react-query-hooks/mutations/data-source/useDeleteDataSource.mutation';
import DeleteModalComponent from '../../../components/modal/delete-modal/DeleteModal.component';
import { DataSourceInterface } from '../../../../types/data-source/DataSource.interface';

interface DeleteDataSourcePropsInterface {
  dataSource: DataSourceInterface;
  close: () => void;
}

const DeleteDataSource: FC<DeleteDataSourcePropsInterface> = ({ dataSource, close }) => {
  const { t } = useTranslation();

  const { mutate: deleteDataSource, isLoading, isSuccess } = useDeleteDataSourceMutation();

  return (
    <DeleteModalComponent
      text={t(
        'dataSourcesSettings.sections.dataSources.subsections.supplierList.deleteDataSourceModal.message',
      )}
      close={close}
      isLoading={isLoading}
      isSuccess={isSuccess}
      mutate={() => deleteDataSource(dataSource)}
    />
  );
};

export default DeleteDataSource;
