import { useTranslation } from 'react-i18next';
import CommonSetErrorType from '../../../../CommonSetError.type';
import { useCommonErrorHandler } from '../../../../useErrorHandler';

const useNylasNotValidAuthorizationCodeErrorHandler = (setError?: CommonSetErrorType) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'NYLAS_NOT_VALID_AUTHORIZATION_CODE',
    errorMessage: t(
      'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.errors.nylasNotValidAuthCode',
    ),
  });

  return handler;
};

export default useNylasNotValidAuthorizationCodeErrorHandler;
