export enum InvoiceFieldValueTypeEnum {
  STRING,
  DATE,
  TIME,
  PHONE_NUMBER,
  FLOAT,
  INT_64,
  LIST,
  DICTIONARY,
  SELECTION_MARK,
  COUNTRY_REGION,
}
