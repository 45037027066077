import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { InvoiceMutationPropsInterface } from '../InvoiceMutationProps.interface';
import { ApiOperation } from '../../../../../api';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { queryClient } from '../../../../../queryClient';
import {
  getProcessedInvoicesQueryKey,
  getToApproveInvoicesQueryKey,
  getToVerifyInvoicesQueryKey,
} from '../../../../queries/queryKeys';
import { useAlert } from '../../../../../alert/Alert.provider';
import { SkipInvoiceDuplicatesPayloadInterface } from './interfaces/SkipInvoiceDuplicatesPayload.interface';
import { skipInvoiceDuplicatesMutationKey } from '../../../mutationKeys';

const useSkipInvoiceDuplicatesMutation = ({ setOtherError }: InvoiceMutationPropsInterface) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.SkipInvoiceDuplicates);

  // TODO: handle other errors if needed
  // const uniqueNameErrorHandler = useRoleUniqueNameErrorHandler(setValidationError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async ({ payload, skipApprovalFlow }: SkipInvoiceDuplicatesPayloadInterface) => {
      const res = await request({
        urlParams: { id: payload.invoiceId, skipApprovalFlow: String(skipApprovalFlow) },
      });

      return res.data;
    },
    {
      mutationKey: skipInvoiceDuplicatesMutationKey,
      onSuccess: (_, { invoiceReference: name }: SkipInvoiceDuplicatesPayloadInterface) => {
        queryClient.invalidateQueries(getToVerifyInvoicesQueryKey);
        queryClient.invalidateQueries(getToApproveInvoicesQueryKey);
        queryClient.invalidateQueries(getProcessedInvoicesQueryKey);

        showAlert(
          <Trans
            i18nKey="invoices.duplicates.skip.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useSkipInvoiceDuplicatesMutation;
