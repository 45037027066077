import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';
import { useValidationErrorHandler } from '../useErrorHandler';
import UserFormInterface from '../../../../views/home-page/components/settings/components/global-settings/components/user-permission/components/user-form/UserForm.interface';

const useUserUniqueEmailErrorHandler = (setValidationError: UseFormSetError<UserFormInterface>) => {
  const { t } = useTranslation();

  return useValidationErrorHandler({
    setError: setValidationError,
    errorCode: 'USER_WITH_GIVER_EMAIL_ALREADY_EXIST',
    errorMessage: t(
      'settings.sections.permission.subsections.user.controls.email.errors.duplicate',
    ),
    field: 'email',
    options: { shouldFocus: true },
  });
};

export default useUserUniqueEmailErrorHandler;
