import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import useAddRoleMutation from '../../../../../../../../../services/react-query-hooks/mutations/role/useAddRole.mutation';
import RolePayloadInterface from '../../../../../../../../../types/roles/RolePayload.interface';
import FormModalComponent from '../../../../../../../../components/modal/form-modal/FormModal.component';
import RoleFormComponent from '../role-form/RoleForm.component';
import RoleFormInterface, { defaultValues } from '../role-form/RoleForm.interface';

interface AddRolePropsInterface {
  close: () => void;
}

const AddRole: FC<AddRolePropsInterface> = ({ close }) => {
  const methods = useForm<RoleFormInterface>({
    mode: 'onChange',
    defaultValues,
  });
  const {
    mutate: addRole,
    isLoading,
    isSuccess,
  } = useAddRoleMutation({
    setValidationError: methods.setError,
  });

  const onSubmitHandler = (data: RolePayloadInterface): void => addRole(data);

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmitHandler)}
      isSuccess={isSuccess}
      isLoading={isLoading}
    >
      <RoleFormComponent />
    </FormModalComponent>
  );
};

export default AddRole;
