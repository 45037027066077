export enum InvoiceErrorCodeEnum {
  PROPERTY_MISSING = 'PROPERTY_MISSING',
  BLOCK_ACCOUNT_MISSING = 'BLOCK_ACCOUNT_MISSING',
  TOTAL_AMOUNT_MISSING = 'TOTAL_AMOUNT_MISSING',
  TOTAL_AMOUNT_THRESHOLD_EXCEEDED = 'CANNOT_SKIP_APPROVAL_AMOUNT_HIGHER_THAN_THRESHOLD',
  DATE_MISSING = 'DATE_MISSING',
  CATEGORY_MISSING = 'CATEGORY_MISSING',
  SCHEDULE_MISSING = 'SCHEDULE_MISSING',
  AMOUNT_MISSING = 'AMOUNT_MISSING',
  INCORRECT_PROPERTY = 'INCORRECT_PROPERTY',
  INCORRECT_BLOCK_ACCOUNT = 'INCORRECT_BLOCK_ACCOUNT',
  INCORRECT_TOTAL_AMOUNT = 'INCORRECT_TOTAL_AMOUNT',
  INCORRECT_INVOICE_DATE = 'INCORRECT_INVOICE_DATE',
  INCORRECT_WORK_ORDER = 'INCORRECT_WORK_ORDER',
  INCORRECT_PAYMENT_DUE_DATE = 'INCORRECT_PAYMENT_DUE_DATE',
  INCORRECT_PERIOD_START = 'INCORRECT_PERIOD_START',
  INCORRECT_PERIOD_END = 'INCORRECT_PERIOD_END',
  INCORRECT_CATEGORY = 'INCORRECT_CATEGORY',
  INCORRECT_SCHEDULE = 'INCORRECT_SCHEDULE',
  INCORRECT_AMOUNT = 'INCORRECT_AMOUNT',
}
