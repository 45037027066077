import React, { FC, ReactElement, useState } from 'react';
import classNames from 'classnames';
import FormContainerComponent from '../../../../../components/form-container/FormContainer.component';
import useAuth from '../../../../../../services/auth/Auth.provider';
import { ForgotPasswordFormDataInterface } from './interfaces/ForgotPasswordFormData.interface';
import ResetPasswordConfirmationComponent from './components/reset-password-confirmation/ResetPasswordConfirmation.component';
import ResetPasswordComponent from './components/reset-password/ResetPassword.component';
import classes from '../../../Landing-container.module.scss';

const ForgotPasswordFormComponent: FC = (): ReactElement => {
  const { resetPassword, resetPasswordSent } = useAuth();
  const [email, setEmail] = useState('');

  const onSubmit = async (data: ForgotPasswordFormDataInterface): Promise<void> => {
    setEmail(() => data.email);

    return new Promise(() => {
      resetPassword(data.email);
    });
  };

  return (
    <FormContainerComponent
      className={classNames(
        classes['landing-container'],
        classes['landing-container--single-column'],
      )}
    >
      {resetPasswordSent && email ? (
        <ResetPasswordConfirmationComponent email={email} onSubmit={onSubmit} />
      ) : (
        <ResetPasswordComponent onSubmit={onSubmit} />
      )}
    </FormContainerComponent>
  );
};

export default ForgotPasswordFormComponent;
