import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../components/modal/types/Props.interface';
import DeleteDataSourceModal from './DeleteDataSource.view';
import { DataSourceInterface } from '../../../../types/data-source/DataSource.interface';

interface DeleteDataSourceModalComponentPropsInterface extends ModalControlPropsInterface {
  dataSource: DataSourceInterface | undefined;
}

const DeleteDataSourceModalComponent = ({
  dataSource,
  open,
  setOpen,
}: DeleteDataSourceModalComponentPropsInterface) => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t(
        'dataSourcesSettings.sections.dataSources.subsections.supplierList.deleteDataSourceModal.title',
      )}
      open={open}
      handleClose={handleClose}
      variant="warning"
      content={dataSource && <DeleteDataSourceModal dataSource={dataSource} close={handleClose} />}
    />
  );
};

export default DeleteDataSourceModalComponent;
