import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RejectInvoiceModalPropsInterface } from './interfaces/RejectInvoiceModalProps.interface';
import ModalComponent from '../../../../../components/modal/modal/Modal.component';
import RejectInvoice from './RejectInvoice.view';
import { InvoiceRejectPayloadInterface } from '../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/reject/interfaces/InvoiceRejectPayload.interface';

const RejectInvoiceModalComponent: FC<RejectInvoiceModalPropsInterface> = ({
  open,
  setOpen,
  onReject,
  variant,
  note,
}): ReactElement => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  const onRejectHandler = (payload?: InvoiceRejectPayloadInterface): void => {
    handleClose();
    onReject(payload);
  };

  return (
    <ModalComponent
      title={t(`invoices.rejectInvoice${variant === 'reject' ? '' : 'ToAmend'}.title`)}
      open={open}
      handleClose={handleClose}
      content={
        <RejectInvoice
          close={handleClose}
          onReject={onRejectHandler}
          variant={variant}
          note={note}
        />
      }
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
      variant="warning"
      closeOnlyOnButton
    />
  );
};

export default RejectInvoiceModalComponent;
