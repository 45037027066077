import React, {
  FC,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { TabsPropsInterface } from './interfaces/TabsProps.interface';
import { TabInterface } from './interfaces/Tab.interface';
import classes from './Tabs.module.scss';
import './Tabs.component.scss';
import TabPanel from './components/tab-panel/TabPanel.component';
import primaryTheme from '../../../themes/primary-theme';

const TabsComponent: FC<TabsPropsInterface> = ({
  tabs,
  variant = 'scrollable',
  size = 'md',
  cards,
  withRouting,
  classNames,
  toolbar,
  color = 'primary',
  uppercase,
  initTabIndex,
  onChangeTab,
  onClickTab,
}): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(initTabIndex || 0);

  useEffect(() => {
    if (initTabIndex && initTabIndex !== value) {
      setValue(initTabIndex);
    }
  }, [initTabIndex, value]);

  const onChangeTabHandler = useCallback(
    (event: SyntheticEvent, tabValue: number | string) => {
      if (withRouting) {
        navigate(String(tabValue));

        if (onChangeTab) {
          onChangeTab(String(tabValue));
        }
      } else if (Number.isInteger(tabValue)) {
        setValue(Number(tabValue));

        if (onChangeTab) {
          onChangeTab(Number(tabValue));
        }
      }
    },
    [navigate, onChangeTab, withRouting],
  );

  const selectedTabValue = withRouting ? location.pathname : value;
  const tabValue = useCallback(
    (tab: TabInterface, index: number) => {
      if (withRouting && tab.path) {
        return tab.path;
      }

      return index;
    },
    [withRouting],
  );

  const onClickTabHandler = useCallback(
    (tab: TabInterface, index: number) => {
      if (onClickTab) {
        onClickTab(tabValue(tab, index));
      }
    },
    [onClickTab, tabValue],
  );

  const isSmallScreen = !useMediaQuery(primaryTheme.breakpoints.up('md'));

  const tabsContent = useMemo(
    () => (
      <Tabs
        value={selectedTabValue}
        onChange={onChangeTabHandler}
        variant={isSmallScreen ? 'scrollable' : variant}
        allowScrollButtonsMobile
        aria-label="tabs"
        className={classnames(
          'data-hub-tabs',
          cards ? 'data-hub-tabs--cards' : '',
          color !== undefined ? `data-hub-tabs--color-${color}` : '',
        )}
      >
        {tabs
          .filter((tab: TabInterface): boolean => !tab.hidden)
          .map((tab: TabInterface, index: number) => (
            <Tab
              className={classnames(
                classes['MuiTab-root'],
                tabValue(tab, index) === selectedTabValue ? classes['Mui-selected'] : '',
                tab.disabled ? classes['Mui-disabled'] : '',
              )}
              label={tab.label}
              key={tab.name}
              value={tabValue(tab, index)}
              id={`tab-${index}`}
              data-cy={`tab-${tab.name}`}
              aria-controls={`tab-${index}`}
              wrapped
              disabled={tab.disabled}
              onClick={() => onClickTabHandler(tab, index)}
            />
          ))}
      </Tabs>
    ),
    [
      selectedTabValue,
      onChangeTabHandler,
      onClickTabHandler,
      isSmallScreen,
      variant,
      cards,
      color,
      tabs,
      tabValue,
    ],
  );

  return (
    <Box
      className={classnames(
        classes.tabs,
        classes[`tabs--${size}`],
        cards ? classes['tabs--cards'] : '',
        classNames ? classNames.root : '',
        color !== undefined ? classes[`tabs--color-${color}`] : '',
        uppercase ? classes['tabs--uppercase'] : '',
      )}
    >
      <div className={classnames(classes.tabs__content, classNames ? classNames.content : '')}>
        {tabsContent}

        {toolbar && <div className={classes.tabs__toolbar}>{toolbar}</div>}
      </div>

      {!withRouting &&
        tabs.map((tab: TabInterface, index: number) => (
          <TabPanel
            value={value}
            key={tab.name}
            index={index}
            className={classNames ? classNames.panel : ''}
          >
            {tab.element || tab.label}
          </TabPanel>
        ))}
    </Box>
  );
};

export default TabsComponent;
