import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteInvoicePropsInterface } from '../interfaces/DeleteInvoiceProps.interface';
import useDeleteInvoiceMutation from '../../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/useDeleteInvoice.mutation';
import DeleteModalComponent from '../../../../../../components/modal/delete-modal/DeleteModal.component';

const DeleteInvoiceView: FC<DeleteInvoicePropsInterface> = ({
  invoice,
  close,
  reloadQuery,
  onMutate,
}) => {
  const { t } = useTranslation();

  const {
    mutate: deleteInvoice,
    mutateAsync: deleteInvoiceAsync,
    isLoading,
    isSuccess,
  } = useDeleteInvoiceMutation(reloadQuery, {});

  const onMutateHandler = (): void => {
    if (onMutate) {
      onMutate(deleteInvoiceAsync(invoice));
    } else {
      deleteInvoice(invoice);
    }
  };

  return (
    <DeleteModalComponent
      text={t('invoices.deleteInvoice.message')}
      close={close}
      isLoading={isLoading}
      isSuccess={isSuccess}
      mutate={onMutateHandler}
    />
  );
};

export default DeleteInvoiceView;
