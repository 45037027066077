import { Grid } from '@mui/material';
import React, { FC, memo, ReactElement, useMemo } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '../../../../../../../components/material';
import classes from './RolePermission.module.scss';
import useGetPermissionsListQuery from '../../../../../../../../services/react-query-hooks/queries/permission/useGetPermissionsList.query';
import useUpdateRoleMutation from '../../../../../../../../services/react-query-hooks/mutations/role/useUpdateRoleMutation';
import { PermissionInterface } from '../../../../../../../../types/permission/Permission.interface';
import { GlobalSettingsSectionPropsInterface } from '../../interfaces/GlobalSettingsSectionProps.interface';
import TableComponent from '../../../../../../../components/table/Table.component';
import { TableColumnInterface } from '../../../../../../../components/table/interfaces/TableColumn.interface';
import { RoleInterface } from '../../../../../../../../types/roles/Role.interface';
import { TableRowInterface } from '../../../../../../../components/table/interfaces/TableRow.interface';
import useTableFilters from '../../../../../../../components/table/hooks/TableFilters.hook';
import useGetRolesListQuery from '../../../../../../../../services/react-query-hooks/queries/role/useGetRolesListQuery';
import { TableColumnTypeEnum } from '../../../../../../../components/table/enums/TableColumnType.enum';
import { AlignEnum } from '../../../../../../../../enums/Align.enum';
import { TableActionNameEnum } from '../../../../../../../components/table/enums/TableActionName.enum';
import useAddRole from './add-role/hooks/useAddRole.hook';
import useDeleteRole from './delete-role/hooks/useDeleteRole.hook';
import { queryClient } from '../../../../../../../../services/queryClient';
import { updateRoleMutationKey } from '../../../../../../../../services/react-query-hooks/mutations/mutationKeys';

const mapPermissionToColumn = (permission: PermissionInterface): TableColumnInterface => ({
  name: `permission_${permission.permissionId}`,
  type: TableColumnTypeEnum.CHECKBOX,
  label: permission.permissionName,
});

const rolePermissionColumns = (
  permissions: PermissionInterface[] | undefined,
): TableColumnInterface[] => {
  const permissionColumns = permissions ? permissions.map(mapPermissionToColumn) : [];

  return [
    {
      name: 'name',
      label: 'Role name',
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '30%',
    },
    {
      name: 'loading',
      type: TableColumnTypeEnum.LOADING,
      align: AlignEnum.RIGHT,
      width: '6rem',
    },
    ...permissionColumns,
    {
      name: 'delete',
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.DEFAULT,
      width: '4rem',
    },
  ];
};

const isPermissionSelected = (role: RoleInterface, permission: PermissionInterface): boolean => {
  return role.permissions.some(
    (rolePermission: PermissionInterface) =>
      rolePermission.permissionId === permission.permissionId,
  );
};

const RolePermissionComponent: FC<GlobalSettingsSectionPropsInterface> = ({
  getRole,
}): ReactElement => {
  const { filters, dispatchFilters } = useTableFilters();
  const { data: roles, pagination } = useGetRolesListQuery(filters);
  const { data: permissions } = useGetPermissionsListQuery();
  const { t } = useTranslation();

  const { addRoleModal, setAddRoleOpen } = useAddRole();
  const { deleteRoleModal, setDeleteRoleOpen, setDeleteRoleData } = useDeleteRole();
  const { mutate: updateRole } = useUpdateRoleMutation();

  const mapRoleToRow = (role: RoleInterface): TableRowInterface => {
    const initialRole = {
      id: role.roleNumber,
      name: role.name,
      delete: (
        <IconButton
          size="large"
          aria-label={role.name}
          onClick={() => {
            setDeleteRoleData(role);
            setDeleteRoleOpen(true);
          }}
        >
          <CloseIcon />
        </IconButton>
      ),
      loading:
        queryClient.isMutating({
          mutationKey: updateRoleMutationKey,
          predicate: (m) => m.options.variables?.id === role.roleNumber,
        }) > 0,
    };
    const mapRoleObject = (acc: TableRowInterface, permission: PermissionInterface) => {
      acc[`permission_${permission.permissionId}`] = isPermissionSelected(role, permission);
      return acc;
    };

    return permissions ? permissions.reduce(mapRoleObject, initialRole) : initialRole;
  };

  const rolePermissionRows = () => {
    return roles ? roles.map(mapRoleToRow) : [];
  };

  const updateRolePermission = (
    role: RoleInterface | undefined,
    permissionId: number,
    action: 'add' | 'remove',
  ) => {
    const permission = permissions?.find(
      (p: PermissionInterface) => p.permissionId === permissionId,
    );

    if (role && permission) {
      let permissionIds = role.permissions.map((p: PermissionInterface) => p.permissionId);

      if (action === 'add') {
        permissionIds.push(permissionId);
      } else if (action === 'remove') {
        permissionIds = permissionIds.filter((pId: number) => pId !== permissionId);
      }

      updateRole({
        id: role.roleNumber,
        payload: {
          permissionIds,
          roleName: role.name,
        },
        permission,
        action,
      });
    }
  };

  const onActionSelectedHandler = (
    actionName: object | TableActionNameEnum,
    rowId: string[] | number[] | string | number,
    columnName?: string | number,
    state?: boolean,
  ) => {
    const isMultiple = Array.isArray(rowId);

    switch (actionName) {
      case TableActionNameEnum.CHECKBOX: {
        if (!isMultiple && columnName) {
          updateRolePermission(
            getRole(rowId),
            Number(String(columnName).split('_')[1]),
            state ? 'add' : 'remove',
          );
        }

        break;
      }

      default:
    }
  };

  const toolbarContent = useMemo(
    () => (
      <Button
        startIcon={<AddOutlinedIcon />}
        onClick={() => setAddRoleOpen(true)}
        variant="outlined"
      >
        {t('settings.sections.permission.subsections.role.addModal.button')}
      </Button>
    ),
    [setAddRoleOpen, t],
  );

  return (
    <Grid className={classes.roles}>
      <TableComponent
        table={{
          rows: rolePermissionRows(),
          columns: rolePermissionColumns(permissions),
        }}
        onActionSelected={onActionSelectedHandler}
        className={classes.roles__table}
        filters={filters}
        onChangeFilters={dispatchFilters}
        toolbar={toolbarContent}
        pagination={pagination}
      />

      {addRoleModal}
      {deleteRoleModal}
    </Grid>
  );
};

const RolePermission = memo(RolePermissionComponent);

export default RolePermission;
