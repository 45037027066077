import React, { FC, ReactElement } from 'react';
import classnames from 'classnames';
import classes from './Header.module.scss';
import PrivateLogoComponent from './components/logo/PrivateLogo.component';
import UserProfileMenuComponent from './components/user-profile/UserProfileMenu.component';
import ContainerComponent from '../../layout/Container.component';
import { PropsInterface } from '../../../interfaces/Props.interface';
import NavigationMenu from '../navigation-menu/NavigationMenu.component';
import NotificationsComponent from '../notifications/Notifications.component';
import { useSubscription } from '../../../services/subscription/Subscription.provider';
import PublicLogoComponent from './components/logo/PublicLogo.component';
import { ReactComponent as LogoDataHubWhite } from '../../../assets/logo/logoDataHubWhite.svg';

const HeaderComponent: FC<PropsInterface> = ({ className }): ReactElement => {
  const { configuration, isLoading } = useSubscription();

  return (
    <header className={classnames(classes.header, className)}>
      <ContainerComponent className={classes.header__container}>
        {configuration?.logoUrl ? (
          <PrivateLogoComponent />
        ) : (
          !isLoading && (
            <PublicLogoComponent
              svgElement={<LogoDataHubWhite className={classes.container__logo} />}
            />
          )
        )}
        <div className={classes.actions__container}>
          <UserProfileMenuComponent className={classes['container__user-profile']} />
          <NavigationMenu className={classes.container__menu} />
          <NotificationsComponent className={classes.container__notifications} />
        </div>
      </ContainerComponent>
    </header>
  );
};

export default HeaderComponent;
