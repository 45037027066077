import React, { FC, ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import classes from './Loading.module.scss';
import { SizeType } from '../../../types/Size.type';

export type LoadingVariantType = 'full' | 'relative' | 'contained';

export interface LazyLoaderProps {
  variant?: LoadingVariantType;
  size?: SizeType;
  delay?: number;
  overlay?: boolean;
}

const LoadingComponent: FC<LazyLoaderProps> = ({
  variant = 'full',
  size = 'md',
  delay = 200,
  overlay,
}): ReactElement | null => {
  const [show, setShow] = useState(delay === 0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? (
    <div
      className={classNames(
        classes.loading,
        overlay ? classes['loading--overlay'] : classes[`loading--${variant}`],
      )}
    >
      <div className={classes.loading__container}>
        <div
          className={classNames(classes.container__loader, classes[`container__loader--${size}`])}
        >
          <div className={classes.loader__cube} />
          <div className={classNames(classes.loader__cube, classes['loader__cube--2'])} />
          <div className={classNames(classes.loader__cube, classes['loader__cube--3'])} />
          <div className={classNames(classes.loader__cube, classes['loader__cube--4'])} />
        </div>
      </div>
    </div>
  ) : null;
};

export default LoadingComponent;
