import { SimpleSmtpSynchronizedInboxInterface } from '../../../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';

export default interface SynchronizedInboxesFormInterface
  extends Omit<SimpleSmtpSynchronizedInboxInterface, 'imapPort'> {
  imapPort: number | '';
}

export const defaultValues: SynchronizedInboxesFormInterface = {
  name: '',
  email: '',
  password: '',
  imapHost: '',
  imapPort: '',
  autoSync: false,
};
