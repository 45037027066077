import React, { Dispatch, FC, ReactElement, SetStateAction, useState } from 'react';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import classes from './LandingPage.module.scss';
import RowComponent from '../layout/Row.component';
import ColumnComponent from '../layout/Column.component';
import ContainerComponent from '../layout/Container.component';
import { AlignEnum } from '../../enums/Align.enum';
import PublicLogoComponent from '../components/header/components/logo/PublicLogo.component';
import LandingHeaderComponent from './components/landing-header/LandingHeader.component';
import BackButtonComponent from './components/back-button/BackButton.component';
import LoadingComponent from '../components/loading/Loading.component';
import { pathTo } from '../components/router/Router.outlet';
import { PathEnum } from '../components/router/enums/Path.enum';

type LandingPageContextType = [boolean, Dispatch<SetStateAction<boolean>>];

const LandingPageView: FC = (): ReactElement => {
  const [isLandingPageLoading, setIsLoadingPageLoading] = useState<boolean>(false);

  const { pathname } = useLocation();
  const isBackButtonActive = pathTo([PathEnum.RESET_PASS]) === pathname;

  return (
    <>
      {isLandingPageLoading && <LoadingComponent overlay delay={0} />}
      <div className={classes['landing-page']}>
        <RowComponent className="row--no-gutter">
          <ColumnComponent md="12" lg="8">
            <ContainerComponent className={classes['landing-page__container']}>
              <RowComponent align={AlignEnum.CENTER}>
                <ColumnComponent xs="12" xl="12" className={classes.container__column}>
                  <div className={classes.column__navigation}>
                    {isBackButtonActive ? <BackButtonComponent /> : ''}
                  </div>
                  <LandingHeaderComponent />
                  <Outlet context={[isLandingPageLoading, setIsLoadingPageLoading]} />
                </ColumnComponent>
              </RowComponent>
            </ContainerComponent>
          </ColumnComponent>

          <ColumnComponent lg="4" className="d-none d-lg-flex d-lg-block flex--auto">
            <div className={classes['landing-page__brand']}>
              <div className={classes['brand__logo-container']}>
                <PublicLogoComponent />
              </div>
            </div>
          </ColumnComponent>
        </RowComponent>
      </div>
    </>
  );
};

export default LandingPageView;

export const useLandingPageContext = () => useOutletContext<LandingPageContextType>();
