import React, { FC, ReactElement, useCallback } from 'react';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Divider, Menu } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { PopupState as PopupStateInterface } from 'material-ui-popup-state/hooks';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './UserProfileMenu.module.scss';
import { Button, MenuItem } from '../../../material';
import useAuth from '../../../../../services/auth/Auth.provider';
import { PropsInterface } from '../../../../../interfaces/Props.interface';
import SettingsMenu from '../../../settings-menu/SettingsMenu.component';
import { useSubscription } from '../../../../../services/subscription/Subscription.provider';
import { useUserPermissions } from '../../../../../services/user/User.provider';
import { PermissionTypeEnum } from '../../../../../enums/permission/PermissionType.enum';

const UserProfileMenuComponent: FC<PropsInterface> = ({ className }): ReactElement => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasPlugins, pluginsTypes } = useSubscription();
  const { hasPermission } = useUserPermissions();

  const hasAccessToAnyPlugin =
    [PermissionTypeEnum.ADMIN, PermissionTypeEnum.UPLOAD_HUB_ADMIN].some(hasPermission) &&
    pluginsTypes.length > 0;

  const onLogout = useCallback(
    (popupState: PopupStateInterface): void => {
      popupState?.close();
      logout();
    },
    [logout],
  );

  const navigateToMenuItemHandler = useCallback(
    (href: string, popupState: PopupStateInterface | undefined): void => {
      popupState?.close();
      navigate(href);
    },
    [navigate],
  );

  const profilePopupContent = useCallback(
    (popupState: PopupStateInterface) => (
      <>
        <Button variant="menu" disableRipple {...bindTrigger(popupState)}>
          <img src={user?.picture} alt="User" className={classes['user-profile-menu__image']} />
          <span data-cy="nickname">{user?.nickname}</span>
        </Button>
        <Menu {...bindMenu(popupState)} className={classes['user-profile-menu__menu']}>
          {hasPlugins && hasAccessToAnyPlugin && (
            <SettingsMenu navigateToMenuItem={navigateToMenuItemHandler} popupState={popupState} />
          )}

          {hasPlugins && hasAccessToAnyPlugin && (
            <Divider className={classnames([classes.menu__divider, classes['MuiDivider-root']])} />
          )}

          <MenuItem onClick={() => onLogout(popupState)}>
            <Logout />
            <span>{t('userProfile.logout')}</span>
          </MenuItem>
        </Menu>
      </>
    ),
    [
      user?.picture,
      user?.nickname,
      hasPlugins,
      hasAccessToAnyPlugin,
      navigateToMenuItemHandler,
      t,
      onLogout,
    ],
  );

  return (
    <div className={classnames(classes['user-profile-menu'], className)}>
      <PopupState variant="popover" popupId="user-profile-popup-menu">
        {profilePopupContent}
      </PopupState>
    </div>
  );
};

export default UserProfileMenuComponent;
