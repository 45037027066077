import React, { FC, ReactElement } from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { useTranslation } from 'react-i18next';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { Logout } from '@mui/icons-material';
import classes from './NoPermissions.module.scss';
import { NoPermissionsPropsInterface } from './interfaces/NoPermissionsProps.interface';
import useAuth from '../../../services/auth/Auth.provider';
import { Button } from '../material';

const NoPermissionsView: FC<NoPermissionsPropsInterface> = ({
  title,
  content,
  actions,
}): ReactElement => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  const reload = (): void => {
    window.location.reload();
  };

  return (
    <div className={classes['no-permissions']}>
      <WarningAmberRoundedIcon className={classes['no-permissions__icon']} />
      <h4 className={classes['no-permissions__header']}>
        {title || t('permission.noPermissions.header')}
      </h4>
      <p className={classes['no-permissions__content']}>
        {content || t('permission.noPermissions.content')}
      </p>

      {actions && (
        <div className={classes['no-permissions__buttons-container']}>
          <Button
            variant="outlined"
            startIcon={<RefreshOutlinedIcon />}
            className={classes['buttons-container__button']}
            onClick={reload}
          >
            {t('subscription.noSubscription.modal.buttons.reload')}
          </Button>

          <Button
            variant="text"
            startIcon={<Logout />}
            className={classes['buttons-container__button']}
            onClick={logout}
          >
            {t('subscription.noSubscription.modal.buttons.logout')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NoPermissionsView;
