import React, { FC, ReactElement, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import classNames from 'classnames';
import { Button } from '../../../../../components/material';
import classes from './InvoiceUpload.module.scss';
import useUploadInvoicesHook from './hooks/useUploadInvoicesHook';
import { PropsInterface } from '../../../../../../interfaces/Props.interface';
import { FileMimeTypeEnum } from '../../../../../components/file-preview/enums/FileMimeTypeEnum';

const InvoiceUploadComponent: FC<PropsInterface> = ({ className }): ReactElement => {
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      [FileMimeTypeEnum.PDF]: ['.pdf', '.PDF'],
      [FileMimeTypeEnum.JPEG]: ['.jpg', '.JPEG'],
      [FileMimeTypeEnum.PNG]: ['.png', '.PNG'],
      [FileMimeTypeEnum.GIF]: ['.gif', '.GIF'],
      [FileMimeTypeEnum.DOC]: ['.doc', '.DOC'],
      [FileMimeTypeEnum.DOCX]: ['.docx', '.DOCX'],
    },
  });
  const { modals, upload } = useUploadInvoicesHook();

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      upload(acceptedFiles);
    }
  }, [acceptedFiles, upload]);

  return (
    <section className={classNames(classes.container, className)}>
      <div {...getRootProps({ className: classes.root })}>
        <input {...getInputProps({ className: classes.input })} />
        <Button
          onClick={open}
          variant="contained"
          className={classes.button}
          startIcon={<UploadOutlinedIcon />}
        >
          {t('invoices.invoiceUpload.button')}
        </Button>
        <p className={classes.subtitle}>{t('invoices.invoiceUpload.dragAndDrop')}</p>
      </div>

      {modals}
    </section>
  );
};

export default InvoiceUploadComponent;
