import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { UserMutationWithValidationPropsInterface } from './interfaces/UserMutationProps.interface';
import { ApiOperation } from '../../../api';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../error-handlers/useOnError';
import { queryClient } from '../../../queryClient';
import { getUsersQueryKey } from '../../queries/queryKeys';
import UserPayloadInterface from '../../../../types/users/UserPayload.interface';
import useUserUniqueEmailErrorHandler from '../../error-handlers/user/useUserUniqueEmailError.handler';
import useIncorrectEmailErrorHandler from '../../error-handlers/common/useIncorrectEmailError.handler';
import UserFormInterface from '../../../../views/home-page/components/settings/components/global-settings/components/user-permission/components/user-form/UserForm.interface';
import { useAlert } from '../../../alert/Alert.provider';

const useAddUserMutation = (
  { setValidationError = () => {}, setOtherError }: UserMutationWithValidationPropsInterface = {
    setValidationError: () => {},
  },
) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.AddUser);

  const uniqueEmailErrorHandler = useUserUniqueEmailErrorHandler(setValidationError);
  const incorrectEmailErrorHandler =
    useIncorrectEmailErrorHandler<UserFormInterface>(setValidationError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError(
    [uniqueEmailErrorHandler, incorrectEmailErrorHandler],
    generalErrorHandler,
  );

  return useMutation(
    async (payload: UserPayloadInterface) => {
      const res = await request({ data: { ...payload } });

      return res.data;
    },
    {
      onSuccess: (_, { email, fullName: name }: UserPayloadInterface) => {
        queryClient.invalidateQueries(getUsersQueryKey);

        showAlert(
          <Trans
            i18nKey="settings.sections.permission.subsections.user.actions.add.success"
            values={{ name, email }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useAddUserMutation;
