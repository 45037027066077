import React, { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';
import { ResidentPropertyInterface } from '../../../../../../../../../../../../types/upload-hub/resident/property/ResidentProperty.interface';
import useCurrency from '../../../../../../../../../../../../utils/hooks/currency/useCurrency.hook';
import classes from './ThresholdInfo.module.scss';
import useGetResidentPropertiesQuery from '../../../../../../../../../../../../services/react-query-hooks/queries/upload-hub/resident/useGetResidentProperties.query';

export const ThresholdInfoComponent: FC = () => {
  const { t } = useTranslation();
  const { data: properties, isLoading } = useGetResidentPropertiesQuery();
  const { getValues } = useFormContext<VerifyInvoiceDataFormInterface>();
  const { renderCurrency } = useCurrency();

  const propertyFormItem = getValues(
    `${ResidentInvoiceDataEnum.PROPERTY}`,
  ) as ResidentPropertyInterface;

  const property = useMemo(() => {
    if (!propertyFormItem || !properties) {
      return undefined;
    }

    return properties.find((p: ResidentPropertyInterface) => p.value === propertyFormItem.value);
  }, [properties, propertyFormItem]);

  if (!property || !property.threshold || isLoading) {
    return <> </>;
  }

  return (
    <Grid item xs={6} className={classes['threshold-info']}>
      <div className={classes['threshold-info__text']}>
        <b>{property.text}</b> {t('global.threshold').toLowerCase()}
        {': '}
        <b>{renderCurrency(property.threshold)}</b>
      </div>
    </Grid>
  );
};
