import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: process.env.NODE_ENV === 'production' ? 30000 : Infinity,
      retry: process.env.NODE_ENV === 'production' ? 5 : false,
      // TODO: verify, perhaps using this settings per query is the way to go
      // refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      refetchOnWindowFocus: false,
    },
  },
});
