import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { UserMutationWithOptionalValidationPropsInterface } from './interfaces/UserMutationProps.interface';
import { ApiOperation } from '../../../api';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useUserNotFoundErrorHandler from '../../error-handlers/user/useUserNotFoundError.handler';
import useOnError from '../../error-handlers/useOnError';
import UserPayloadInterface from '../../../../types/users/UserPayload.interface';
import { queryClient } from '../../../queryClient';
import { getUsersQueryKey } from '../../queries/queryKeys';
import useIncorrectEmailErrorHandler from '../../error-handlers/common/useIncorrectEmailError.handler';
import useUserUniqueEmailErrorHandler from '../../error-handlers/user/useUserUniqueEmailError.handler';
import UserFormInterface from '../../../../views/home-page/components/settings/components/global-settings/components/user-permission/components/user-form/UserForm.interface';
import { updateUserMutationKey } from '../mutationKeys';
import { useAlert } from '../../../alert/Alert.provider';

interface UpdateUserPayloadInterface {
  id: string;
  payload: UserPayloadInterface;
}

const useUpdateUserMutation = ({
  setValidationError = () => {},
  setOtherError,
}: UserMutationWithOptionalValidationPropsInterface = {}) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateUser);

  const uniqueEmailErrorHandler = useUserUniqueEmailErrorHandler(setValidationError);
  const incorrectEmailErrorHandler =
    useIncorrectEmailErrorHandler<UserFormInterface>(setValidationError);
  const notFoundErrorHandler = useUserNotFoundErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError(
    [uniqueEmailErrorHandler, incorrectEmailErrorHandler, notFoundErrorHandler],
    generalErrorHandler,
  );

  return useMutation(
    async ({ id, payload }: UpdateUserPayloadInterface) => {
      const res = await request({ data: { ...payload }, urlParams: { id } });

      return { data: res.data, id, payload };
    },
    {
      mutationKey: updateUserMutationKey,
      onSuccess: (_, { payload: { fullName: name, email } }: UpdateUserPayloadInterface) => {
        queryClient.invalidateQueries(getUsersQueryKey);

        showAlert(
          <Trans
            i18nKey="settings.sections.permission.subsections.user.actions.edit.success"
            values={{ name, email }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useUpdateUserMutation;
