import React from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { InvoiceUpdateMutationWithValidationPropsInterface } from './InvoiceUpdateMutationProps.interface';
import { ApiOperation } from '../../../../api';
import useGeneralErrorHandler from '../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../error-handlers/useOnError';
import { queryClient } from '../../../../queryClient';
import {
  getProcessedInvoicesQueryKey,
  getToApproveInvoicesQueryKey,
  getToVerifyInvoicesQueryKey,
} from '../../../queries/queryKeys';
import {
  InvoiceUpdateItemInterface,
  InvoiceUpdatePayloadInterface,
} from '../../../../../types/upload-hub/invoice/verification/InvoiceUpdatePayload.interface';
import useVerificationFormErrorHandler from '../../../error-handlers/upload-hub/invoice/useVerificationFormError.handler';
import useIncorrectItemVatErrorHandler from '../../../error-handlers/upload-hub/invoice/useIncorrectItemVatError.handler';
import useInvalidTotalItemsAmountErrorHandler from '../../../error-handlers/upload-hub/invoice/useInvalidTotalItemsAmountError.handler';
import { useAlert } from '../../../../alert/Alert.provider';
import { ResidentInvoiceDataEnum } from '../../../../../enums/upload-hub/ResidentInvoiceData.enum';
import { updateInvoiceMutationKey } from '../../mutationKeys';

const useUpdateInvoiceMutation = ({
  setValidationError,
  setOtherError,
}: InvoiceUpdateMutationWithValidationPropsInterface) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.UpdateInvoice);

  const incorrectItemVatErrorHandler = useIncorrectItemVatErrorHandler(setOtherError);
  const invalidTotalItemsAmountErrorHandler = useInvalidTotalItemsAmountErrorHandler(setOtherError);
  const verificationFormErrorHandler = useVerificationFormErrorHandler({
    setError: setValidationError,
    options: { shouldFocus: true },
  });

  const generalErrorHandler = useGeneralErrorHandler(
    setOtherError,
    t('invoices.tabs.toVerify.verificationModal.steps.verification.errors.general'),
  );
  const { onError } = useOnError(
    [
      incorrectItemVatErrorHandler,
      invalidTotalItemsAmountErrorHandler,
      verificationFormErrorHandler,
    ],
    generalErrorHandler,
  );

  return useMutation(
    async ({ skipApprovalFlow, ...payload }: InvoiceUpdatePayloadInterface) => {
      const res = await request({
        data: { ...payload, skipApprovalFlow: Boolean(skipApprovalFlow) },
        urlParams: { id: payload.invoiceId },
      });

      return res.data;
    },
    {
      mutationKey: updateInvoiceMutationKey,
      onSuccess: (_, { invoiceUpdateData }: InvoiceUpdatePayloadInterface) => {
        queryClient.invalidateQueries(getToVerifyInvoicesQueryKey);
        queryClient.invalidateQueries(getToApproveInvoicesQueryKey);
        queryClient.invalidateQueries(getProcessedInvoicesQueryKey);

        const invoiceReferenceItem = invoiceUpdateData.find(
          (invoiceDataItem: InvoiceUpdateItemInterface): boolean =>
            invoiceDataItem.residentFieldName === ResidentInvoiceDataEnum.INVOICE_REFERENCE,
        );

        showAlert(
          <Trans
            i18nKey="invoices.tabs.toVerify.verificationModal.steps.verification.success"
            values={{ name: invoiceReferenceItem ? invoiceReferenceItem.value : '' }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useUpdateInvoiceMutation;
