import React, { FC, ReactElement } from 'react';
import classes from './NotificationsHeader.module.scss';
import CloseButtonComponent from '../../../close-button/CloseButton.component';
import { NotificationsHeaderPropsInterface } from './interfaces/NotificationsHeaderProps.interface';
import { useNotification } from '../../../../../services/notification/Notification.provider';

const NotificationsHeaderComponent: FC<NotificationsHeaderPropsInterface> = ({
  onClose,
}): ReactElement => {
  const { unreadNotifications, pagination } = useNotification();

  return (
    <header className={classes['notifications-header']}>
      <div className={classes['notifications-header__content']}>
        <div className={classes['notifications-header__title-container']}>
          <h6 className={classes['title-container__title']}>Notifications</h6>
          <span className={classes['title-container__summary']}>
            ({unreadNotifications()}/{pagination?.TotalCount || '0'})
          </span>
        </div>
      </div>
      <div className={classes['notifications-header__separator']} />
      <CloseButtonComponent
        onClose={onClose}
        classNames={{
          root: classes['notifications-header__close-button'],
          wrapper: classes['close-button__wrapper'],
          icon: classes['close-button__icon'],
        }}
      />
    </header>
  );
};

export default NotificationsHeaderComponent;
