import React, { FC, ReactElement, useEffect } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import primaryTheme from './themes/primary-theme';
import { NotificationProvider } from './services/notification/Notification.provider';
import MainAbstract from './views/Main.abstract';
import { AlertProvider } from './services/alert/Alert.provider';

const App: FC = (): ReactElement => {
  useEffect(() => {
    document.body.id = '';
  }, []);

  return (
    <ThemeProvider theme={primaryTheme}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AlertProvider>
            <NotificationProvider>
              <MainAbstract />
            </NotificationProvider>
          </AlertProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
