import { useTranslation } from 'react-i18next';
import React, { ReactNode, useCallback } from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import classes from './VerificationButtons.module.scss';
import useSubmitButtonsHook from '../../../../../../../../../../../utils/hooks/useSubmitButtons.hook';
import { queryClient } from '../../../../../../../../../../../services/queryClient';
import { updateInvoiceMutationKey } from '../../../../../../../../../../../services/react-query-hooks/mutations/mutationKeys';
import { Button } from '../../../../../../../../../../components/material';
import { VerificationFormPayloadInterface } from '../../../services/interfaces/VerificationFormPayload.interface';

export interface VerificationButtonsInterface {
  id: string;
  isLoading?: boolean;
  onClose?: () => void;
  onSubmit?: (payload?: VerificationFormPayloadInterface) => Promise<void>;
  onPreviousStep?: () => void;
}

const useVerificationButtons = ({
  id,
  isLoading,
  onClose,
  onSubmit,
  onPreviousStep,
}: VerificationButtonsInterface) => {
  const { t } = useTranslation();

  const isUpdateWithApprovalLoading =
    queryClient.isMutating({
      mutationKey: updateInvoiceMutationKey,
      predicate: (m) =>
        m.options.variables?.invoiceId === id && !m.options.variables?.skipApprovalFlow,
    }) > 0;
  const isUpdateWithoutApprovalLoading =
    queryClient.isMutating({
      mutationKey: updateInvoiceMutationKey,
      predicate: (m) =>
        m.options.variables?.invoiceId === id && m.options.variables?.skipApprovalFlow,
    }) > 0;

  const { buttons } = useSubmitButtonsHook({
    isLoading: isUpdateWithApprovalLoading,
    disabled: isUpdateWithoutApprovalLoading,
    close: onClose || (() => {}),
    handleSubmit: onSubmit || (() => {}),
    submitText: t(
      'invoices.tabs.toVerify.verificationModal.steps.verification.actions.toApprove.label',
    ),
  });

  const onPreviousStepHandler = useCallback(() => {
    if (onPreviousStep) {
      onPreviousStep();
    }
  }, [onPreviousStep]);

  const onSubmitHandler = useCallback(
    (payload?: VerificationFormPayloadInterface): void => {
      if (onSubmit) {
        onSubmit(payload).then();
      }
    },
    [onSubmit],
  );

  const verificationButtons = useCallback(
    (payload?: VerificationFormPayloadInterface): ReactNode | void => {
      return (
        <Grid
          container
          columnSpacing={4}
          rowSpacing={2}
          className={classes['verification-buttons']}
          data-cy="verification-buttons"
        >
          <Grid
            item
            xs={4}
            className={classNames(
              classes['verification-buttons__button'],
              classes['verification-buttons__button--first'],
            )}
          >
            <Button variant="text" onClick={onPreviousStepHandler} disabled={isLoading}>
              {t('invoices.tabs.toVerify.verificationModal.steps.verification.rematch.button')}
            </Button>
          </Grid>

          <Grid item xs={4} className={classNames(classes['verification-buttons__button'])}>
            {buttons(payload)}
          </Grid>

          <Grid
            item
            xs={4}
            className={classNames(
              classes['verification-buttons__button'],
              classes['verification-buttons__button--last'],
            )}
          >
            <Button
              variant="text"
              onClick={() =>
                onSubmitHandler(
                  payload
                    ? {
                        ...payload,
                        skipApprovalFlow: true,
                      }
                    : undefined,
                )
              }
              disabled={isUpdateWithApprovalLoading}
              loading={isUpdateWithoutApprovalLoading}
            >
              {t(
                'invoices.tabs.toVerify.verificationModal.steps.verification.actions.skipApproval.label',
              )}
            </Button>
          </Grid>
        </Grid>
      );
    },
    [
      buttons,
      isLoading,
      isUpdateWithApprovalLoading,
      isUpdateWithoutApprovalLoading,
      onPreviousStepHandler,
      onSubmitHandler,
      t,
    ],
  );

  return {
    verificationButtons,
  };
};

export default useVerificationButtons;
