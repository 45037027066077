import React, { useMemo } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, DialogActions, Typography } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ErrorIcon from '@mui/icons-material/Error';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import './Modal.component.scss';
import classNames from 'classnames';
import { ModalPropsInterface } from '../types/Props.interface';
import LoadingComponent from '../../loading/Loading.component';
import CloseButtonComponent from '../../close-button/CloseButton.component';

const ModalComponent = (props: ModalPropsInterface) => {
  const {
    open,
    handleClose,
    title,
    content,
    actions,
    variant = 'full',
    dialogProps,
    closeOnlyOnButton = false,
    isLoading = false,
    className,
    required,
    fixedContent,
  } = props;

  let topIcon;

  switch (variant) {
    case 'warning': {
      topIcon = <WarningAmberRoundedIcon className="data-hub-modal__top-icon" />;
      break;
    }
    case 'error': {
      topIcon = <ErrorIcon className="data-hub-modal__top-icon" />;
      break;
    }
    case 'success': {
      topIcon = <GppGoodOutlinedIcon className="data-hub-modal__top-icon" />;
      break;
    }
    default:
  }

  const modalContent = useMemo(() => {
    const renderedContent = typeof content === 'function' ? content() : content;

    return (
      <DialogContent
        className={classNames(
          'data-hub-modal__content',
          fixedContent ? 'data-hub-modal__content--fixed' : '',
        )}
        data-cy="modal-content"
      >
        <Box
          className={classNames('content__inner', `content__inner--${dialogProps?.maxWidth}`)}
          data-cy="modal-content-inner"
        >
          {isLoading ? <LoadingComponent variant="relative" size="sm" /> : renderedContent}
        </Box>
      </DialogContent>
    );
  }, [content, dialogProps?.maxWidth, fixedContent, isLoading]);

  return (
    <Dialog
      open={open}
      onClose={closeOnlyOnButton ? undefined : handleClose}
      className={classNames(
        title ? 'data-hub-modal' : 'data-hub-modal data-hub-modal--no-title',
        actions ? 'data-hub-modal--with-actions' : '',
        `data-hub-modal--${variant}`,
        className,
        isLoading ? 'data-hub-modal--loading' : '',
      )}
      {...dialogProps}
      scroll="paper"
      aria-labelledby={String(title) || ''}
      data-cy="modal"
    >
      <Box className="data-hub-modal__accent" />
      {title && (
        <DialogTitle id={String(title)}>
          <div className="data-hub-modal__title-container">
            {topIcon}

            <Typography
              component="span"
              variant="h4"
              className={classNames(
                'data-hub-modal__title',
                required ? 'data-hub-modal__title--required' : '',
              )}
              data-cy="modal-title"
            >
              {title}
            </Typography>
          </div>

          <CloseButtonComponent
            onClose={handleClose}
            classNames={{
              root: 'data-hub-modal__close-button',
              wrapper: 'close-button__wrapper',
              icon: 'close-button__icon',
            }}
          />

          {variant === 'full' && <Box className="data-hub-modal__divider" />}
        </DialogTitle>
      )}

      {modalContent}

      {actions && (
        <DialogActions>
          <div
            id="modalActions"
            className="data-hub-modal__actions-container"
            data-cy="modal-actions"
          >
            {typeof actions === 'function' ? actions() : actions}
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalComponent;
