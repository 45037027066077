import React, { FC, ReactElement } from 'react';
import { MenuItem as MatMenuItem, MenuItemProps } from '@mui/material';
import classNames from 'classnames';
import './MenuItem.component.scss';

const MenuItem: FC<MenuItemProps> = ({ className, ...rest }): ReactElement => {
  return <MatMenuItem className={classNames('data-hub-menu-item', className)} {...rest} />;
};

export { MenuItem };
