import { useRef } from 'react';
import debounce from 'lodash.debounce';

interface MutationStackItemInterface {
  id: string | number;
  lastCallId: number;
}

const useDebouncedMutation = <T>(mutateFn: (payload: T) => void, debounceTime = 500) => {
  const mutationStack = useRef<MutationStackItemInterface[]>([]);

  const getMutationInfo = (id: string | number) => {
    return mutationStack.current.find((mutation: MutationStackItemInterface) => mutation.id === id);
  };

  const uniqueMutationFn = (mutationInfo: MutationStackItemInterface, payload: T) => {
    const mutation = getMutationInfo(mutationInfo.id);

    if (mutation && mutationInfo.lastCallId === mutation.lastCallId) {
      mutateFn(payload);
    }
  };

  const debouncedMutationFn = debounce(uniqueMutationFn, debounceTime);

  const mutateDebounced = (payload: T, id?: number | string) => {
    const mutationId = id || '0';
    const mutationInfo = getMutationInfo(mutationId);
    const mutationCallId = performance.now();
    const updatedMutationInfo = {
      id: mutationId,
      lastCallId: mutationCallId,
    };

    if (!mutationInfo) {
      mutationStack.current.push(updatedMutationInfo);
    } else {
      mutationStack.current = mutationStack.current.map((m: MutationStackItemInterface) => {
        if (m.id !== mutationId) {
          return m;
        }

        return updatedMutationInfo;
      });
    }

    debouncedMutationFn(updatedMutationInfo, payload);
  };

  return {
    mutateDebounced,
  };
};

export default useDebouncedMutation;
