export enum PathEnum {
  DASHBOARD = 'dashboard',
  LOGIN = 'login',
  RESET_PASS = 'reset-password',
  CONFIRM_INVITATION = 'confirm-invitation',
  SETTINGS = 'settings',
  GLOBAL = 'global',
  DATA_SRC = 'data-sources',
  PLUGIN = 'plugin',
  INVOICES = 'invoices',
  IN_PROGRESS = 'in-progress',
  TO_VERIFY = 'to-verify',
  TO_APPROVE = 'to-approve',
  PROCESSED = 'processed',
}
