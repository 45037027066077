import { NotificationTypeEnum } from '../../enums/notification/NotificationType.enum';
import { queryClient } from '../../services/queryClient';
import {
  getInProgressInvoicesQueryKey,
  getOcrProcessedPages,
  getProcessedInvoicesQueryKey,
  getToApproveInvoicesQueryKey,
  getToVerifyInvoicesQueryKey,
} from '../../services/react-query-hooks/queries/queryKeys';
import { InvoiceStateInterface } from '../upload-hub/invoice/state/InvoiceState.interface';
import { InvoiceStatusEnum } from '../../enums/upload-hub/InvoiceStatus.enum';

export const NotificationTypes = Object.values(NotificationTypeEnum);

export type NotificationType = (typeof NotificationTypes)[number];

export interface NotificationOptions<T = undefined> {
  translationKey: string;
  signalREvent: string;
  add?: boolean;
  showAlert?: boolean;
  onReceiveCallback?: (data: T) => void;
  urlTranslationKey?: string;
  urlSelfOpen?: boolean;
}

export const NotificationsTypeOptions: { [key in NotificationType]: NotificationOptions } = {
  [NotificationTypeEnum.ADMIN_NOTIFICATION]: {
    translationKey: 'adminNotification',
    signalREvent: 'AdminNotification',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT]: {
    translationKey: 'invoiceFailedToBeSendToResident',
    signalREvent: 'InvoiceFailedToBeSendToResident',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.APPROVED_INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT]: {
    translationKey: 'approvedInvoiceFailedToBeSendToResident',
    signalREvent: 'ApprovedInvoiceFailedToBeSendToResident',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.OCR_FAILED_TO_PROCESS_INVOICE]: {
    translationKey: 'ocrFailedToProcessInvoice',
    signalREvent: 'OcrFailedToProcessInvoice',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.MAILBOX_PROCESSING_ERROR]: {
    translationKey: 'mailboxProcessingError',
    signalREvent: 'MailBoxProcessingError',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.LESS_THAN_HUNDRED_PAGES_LEFT]: {
    translationKey: 'lessThanHundredPagesLeft',
    signalREvent: 'LessThanHundredPagesLeft',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.INVOICES_WAITING_TO_BE_VERIFIED]: {
    translationKey: 'invoicesWaitingToBeVerified',
    signalREvent: 'InvoicesWaitingToBeVerified',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.INVOICES_WAITING_FOR_FINANCIAL_APPROVAL]: {
    translationKey: 'invoicesWaitingForFinancialApproval',
    signalREvent: 'InvoicesWaitingForFinancialApproval',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.PRIMARY_DATA_SOURCE_REFRESHED]: {
    translationKey: 'primaryDataSourceRefreshed',
    signalREvent: 'PrimaryDataSourceRefreshed',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.NYLAS_ACCOUNT_INACTIVE]: {
    translationKey: 'nylasAccountInactive',
    signalREvent: 'NylasAccountInactive',
    add: true,
    showAlert: true,
    urlTranslationKey: 'nylasAccountInactive',
    urlSelfOpen: true,
  },
  [NotificationTypeEnum.DATA_SOURCE_SYNCHRONIZATION_FAILED]: {
    translationKey: 'dataSourceSynchronizationFailed',
    signalREvent: 'DataSourceSynchronizationFailed',
    add: true,
    showAlert: true,
  },
  [NotificationTypeEnum.INVOICE_OCR_RECOGNITION_FINISHED]: {
    translationKey: 'invoiceOcrRecognitionFinished',
    signalREvent: 'InvoiceOcrRecognitionFinished',
    onReceiveCallback: (invoiceState: InvoiceStateInterface | undefined): void => {
      queryClient.invalidateQueries(getOcrProcessedPages);
      queryClient.invalidateQueries(getInProgressInvoicesQueryKey);

      if (invoiceState) {
        const { state } = invoiceState;

        switch (state) {
          case InvoiceStatusEnum.SUPPLIER_MATCHING_IN_PROGRESS:
          case InvoiceStatusEnum.NO_SUPPLIER:
          case InvoiceStatusEnum.MATCHING_IN_PROGRESS:
          case InvoiceStatusEnum.NO_MATCHING:
          case InvoiceStatusEnum.NO_DATA:
          case InvoiceStatusEnum.DUPLICATES_DETECTION_IN_PROGRESS:
          case InvoiceStatusEnum.DUPLICATES: {
            queryClient.invalidateQueries(getToVerifyInvoicesQueryKey);
            break;
          }
          case InvoiceStatusEnum.TO_APPROVE: {
            queryClient.invalidateQueries(getToApproveInvoicesQueryKey);
            break;
          }
          case InvoiceStatusEnum.TO_BE_SEND_TO_RESIDENT:
          case InvoiceStatusEnum.SENT_TO_RESIDENT:
          case InvoiceStatusEnum.NOT_SEND_TO_RESIDENT: {
            queryClient.invalidateQueries(getProcessedInvoicesQueryKey);
            break;
          }
          default:
        }
      }
    },
  },
};
