import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Input, FormControl } from '../../../../../../../components/material';
import FormContainerComponent from '../../../../../../../components/form-container/FormContainer.component';
import PasswordInputComponent from '../../../../../../../landing-page/components/form/PasswordInput.component';
import SynchronizedInboxesFormInterface from './SynchronizedInboxesForm.interface';
import classes from './SynchronizedInboxesForm.module.scss';
import { useEmailValidator } from '../../../../../../../landing-page/components/form/validators/Email.validator';
import EmailInputComponent from '../../../../../../../landing-page/components/form/EmailInput.component';

interface SynchronizedInboxesFormComponentPropsInterface {
  mode: 'add' | 'update';
}

const SynchronizedInboxesFormComponent: FC<SynchronizedInboxesFormComponentPropsInterface> = () => {
  const { t } = useTranslation();

  const { options: emailInputOptions } = useEmailValidator();
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<SynchronizedInboxesFormInterface>();

  // TODO: check performance, maybe we should move controllers to callbacks
  return (
    <FormContainerComponent className={classes['synchronized-inboxes-form-section']} fullWidth>
      <Grid container direction="row">
        <Grid className={classes['synchronized-inboxes-form-section__form']} item xs={12}>
          <Grid container direction="column" rowSpacing={4}>
            <Grid item>
              <FormControl error={!!errors.name}>
                <Input
                  id="name"
                  fullWidth
                  label={t(
                    'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.name.label',
                  )}
                  {...register('name', {
                    required: t(
                      'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.name.errors.required',
                    ),
                  })}
                  required
                />
                <span role="alert">{errors.name?.message}</span>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl error={!!errors.email}>
                <EmailInputComponent
                  label={t(
                    'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.email.label',
                  )}
                  placeholder={t(
                    'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.email.label',
                  )}
                  {...register('email', emailInputOptions)}
                  required
                />
                <span role="alert">{errors.email?.message}</span>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl error={!!errors.password}>
                <PasswordInputComponent
                  id="password"
                  autoComplete="new-password"
                  label={t(
                    'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.password.label',
                  )}
                  {...register('password', {
                    required: t(
                      'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.password.errors.required',
                    ),
                  })}
                  required
                />
                <span role="alert">{errors.password?.message}</span>
              </FormControl>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl error={!!errors.imapHost}>
                    <Input
                      id="imapHost"
                      fullWidth
                      label={t(
                        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.imapHost.label',
                      )}
                      {...register('imapHost', {
                        required: t(
                          'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.imapHost.errors.required',
                        ),
                      })}
                      required
                    />
                    <span role="alert">{errors.imapHost?.message}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl error={!!errors.imapPort}>
                    <Input
                      id="imapPort"
                      type="number"
                      fullWidth
                      label={t(
                        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.imapPort.label',
                      )}
                      {...register('imapPort', {
                        required: t(
                          'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.imapPort.errors.required',
                        ),
                      })}
                      required
                    />
                    <span role="alert">{errors.imapPort?.message}</span>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl>
                <Controller
                  name="autoSync"
                  control={control}
                  defaultValue={false}
                  render={(renderProps) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={renderProps.field.value}
                          onChange={renderProps.field.onChange}
                        />
                      }
                      label={t(
                        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.autoSync.label',
                      )}
                    />
                  )}
                />
                <span role="alert">{errors.autoSync?.message}</span>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormContainerComponent>
  );
};

export default SynchronizedInboxesFormComponent;
