import { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import Cookie from 'js-cookie';
import useAuth from '../../auth/Auth.provider';
import { AuthCookieEnum } from '../../auth/enums/AuthCookie.enum';
import { useUserPermissions } from '../../user/User.provider';
import { stagingDomain } from '../../../domains';

const hubSuffix = 'notificationHub';

const useSignalRConnection = () => {
  const { isAuthenticated } = useAuth();
  const { hasAnyPermission } = useUserPermissions();

  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    if (isAuthenticated && hasAnyPermission) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(
          `${
            window.location.hostname === stagingDomain && process.env.REACT_APP_APP_API_URL_STAGING
              ? process.env.REACT_APP_APP_API_URL_STAGING
              : process.env.REACT_APP_APP_API_URL
          }${hubSuffix}`,
          {
            accessTokenFactory: () => `${Cookie.get(AuthCookieEnum.ID_TOKEN)}`,
          },
        )
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    }

    return () => {};
  }, [hasAnyPermission, isAuthenticated]);

  return { connection };
};

export default useSignalRConnection;
