import React, { useMemo, useState } from 'react';
import InvoiceDuplicatesModalComponent from '../InvoiceDuplicatesModal.component';

const useInvoiceDuplicates = () => {
  const [isDuplicatesModalOpen, setIsDuplicatesModalOpen] = useState(false);
  const [duplicatesInvoiceDataId, setDuplicatesInvoiceDataId] = useState<string | undefined>(
    undefined,
  );

  const duplicatesModal = useMemo(() => {
    if (!duplicatesInvoiceDataId) {
      return <> </>;
    }

    return (
      <InvoiceDuplicatesModalComponent
        id={duplicatesInvoiceDataId}
        open={isDuplicatesModalOpen}
        setOpen={setIsDuplicatesModalOpen}
      />
    );
  }, [duplicatesInvoiceDataId, isDuplicatesModalOpen]);

  return { duplicatesModal, setIsDuplicatesModalOpen, setDuplicatesInvoiceDataId };
};

export default useInvoiceDuplicates;
