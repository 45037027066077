import { useCallback } from 'react';
import useGetResidentPropertiesQuery from '../../../../../../../../../../services/react-query-hooks/queries/upload-hub/resident/useGetResidentProperties.query';
import { InvoiceUpdateItemInterface } from '../../../../../../../../../../types/upload-hub/invoice/verification/InvoiceUpdatePayload.interface';
import { ThresholdDataInterface } from '../interfaces/ThresholdData.interface';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';
import useNumber from '../../../../../../../../../../utils/hooks/number/useNumber.hook';

export const useThresholdVerification = () => {
  const { data: properties } = useGetResidentPropertiesQuery();
  const { toNumber } = useNumber();
  const getField = (field: ResidentInvoiceDataEnum) => (d: InvoiceUpdateItemInterface) =>
    d.residentFieldName === field;

  const getThresholdData = useCallback(
    (invoiceData: InvoiceUpdateItemInterface[]): ThresholdDataInterface => {
      const propertyField = invoiceData.find(getField(ResidentInvoiceDataEnum.PROPERTY));
      const invoiceAmountField = invoiceData.find(getField(ResidentInvoiceDataEnum.TOTAL_AMOUNT));

      const invoiceAmount = invoiceAmountField ? toNumber(invoiceAmountField.value) : undefined;
      const invoiceProperty =
        propertyField && properties
          ? properties.find((p) => p.value === propertyField.value)
          : undefined;

      return {
        amount: invoiceAmount,
        property: invoiceProperty,
      };
    },
    [properties, toNumber],
  );

  return { getThresholdData };
};
