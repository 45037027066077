import React, { FC, ReactElement } from 'react';
import { Chip as MatChip } from '@mui/material';
import classNames from 'classnames';
import { ChipPropsInterface } from './interfaces/ChipProps.interface';
import './Chip.component.scss';

const Chip: FC<ChipPropsInterface> = ({ className, color, uppercase, ...rest }): ReactElement => {
  return (
    <MatChip
      className={classNames(
        'data-hub-chip',
        className,
        color && color !== 'default' ? `data-hub-chip--${color}` : '',
        uppercase ? 'data-hub-chip--uppercase' : '',
      )}
      {...rest}
    />
  );
};

export { Chip };
