import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../api';
import { UserInvitationStatusResponseType } from '../../../../types/users/UserInvitationStatusResponse.type';
import { getUserInvitationStatusQueryKey } from '../queryKeys';

const useGetUserInvitationStatusQuery = (invitationId: string, isValid: boolean) => {
  const { request } = useRequest<UserInvitationStatusResponseType>(
    ApiOperation.GetUserInvitationStatus,
  );
  const invalidQueryData = {
    isError: true,
    isLoading: false,
    status: 'error',
    error: {
      response: {
        data: {
          message: 'INVITATION_NOT_FOUND',
          responseModel: null,
          status: 2,
        },
      },
    },
  };

  const invalidData = isValid ? {} : invalidQueryData;

  return {
    ...useQuery([getUserInvitationStatusQueryKey, { id: invitationId }], async () => {
      if (isValid) {
        const res = await request({ params: { invitationId } });

        return res.data.responseModel;
      }

      return null;
    }),
    ...invalidData,
  };
};

export default useGetUserInvitationStatusQuery;
