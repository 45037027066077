import { useTranslation } from 'react-i18next';
import CommonSetErrorType from '../CommonSetError.type';
import { useCommonErrorHandler } from '../useErrorHandler';

const useAgencyNotFoundErrorHandler = (setError?: CommonSetErrorType) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'AGENCY_NOT_FOUND',
    errorMessage: t(
      'settings.sections.permission.subsections.user.actions.invite.errors.agencyNotFound',
    ),
  });

  return handler;
};

export default useAgencyNotFoundErrorHandler;
