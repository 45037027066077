import React, { FC, ReactElement } from 'react';
import { Accordion as MatAccordion, AccordionProps } from '@mui/material';
import classnames from 'classnames';
import './Accordion.component.scss';

const Accordion: FC<AccordionProps> = ({ className, children, ...rest }): ReactElement => {
  return (
    <MatAccordion {...rest} className={classnames(className, 'data-hub-accordion')}>
      {children}
    </MatAccordion>
  );
};

export { Accordion };
