import React, { forwardRef, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputPropsInterface } from '../../../components/material';

const EmailInputComponent = forwardRef(
  ({ placeholder, ...props }: PropsWithChildren<InputPropsInterface>, ref) => {
    const { t } = useTranslation();

    return (
      <Input
        id="email"
        placeholder={placeholder ? '' : t('landingPage.loginForm.controls.email.placeholder')}
        autoComplete="email"
        fullWidth
        ref={ref}
        {...props}
      />
    );
  },
);

export default EmailInputComponent;
