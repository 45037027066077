import React, { ReactNode, useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './stepNavigation.module.scss';
import { Button } from '../../../views/components/material';
import useSubmitButtonsHook from '../useSubmitButtons.hook';
import { StepNavigationInterface } from './interfaces/StepNavigation.interface';

export interface StepsNavigationPropsInterface<T extends StepNavigationInterface, P> {
  isLoading?: boolean;
  steps: T[];
  onClose?: () => void;
  onSubmit?: (payload?: P) => Promise<void>;
  onPreviousStepClick?: (payload?: P) => Promise<void>;
  onNextStepClick?: (payload?: P) => Promise<void>;
}

const useStepsNavigation = <T extends StepNavigationInterface, P extends {}>({
  isLoading,
  steps,
  onClose,
  onSubmit,
  onPreviousStepClick,
  onNextStepClick,
}: StepsNavigationPropsInterface<T, P>) => {
  const { t } = useTranslation();

  const initialStep = steps.find((step: StepNavigationInterface) => step.initial);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [isNextStepLoading, setIsNextStepLoading] = useState(false);
  const [isPreviousStepLoading, setIsPreviousStepLoading] = useState(false);

  const currentStepIndex = steps.findIndex(
    (step: StepNavigationInterface) => step.name === currentStep?.name,
  );
  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;

  const onPreviousStepClickHandler = useCallback(
    (payload?: P) => {
      if (isFirstStep) {
        return;
      }

      if (onPreviousStepClick) {
        setIsPreviousStepLoading(true);

        if (payload) {
          onPreviousStepClick(payload).finally(() => {
            setIsPreviousStepLoading(false);
            setCurrentStep({ ...steps[currentStepIndex - 1] });
          });
        } else {
          onPreviousStepClick().finally(() => {
            setIsPreviousStepLoading(false);
            setCurrentStep({ ...steps[currentStepIndex - 1] });
          });
        }
      } else {
        setCurrentStep({ ...steps[currentStepIndex - 1] });
      }
    },
    [currentStepIndex, isFirstStep, onPreviousStepClick, steps],
  );

  const onNextStepClickHandler = useCallback(
    (payload?: P) => {
      if (isLastStep) {
        return;
      }

      if (onNextStepClick) {
        setIsNextStepLoading(true);

        if (payload) {
          onNextStepClick(payload).finally(() => {
            setIsNextStepLoading(false);
            setCurrentStep({ ...steps[currentStepIndex + 1] });
          });
        } else {
          onNextStepClick().finally(() => {
            setIsNextStepLoading(false);
            setCurrentStep({ ...steps[currentStepIndex + 1] });
          });
        }
      } else {
        setCurrentStep({ ...steps[currentStepIndex + 1] });
      }
    },
    [currentStepIndex, isLastStep, onNextStepClick, steps],
  );

  const { buttons } = useSubmitButtonsHook<P>({
    isLoading: (isLoading && !isNextStepLoading && !isPreviousStepLoading) || false,
    disabled: (isLoading && (isPreviousStepLoading || isNextStepLoading)) || false,
    close: onClose || (() => {}),
    handleSubmit: onSubmit || (() => {}),
    submitText: t('global.save'),
  });

  const navigationButtons = useCallback(
    (payload?: P): ReactNode | void => {
      return (
        <Grid
          container
          columnSpacing={4}
          rowSpacing={2}
          className={classes['step-navigation-buttons']}
          data-cy="step-navigation-buttons"
        >
          <Grid
            item
            xs={4}
            className={classNames(
              classes['step-navigation-buttons__button'],
              classes['step-navigation-buttons__button--first'],
            )}
          >
            {!isFirstStep && !currentStep?.isFirstStep && (
              <Button
                variant={currentStep?.previousStepButtonVariant || 'text'}
                onClick={
                  payload ? () => onPreviousStepClickHandler(payload) : onPreviousStepClickHandler
                }
                disabled={isLoading && !isPreviousStepLoading}
                loading={isLoading && isPreviousStepLoading}
              >
                {currentStep?.previousStepButtonLabel || t('global.previous')}
              </Button>
            )}
          </Grid>

          <Grid item xs={4} className={classes.button}>
            {buttons(payload)}
          </Grid>

          <Grid
            item
            xs={4}
            className={classNames(
              classes['step-navigation-buttons__button'],
              classes['step-navigation-buttons__button--last'],
            )}
          >
            {!isLastStep && !currentStep?.isLastStep && (
              <Button
                variant={currentStep?.nextStepButtonVariant || 'text'}
                onClick={payload ? () => onNextStepClickHandler(payload) : onNextStepClickHandler}
                disabled={isLoading && !isNextStepLoading}
                loading={isLoading && isNextStepLoading}
              >
                {currentStep?.nextStepButtonLabel || t('global.next')}
              </Button>
            )}
          </Grid>
        </Grid>
      );
    },
    [
      isFirstStep,
      currentStep?.isFirstStep,
      currentStep?.previousStepButtonVariant,
      currentStep?.previousStepButtonLabel,
      currentStep?.isLastStep,
      currentStep?.nextStepButtonVariant,
      currentStep?.nextStepButtonLabel,
      onPreviousStepClickHandler,
      isLoading,
      isPreviousStepLoading,
      t,
      buttons,
      isLastStep,
      onNextStepClickHandler,
      isNextStepLoading,
    ],
  );

  return {
    currentStep,
    isFirstStep,
    isLastStep,
    onPreviousStepClick: onPreviousStepClickHandler,
    onNextStepClick: onNextStepClickHandler,
    navigationButtons,
  };
};

export default useStepsNavigation;
