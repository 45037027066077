import React, { FC, ReactElement } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../../../../../../../components/material';
import { ForgotPasswordPropsInterface } from '../../interfaces/ForgotPasswordProps.interface';

const ResetPasswordConfirmationComponent: FC<ForgotPasswordPropsInterface> = ({
  onSubmit,
  email,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" rowSpacing={4}>
      <Grid item>
        <p>{t('landingPage.loginForm.forgotPassword.confirmation', { email })}</p>
        {email ? (
          <p>
            <span>{t('landingPage.loginForm.notReceivedEmail')} </span>
            <Button variant="link" disableRipple onClick={() => onSubmit({ email })}>
              {t('global.tryAgain')}.
            </Button>
          </p>
        ) : null}
      </Grid>
      <Grid item>
        <Button component={Link} to="/" variant="contained" type="button" fullWidth>
          {t('landingPage.loginForm.login')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordConfirmationComponent;
