import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import { getInvoiceMatchingQueryKey } from '../../queryKeys';
import { InvoiceMatchingResponseType } from '../../../../../types/upload-hub/invoice/matching/InvoiceMatchingResponse.type';
import { InvoiceFieldInterface } from '../../../../../types/upload-hub/invoice/InvoiceField.interface';
import { InvoiceItemInterface } from '../../../../../types/upload-hub/invoice/InvoiceItem.interface';
import { getFieldColor } from '../../../../../utils/Colors';

const assignColorToField = (field: InvoiceFieldInterface): InvoiceFieldInterface => {
  return {
    ...field,
    color: getFieldColor(field.name),
  };
};

const assignColorToItemFields = (item: InvoiceItemInterface) => ({
  ...item,
  fields: item.fields.map(assignColorToField),
});

const useGetInvoiceMatchingQuery = (id: string) => {
  const { request } = useRequest<InvoiceMatchingResponseType>(ApiOperation.GetInvoiceMatching);

  const queryResult = useQuery([getInvoiceMatchingQueryKey, id], async () => {
    const res = await request({ urlParams: { id } });

    return res.data.responseModel;
  });

  if (queryResult.isLoading) {
    return queryResult;
  }

  return {
    ...queryResult,
    data: {
      ...queryResult.data,
      fields: queryResult.data ? queryResult.data.fields.map(assignColorToField) : [],
      items: queryResult.data ? queryResult.data.items.map(assignColorToItemFields) : [],
      residentInvoiceDataMapping:
        queryResult.data && queryResult.data.residentInvoiceDataMapping
          ? queryResult.data.residentInvoiceDataMapping
          : {},
      fileLocalization: queryResult.data ? queryResult.data.fileLocalization : '',
      tags: queryResult.data ? queryResult.data.tags : [],
    },
  };
};

export default useGetInvoiceMatchingQuery;
