import React, { useMemo, useState } from 'react';
import SupplierMatchModalComponent from '../SupplierMatchModal.component';
import { UploadedFileInterface } from '../../../../../../../../../types/upload-hub/UploadedFile.interface';
import { SupplierMatchModalType } from '../types/SupplierMatch.type';
import { InvoiceFieldValueInterface } from '../../../../../../../../../types/upload-hub/invoice/InvoiceProperty.interface';

export interface SupplierMatchDataInterface extends UploadedFileInterface {
  id: string;
  supplier: InvoiceFieldValueInterface | null;
}

const useSupplierMatch = (
  variant: SupplierMatchModalType = 'assign',
  submitCallback: () => void = () => {},
) => {
  const [matchSupplierInvoiceOpen, setMatchSupplierInvoiceOpen] = useState(false);
  const [matchSupplierInvoiceData, setMatchSupplierInvoiceData] = useState<
    SupplierMatchDataInterface | undefined
  >(undefined);

  const supplierMatchModal = useMemo(() => {
    if (!matchSupplierInvoiceData) {
      return <> </>;
    }

    return (
      <SupplierMatchModalComponent
        id={matchSupplierInvoiceData.id}
        fileName={matchSupplierInvoiceData.fileName}
        fileLocalization={matchSupplierInvoiceData.fileLocalization}
        supplier={matchSupplierInvoiceData.supplier}
        submitCallback={submitCallback}
        open={matchSupplierInvoiceOpen}
        variant={variant}
        setOpen={(isOpen: boolean) => {
          if (!isOpen) {
            setMatchSupplierInvoiceData(undefined);
            setMatchSupplierInvoiceOpen(false);
          }
        }}
      />
    );
  }, [matchSupplierInvoiceData, matchSupplierInvoiceOpen, submitCallback, variant]);

  return { supplierMatchModal, setMatchSupplierInvoiceOpen, setMatchSupplierInvoiceData };
};

export default useSupplierMatch;
