import { ApiMethodEnum, ApiOperationsType } from '@datahub/api-client';
import { restClients } from './Clients';

export enum ApiOperation {
  // Auth0 --------------------------------------------------------------------------------------
  LogIn,

  // Nylas -------------------------------------------------------------------------------------
  AuthNylas,
  NylasSendAuthCode,
  NylasDeleteAccount,
  NylasGetAccounts,
  NylasSetAccountAutoSync,
  NylasScheduleAccountMessagesProcessing,
  NylasScheduleAccountsMessagesProcessing,

  // App --------------------------------------------------------------------------------------
  GetUser,
  GetUsers,
  AddUser,
  UpdateUser,
  DeleteUser,
  InviteUser,
  ConfirmUserInvitation,
  GetUserInvitationStatus,

  GetDataSources,
  GetDataSourcesForGeneralType,
  AddDataSource,
  UpdateDataSource,
  DeleteDataSource,
  SyncDataSource,

  GetRoles,
  AddRole,
  UpdateRole,
  DeleteRole,

  GetNotifications,
  MarkNotificationAsRead,
  MarkAllNotificationsAsRead,

  GetPermissions,

  UploadInvoice,
  SyncMailbox,
  SyncMailboxes,
  SendToOCR,

  GetOcrProcessedPages,

  GetInProgressInvoices,
  GetToVerifyInvoices,
  GetToApproveInvoices,
  GetProcessedInvoices,
  GetInvoice,
  UpdateInvoice,
  ApproveInvoice,
  DeleteInvoice,
  BulkDeleteInvoices,
  RejectInvoice,
  RejectToAmendInvoice,
  GetInvoiceState,
  GetInvoiceDetails,
  GetInvoiceHistory,
  GetInvoiceMatching,
  UpdateInvoiceSupplierMatching,
  UpdateInvoiceMatching,
  GetInvoiceDuplicates,
  SkipInvoiceDuplicates,
  BackInvoiceToReview,
  SendInvoiceToIntegrationService,
  GetInvoicesReport,

  GetUploadHubSettings,
  GetUploadHubRoles,
  UpdateAutoSendInvoiceToOCR,
  UpdateAllowUsersToSeeOnlyInvoicesUploadedByThem,
  UpdateAutoSendInvoiceToIntegrationService,
  UpdateOcrProcessedPagesLimit,
  UpdateInvoiceReceiver,
  UpdateUsIteGrossAmount,
  GetSynchronizedInboxes,
  AddSynchronizedInbox,
  UpdateSynchronizedInbox,
  UpdateSynchronizedInboxAutoSync,
  DeleteSynchronizedInbox,

  GetResidentProperties,
  GetResidentSuppliers,
  GetResidentBankAccounts,
  GetResidentWorkOrders,
  GetResidentSchedules,
  GetResidentCategories,

  GetConfiguration,
  SetConfigurationLogo,
}

export const operations: ApiOperationsType<ApiOperation, restClients> = {
  // Auth0 --------------------------------------------------------------------------------------
  [ApiOperation.LogIn]: {
    client: 'authClient',
    method: ApiMethodEnum.GET,
    url: () => '/login',
  },

  // Nylas -------------------------------------------------------------------------------------
  [ApiOperation.AuthNylas]: {
    client: 'nylasClient',
    method: ApiMethodEnum.GET,
    url: () => '/oauth/authorize',
  },
  [ApiOperation.NylasSendAuthCode]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => '/Nylas/SendAuthorizationCode',
  },
  [ApiOperation.NylasDeleteAccount]: {
    client: 'appClient',
    method: ApiMethodEnum.DELETE,
    url: () => '/Nylas/DeleteAccount',
  },
  [ApiOperation.NylasGetAccounts]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/Nylas/GetAccounts',
  },
  [ApiOperation.NylasSetAccountAutoSync]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/Nylas/SetAccountAutoSync',
  },
  [ApiOperation.NylasScheduleAccountMessagesProcessing]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => '/Nylas/ScheduleAccountMessagesProcessing',
  },
  [ApiOperation.NylasScheduleAccountsMessagesProcessing]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => '/Nylas/ScheduleAccountsMessagesProcessing',
  },

  // App --------------------------------------------------------------------------------------
  [ApiOperation.GetUser]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => `/User/permissions`,
  },
  [ApiOperation.GetUsers]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => `/User`,
  },
  [ApiOperation.AddUser]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => `/User`,
  },
  [ApiOperation.UpdateUser]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/User/${id}`,
  },
  [ApiOperation.DeleteUser]: {
    client: 'appClient',
    method: ApiMethodEnum.DELETE,
    url: ({ id }) => `/User/${id}`,
  },
  [ApiOperation.InviteUser]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => `/User/invite`,
  },
  [ApiOperation.ConfirmUserInvitation]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => `/User/ConfirmInvitation`,
  },
  [ApiOperation.GetUserInvitationStatus]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => `/User/CheckInvitationStatus`,
  },

  [ApiOperation.GetDataSources]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/DataSource',
  },
  [ApiOperation.GetDataSourcesForGeneralType]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: ({ id }) => `/DataSource/forgeneraltype/${id}`,
  },
  [ApiOperation.AddDataSource]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => '/DataSource',
  },
  [ApiOperation.UpdateDataSource]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/DataSource/${id}`,
  },
  [ApiOperation.DeleteDataSource]: {
    client: 'appClient',
    method: ApiMethodEnum.DELETE,
    url: ({ id }) => `/DataSource/${id}`,
  },
  [ApiOperation.SyncDataSource]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: ({ id }) => `/DataSource/${id}/synchronize`,
  },

  [ApiOperation.GetRoles]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/Role',
  },
  [ApiOperation.AddRole]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => '/Role',
  },
  [ApiOperation.UpdateRole]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/Role/${id}`,
  },
  [ApiOperation.DeleteRole]: {
    client: 'appClient',
    method: ApiMethodEnum.DELETE,
    url: ({ id }) => `/Role/${id}`,
  },

  [ApiOperation.GetNotifications]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/Notification',
  },
  [ApiOperation.MarkNotificationAsRead]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/Notification',
  },
  [ApiOperation.MarkAllNotificationsAsRead]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/Notification/markAllAsRead',
  },

  [ApiOperation.GetPermissions]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/Permission',
  },

  [ApiOperation.UploadInvoice]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => `/uploadhub/invoice/upload`,
  },
  [ApiOperation.SyncMailbox]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => `/uploadhub/invoice/syncmailbox`,
  },
  [ApiOperation.SyncMailboxes]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => `/uploadhub/invoice/syncmailbox/manual`,
  },
  [ApiOperation.SendToOCR]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => `/uploadhub/invoice/sendtoocr`,
  },

  [ApiOperation.GetOcrProcessedPages]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => `/uploadhub/resident/ocrprocessedpages`,
  },

  [ApiOperation.GetInProgressInvoices]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => `/uploadhub/resident/invoices/inprogress`,
  },
  [ApiOperation.GetToVerifyInvoices]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => `/uploadhub/resident/invoices/toverify`,
  },
  [ApiOperation.GetToApproveInvoices]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => `/uploadhub/resident/invoices/toApprove`,
  },
  [ApiOperation.GetProcessedInvoices]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => `/uploadhub/resident/invoices/processed`,
  },
  [ApiOperation.GetInvoice]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}`,
  },
  [ApiOperation.UpdateInvoice]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}`,
  },
  [ApiOperation.ApproveInvoice]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/approve`,
  },
  [ApiOperation.DeleteInvoice]: {
    client: 'appClient',
    method: ApiMethodEnum.DELETE,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}`,
  },
  [ApiOperation.BulkDeleteInvoices]: {
    client: 'appClient',
    method: ApiMethodEnum.DELETE,
    url: () => `/uploadhub/resident/invoice/deleteinvoiceslist`,
  },
  [ApiOperation.RejectInvoice]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/reject`,
  },
  [ApiOperation.RejectToAmendInvoice]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/rejectToAmend`,
  },
  [ApiOperation.GetInvoiceState]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/state`,
  },
  [ApiOperation.GetInvoiceDetails]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/details`,
  },
  [ApiOperation.GetInvoiceHistory]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/history`,
  },
  [ApiOperation.GetInvoiceMatching]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/matching`,
  },
  [ApiOperation.UpdateInvoiceSupplierMatching]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/matching/supplier`,
  },
  [ApiOperation.UpdateInvoiceMatching]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/matching`,
  },
  [ApiOperation.GetInvoiceDuplicates]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/duplicates`,
  },
  [ApiOperation.SkipInvoiceDuplicates]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: ({ id, skipApprovalFlow }) =>
      `/uploadhub/resident/invoice/${id}/duplicates/skip/${skipApprovalFlow}`,
  },
  [ApiOperation.BackInvoiceToReview]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/backtoreview`,
  },
  [ApiOperation.SendInvoiceToIntegrationService]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: ({ id }) => `/uploadhub/resident/invoice/${id}/integrationsend`,
  },
  [ApiOperation.GetInvoicesReport]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => `/uploadhub/resident/invoice/report`,
  },

  [ApiOperation.GetUploadHubSettings]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/uploadhub/setting',
  },
  [ApiOperation.GetUploadHubRoles]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/Configuration/roles',
  },
  [ApiOperation.UpdateAutoSendInvoiceToOCR]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/uploadhub/setting/autoSendInvoiceToOCR',
  },
  [ApiOperation.UpdateAllowUsersToSeeOnlyInvoicesUploadedByThem]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/uploadhub/setting/allowUsersToSeeOnlyInvoicesUploadedByThem',
  },
  [ApiOperation.UpdateAutoSendInvoiceToIntegrationService]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/uploadhub/setting/autoSendInvoiceToIntegrationService',
  },
  [ApiOperation.UpdateOcrProcessedPagesLimit]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/uploadhub/setting/ocrProcessedPagesLimit',
  },
  [ApiOperation.UpdateInvoiceReceiver]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/uploadhub/setting/invoiceReceiver',
  },
  [ApiOperation.UpdateUsIteGrossAmount]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/uploadhub/setting/doNotUseVat',
  },
  [ApiOperation.GetSynchronizedInboxes]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/uploadhub/setting/email',
  },
  [ApiOperation.AddSynchronizedInbox]: {
    client: 'appClient',
    method: ApiMethodEnum.POST,
    url: () => '/uploadhub/setting/email',
  },
  [ApiOperation.UpdateSynchronizedInbox]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/uploadhub/setting/email/${id}`,
  },
  [ApiOperation.UpdateSynchronizedInboxAutoSync]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: ({ id }) => `/uploadhub/setting/email/${id}/autosync`,
  },
  [ApiOperation.DeleteSynchronizedInbox]: {
    client: 'appClient',
    method: ApiMethodEnum.DELETE,
    url: ({ id }) => `/uploadhub/setting/email/${id}`,
  },

  [ApiOperation.GetResidentProperties]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/uploadhub/datasource/resident/properties',
  },
  [ApiOperation.GetResidentSuppliers]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/uploadhub/datasource/resident/suppliers',
  },
  [ApiOperation.GetResidentBankAccounts]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/uploadhub/datasource/resident/bankaccounts',
  },
  [ApiOperation.GetResidentWorkOrders]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/uploadhub/datasource/resident/workorders',
  },
  [ApiOperation.GetResidentSchedules]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/uploadhub/datasource/resident/schedules',
  },
  [ApiOperation.GetResidentCategories]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/uploadhub/datasource/resident/categories',
  },

  [ApiOperation.GetConfiguration]: {
    client: 'appClient',
    method: ApiMethodEnum.GET,
    url: () => '/Configuration',
  },
  [ApiOperation.SetConfigurationLogo]: {
    client: 'appClient',
    method: ApiMethodEnum.PUT,
    url: () => '/Configuration/logo',
  },
};
