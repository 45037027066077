import { DataSourceTypesType } from '../../../../../../../types/data-source/DataSourceType.type';

export interface InvoiceReceiverFormInterface {
  invoiceReceiver: number | '';
  primaryDataSources: { [key in DataSourceTypesType]: string };
}

export const defaultValues: InvoiceReceiverFormInterface = {
  invoiceReceiver: '',
  primaryDataSources: {
    residentSuppliers: '',
    residentProperties: '',
    residentBankAccounts: '',
    residentWorkOrders: '',
    residentCategories: '',
  },
};
