import React, { FC, ReactElement } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import classes from './SupplierEditWarning.module.scss';

const SupplierEditWarningComponent: FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={classes['supplier-edit-warning']} data-cy="supplier-edit-warning">
      <WarningIcon className={classes['supplier-edit-warning__icon']} />
      <p className={classes['supplier-edit-warning__text']}>
        {t('invoices.tabs.toVerify.supplierMatchModal.warnings.edit')}
      </p>
    </div>
  );
};

export default SupplierEditWarningComponent;
