import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useDeleteRoleMutation from '../../../../../../../../../services/react-query-hooks/mutations/role/useDeleteRoleMutation';
import DeleteModalComponent from '../../../../../../../../components/modal/delete-modal/DeleteModal.component';
import { RoleInterface } from '../../../../../../../../../types/roles/Role.interface';

interface DeleteRolePropsInterface {
  role: RoleInterface;
  close: () => void;
}

const DeleteRole: FC<DeleteRolePropsInterface> = ({ role, close }) => {
  const { t } = useTranslation();

  const { mutate: deleteRole, isLoading, isSuccess } = useDeleteRoleMutation();

  return (
    <DeleteModalComponent
      text={t('settings.sections.permission.subsections.role.deleteModal.message')}
      close={close}
      isLoading={isLoading}
      isSuccess={isSuccess}
      mutate={() => deleteRole(role)}
    />
  );
};

export default DeleteRole;
