import React, { FC, ReactElement } from 'react';
import { TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { InvoiceDuplicateRowPropsInterface } from './interfaces/InvoiceDuplicateRowProps.interface';
import { AlignEnum } from '../../../../../../../../enums/Align.enum';
import { Button } from '../../../../../../../components/material';
import classes from './InvoiceDuplicateRow.module.scss';
import useDate from '../../../../../../../../utils/hooks/date-format/useDate';
import useFile from '../../../../../../../../utils/hooks/file/useFile.hook';

const InvoiceDuplicateRowComponent: FC<InvoiceDuplicateRowPropsInterface> = ({
  invoice,
  variant = 'row',
}): ReactElement => {
  const { t } = useTranslation();
  const { format } = useDate({ time: true });
  const { download } = useFile(invoice.fileLocalization);

  return (
    <TableRow
      className={classNames(
        classes['invoice-duplicate-row'],
        classes[`invoice-duplicate-row--${variant}`],
      )}
    >
      <TableCell
        component="th"
        scope="row"
        className={classNames(classes['invoice-duplicate-row__cell'], classes['cell--name'])}
        align={AlignEnum.CENTER}
      >
        <p className={classes.cell__title}>{t('invoices.duplicates.columns.blockName')}</p>
        <p className={classes.cell__value}>{invoice.blockName}</p>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        className={classNames(classes['invoice-duplicate-row__cell'], classes['cell--name'])}
        align={AlignEnum.CENTER}
      >
        <p className={classes.cell__title}>{t('invoices.duplicates.columns.supplierName')}</p>
        <p className={classes.cell__value}>{invoice.supplierName}</p>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        className={classNames(classes['invoice-duplicate-row__cell'], classes['cell--name'])}
        align={AlignEnum.CENTER}
      >
        <p className={classes.cell__title}>{t('invoices.duplicates.columns.invoiceNumber')}</p>
        <p className={classes.cell__value}>{invoice.invoiceNumber}</p>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        className={classNames(classes['invoice-duplicate-row__cell'], classes['cell--name'])}
        align={AlignEnum.CENTER}
      >
        <p className={classes.cell__title}>{t('invoices.duplicates.columns.totalAmount')}</p>
        <p className={classes.cell__value}>{invoice.totalAmount}</p>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        className={classNames(classes['invoice-duplicate-row__cell'], classes['cell--name'])}
        align={AlignEnum.CENTER}
      >
        <p className={classes.cell__title}>{t('invoices.duplicates.columns.registerDate')}</p>
        <p className={classes.cell__value}>{format(invoice.registerDate)}</p>
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        className={classNames(classes['invoice-duplicate-row__cell'], classes['cell--name'])}
        align={AlignEnum.CENTER}
      >
        <Button onClick={() => download()}>{t('global.download')}</Button>
      </TableCell>
    </TableRow>
  );
};

export default InvoiceDuplicateRowComponent;
