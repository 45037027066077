import { UseFormSetError } from 'react-hook-form';
import { ApiError } from '@datahub/api-client';
import CommonSetErrorType from './CommonSetError.type';
import ErrorHandled from './ErrorHandled.enum';
import { useAlert } from '../../alert/Alert.provider';

interface useErrorHandlerPropsInterface {
  errorCode: string;
  errorMessage: string;
  message?: string;
  field?: string;
  hideAlert?: boolean;
}

interface useCommonErrorHandlerPropsInterface extends useErrorHandlerPropsInterface {
  setError?: CommonSetErrorType;
}

export interface CommonValidationErrorHandlerInterface {
  setError: UseFormSetError<any>;
  options?: {
    shouldFocus: boolean;
  };
}

interface useValidationErrorHandlerPropsInterface
  extends CommonValidationErrorHandlerInterface,
    useErrorHandlerPropsInterface {
  field: string;
}

const uppercaseFirstChar = (field: string | undefined): string => {
  if (!field) {
    return '';
  }

  const [first, ...rest] = field.split('');

  return `${first.toUpperCase()}${rest.join('')}`;
};

export const handleCommonErrorMessage = ({
  message,
  setError,
  errorCode,
  errorMessage,
}: useCommonErrorHandlerPropsInterface) => {
  if (message === errorCode) {
    if (setError) {
      setError(errorMessage);
    }

    return ErrorHandled.HANDLED;
  }

  return ErrorHandled.NOT_HANDLED;
};

export const useCommonErrorHandler = ({
  setError,
  errorCode,
  errorMessage,
  field,
  hideAlert,
}: useCommonErrorHandlerPropsInterface) => {
  const { showAlert } = useAlert();

  const handleErrorMessage = ({ message }: useCommonErrorHandlerPropsInterface) => {
    if (message === errorCode) {
      if (setError) {
        setError(errorMessage);
      }

      if (!hideAlert) {
        showAlert(errorMessage, 'error');
      }

      return ErrorHandled.HANDLED;
    }

    return ErrorHandled.NOT_HANDLED;
  };

  const handler = (error: ApiError<any>) => {
    const message = error.response?.data.message;
    const responseModel = error.response?.data.responseModel;

    if (responseModel && field) {
      const valField = uppercaseFirstChar(field);
      const responseValFieldModel = responseModel[valField];

      if (
        responseValFieldModel &&
        Array.isArray(responseValFieldModel) &&
        responseValFieldModel.length > 0
      ) {
        return handleErrorMessage({
          message: responseValFieldModel[0],
          setError,
          errorCode,
          errorMessage,
          hideAlert,
        });
      }
    }

    return handleErrorMessage({
      message,
      setError,
      errorCode,
      errorMessage,
      hideAlert,
    });
  };

  return {
    handler,
    handleErrorMessage,
  };
};

export const useValidationErrorHandler = ({
  setError,
  errorCode,
  errorMessage,
  field,
  options,
}: useValidationErrorHandlerPropsInterface) => {
  const { handler } = useCommonErrorHandler({
    setError: (message: string) => setError(field, { message }, options),
    errorCode,
    errorMessage,
    field,
    hideAlert: true,
  });

  return handler;
};
