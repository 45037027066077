import { useTranslation } from 'react-i18next';
import CommonSetErrorType from '../CommonSetError.type';
import { useCommonErrorHandler } from '../useErrorHandler';

const useUserNotFoundErrorHandler = (setError?: CommonSetErrorType) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'USER_NOT_FOUND',
    errorMessage: t('settings.sections.permission.subsections.user.controls.errors.notFound'),
  });

  return handler;
};

export default useUserNotFoundErrorHandler;
