import React, { forwardRef, Ref, useRef } from 'react';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { TextFieldProps, useMediaQuery } from '@mui/material';
import TextField from '../text-field/TextField.component';
import { DatePickerPropsInterface } from './interfaces/DatePickerProps.interface';
import primaryTheme from '../../../../themes/primary-theme';
import './DatePicker.component.scss';

// TODO: support string value
const DatePicker = forwardRef(
  (props: DatePickerPropsInterface, ref: Ref<HTMLInputElement> | undefined) => {
    const textFieldRef = useRef<unknown>();
    const { format, onBlur, id, ...rest } = props;
    const isSmallScreen = !useMediaQuery(primaryTheme.breakpoints.up('md'));
    const datePickerProps = {
      ...rest,
      inputFormat: format,
      disablePast: props.disablePast,
      renderInput: (inputProps: TextFieldProps) => (
        <TextField
          {...inputProps}
          id={id}
          error={props.error}
          onBlur={() => {
            onBlur();
          }}
          ref={textFieldRef}
          required={props.required}
          disabled={props.disabled}
          data-cy={props['data-cy']}
        />
      ),
      inputRef: ref,
      className: 'data-hub-date-picker',
      onClose: () => onBlur(),
    };

    return isSmallScreen ? (
      <MobileDatePicker {...datePickerProps} />
    ) : (
      <DesktopDatePicker {...datePickerProps} />
    );
  },
);

export default DatePicker;
