import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { ConfirmInvitationPropsInterface } from '../../interfaces/ConfirmInvitationPropsInterface';
import { Button } from '../../../../../components/material';

const InvitationCompletedConfirmationComponent: FC<ConfirmInvitationPropsInterface> = ({
  email,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" rowSpacing={4}>
      <Grid item>
        <p>{t('landingPage.confirmInvitationForm.invitationCompleted.confirmation', { email })}</p>
        <p>{t('landingPage.confirmInvitationForm.invitationCompleted.signInHint')}</p>
      </Grid>
      <Grid item>
        <Button component={Link} to="/" variant="contained" type="button" fullWidth>
          {t('landingPage.loginForm.login')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default InvitationCompletedConfirmationComponent;
