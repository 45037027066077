import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import { InvoiceDuplicatesResponseType } from '../../../../../types/upload-hub/invoice/duplicates/InvoiceDuplicatesResponse.type';
import { getInvoiceDuplicatesQueryKey } from '../../queryKeys';

const useGetInvoiceDuplicatesQuery = (id: string) => {
  const { request } = useRequest<InvoiceDuplicatesResponseType>(ApiOperation.GetInvoiceDuplicates);

  return useQuery([getInvoiceDuplicatesQueryKey, id], async () => {
    const res = await request({ urlParams: { id } });

    return res.data.responseModel;
  });
};

export default useGetInvoiceDuplicatesQuery;
