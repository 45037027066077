import React, { FC, ReactElement } from 'react';
import { Grid } from '@mui/material';
import { SupplierMatchFormPropsInterface } from './interfaces/SupplierMatchFormProps.interface';
import FormContainerComponent from '../../../../../../../../../components/form-container/FormContainer.component';
import classes from './SupplierMatchForm.module.scss';
import SupplierController from './components/supplier-controller/SupplierController.component';
import FilePreview from '../../../../../../../../../components/file-preview/FilePreview.component';
import SupplierEditWarningComponent from './components/supplier-edit-warning/SupplierEditWarning.component';

const SupplierMatchFormComponent: FC<SupplierMatchFormPropsInterface> = ({
  fileName,
  fileLocalization,
  variant,
}): ReactElement => {
  return (
    <FormContainerComponent className={classes['supplier-match-form']} fullWidth>
      <Grid container direction="column" rowSpacing={4}>
        <Grid item className={classes['supplier-match-form__form']} xs={12}>
          <SupplierController />
        </Grid>

        {variant === 'assign' && (
          <Grid item xs={12}>
            <FilePreview fileName={fileName} path={fileLocalization} />
          </Grid>
        )}

        {variant === 'edit' && <SupplierEditWarningComponent />}
      </Grid>
    </FormContainerComponent>
  );
};

export default SupplierMatchFormComponent;
