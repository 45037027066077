import React, { useMemo, useState } from 'react';
import UpdateDataSourceModalComponent from '../UpdateDataSourceModal.component';

const useUpdateDataSource = () => {
  const [updateDataSourceModalOpen, setUpdateSourceModalOpen] = useState(false);
  const [updateDataSourceData, setUpdateDataSourceData] = useState<
    { id: string; name: string; origin: number; type: number; autoSync: boolean } | undefined
  >(undefined);

  const updateDataSourceModal = useMemo(() => {
    if (!updateDataSourceData) {
      return <> </>;
    }

    return (
      <UpdateDataSourceModalComponent
        open={updateDataSourceModalOpen}
        setOpen={setUpdateSourceModalOpen}
        {...updateDataSourceData}
      />
    );
  }, [updateDataSourceData, updateDataSourceModalOpen]);

  return { updateDataSourceModal, setUpdateSourceModalOpen, setUpdateDataSourceData };
};

export default useUpdateDataSource;
