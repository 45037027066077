import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import { getResidentSuppliersQueryKey } from '../../queryKeys';
import { ResidentSuppliersResponseType } from '../../../../../types/upload-hub/resident/supplier/ResidentSuppliersResponse.type';

const useGetResidentSuppliersQuery = () => {
  const { request } = useRequest<ResidentSuppliersResponseType>(ApiOperation.GetResidentSuppliers);

  return useQuery(
    getResidentSuppliersQueryKey,
    async () => {
      const res = await request();

      return res.data.responseModel.residentSuppliers;
    },
    {
      enabled: true,
    },
  );
};

export default useGetResidentSuppliersQuery;
