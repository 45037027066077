import React, { FC, memo, ReactElement, useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormControl, MenuItem, Button } from '../../../../../components/material';
import Select from '../../../../../components/material/select/Select.component';
import { InvoiceReceiverPropsInterface } from './interfaces/InvoiceReceiverProps.interface';
import classes from './InvoiceReceiver.module.scss';
import {
  InvoiceReceivers,
  InvoiceReceiversMap,
  InvoiceReceiversType,
} from '../../../../../../types/upload-hub/invoice-receiver/InvoiceReceivers.type';
import useUpdateInvoiceReceiverMutation from '../../../../../../services/react-query-hooks/mutations/plugin-settings/upload-hub/settings/useUpdateInvoiceReceiver.mutation';
import PrimaryDataSourcesComponent from './components/primary-data-sources/PrimaryDataSources.component';
import FormContainerComponent from '../../../../../components/form-container/FormContainer.component';
import {
  DataSourceTypesMap,
  DataSourceTypesType,
} from '../../../../../../types/data-source/DataSourceType.type';
import {
  defaultValues,
  InvoiceReceiverFormInterface,
} from './interfaces/InvoiceReceiverForm.interface';
import { PrimaryDataSourceInterface } from '../../../../../../types/data-source/PrimaryDataSource.interface';

const InvoiceReceiverComponent: FC<InvoiceReceiverPropsInterface> = ({
  invoiceReceiver,
  primaryDataSources,
}): ReactElement => {
  const { t } = useTranslation();

  const { mutate: updateInvoiceReceiver, isLoading } = useUpdateInvoiceReceiverMutation();

  const methods = useForm<InvoiceReceiverFormInterface>({
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues,
      invoiceReceiver:
        Number.isInteger(invoiceReceiver) && invoiceReceiver > 0 ? invoiceReceiver : '',
    },
  });

  const onSubmitHandler = ({
    invoiceReceiver: submittedInvoiceReceiver,
    primaryDataSources: submittedPrimaryDataSources,
  }: InvoiceReceiverFormInterface) => {
    if (submittedInvoiceReceiver !== '') {
      updateInvoiceReceiver({
        invoiceReceiver: submittedInvoiceReceiver,
        primaryDataSources: Object.entries(submittedPrimaryDataSources).map(
          (entry: [string, string]): PrimaryDataSourceInterface => ({
            primaryDataSourceNumber: entry[1],
            primaryDataSourceType: DataSourceTypesMap[entry[0] as DataSourceTypesType],
          }),
        ),
      });
    }
  };

  const getDataSourceForType = useCallback(
    (type: number): PrimaryDataSourceInterface | undefined => {
      return primaryDataSources.find(
        (dataSource: PrimaryDataSourceInterface) => dataSource.primaryDataSourceType === type,
      );
    },
    [primaryDataSources],
  );

  useEffect(() => {
    if (primaryDataSources && primaryDataSources.length > 0) {
      const { setValue } = methods;

      setValue('primaryDataSources', {
        residentSuppliers: getDataSourceForType(1)?.primaryDataSourceNumber || '',
        residentProperties: getDataSourceForType(2)?.primaryDataSourceNumber || '',
        residentBankAccounts: getDataSourceForType(3)?.primaryDataSourceNumber || '',
        residentWorkOrders: getDataSourceForType(4)?.primaryDataSourceNumber || '',
        residentCategories: getDataSourceForType(5)?.primaryDataSourceNumber || '',
      });
    }
  }, [methods, primaryDataSources, getDataSourceForType]);

  return (
    <FormProvider {...methods}>
      <FormContainerComponent className={classes['invoice-receiver']} fullWidth>
        <Grid container direction="column" rowSpacing={4}>
          <Grid item>
            <FormControl error={!!methods.formState.errors?.invoiceReceiver}>
              <Controller
                name="invoiceReceiver"
                control={methods.control}
                defaultValue={invoiceReceiver}
                rules={{
                  required: t(
                    'pluginSettings.sections.uploadHub.subsections.invoiceReceiver.controls.invoiceReceiver.errors.required',
                  ),
                }}
                render={(renderProps) => (
                  <Select
                    disabled={isLoading}
                    error={!!methods.formState.errors?.invoiceReceiver}
                    {...renderProps.field}
                  >
                    {Object.values(InvoiceReceivers).map((invReceiver: InvoiceReceiversType) => (
                      <MenuItem
                        value={InvoiceReceiversMap[invReceiver]}
                        key={InvoiceReceiversMap[invReceiver]}
                      >
                        {t(
                          `pluginSettings.sections.uploadHub.subsections.invoiceReceiver.receivers.${invReceiver}`,
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <span role="alert">{methods.formState.errors?.invoiceReceiver?.message}</span>
            </FormControl>
          </Grid>
          <Grid item>
            {methods.watch('invoiceReceiver') !== '' && (
              <PrimaryDataSourcesComponent
                invoiceReceiver={Number(methods.watch('invoiceReceiver'))}
                primaryDataSources={primaryDataSources}
                isLoading={isLoading}
              >
                <Grid item xs={12} className={classes['invoice-receiver__button-container']}>
                  <Button
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                    onClick={methods.handleSubmit(onSubmitHandler)}
                    className={classes['button-container__button']}
                  >
                    {t('global.save')}
                  </Button>
                </Grid>
              </PrimaryDataSourcesComponent>
            )}
          </Grid>
        </Grid>
      </FormContainerComponent>
    </FormProvider>
  );
};

const InvoiceReceiver = memo(InvoiceReceiverComponent);

export default InvoiceReceiver;
