import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSubscription } from '../../../../services/subscription/Subscription.provider';
import CommercialWebsiteCardComponent from '../../../components/commercial-website-card/CommercialWebsiteCard.component';
import classes from './Dashboard.module.scss';
import { useUserPermissions } from '../../../../services/user/User.provider';
import NoPermissionsView from '../../../components/no-permissions/NoPermissions.view';

const DashboardComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { hasPlugins } = useSubscription();
  const { hasAnyPermission } = useUserPermissions();

  if (!hasAnyPermission) {
    return <NoPermissionsView content={t('dashboard.noPermissions.content')} />;
  }

  if (!hasPlugins) {
    return (
      <div className={classNames(classes.dashboard, classes['dashboard--empty'])}>
        <h4 className={classes.dashboard__header}>{t('dashboard.empty.header')}</h4>
        <p className={classes.dashboard__content}>{t('dashboard.empty.content')}</p>
        <CommercialWebsiteCardComponent />
      </div>
    );
  }

  return (
    <div className={classes.dashboard}>
      {/* TODO: add plugins cards/widgets/general info when dashboard functionality is in development */}
    </div>
  );
};

export default DashboardComponent;
