import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, FormControl } from '../../../../../../../../components/material';
import FormContainerComponent from '../../../../../../../../components/form-container/FormContainer.component';
import RoleFormInterface from './RoleForm.interface';
import classes from './RoleForm.module.scss';

const RoleFormComponent: FC = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<RoleFormInterface>();

  return (
    <FormContainerComponent className={classes['role-form-section']} fullWidth>
      <Grid container direction="row">
        <Grid className={classes['role-form-section__form']} item xs={12}>
          <Grid container direction="column" rowSpacing={4}>
            <Grid item>
              <FormControl error={!!errors.roleName}>
                <Input
                  id="name"
                  fullWidth
                  label={t('settings.sections.permission.subsections.role.controls.name.label')}
                  {...register('roleName', {
                    required: t(
                      'settings.sections.permission.subsections.role.controls.name.errors.required',
                    ),
                  })}
                  required
                />
                <span role="alert">{errors.roleName?.message}</span>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormContainerComponent>
  );
};

export default RoleFormComponent;
