import { Grid, Select as MatSelect, SelectProps } from '@mui/material';
import React, { forwardRef, PropsWithChildren, ReactElement, useRef } from 'react';
import './Select.component.scss';
import classNames from 'classnames';

type SelectCustomPropsInterface = PropsWithChildren<SelectProps> & {
  grid?: number;
};

const Select = forwardRef((props: SelectCustomPropsInterface, ref): ReactElement => {
  const labelId = `${props.name}_label`;
  const refTest = useRef<HTMLSelectElement>();
  let label;

  if (props.label) {
    label = (
      <label
        htmlFor={labelId}
        className={classNames(
          'data-hub-select__label',
          props.required ? 'data-hub-select__label--required' : '',
        )}
      >
        {props.label}
      </label>
    );
  }

  const input = (
    <MatSelect
      labelId={labelId}
      className="data-hub-select__input"
      ref={ref}
      inputRef={refTest}
      {...props}
    >
      {props.children}
    </MatSelect>
  );

  if (props.grid) {
    return (
      <Grid
        container
        direction="row"
        columnSpacing={2}
        className="data-hub-select data-hub-select--grid"
      >
        <Grid item xs={4}>
          {label}
        </Grid>
        <Grid item xs={8}>
          {input}
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {label}
      {input}
    </>
  );
});

export default Select;
