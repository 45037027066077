import { InvoiceVerificationPayloadInterface } from '../../../../../../../../../../../types/upload-hub/invoice/verification/InvoiceVerificationPayload.interface';
import { ResidentPropertyInterface } from '../../../../../../../../../../../types/upload-hub/resident/property/ResidentProperty.interface';
import { ResidentSupplierInterface } from '../../../../../../../../../../../types/upload-hub/resident/supplier/ResidentSupplier.interface';
import { ResidentBankAccountInterface } from '../../../../../../../../../../../types/upload-hub/resident/bank-account/ResidentBankAccount.interface';
import { ResidentWorkOrderInterface } from '../../../../../../../../../../../types/upload-hub/resident/work-orders/ResidentWorkOrder.interface';
import { ResidentScheduleInterface } from '../../../../../../../../../../../types/upload-hub/resident/schedule/ResidentSchedule.interface';
import { ResidentCategoryInterface } from '../../../../../../../../../../../types/upload-hub/resident/category/ResidentCategory.interface';
import { InvoiceFieldValueInterface } from '../../../../../../../../../../../types/upload-hub/invoice/InvoiceProperty.interface';

export interface VerifyInvoiceDataFormInterface extends InvoiceVerificationPayloadInterface {
  invoiceDate: Date | null;
  items: VerifyInvoiceDataFormInterface[];
  [key: number | string]:
    | VerifyInvoiceDataFormInterface[]
    | InvoiceFieldValueInterface
    | ResidentPropertyInterface
    | ResidentSupplierInterface
    | ResidentBankAccountInterface
    | ResidentWorkOrderInterface
    | ResidentScheduleInterface
    | ResidentCategoryInterface
    | Date
    | string
    | number
    | undefined
    | null;
}

export const defaultValues: VerifyInvoiceDataFormInterface = {
  invoiceDate: null,
  items: [],
};
