import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../../api';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import { InvoiceReportMutationPropsInterfaceWithValidationPropsInterface } from './InvoicesReportMutationProps.interface';
import useOnError from '../../../../error-handlers/useOnError';
import { InvoicesReportPayloadInterface } from '../../../../../../types/upload-hub/invoice/report/InvoicesReportPayload.interface';
import { InvoicesReportResponseType } from '../../../../../../types/upload-hub/invoice/report/InvoicesReportResponse.type';
import { useAlert } from '../../../../../alert/Alert.provider';

const useGetInvoicesReportMutation = ({
  setOtherError,
}: InvoiceReportMutationPropsInterfaceWithValidationPropsInterface) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request } = useRequest<InvoicesReportResponseType>(ApiOperation.GetInvoicesReport);

  // TODO: handle invalid dates or something if needed
  // console.log(setValidationError);
  // const uniqueNameErrorHandler = useRoleUniqueNameErrorHandler(setValidationError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async (payload: InvoicesReportPayloadInterface) => {
      const res = await request({ params: { ...payload } });

      return { data: res.data, payload };
    },
    {
      onSuccess: () => {
        showAlert(t('invoices.tabs.processed.actions.report.success'), 'success');
      },
      onError,
    },
  );
};

export default useGetInvoicesReportMutation;
