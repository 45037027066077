import React, { FC, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import UserFormInterface from '../user-form/UserForm.interface';
import UserPayloadInterface, {
  UserPayloadWithId,
} from '../../../../../../../../../../types/users/UserPayload.interface';
import FormModalComponent from '../../../../../../../../../components/modal/form-modal/FormModal.component';
import UserFormComponent from '../user-form/UserForm.component';
import { RoleInterface } from '../../../../../../../../../../types/roles/Role.interface';
import useUpdateUserMutation from '../../../../../../../../../../services/react-query-hooks/mutations/user/useUpdateUserMutation';

interface UpdateUserPropsInterface extends UserPayloadWithId {
  availableRoles: RoleInterface[] | undefined;
  close: () => void;
}

const UpdateUserComponent: FC<UpdateUserPropsInterface> = ({
  id,
  email,
  fullName,
  roles,
  availableRoles,
  close,
}): ReactElement => {
  const methods = useForm<UserFormInterface>({
    mode: 'onBlur',
    defaultValues: {
      email,
      fullName,
      roles,
    },
  });

  const {
    mutate: updateUser,
    isLoading,
    isSuccess,
  } = useUpdateUserMutation({
    setValidationError: methods.setError,
  });

  const onSubmitHandler = (data: UserPayloadInterface) => updateUser({ id, payload: data });

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmitHandler)}
      isSuccess={isSuccess}
      isLoading={isLoading}
    >
      <UserFormComponent roles={availableRoles} />
    </FormModalComponent>
  );
};

export default UpdateUserComponent;
