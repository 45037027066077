import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { InvoiceMutationPropsInterface } from './InvoiceMutationProps.interface';
import { ApiOperation } from '../../../../api';
import useGeneralErrorHandler from '../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../error-handlers/useOnError';
import { queryClient } from '../../../../queryClient';
import { useAlert } from '../../../../alert/Alert.provider';

const useBulkDeleteInvoiceMutation = (
  reloadQuery: string,
  { setOtherError }: InvoiceMutationPropsInterface = {},
) => {
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const { request } = useRequest(ApiOperation.BulkDeleteInvoices);

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async (invoicesIds: string[]) => {
      const res = await request({
        data: {
          invoicesIds,
        },
      });
      return res.data;
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(reloadQuery);

        showAlert(t('invoices.bulkDeleteInvoices.success'), 'info');
      },
      onError,
    },
  );
};

export default useBulkDeleteInvoiceMutation;
