import React, { FC, ReactElement } from 'react';
import { Pagination as MatPagination, PaginationProps } from '@mui/material';
import classNames from 'classnames';
import './Pagination.component.scss';

const Pagination: FC<PaginationProps> = ({ className, ...rest }): ReactElement => {
  return (
    <MatPagination
      className={classNames('data-hub-pagination', className)}
      data-cy="pagination"
      {...rest}
    />
  );
};

export default Pagination;
