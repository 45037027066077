import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { getConfigurationQueryKey } from '../../queries/queryKeys';
import { queryClient } from '../../../queryClient';
import { ApiOperation } from '../../../api';
import { useSubscription } from '../../../subscription/Subscription.provider';
import { useAlert } from '../../../alert/Alert.provider';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../error-handlers/useOnError';
import { SetLogoMutationPropsInterface } from './SetLogoMutationProps.interface';

const useSetLogoMutation = ({ setOtherError }: SetLogoMutationPropsInterface = {}) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.SetConfigurationLogo);
  const { retrieveConfiguration } = useSubscription();

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async (logo: File) => {
      const data = new FormData();
      data.append('logo', logo);
      const res = await request({ data });

      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getConfigurationQueryKey);
        retrieveConfiguration().then();

        showAlert(t('settings.sections.global.subsections.logo.actions.upload.success'), 'success');
      },
      onError,
    },
  );
};

export default useSetLogoMutation;
