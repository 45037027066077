import { useQuery } from 'react-query';
import { QueryObserverOptions } from 'react-query/types/core/types';
import { ApiResponse, RequestPropsInterface, UrlParamsTypeInterface } from '@datahub/api-client';
import { TableFiltersInterface } from '../../../views/components/table/interfaces/TableFilters.interface';

export interface PaginatedDataInterface<T> {
  data: T;
  pagination: PaginationInterface;
}

export interface PaginationInterface {
  CurrentPage: number;
  HasNext: boolean;
  HasPrevious: boolean;
  PageSize: number;
  TotalCount: number;
  TotalPages: number;
}

const usePaginatedListQuery = <T>(
  request: (props?: RequestPropsInterface) => Promise<ApiResponse<T>>,
  queryKey: string,
  filters: TableFiltersInterface | undefined,
  urlParams?: UrlParamsTypeInterface,
  queryOptions?: QueryObserverOptions<PaginatedDataInterface<T>, any, any, any, any>,
) => {
  const { data, ...rest } = useQuery(
    [queryKey, { ...filters, ...urlParams }],
    async (): Promise<PaginatedDataInterface<T>> => {
      const res = await request({ params: filters, urlParams });

      return {
        data: res.data,
        pagination: res.headers['x-pagination'] ? JSON.parse(res.headers['x-pagination']) : null,
      };
    },
    { keepPreviousData: true, ...queryOptions },
  );

  return {
    data: data?.data,
    pagination: data?.pagination,
    ...rest,
  };
};

export default usePaginatedListQuery;
