import CurrencyType from '../../../types/Currency.type';
import { ISO4217CodeEnum } from './enums/ISO4217Code.enum';

interface CurrencyConfigInterface {
  symbol?: string;
  iso4217?: ISO4217CodeEnum;
  symbolPosition?: 'start' | 'end';
  decimalSeparator?: string;
  thousandSeparator?: string;
  whitespace?: boolean;
  regex?: RegExp;
  regexOptionalSymbol?: RegExp;
  regexWithZero?: RegExp;
  regexOptionalSymbolWithZero?: RegExp;
  regexForNonZero?: RegExp;
}

const defaultCurrencyConfig: CurrencyConfigInterface = {
  decimalSeparator: '.',
  thousandSeparator: ',',
  symbolPosition: 'end',
  whitespace: true,
};

const currencySymbolConfig: { [key in CurrencyType]: CurrencyConfigInterface } = {
  pound: {
    symbol: '£',
    iso4217: ISO4217CodeEnum.GBP,
    symbolPosition: 'start',
    decimalSeparator: '.',
    thousandSeparator: ',',
    whitespace: false,
    regex: /^£(?:0\.\d?[1-9]|((?!(0))\d*(,(?=\d{3}))?\d+)+((?!2)[.]\d?\d)?)$/,
    regexOptionalSymbol:
      /(^£? ?(?:0\.\d?[1-9]|((?!(0))\d*(,(?=\d{3}))?\d+)+((?!2)[.]\d?\d)?)$)|(^(?:0\.\d?[1-9] ?£?|((?!(0))\d*(,(?=\d{3}))?\d+)+((?!2)[.]\d?\d)? ?£?)$)/,
    regexWithZero: /^£(?:0\.\d?[1-9]|(\d*(,(?=\d{3}))?\d+)+((?!2)[.]\d?\d)?)$/,
    regexOptionalSymbolWithZero:
      /(^£? ?(?:0\.\d?[1-9]|(\d*(,(?=\d{3}))?\d+)+((?!2)[.]\d?\d)?)$)|(^(?:0\.\d?[1-9] ?£?|(\d*(,(?=\d{3}))?\d+)+((?!2)[.]\d?\d)? ?£?)$)/,
    regexForNonZero: /^(?!(£?0|£?0.0|£?0.00)$).*$/,
  },
};

const getCurrencyConfig = (currency: CurrencyType | undefined) => {
  return { ...defaultCurrencyConfig, ...(currency && currencySymbolConfig[currency]) };
};

export default getCurrencyConfig;
