import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InvoiceNoteFormInterface, {
  defaultValues,
} from '../to-verify/components/verification/components/note-form/interfaces/InvoiceNoteForm.interface';
import { InvoiceNotePropsInterface } from '../to-verify/components/verification/components/note/interfaces/InvoiceNoteProps.interface';
import FormModalComponent from '../../../../../components/modal/form-modal/FormModal.component';
import InvoiceNoteFormComponent from '../to-verify/components/verification/components/note-form/InvoiceNoteForm.component';
import { InvoiceRejectPayloadInterface } from '../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/reject/interfaces/InvoiceRejectPayload.interface';
import { RejectInvoiceVariant } from './hooks/useRejectInvoice.hook';

interface RejectInvoicePropsInterface {
  close: () => void;
  onReject: (payload?: InvoiceRejectPayloadInterface) => void;
  variant: RejectInvoiceVariant;
  note?: string;
}

const RejectInvoice: FC<RejectInvoicePropsInterface> = ({
  close,
  onReject,
  variant,
  note: defaultNote,
}) => {
  const { t } = useTranslation();
  const methods = useForm<InvoiceNoteFormInterface>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      note: defaultNote || defaultValues.note,
    },
  });

  // @ts-ignore
  const onSubmitHandler = ({ note }: InvoiceNotePropsInterface): void => {
    onReject({
      note,
      invoiceId: '',
      invoiceReference: '',
    });
  };

  return (
    <FormModalComponent
      close={close}
      methods={methods}
      submit={methods.handleSubmit(onSubmitHandler)}
      submitText={t('global.confirm')}
      isSuccess={false}
      isLoading={false}
      disablePortal
    >
      <InvoiceNoteFormComponent
        message={t(`invoices.rejectInvoice${variant === 'reject' ? '' : 'ToAmend'}.message`)}
        hidden={!!defaultNote}
      />
    </FormModalComponent>
  );
};

export default RejectInvoice;
