import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleSmtpSynchronizedInboxInterface } from '../../../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';
import ModalComponent from '../../../../../../../components/modal/modal/Modal.component';
import { ModalControlPropsInterface } from '../../../../../../../components/modal/types/Props.interface';
import UpdateSynchronizedInboxView from './UpdateSynchronizedInbox.view';

interface UpdateSynchronizedInboxModalComponentProps
  extends SimpleSmtpSynchronizedInboxInterface,
    ModalControlPropsInterface {
  id: string;
}

const UpdateSynchronizedInboxModalComponent = (
  props: UpdateSynchronizedInboxModalComponentProps,
) => {
  const { id, open, setOpen, ...rest } = props;
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t(
        'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.updateSynchronizedInboxesModal.title',
      )}
      open={open}
      handleClose={handleClose}
      content={<UpdateSynchronizedInboxView id={id} close={handleClose} {...rest} />}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
      actions
    />
  );
};

export default UpdateSynchronizedInboxModalComponent;
