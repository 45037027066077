import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PropsInterface } from '../../interfaces/Props.interface';
import { NotificationContextTypeInterface } from './interfaces/NotificationContextType.interface';
import useGetNotificationsListQuery from '../react-query-hooks/queries/notification/useGetNotificationsList.query';
import useTableFilters from '../../views/components/table/hooks/TableFilters.hook';
import { NotificationInterface } from '../../types/notification/Notification.interface';
import { NotificationOriginEnum } from '../../enums/notification/NotificationOrigin.enum';
import { NotificationTypeEnum } from '../../enums/notification/NotificationType.enum';
import { queryClient } from '../queryClient';
import { getNotificationsQueryKey } from '../react-query-hooks/queries/queryKeys';
import {
  NotificationsTypeOptions,
  NotificationType,
  NotificationTypes,
} from '../../types/notification/Notification.type';
import {
  NotificationOriginType,
  NotificationOriginTypeGroupsMap,
  NotificationOriginTypes,
} from '../../types/notification/NotificationOrigin.type';
import useSignalRConnection from './connections/SignalR.connection';
import { useAlert } from '../alert/Alert.provider';
import { SnackbarAlertVariantInterface } from '../alert/interfaces/SnackbarAlert.interface';
import { pathTo } from '../../views/components/router/Router.outlet';
import { PathEnum } from '../../views/components/router/enums/Path.enum';
import useMarkNotificationAsReadMutation from '../react-query-hooks/mutations/notification/useMarkNotificationAsRead.mutation';

const notificationsPageSize = 10;

const NotificationContext = createContext<NotificationContextTypeInterface>(
  {} as NotificationContextTypeInterface,
);

const isNotificationUnread = (notification: NotificationInterface): boolean => !notification.isRead;

export const NotificationProvider: FC<PropsWithChildren<PropsInterface>> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { connection } = useSignalRConnection();
  const { showAlert } = useAlert();

  const [signalRNotifications, setSignalRNotifications] = useState<NotificationInterface[]>([]);
  const [hasNotOpenedSignalRNotifications, setHasNotOpenedSignalRNotifications] =
    useState<boolean>(false);

  const [anchorPopoverEl, setAnchorPopoverEl] = useState<HTMLButtonElement | null>(null);
  const [selectedNotificationTabIndex, setSelectedNotificationTabIndex] = useState<number>(0);

  const { mutate: markNotificationAsRead } = useMarkNotificationAsReadMutation({
    setOtherError: () => {},
    disableSuccessCallback: true,
  });

  const markNewNotificationAsRead = (notificationId: string) => {
    setSignalRNotifications((currentNotifications: NotificationInterface[]) => [
      ...currentNotifications.map((notification: NotificationInterface) => {
        return notification.notificationUniqueNumber === notificationId
          ? { ...notification, isRead: true }
          : { ...notification };
      }),
    ]);
  };

  const markAllNewNotificationsAsRead = () => {
    setSignalRNotifications((currentNotifications: NotificationInterface[]) => [
      ...currentNotifications.map((notification: NotificationInterface) => ({
        ...notification,
        isRead: true,
      })),
    ]);
  };

  const matchAlertVariantWithNotificationType = (
    type: NotificationTypeEnum,
  ): SnackbarAlertVariantInterface => {
    switch (type) {
      case NotificationTypeEnum.INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT: {
        return {
          variant: 'error',
          preemption: true,
        };
      }
      case NotificationTypeEnum.APPROVED_INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT: {
        return {
          variant: 'error',
          preemption: true,
        };
      }
      case NotificationTypeEnum.OCR_FAILED_TO_PROCESS_INVOICE: {
        return {
          variant: 'warning',
          preemption: true,
        };
      }
      case NotificationTypeEnum.MAILBOX_PROCESSING_ERROR: {
        return {
          variant: 'warning',
          preemption: false,
        };
      }
      case NotificationTypeEnum.LESS_THAN_HUNDRED_PAGES_LEFT: {
        return {
          variant: 'warning',
          preemption: false,
        };
      }
      case NotificationTypeEnum.DATA_SOURCE_SYNCHRONIZATION_FAILED: {
        return {
          variant: 'warning',
          preemption: false,
        };
      }
      default:
        return {
          variant: 'info',
          preemption: false,
        };
    }
  };

  const onNotificationClickHandler = useCallback(
    (notification: NotificationInterface): void => {
      switch (notification.type) {
        case NotificationTypeEnum.INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT: {
          navigate(pathTo([PathEnum.INVOICES, PathEnum.PROCESSED]));
          break;
        }

        case NotificationTypeEnum.APPROVED_INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT: {
          navigate(pathTo([PathEnum.INVOICES, PathEnum.PROCESSED]));
          break;
        }

        case NotificationTypeEnum.OCR_FAILED_TO_PROCESS_INVOICE: {
          navigate(pathTo([PathEnum.INVOICES, PathEnum.IN_PROGRESS]));
          break;
        }

        case NotificationTypeEnum.MAILBOX_PROCESSING_ERROR: {
          navigate(pathTo([PathEnum.SETTINGS, PathEnum.PLUGIN]));
          break;
        }

        case NotificationTypeEnum.LESS_THAN_HUNDRED_PAGES_LEFT: {
          navigate(pathTo([PathEnum.INVOICES, PathEnum.IN_PROGRESS]));
          break;
        }

        case NotificationTypeEnum.DATA_SOURCE_SYNCHRONIZATION_FAILED: {
          navigate(pathTo([PathEnum.SETTINGS, PathEnum.DATA_SRC]));
          break;
        }

        case NotificationTypeEnum.NYLAS_ACCOUNT_INACTIVE: {
          navigate(pathTo([PathEnum.SETTINGS, PathEnum.PLUGIN]));
          break;
        }

        default:
      }
    },
    [navigate],
  );

  const onNotificationReceivedHandler =
    (type: NotificationType) => (id: string, message: string, url: string, data: any) => {
      setHasNotOpenedSignalRNotifications(true);

      let origin = NotificationOriginEnum.SYSTEM;

      NotificationOriginTypes.forEach((originType: NotificationOriginType) => {
        const originTypeGroup = NotificationOriginTypeGroupsMap[originType];

        if (
          originTypeGroup &&
          NotificationOriginTypeGroupsMap[originType].includes(type as NotificationTypeEnum)
        ) {
          origin = originType as NotificationOriginEnum;
        }
      });

      const {
        translationKey: notificationTranslation,
        add,
        showAlert: showNotificationAlert,
        onReceiveCallback,
        urlSelfOpen,
        urlTranslationKey,
      } = NotificationsTypeOptions[type];

      const notification: NotificationInterface = {
        notificationUniqueNumber: id,
        message,
        origin,
        type: type as NotificationTypeEnum,
        isRead: false,
        url,
        urlSelfOpen,
        // @ts-ignore
        urlLabel: urlTranslationKey ? `notification.url.${urlTranslationKey}` : '',
      };

      if (add) {
        setSignalRNotifications((currentSignalRNotifications: NotificationInterface[]) => [
          notification,
          ...currentSignalRNotifications,
        ]);
      }

      if (showNotificationAlert) {
        const { variant, preemption } = matchAlertVariantWithNotificationType(
          type as NotificationTypeEnum,
        );

        showAlert(
          message ||
            String(
              t(
                // @ts-ignore
                `notification.types.${notificationTranslation}`,
              ),
            ),
          variant,
          { vertical: 'bottom', horizontal: 'right' },
          preemption,
          (): void => {
            markNotificationAsRead({
              notificationUniqueNumber: notification.notificationUniqueNumber,
            });
            markNewNotificationAsRead(notification.notificationUniqueNumber);
            onNotificationClickHandler(notification);
          },
        );
      }

      if (onReceiveCallback) {
        onReceiveCallback(data);
      }
    };

  useEffect(() => {
    if (hasNotOpenedSignalRNotifications && Boolean(anchorPopoverEl)) {
      setHasNotOpenedSignalRNotifications(false);

      queryClient.invalidateQueries(getNotificationsQueryKey);
    }
  }, [anchorPopoverEl, hasNotOpenedSignalRNotifications]);

  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      connection
        .start()
        .then(() => {
          NotificationTypes.forEach((type: NotificationType) => {
            const notificationTypeOptions = NotificationsTypeOptions[type];

            if (notificationTypeOptions) {
              const { signalREvent: notificationTypeSignalREvent } = NotificationsTypeOptions[type];

              if (notificationTypeSignalREvent) {
                connection.on(notificationTypeSignalREvent, onNotificationReceivedHandler(type));
              }
            }
          });
        })
        .catch((e) => console.error('SignalR connection failed: ', e));
    }

    return () => {
      if (connection) {
        NotificationTypes.forEach((type: NotificationType) => {
          const notificationTypeOptions = NotificationsTypeOptions[type];

          if (notificationTypeOptions) {
            const { signalREvent: notificationTypeSignalREvent } = NotificationsTypeOptions[type];

            if (notificationTypeSignalREvent) {
              connection.off(notificationTypeSignalREvent, onNotificationReceivedHandler(type));
            }
          }
        });

        connection.stop().then(() => console.info('SignalR connection closed'));
      }
    };
  }, [connection]);

  const { filters, dispatchFilters } = useTableFilters({
    PageSize: notificationsPageSize,
    OrderBy: 'Id desc',
  });
  const {
    data: notifications,
    pagination,
    isLoading,
    isFetching,
  } = useGetNotificationsListQuery(filters);

  const unreadNotifications = useCallback(
    (onlyFirstPage = false) => {
      const firstPageNotifications =
        notifications &&
        ((onlyFirstPage && pagination && pagination.CurrentPage === 1) || !onlyFirstPage)
          ? notifications
          : [];

      const allNotifications = [...(firstPageNotifications || []), ...signalRNotifications];
      const allNotificationsMap = new Map();

      allNotifications.forEach((notification: NotificationInterface) => {
        if (isNotificationUnread(notification)) {
          allNotificationsMap.set(notification.notificationUniqueNumber, notification);
        }
      });

      return allNotificationsMap.size;
    },
    [notifications, pagination, signalRNotifications],
  );

  const memoizedSubscriptionState = useMemo(() => {
    return {
      selectedNotificationTabIndex,
      setSelectedNotificationTabIndex,
      anchorPopoverEl,
      setAnchorPopoverEl,

      newNotifications: signalRNotifications,
      hasNotOpenedNewNotifications: hasNotOpenedSignalRNotifications,
      markNewNotificationAsRead,
      markAllNewNotificationsAsRead,
      onNotificationClickHandler,

      unreadNotifications,
      areNewNotificationsReceived: unreadNotifications(true) > 0,
      notifications,
      isLoading,
      isFetching,

      notificationsPageSize,
      filters,
      dispatchNotificationsFilters: dispatchFilters,
      pagination,
    };
  }, [
    anchorPopoverEl,
    dispatchFilters,
    filters,
    hasNotOpenedSignalRNotifications,
    isFetching,
    isLoading,
    notifications,
    onNotificationClickHandler,
    pagination,
    selectedNotificationTabIndex,
    signalRNotifications,
    unreadNotifications,
  ]);

  return (
    <NotificationContext.Provider value={memoizedSubscriptionState}>
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotification() {
  return useContext(NotificationContext);
}
