import React from 'react';
import { Checkbox, List, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import classes from './TableToolbar.module.scss';
import { TableToolbarPropsInterface } from './interfaces/TableToolbarProps.interface';
import { TableActionInterface } from '../../interfaces/TableProps.interface';
import { MenuItem } from '../../../material';

const TableToolbarComponent = <T,>({
  rowsCount,
  onSelectAllRows,
  onActionSelected,
  actions,
  pageNumber,
  selectedRows,
  multiselect,
}: TableToolbarPropsInterface<T>) => {
  const { t } = useTranslation();

  const selectedRowsTotal = selectedRows.flat().length;

  return (
    <Table aria-label="data-hub-table-toolbar" className={classes['table-toolbar']}>
      <TableHead>
        <TableRow>
          {multiselect && (
            <TableCell padding="checkbox" className={classes['table-toolbar__checkbox-cell']}>
              <Checkbox
                color="primary"
                indeterminate={
                  selectedRows[pageNumber].length > 0 && selectedRows[pageNumber].length < rowsCount
                }
                checked={rowsCount > 0 && selectedRows[pageNumber].length === rowsCount}
                onChange={onSelectAllRows}
                inputProps={{
                  'aria-label': t('table.selectAllRows'),
                }}
              />
            </TableCell>
          )}
          <TableCell className={classes['table-toolbar__actions-cell']}>
            <List className={classes['actions-cell__actions']}>
              {actions.map((action: TableActionInterface<T>) => (
                <MenuItem
                  key={String(action.name)}
                  className={classes.actions__action}
                  onClick={() => onActionSelected(action)}
                >
                  {action.icon}
                  <span>{action.label}</span>
                </MenuItem>
              ))}
            </List>
          </TableCell>
          <TableCell className={classes['table-toolbar__details-box']}>
            {selectedRowsTotal === 1 && (
              <Trans
                i18nKey="invoices.bulkDeleteInvoices.selectedInvoicesInfoSingle"
                values={{ selectedRowsTotal }}
              />
            )}
            {selectedRowsTotal > 1 && (
              <Trans
                i18nKey="invoices.bulkDeleteInvoices.selectedInvoicesInfo"
                values={{ selectedRowsTotal }}
              />
            )}
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default TableToolbarComponent;
