import React, { FC, memo, ReactElement, useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useTranslation } from 'react-i18next';
import useGetUsersListQuery from '../../../../../../../../services/react-query-hooks/queries/user/useGetUsersList.query';
import { Button, IconButton } from '../../../../../../../components/material';
import { UserInterface } from '../../../../../../../../types/users/User.interface';
import { GlobalSettingsSectionPropsInterface } from '../../interfaces/GlobalSettingsSectionProps.interface';
import { RoleInterface } from '../../../../../../../../types/roles/Role.interface';
import useUpdateUserMutation from '../../../../../../../../services/react-query-hooks/mutations/user/useUpdateUserMutation';
import TableComponent from '../../../../../../../components/table/Table.component';
import classes from './UserPermission.module.scss';
import { TableColumnInterface } from '../../../../../../../components/table/interfaces/TableColumn.interface';
import { TableRowInterface } from '../../../../../../../components/table/interfaces/TableRow.interface';
import useTableFilters from '../../../../../../../components/table/hooks/TableFilters.hook';
import { TableActionInterface } from '../../../../../../../components/table/interfaces/TableProps.interface';
import { TableColumnTypeEnum } from '../../../../../../../components/table/enums/TableColumnType.enum';
import { AlignEnum } from '../../../../../../../../enums/Align.enum';
import { TableActionNameEnum } from '../../../../../../../components/table/enums/TableActionName.enum';
import { UserPermissionActionNameEnum } from './enums/UserPermissionActionName.enum';
import useInviteUserMutation from '../../../../../../../../services/react-query-hooks/mutations/user/useInviteUser.mutation';
import { StatusEnum } from '../../../../../../../../enums/Status.enum';
import useDeleteUser from './components/delete-user/hooks/useDeleteUser.hook';
import useUpdateUser from './components/update-user/hooks/useUpdateUser.hook';
import useAddUser from './components/add-user/hooks/useAddUser.hook';
import { queryClient } from '../../../../../../../../services/queryClient';
import { updateUserMutationKey } from '../../../../../../../../services/react-query-hooks/mutations/mutationKeys';

const mapRoleToColumn = (role: RoleInterface): TableColumnInterface => ({
  name: `role_${role.roleNumber}`,
  type: TableColumnTypeEnum.CHECKBOX,
  label: role.name,
});

const userPermissionColumns = (roles: RoleInterface[] | undefined): TableColumnInterface[] => {
  const roleColumns = roles ? roles.map(mapRoleToColumn) : [];

  return [
    {
      name: 'gap',
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      width: '1px',
    },
    {
      name: 'fullName',
      label: 'User name',
      align: AlignEnum.LEFT,
      type: TableColumnTypeEnum.DEFAULT,
      sortable: true,
      width: '20rem',
    },
    {
      name: 'status',
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.STATUS,
    },
    {
      name: 'actions',
      type: TableColumnTypeEnum.ACTIONS,
      align: AlignEnum.RIGHT,
    },
    ...roleColumns,
    {
      name: 'delete',
      align: AlignEnum.RIGHT,
      type: TableColumnTypeEnum.DEFAULT,
      width: '4rem',
    },
  ];
};

const isRoleSelected = (user: UserInterface, role: RoleInterface): boolean => {
  return user.roleNumbers.some((roleNumber: string) => roleNumber === role.roleNumber);
};

const UserPermissionComponent: FC<GlobalSettingsSectionPropsInterface> = ({
  roles,
}): ReactElement => {
  const { t } = useTranslation();

  const { filters, dispatchFilters } = useTableFilters();
  const { data: users, pagination } = useGetUsersListQuery(filters);

  const getUser = useCallback(
    (userId: string | number) => users?.find((user: UserInterface) => user.userNumber === userId),
    [users],
  );

  const { addUserModal, setAddUserOpen } = useAddUser(roles);
  const { deleteUserModal, setDeleteUserOpen, setDeleteUserData } = useDeleteUser();
  const { updateUserModal, setUpdateUserOpen, setUpdateUserData } = useUpdateUser(roles);

  const { mutate: updateUser } = useUpdateUserMutation();
  const { mutate: inviteUser } = useInviteUserMutation();

  const actions: TableActionInterface<UserPermissionActionNameEnum>[] = [
    {
      name: UserPermissionActionNameEnum.SEND_INVITATION,
      icon: <FileUploadOutlinedIcon />,
      label: t('settings.sections.permission.subsections.user.actions.invite.title'),
      multiselect: false,
    },
    {
      name: TableActionNameEnum.EDIT,
      icon: <EditOutlinedIcon />,
      label: 'Edit',
    },
    {
      name: TableActionNameEnum.DELETE,
      icon: <ClearOutlinedIcon />,
      label: 'Delete',
    },
  ];

  const mapUserToRow = (user: UserInterface): TableRowInterface => {
    const initialRole = {
      id: user.userNumber,
      fullName: (
        <div className={classes.table__name}>
          <p>{user.fullName}</p>
          <p>{user.email}</p>
        </div>
      ),
      delete: (
        <IconButton
          size="large"
          aria-label={user.email}
          onClick={() => {
            setDeleteUserData(user);
            setDeleteUserOpen(true);
          }}
        >
          <CloseIcon />
        </IconButton>
      ),
      // TODO: temporary, remove when API provides user's invitation status
      status: StatusEnum.NULL,
      // TODO: remove when multiselect is implemented
      gap: '',
      loading:
        queryClient.isMutating({
          mutationKey: updateUserMutationKey,
          predicate: (m) => m.options.variables?.id === user.userNumber,
        }) > 0,
    };
    const mapRoleObject = (acc: TableRowInterface, role: RoleInterface) => {
      acc[`role_${role.roleNumber}`] = isRoleSelected(user, role);
      return acc;
    };

    return roles ? roles.reduce(mapRoleObject, initialRole) : initialRole;
  };

  const userPermissionRows = () => {
    return users ? users.map(mapUserToRow) : [];
  };

  const updateUserPermission = (
    user: UserInterface | undefined,
    roleNumber: string,
    action: 'add' | 'remove',
  ) => {
    if (user) {
      let roleIds = [...user.roleNumbers];

      if (action === 'add') {
        roleIds.push(roleNumber);
      } else if (action === 'remove') {
        roleIds = roleIds.filter((userRoleNumber: string) => userRoleNumber !== roleNumber);
      }

      updateUser({
        id: user.userNumber,
        payload: { email: user.email, fullName: user.fullName, roles: roleIds },
      });
    }
  };

  const onActionSelectedHandler = (
    actionName: UserPermissionActionNameEnum | TableActionNameEnum,
    rowId: string[] | number[] | string | number,
    columnName?: string | number,
    state?: boolean,
  ) => {
    const isMultiple = Array.isArray(rowId);

    switch (actionName) {
      case TableActionNameEnum.DELETE: {
        if (!isMultiple) {
          const user = getUser(rowId);

          if (user) {
            setDeleteUserData(user);
            setDeleteUserOpen(true);
          }
        }

        break;
      }

      case TableActionNameEnum.CHECKBOX: {
        if (!isMultiple && columnName) {
          updateUserPermission(
            getUser(rowId),
            String(columnName).split('_')[1],
            state ? 'add' : 'remove',
          );
        }

        break;
      }

      case TableActionNameEnum.EDIT: {
        if (!isMultiple) {
          const user = getUser(rowId);

          if (user) {
            setUpdateUserData({
              id: user.userNumber,
              email: user.email,
              fullName: user.fullName,
              roles: user.roleNumbers,
            });
            setUpdateUserOpen(true);
          }
        }

        break;
      }

      case UserPermissionActionNameEnum.SEND_INVITATION: {
        const userIds = isMultiple ? rowId : [rowId];

        inviteUser({
          payload: {
            userUniqueNumbers: userIds.map((userId: string | number) => String(userId)),
          },
          user: !isMultiple ? getUser(rowId) : undefined,
        });

        break;
      }

      default:
    }
  };

  const toolbarContent = useMemo(
    () => (
      <Button
        startIcon={<AddOutlinedIcon />}
        onClick={() => setAddUserOpen(true)}
        variant="outlined"
      >
        {t('settings.sections.permission.subsections.user.addModal.button')}
      </Button>
    ),
    [setAddUserOpen, t],
  );

  return (
    <Grid className={classes['user-permission']}>
      <TableComponent
        table={{ rows: userPermissionRows(), columns: userPermissionColumns(roles) }}
        onActionSelected={onActionSelectedHandler}
        className={classes['user-permission__table']}
        multiselect={false}
        actions={actions}
        filters={filters}
        onChangeFilters={dispatchFilters}
        toolbar={toolbarContent}
        pagination={pagination}
      />

      {addUserModal}
      {deleteUserModal}
      {updateUserModal}
    </Grid>
  );
};

const UserPermission = memo(UserPermissionComponent);

export default UserPermission;
