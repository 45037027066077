export enum InvoiceHistoryEventTypeEnum {
  NEW_INVOICE_FROM_EMAIL_UPLOADED = 'newInvoiceFromEmailUploaded',
  NEW_MANUAL_INVOICE_UPLOADED = 'newManualInvoiceUploaded',
  INVOICE_DATA_UPDATED = 'invoiceDataUpdated',
  INVOICE_DELETED = 'invoiceDeleted',
  INVOICE_AUTO_MATCHING_FAILED = 'invoiceAutoMatchingFailed',
  INVOICE_DUPLICATES_FOUND = 'invoiceDuplicatesFound',
  INVOICE_DUPLICATES_NOT_FOUND = 'invoiceDuplicatesNotFound',
  INVOICE_DUPLICATES_SKIPPED = 'invoiceDuplicatesSkipped',
  INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT = 'invoiceFailedToBeSendToResident',
  INVOICE_FAILED_TO_BE_UPDATED_IN_RESIDENT = 'invoiceFailedToBeUpdatedInResident',
  INVOICE_MATCHING_AUTO_MATCHED = 'invoiceMatchingAutoMatched',
  INVOICE_MATCHING_SET_MANUALLY = 'invoiceMatchingSetManually',
  INVOICE_MOVED_BACK_TO_REVIEW = 'invoiceMovedBackToReview',
  INVOICE_OCR_RECOGNITION_FAILED = 'invoiceOcrRecognitionFailed',
  INVOICE_OCR_RECOGNITION_SUCCEEDED = 'invoiceOcrRecognitionSucceeded',
  INVOICE_REJECTED = 'invoiceRejected',
  INVOICE_SEND_TO_RESIDENT = 'invoiceSendToResident',
  INVOICE_SUPPLIER_AUTO_MATCHED = 'invoiceSupplierAutoMatched',
  INVOICE_SUPPLIER_COULD_NOT_BE_AUTO_MATCHED = 'invoiceSupplierCouldNotBeAutoMatched',
  INVOICE_SUPPLIER_SET_MANUALLY = 'invoiceSupplierSetManually',
  INVOICE_UPDATED_IN_RESIDENT = 'invoiceUpdatedInResident',
  INVOICE_PROPERTIES_AUTO_MATCHED = 'invoicePropertiesAutoMatched',
  INVOICE_MATCHING_SET_DEFAULT = 'invoiceMatchingSetDefault',
  INVOICE_APPROVED = 'invoiceApproved',
  INVOICE_REJECTED_TO_AMEND = 'invoiceRejectedToAmend',
}
