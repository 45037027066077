import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { InvoiceMutationPropsInterface } from './InvoiceMutationProps.interface';
import { ApiOperation } from '../../../../api';
import useGeneralErrorHandler from '../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../error-handlers/useOnError';
import { queryClient } from '../../../../queryClient';
import { useAlert } from '../../../../alert/Alert.provider';
import { BaseProcessedInvoiceInterface } from '../../../../../types/upload-hub/invoice/common/BaseProcessedInvoice.interface';
import { InvoiceFileInterface } from '../../../../../types/upload-hub/invoice/InvoiceFile.interface';

const useDeleteInvoiceMutation = (
  reloadQuery: string,
  { setOtherError }: InvoiceMutationPropsInterface = {},
) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.DeleteInvoice);

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const { onError } = useOnError([], generalErrorHandler);

  return useMutation(
    async ({ invoiceId: id }: BaseProcessedInvoiceInterface | InvoiceFileInterface) => {
      const res = await request({ urlParams: { id } });

      return res.data;
    },
    {
      onSuccess: (_, invoice: BaseProcessedInvoiceInterface | InvoiceFileInterface) => {
        queryClient.invalidateQueries(reloadQuery);

        showAlert(
          <Trans
            i18nKey="invoices.deleteInvoice.success"
            values={{
              name:
                'invoiceReference' in invoice && invoice.invoiceReference
                  ? invoice.invoiceReference
                  : invoice.fileName || '',
            }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useDeleteInvoiceMutation;
