import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SettingsOutlined } from '@mui/icons-material';
import classes from './UploadHubWelcome.module.scss';
import PublicLogoComponent from '../../../../../components/header/components/logo/PublicLogo.component';
import { ReactComponent as LogoDataHubBlack } from '../../../../../../assets/logo/logoDataHubBlack.svg';
import { pathTo } from '../../../../../components/router/Router.outlet';
import { PathEnum } from '../../../../../components/router/enums/Path.enum';
import { useUserPermissions } from '../../../../../../services/user/User.provider';
import { PermissionTypeEnum } from '../../../../../../enums/permission/PermissionType.enum';
import { Button } from '../../../../../components/material';

const UploadHubWelcomeComponent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPermission } = useUserPermissions();

  const onSettingsClickHandler = () => {
    navigate(pathTo([PathEnum.SETTINGS, PathEnum.DATA_SRC]));
  };

  const welcomeText = hasPermission(PermissionTypeEnum.ADMIN)
    ? t('invoices.welcome.info.admin')
    : t('invoices.welcome.info.user');

  return (
    <div className={classes['upload-hub-welcome']}>
      <PublicLogoComponent
        svgElement={<LogoDataHubBlack className={classes['upload-hub-welcome__logo']} />}
      />

      <h4 className={classes['upload-hub-welcome__header']}>{t('invoices.welcome.header')}</h4>
      <p className={classes['upload-hub-welcome__text']}>{welcomeText}</p>

      {hasPermission(PermissionTypeEnum.ADMIN) && (
        <Button
          variant="outlined"
          onClick={onSettingsClickHandler}
          startIcon={<SettingsOutlined />}
        >
          {t('invoices.welcome.settings')}
        </Button>
      )}
    </div>
  );
};

export default UploadHubWelcomeComponent;
