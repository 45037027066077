import React, { useMemo, useState } from 'react';
import AddRoleModalComponent from '../AddRoleModal.component';

const useAddRole = () => {
  const [addRoleOpen, setAddRoleOpen] = useState(false);

  const addRoleModal = useMemo(
    () => <AddRoleModalComponent open={addRoleOpen} setOpen={setAddRoleOpen} />,
    [addRoleOpen],
  );

  return { addRoleModal, setAddRoleOpen };
};

export default useAddRole;
