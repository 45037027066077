import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Grid } from '@mui/material';
import { NotificationsListPropsInterface } from './interfaces/NotificationsListProps.interface';
import { NotificationInterface } from '../../../../../types/notification/Notification.interface';
import NotificationsListItemComponent from './components/notifications-list-item/NotificationsListItem.component';
import classes from './NotificationsList.module.scss';
import LoadingComponent from '../../../loading/Loading.component';
import { useNotification } from '../../../../../services/notification/Notification.provider';

const NotificationsListComponent: FC<NotificationsListPropsInterface> = ({
  notifications,
  isNew = false,
  onClose,
}): ReactElement => {
  const { t } = useTranslation();
  const { isLoading } = useNotification();

  if (isLoading && !isNew) {
    return (
      <Grid item xs={6} className={classnames(classes['notification-list'])}>
        <div style={{ display: 'flex', height: '100%' }}>
          <LoadingComponent variant="relative" size="sm" delay={0} />
        </div>
      </Grid>
    );
  }

  const emptyLabel = isNew
    ? t('notification.tabs.new.empty')
    : t('notification.tabs.overall.empty');

  return !notifications.length ? (
    <div className={classnames(classes['notification-list'], classes['notification-list--empty'])}>
      <span>{emptyLabel}</span>
    </div>
  ) : (
    <ul className={classes['notification-list']}>
      {notifications.map((notification: NotificationInterface) => (
        <NotificationsListItemComponent
          key={notification.notificationUniqueNumber}
          notification={notification}
          onClose={onClose}
        />
      ))}
    </ul>
  );
};

export default NotificationsListComponent;
