import { useTranslation } from 'react-i18next';
import React, { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';
import AutocompleteSelect from '../../../../../../../../../../../components/material/autocomplete-select/AutocompleteSelect.component';
import { ResidentPropertyInterface } from '../../../../../../../../../../../../types/upload-hub/resident/property/ResidentProperty.interface';
import { FormControl } from '../../../../../../../../../../../components/material';
import { ResidentWorkOrderInterface } from '../../../../../../../../../../../../types/upload-hub/resident/work-orders/ResidentWorkOrder.interface';
import useGetResidentWorkOrdersQuery from '../../../../../../../../../../../../services/react-query-hooks/queries/upload-hub/resident/useGetResidentWorkOrders.query';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';
import { ResidentSupplierInterface } from '../../../../../../../../../../../../types/upload-hub/resident/supplier/ResidentSupplier.interface';

// @ts-ignore
const WorkOrdersControllerComponent = ({ name, error, disabled = false }) => {
  const { t } = useTranslation();
  const { control, getValues } = useFormContext<VerifyInvoiceDataFormInterface>();

  const propertyValue = getValues(String(ResidentInvoiceDataEnum.PROPERTY));
  const supplierValue = getValues(String(ResidentInvoiceDataEnum.SUPPLIER));

  const { refetch: fetchWorkOrders } = useGetResidentWorkOrdersQuery(
    supplierValue ? (supplierValue as ResidentSupplierInterface).value : '',
    propertyValue ? (propertyValue as ResidentPropertyInterface).value : '',
  );

  const workOrdersController = useMemo(
    () => (
      <Controller
        name={name}
        control={control}
        render={({ field: fieldProps }) => (
          <AutocompleteSelect
            {...fieldProps}
            value={fieldProps.value as ResidentWorkOrderInterface}
            fetch={fetchWorkOrders as () => Promise<any>}
            placeholder={t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.workOrder.label',
            )}
            error={!!error}
            disabled={disabled || propertyValue === null}
            data-cy="work-order-input"
          />
        )}
      />
    ),
    [control, disabled, error, fetchWorkOrders, name, propertyValue, t],
  );

  return (
    <FormControl error={!!error} style={{ width: '50%' }} data-cy="work-order-controller">
      {workOrdersController}
      <span role="alert">{(error as any)?.message}</span>
    </FormControl>
  );
};

const WorkOrdersController = memo(WorkOrdersControllerComponent);

export default WorkOrdersController;
