import { useCallback, useEffect, useState } from 'react';
import { CoordinatePointInterface } from '../../../interfaces/CoordinatePoint.interface';
import { CanvasCoordinatePointUnitEnum } from './enums/CanvasCoordinatePointUnit.enum';
import { CanvasShapeTypeEnum } from './enums/CanvasShapeType.enum';
import { CanvasShapeInterface } from './interfaces/CanvasShape.interface';
import { CanvasStrokeInterface } from './interfaces/CanvasStroke.interface';
import { maxScale, minScale } from '../../../views/components/file-preview/FilePreview.component';

// TODO: better solution for DPI scaling
export const setDPI = (canvas: HTMLCanvasElement, dpi: number) => {
  // Set up CSS size.
  canvas.style.width = canvas.style.width || `${canvas.width}px`;
  canvas.style.height = canvas.style.height || `${canvas.height}px`;

  // Get size information.
  const scaleFactor = dpi / 96;
  const width = parseFloat(canvas.style.width);
  const height = parseFloat(canvas.style.height);

  // Backup the canvas contents.
  const oldScale = canvas.width / width;
  const backupScale = scaleFactor / oldScale;
  const backup = canvas.cloneNode(false) as HTMLCanvasElement;
  backup?.getContext('2d')?.drawImage(canvas, 0, 0);

  // Resize the canvas.
  const ctx = canvas.getContext('2d');
  canvas.width = Math.ceil(width * scaleFactor);
  canvas.height = Math.ceil(height * scaleFactor);

  // Redraw the canvas image and scale future draws.
  ctx?.setTransform(backupScale, 0, 0, backupScale, 0, 0);
  ctx?.drawImage(backup, 0, 0);
  ctx?.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);
};

const scalePolygonCoordinatePoints = (
  cPoints: CoordinatePointInterface[],
  factor = 1,
): CoordinatePointInterface[] => {
  if (cPoints.length !== 4) {
    return cPoints;
  }

  return cPoints.map((cPoint: CoordinatePointInterface, index) => ({
    x: [0, 3].includes(index) ? cPoint.x / factor : cPoint.x * factor,
    y: index < 2 ? cPoint.y / factor : cPoint.y * factor,
  }));
};

const cloneCanvas = (canvas: HTMLCanvasElement): HTMLCanvasElement => {
  const newCanvas = document.createElement('canvas');
  const context = newCanvas.getContext('2d');

  newCanvas.width = canvas.width;
  newCanvas.height = canvas.height;

  if (context) {
    context.drawImage(canvas, 0, 0);
  }

  return newCanvas;
};

const useCanvasDraw = () => {
  // TODO: consider to move zoom logic to separate hook which extends draw hook -> useCanvas()
  const [initialCanvas, setInitialCanvas] = useState<HTMLCanvasElement | null>(null);
  const [copiedCanvas, setCopiedCanvas] = useState<HTMLCanvasElement | null>(null);

  const scaleMultiplier = 0.9;
  const [scale, setScale] = useState(1);

  const [translatePos, setTranslatePos] = useState<{ x: number; y: number } | undefined>(undefined);
  const [hasMouseTarget, setHasMouseTarget] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseZoomActive, setMouseZoomActive] = useState(false);
  const [controlMode, setControlMode] = useState(false);
  const [startDragOffset, setStartDragOffset] = useState<{ x: number; y: number } | undefined>(
    undefined,
  );

  const calculateCanvas = useCallback(
    (canvas?: HTMLCanvasElement | null) => {
      if (canvas) {
        setInitialCanvas(canvas);
        setCopiedCanvas(cloneCanvas(canvas));
      }

      const canvasToRender = canvas || initialCanvas;
      const canvasToCopy = canvas ? cloneCanvas(canvas) : copiedCanvas;

      if (canvasToRender && canvasToCopy) {
        const canvasContext = canvasToRender.getContext('2d');

        if (canvasContext && translatePos) {
          const { width, height } = canvasContext.canvas;

          canvasContext.clearRect(0, 0, canvasContext.canvas.width, canvasContext.canvas.height);
          canvasContext.save();

          const newWidth = width * scale;
          const newHeight = height * scale;

          const transformX = translatePos.x - (newWidth - canvasContext.canvas.width * scale) / 2;
          const transformY = translatePos.y - (newHeight - canvasContext.canvas.height * scale) / 2;

          canvasContext.translate(width / 2, height / 2);
          canvasContext.scale(scale, scale);
          canvasContext.translate(transformX, transformY);
          canvasContext.drawImage(canvasToCopy, -width / 2, -height / 2);
          canvasContext.restore();
        }
      }
    },
    [copiedCanvas, initialCanvas, scale, translatePos],
  );

  const zoomIn = () => setScale((prevScale: number): number => prevScale / scaleMultiplier);
  const zoomOut = () => setScale((prevScale: number): number => prevScale * scaleMultiplier);

  const onMouseDownHandler = useCallback(
    (event: MouseEvent) => {
      const { button } = event;

      if (button === 0) {
        setMouseDown(true);

        if (translatePos) {
          setStartDragOffset({
            x: event.clientX - translatePos.x,
            y: event.clientY - translatePos.y,
          });
        }
      }
    },
    [translatePos],
  );

  const onMouseUpHandler = (): void => setMouseDown(false);
  const onAuxClickHandler = (event: MouseEvent): void => {
    const { button } = event;

    if (button === 1) {
      setMouseZoomActive((prevMouseZoomActive: boolean): boolean => !prevMouseZoomActive);
    }
  };

  const onWindowKeyDownHandler = useCallback(
    (event: KeyboardEvent): void => {
      const { key, code } = event;

      if (key === 'Control' && !controlMode) {
        setControlMode(true);
      } else if (controlMode && hasMouseTarget) {
        if ((code === 'Equal' || code === 'Plus') && scale < maxScale) {
          zoomIn();
        }

        if (code === 'Minus' && scale > minScale) {
          zoomOut();
        }

        event.preventDefault();
      }
    },
    [controlMode, hasMouseTarget, scale],
  );

  const onWindowKeyUpHandler = useCallback(
    (event: KeyboardEvent): void => {
      const { key } = event;

      if (key === 'Control' && controlMode) {
        setControlMode(false);
      }
    },
    [controlMode],
  );

  const onWindowMouseMoveHandler = (event: MouseEvent): void =>
    setHasMouseTarget((): boolean => String(event.target) === '[object HTMLCanvasElement]');

  const onMouseMoveHandler = useCallback(
    (event: MouseEvent): void => {
      if (mouseDown && startDragOffset) {
        setTranslatePos({
          x: event.clientX - startDragOffset.x,
          y: event.clientY - startDragOffset.y,
        });
      }
    },
    [mouseDown, startDragOffset],
  );

  const mouseScrollHandler = useCallback(
    (event: WheelEvent): boolean => {
      const { deltaY, detail } = event;
      const detailDelta = detail ? -detail : 0;
      const delta = deltaY ? deltaY / 40 : detailDelta;

      if (translatePos && (mouseZoomActive || controlMode)) {
        const newScale = (s: number) => {
          if ((s > maxScale && delta < 0) || (s < minScale && delta > 0)) {
            return s;
          }

          return delta < 0 ? s / scaleMultiplier : s * scaleMultiplier;
        };

        setScale((prevScale: number) => newScale(prevScale));
        event.preventDefault();
      }

      return false;
    },
    [controlMode, mouseZoomActive, translatePos],
  );

  useEffect(() => {
    window.addEventListener('keydown', onWindowKeyDownHandler);
    window.addEventListener('keyup', onWindowKeyUpHandler);
    window.addEventListener('mousemove', onWindowMouseMoveHandler);

    if (initialCanvas) {
      if (!copiedCanvas) {
        setCopiedCanvas(cloneCanvas(initialCanvas));
      }

      if (translatePos) {
        calculateCanvas();
      } else {
        setTranslatePos({
          x: 0,
          y: 0,
        });
      }

      initialCanvas.addEventListener('mousedown', onMouseDownHandler);
      initialCanvas.addEventListener('mouseup', onMouseUpHandler);
      initialCanvas.addEventListener('mouseover', onMouseUpHandler);
      initialCanvas.addEventListener('mouseover', onMouseUpHandler);
      initialCanvas.addEventListener('mousemove', onMouseMoveHandler);
      initialCanvas.addEventListener('auxclick', onAuxClickHandler);
      initialCanvas.addEventListener('wheel', mouseScrollHandler);
    }

    return () => {
      window.removeEventListener('keydown', onWindowKeyDownHandler);
      window.removeEventListener('keyup', onWindowKeyUpHandler);
      window.removeEventListener('mousemove', onWindowMouseMoveHandler);

      initialCanvas?.removeEventListener('mousedown', onMouseDownHandler);
      initialCanvas?.removeEventListener('mouseup', onMouseUpHandler);
      initialCanvas?.removeEventListener('mouseover', onMouseUpHandler);
      initialCanvas?.removeEventListener('mouseover', onMouseUpHandler);
      initialCanvas?.removeEventListener('mousemove', onMouseMoveHandler);
      initialCanvas?.removeEventListener('auxclick', onAuxClickHandler);
      initialCanvas?.removeEventListener('wheel', mouseScrollHandler);
    };
  }, [
    copiedCanvas,
    initialCanvas,
    mouseDown,
    mouseScrollHandler,
    onMouseDownHandler,
    onMouseMoveHandler,
    translatePos,
    calculateCanvas,
    onWindowKeyDownHandler,
    onWindowKeyUpHandler,
  ]);

  const drawPolygon = (
    canvas: HTMLCanvasElement,
    cPoints: CoordinatePointInterface[],
    scaleFactor = 1,
    color = 'black',
    lineWidth = 2,
    translate: { x: number; y: number } | undefined = undefined,
    isFirst = false,
  ): void => {
    const canvasContext = canvas.getContext('2d');
    const firstCPoint = cPoints.shift();

    if (cPoints.length < 2 || !canvasContext || !firstCPoint) {
      return;
    }

    canvasContext.save();

    if (translate && isFirst) {
      canvasContext.translate(translate.x, translate.y);
    }

    canvasContext.beginPath();
    canvasContext.lineWidth = lineWidth;
    canvasContext.strokeStyle = color;
    canvasContext.moveTo(firstCPoint.x * scaleFactor, firstCPoint.y * scaleFactor);

    cPoints.forEach((cPoint: CoordinatePointInterface) => {
      canvasContext.lineTo(cPoint.x * scaleFactor, cPoint.y * scaleFactor);
    });

    canvasContext.lineTo(firstCPoint.x * scaleFactor, firstCPoint.y * scaleFactor);
    canvasContext.stroke();

    cPoints.push(firstCPoint);
  };

  const draw = (
    canvas: HTMLCanvasElement | null,
    stroke: CanvasStrokeInterface,
    page = 1,
    scaleCanvasFactor = 1,
    translate: { x: number; y: number } | undefined = undefined,
  ): void => {
    if (canvas) {
      // TODO: better solution for DPI scaling
      // TODO: revert to 300 combine set DPI with zoom in and zoom out - when both enabled there is a problem with canvas dimensions which breaks zoom calculations
      setDPI(canvas, 96);
    }

    // TODO: create scale factor map
    const scaleFactor = stroke.unit === CanvasCoordinatePointUnitEnum.INCH ? 72 : 1;
    const polygonScaleFactor = stroke.unit === CanvasCoordinatePointUnitEnum.INCH ? 1.008 : 1.004;

    stroke.shapes.forEach((shape: CanvasShapeInterface, index: number) => {
      if (canvas && shape.page === page) {
        const drawPolygonFn = () =>
          drawPolygon(
            canvas,
            shape.scale
              ? scalePolygonCoordinatePoints(shape.coordinates, polygonScaleFactor)
              : shape.coordinates,
            scaleFactor * scaleCanvasFactor,
            shape.color || 'black',
            stroke.unit === CanvasCoordinatePointUnitEnum.INCH ? 2 : 3,
            translate,
            index === 0,
          );

        switch (shape.type) {
          // Add here other shapes (circle, rectangle) when needed in the future
          case CanvasShapeTypeEnum.POLYGON: {
            drawPolygonFn();
            break;
          }
          default:
            drawPolygonFn();
        }
      }
    });
  };

  return {
    draw,
    drawPolygon,
    scale,
    setScale,
    initialCanvas,
    setInitialCanvas,
    calculateCanvas,
    zoomIn,
    zoomOut,
    mouseZoomActive: mouseZoomActive || controlMode,
  };
};

export default useCanvasDraw;
