import React, { useMemo, useState } from 'react';
import AddUserModalComponent from '../AddUserModal.component';
import { RoleInterface } from '../../../../../../../../../../../types/roles/Role.interface';

const useAddUser = (roles: RoleInterface[] | undefined) => {
  const [addUserOpen, setAddUserOpen] = useState(false);

  const addUserModal = useMemo(
    () => <AddUserModalComponent roles={roles} open={addUserOpen} setOpen={setAddUserOpen} />,
    [addUserOpen, roles],
  );

  return { addUserModal, setAddUserOpen };
};

export default useAddUser;
