import React, { memo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import useSetLogoMutation from '../../../../../../../../services/react-query-hooks/mutations/configuration/useSetLogoMutation';
import { useSubscription } from '../../../../../../../../services/subscription/Subscription.provider';
import { Button } from '../../../../../../../components/material';
import classes from './LogoUpload.module.scss';
import LoadingComponent from '../../../../../../../components/loading/Loading.component';
import { FileMimeTypeEnum } from '../../../../../../../components/file-preview/enums/FileMimeTypeEnum';

const LogoUploadComponent = () => {
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    noDrag: true,
    accept: {
      [FileMimeTypeEnum.JPG]: ['.jpg', '.JPG'],
      [FileMimeTypeEnum.JPEG]: ['.jpeg', '.JPEG'],
      [FileMimeTypeEnum.PNG]: ['.png', '.PNG'],
      [FileMimeTypeEnum.GIF]: ['.gif', '.GIF'],
    },
  });
  const { t } = useTranslation();
  const { configuration, isLoading, isFetching } = useSubscription();
  const { mutate, isLoading: isUploading } = useSetLogoMutation();

  useEffect(() => {
    if (acceptedFiles[0]) {
      mutate(acceptedFiles[0]);
    }
  }, [acceptedFiles, mutate]);

  if (isLoading || isFetching || !configuration) {
    return (
      <section className={classes.container}>
        <LoadingComponent size="sm" variant="relative" />
      </section>
    );
  }

  return (
    <section className={classes.container}>
      <div {...getRootProps({ className: classes.root })}>
        <div className={classes.root__container}>
          <input {...getInputProps({ className: classes.input })} />
          {configuration.logoUrl ? (
            <img
              src={configuration.logoUrl}
              alt="currentLogo"
              className={classes['current-logo']}
            />
          ) : (
            <p>{t('settings.sections.global.subsections.logo.noLogo')}</p>
          )}
          <p>({t('settings.sections.global.subsections.logo.types')})</p>
        </div>
        <Button onClick={open} variant="outlined" className={classes.button} loading={isUploading}>
          {t('settings.sections.global.subsections.logo.button')}
        </Button>
      </div>
    </section>
  );
};

const LogoUpload = memo(LogoUploadComponent);

export default LogoUpload;
