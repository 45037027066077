export const getUrlParams = <T = Record<string, any>>(
  urlQuery: string,
): T | Record<string, any> => {
  const hashQueryList = urlQuery.substring(1).split('&');

  return hashQueryList.reduce((acc: T | Record<string, any>, param: string) => {
    const [key, val] = param.split('=');

    if (val) {
      acc[key] = decodeURIComponent(val);
    }

    return acc;
  }, {});
};

export const joinUrlParams = <T extends Record<string, any>>(params: T): string =>
  Object.keys(params)
    .filter((key: string) => key && params[key])
    .map((key: string): string => `${key}=${params[key]}`)
    .join('&');
