import { InvoiceErrorCodeEnum } from '../../../enums/upload-hub/InvoiceErrorCode.enum';

export const InvoiceErrorCodeTypes = Object.values(InvoiceErrorCodeEnum);

export type InvoiceErrorCodeType = (typeof InvoiceErrorCodeTypes)[number];

export const InvoiceErrorCodeTypeTranslationMap: {
  [key in InvoiceErrorCodeType]: string;
} = {
  [InvoiceErrorCodeEnum.PROPERTY_MISSING]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.property.errors.required',
  [InvoiceErrorCodeEnum.BLOCK_ACCOUNT_MISSING]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.bankAccount.errors.required',
  [InvoiceErrorCodeEnum.TOTAL_AMOUNT_MISSING]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.totalAmount.errors.required',
  [InvoiceErrorCodeEnum.TOTAL_AMOUNT_THRESHOLD_EXCEEDED]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.totalAmount.errors.thresholdExceeded',
  [InvoiceErrorCodeEnum.DATE_MISSING]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.invoiceDate.errors.required',
  [InvoiceErrorCodeEnum.CATEGORY_MISSING]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.category.errors.required',
  [InvoiceErrorCodeEnum.SCHEDULE_MISSING]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.schedule.errors.required',
  [InvoiceErrorCodeEnum.AMOUNT_MISSING]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.amount.errors.required',
  [InvoiceErrorCodeEnum.INCORRECT_PROPERTY]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.property.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_BLOCK_ACCOUNT]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.bankAccount.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_TOTAL_AMOUNT]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.totalAmount.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_INVOICE_DATE]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.invoiceDate.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_WORK_ORDER]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.workOrder.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_PAYMENT_DUE_DATE]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.paymentDueDate.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_PERIOD_START]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.periodStart.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_PERIOD_END]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.residentData.controls.periodEnd.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_CATEGORY]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.category.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_SCHEDULE]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.schedule.errors.incorrect',
  [InvoiceErrorCodeEnum.INCORRECT_AMOUNT]:
    'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.amount.errors.incorrect',
};
