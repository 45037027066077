import React, { FC, MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import { Fade, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { InvoiceDuplicatesPropsInterface } from './interfaces/InvoiceDuplicatesProps.interface';
import classes from './InvoiceDuplicates.module.scss';
import InvoiceDuplicateRowComponent from './components/duplicate-row/InvoiceDuplicateRow.component';
import { InvoiceDuplicateInterface } from '../../../../../../types/upload-hub/invoice/duplicates/InvoiceDuplicates.interface';
import { Button } from '../../../../../components/material';
import useSkipInvoiceDuplicatesMutation from '../../../../../../services/react-query-hooks/mutations/upload-hub/invoice/skip-duplicates/useSkipInvoiceDuplicates.mutation';
import useRejectInvoice from '../reject/hooks/useRejectInvoice.hook';
import useDelay from '../../../../../../utils/hooks/useDelay.hook';
import PortalComponent from '../../../../../components/portal/Portal.component';
import { useUploadHub } from '../../providers/UploadHub.provider';
import { InvoiceReceiversTranslationMap } from '../../../../../../types/upload-hub/invoice-receiver/InvoiceReceivers.type';
import { queryClient } from '../../../../../../services/queryClient';
import { skipInvoiceDuplicatesMutationKey } from '../../../../../../services/react-query-hooks/mutations/mutationKeys';

const InvoiceDuplicatesView: FC<InvoiceDuplicatesPropsInterface> = ({
  invoiceDuplicates,
  handleClose,
}): ReactElement => {
  const { t } = useTranslation();
  const { isAvailable: areButtonsReady } = useDelay();
  const viewModalContainerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const [, setError] = useState('');
  const {
    rejectModal,
    setRejectInvoiceData,
    setRejectInvoiceOpen,
    isLoading: isRejectLoading,
    isSuccess: isRejectSuccess,
  } = useRejectInvoice();
  const { mutate: skipInvoiceDuplicates, isSuccess: isSkipSuccess } =
    useSkipInvoiceDuplicatesMutation({ setOtherError: setError });

  const { settings } = useUploadHub();

  const rejectInvoiceHandler = (): void => {
    setError('');
    setRejectInvoiceData({
      invoiceId: invoiceDuplicates.invoice.invoiceId,
      invoiceReference: invoiceDuplicates.invoice.invoiceNumber,
      variant: 'reject',
      note: 'Duplicate',
    });
    setRejectInvoiceOpen(true);
  };

  const skipInvoiceDuplicatesHandler = (skipApprovalFlow: boolean): void => {
    setError('');
    skipInvoiceDuplicates({
      invoiceReference: invoiceDuplicates.invoice.invoiceNumber,
      skipApprovalFlow,
      payload: {
        invoiceId: invoiceDuplicates.invoice.invoiceId,
      },
    });
  };

  useEffect(() => {
    if (isRejectSuccess || isSkipSuccess) {
      handleClose();
    }
  }, [handleClose, isRejectSuccess, isSkipSuccess]);

  const isSkipWithApprovalLoading =
    queryClient.isMutating({
      mutationKey: skipInvoiceDuplicatesMutationKey,
      predicate: (m) =>
        m.options.variables?.payload.invoiceId === invoiceDuplicates.invoice.invoiceId &&
        !m.options.variables?.skipApprovalFlow,
    }) > 0;

  const isSkipWithoutApprovalLoading =
    queryClient.isMutating({
      mutationKey: skipInvoiceDuplicatesMutationKey,
      predicate: (m) =>
        m.options.variables?.payload.invoiceId === invoiceDuplicates.invoice.invoiceId &&
        m.options.variables?.skipApprovalFlow,
    }) > 0;

  return (
    <>
      <Grid className={classes['invoice-duplicates']} ref={viewModalContainerRef}>
        <TableContainer>
          <Table
            className={classes['invoice-duplicates__table']}
            aria-label="invoice-duplicates-table"
          >
            <TableBody>
              <InvoiceDuplicateRowComponent invoice={invoiceDuplicates.invoice} variant="header" />
              <TableRow>
                <TableCell colSpan={6} className={classes.table__separator}>
                  {t('invoices.duplicates.subtitle')}
                </TableCell>
              </TableRow>
              {invoiceDuplicates.duplicates.map((duplicate: InvoiceDuplicateInterface) => (
                <InvoiceDuplicateRowComponent key={duplicate.invoiceId} invoice={duplicate} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {areButtonsReady && (
          <PortalComponent id="modalActions" anchorEl={viewModalContainerRef.current}>
            <Fade in={areButtonsReady}>
              <div className={classes['invoice-duplicates__buttons']}>
                <Button
                  variant="text"
                  onClick={rejectInvoiceHandler}
                  className={classes.buttons__button}
                  disabled={isSkipWithApprovalLoading || isSkipWithoutApprovalLoading}
                  loading={isRejectLoading}
                >
                  {t('invoices.duplicates.reject.button')}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => skipInvoiceDuplicatesHandler(false)}
                  className={classes.buttons__button}
                  disabled={isRejectLoading || isSkipWithoutApprovalLoading}
                  loading={isSkipWithApprovalLoading}
                >
                  {t('invoices.duplicates.skip.approval')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => skipInvoiceDuplicatesHandler(true)}
                  className={classes.buttons__button}
                  disabled={isRejectLoading || isSkipWithApprovalLoading}
                  loading={isSkipWithoutApprovalLoading}
                >
                  {settings && (
                    <Trans
                      i18nKey="invoices.duplicates.skip.integrationService.label"
                      // @ts-ignore
                      values={{ name: t(InvoiceReceiversTranslationMap[settings.invoiceReceiver]) }}
                    />
                  )}
                  {!settings && t('invoices.duplicates.skip.integrationService.fallback')}
                </Button>
              </div>
            </Fade>
          </PortalComponent>
        )}
      </Grid>

      {rejectModal}
    </>
  );
};

export default InvoiceDuplicatesView;
