import React, { FC, ReactElement, useMemo } from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useGetInvoiceMatchingQuery from '../../../../../../../../services/react-query-hooks/queries/upload-hub/invoice/useGetInvoiceMatching.query';
import { CanvasCoordinatePointUnitEnum } from '../../../../../../../../utils/hooks/canvas/enums/CanvasCoordinatePointUnit.enum';
import { InvoiceFieldInterface } from '../../../../../../../../types/upload-hub/invoice/InvoiceField.interface';
import { InvoiceItemInterface } from '../../../../../../../../types/upload-hub/invoice/InvoiceItem.interface';
import { CanvasShapeInterface } from '../../../../../../../../utils/hooks/canvas/interfaces/CanvasShape.interface';
import { CanvasStrokeInterface } from '../../../../../../../../utils/hooks/canvas/interfaces/CanvasStroke.interface';
import { CanvasShapeTypeEnum } from '../../../../../../../../utils/hooks/canvas/enums/CanvasShapeType.enum';
import { VerificationPropsInterface } from './interfaces/VerificationProps.interface';
import FilePreview from '../../../../../../../components/file-preview/FilePreview.component';
import useFile from '../../../../../../../../utils/hooks/file/useFile.hook';
import { FileMimeTypeEnum } from '../../../../../../../components/file-preview/enums/FileMimeTypeEnum';
import { useVerificationFormState } from './services/VerificationForm.provider';
import classes from './Verification.module.scss';
import LoadingGridContainerComponent from '../../../../../../../components/loading/LoadingGridContainer.component';

const mapFieldIntoStroke = (field: InvoiceFieldInterface): CanvasShapeInterface => {
  return {
    page: field.pageNumber,
    coordinates: field.boundingBox,
    color: field.color,
    type: CanvasShapeTypeEnum.POLYGON,
    scale: true,
  };
};

const extractFieldsFromInvoiceItem = (
  accShapes: CanvasShapeInterface[],
  item: InvoiceItemInterface,
) => {
  return [...accShapes, ...item.fields.map(mapFieldIntoStroke)];
};

const VerificationView: FC<VerificationPropsInterface> = ({
  id,
  step: { content },
}): ReactElement => {
  const { verificationContainerRef } = useVerificationFormState();
  const { data, isLoading } = useGetInvoiceMatchingQuery(id);
  const { fileType } = useFile('', data ? data.fileLocalization : '');
  const { t } = useTranslation();

  const stroke: CanvasStrokeInterface = useMemo(() => {
    const itemFieldsShapes = data ? data.items.reduce(extractFieldsFromInvoiceItem, []) : [];

    return {
      unit:
        fileType === FileMimeTypeEnum.JPEG ||
        fileType === FileMimeTypeEnum.JPG ||
        fileType === FileMimeTypeEnum.PNG
          ? CanvasCoordinatePointUnitEnum.PX
          : CanvasCoordinatePointUnitEnum.INCH,
      shapes: data ? [...data.fields.map(mapFieldIntoStroke), ...itemFieldsShapes] : [],
    };
  }, [data, fileType]);

  const renderContent = useMemo(
    () => (typeof content === 'function' ? content() : content),
    [content],
  );

  if (isLoading) {
    return (
      <LoadingGridContainerComponent
        className={classNames(classes.verification, classes['verification--loading'])}
      />
    );
  }

  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={8}
      ref={verificationContainerRef}
      className={classes.verification}
    >
      <Grid
        item
        xs={6}
        className={classNames(
          classes.verification__col,
          classes['verification__col--hidden-scrollbar'],
        )}
      >
        <div className={classes.col__inner}>
          <Grid item xs={9} id="invoiceNote" />

          <h6 className={classes.verification__title}>
            {t('invoices.tabs.toVerify.verificationModal.steps.verification.preview.preview')}
          </h6>
          <FilePreview
            fileName={data ? data.fileLocalization : ''}
            path={data?.fileLocalization}
            stroke={stroke}
          />
        </div>
      </Grid>

      {renderContent}
    </Grid>
  );
};

export default VerificationView;
