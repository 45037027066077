import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteInvoiceModalPropsInterface } from '../interfaces/DeleteInvoiceModalProps.interface';
import ModalComponent from '../../../../../../components/modal/modal/Modal.component';
import DeleteInvoiceView from './DeleteInvoice.view';

const DeleteInvoiceModalComponent: FC<DeleteInvoiceModalPropsInterface> = ({
  invoice,
  open,
  setOpen,
  reloadQuery,
  onMutate,
}) => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <ModalComponent
      title={t('invoices.deleteInvoice.title')}
      open={open}
      handleClose={handleClose}
      variant="warning"
      content={
        invoice && (
          <DeleteInvoiceView
            invoice={invoice}
            close={handleClose}
            reloadQuery={reloadQuery}
            onMutate={onMutate}
          />
        )
      }
    />
  );
};

export default DeleteInvoiceModalComponent;
