export enum NotificationTypeEnum {
  // System notifications
  ADMIN_NOTIFICATION = 0,

  // UploadHub notifications
  INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT = 100,
  APPROVED_INVOICE_FAILED_TO_BE_SEND_TO_RESIDENT = 101,
  OCR_FAILED_TO_PROCESS_INVOICE = 102,
  MAILBOX_PROCESSING_ERROR = 103,
  LESS_THAN_HUNDRED_PAGES_LEFT = 104,
  INVOICES_WAITING_TO_BE_VERIFIED = 105,
  INVOICES_WAITING_FOR_FINANCIAL_APPROVAL = 106,
  NYLAS_ACCOUNT_INACTIVE = 107,

  // DataSource notifications
  PRIMARY_DATA_SOURCE_REFRESHED = 200,
  DATA_SOURCE_SYNCHRONIZATION_FAILED = 201,

  // Internal notifications
  INVOICE_OCR_RECOGNITION_FINISHED = 307,
}
