import { useTranslation } from 'react-i18next';
import CommonSetErrorType from '../CommonSetError.type';
import { useCommonErrorHandler } from '../useErrorHandler';

const useDataSourceNotFoundErrorHandler = (setError?: CommonSetErrorType) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'DATA_SOURCE_NOT_FOUND',
    errorMessage: t(
      'dataSourcesSettings.sections.dataSources.subsections.supplierList.controls.errors.notFound',
    ),
  });

  return handler;
};

export default useDataSourceNotFoundErrorHandler;
