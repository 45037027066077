import { useRequest } from '@datahub/api-client';
import { TableFiltersInterface } from '../../../../../../views/components/table/interfaces/TableFilters.interface';
import { ToVerifyInvoiceResponseType } from '../../../../../../types/upload-hub/invoice/to-verify/ToVerifyInvoiceResponse.type';
import { ApiOperation } from '../../../../../api';
import usePaginatedListQuery from '../../../../pagination/usePaginatedList.query';
import { getToVerifyInvoicesQueryKey } from '../../../queryKeys';

const useGetToVerifyInvoicesListQuery = (filters?: TableFiltersInterface) => {
  const { request } = useRequest<ToVerifyInvoiceResponseType>(ApiOperation.GetToVerifyInvoices);
  const { data, ...rest } = usePaginatedListQuery<ToVerifyInvoiceResponseType>(
    request,
    getToVerifyInvoicesQueryKey,
    filters,
  );

  return {
    data: data?.responseModel,
    ...rest,
  };
};

export default useGetToVerifyInvoicesListQuery;
