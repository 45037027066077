import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/i18n/en.json';

const resources = {
  en: {
    translation: en,
  },
};

const isDevelopment = process.env.NODE_ENV === 'development';

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  debug: isDevelopment,
  saveMissing: isDevelopment,
  // https://github.com/i18next/react-i18next/issues/1559
  returnNull: false,
  missingKeyHandler: (lng: readonly string[], ns: string, key: string) => {
    if (key) {
      // eslint-disable-next-line no-console
      console.error(`i18n: Missing key '${key}' for '${lng}' language`);
    }
  },
  react: {
    useSuspense: true,
  },
});

i18n.services.formatter?.add('lowercase', (value: string) => value.toLowerCase());
i18n.services.formatter?.add('uppercase', (value: string) => value.toUpperCase());

export default i18n;
