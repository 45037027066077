import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { getAllRolesQueryKey, getRolesQueryKey } from '../../queries/queryKeys';
import { queryClient } from '../../../queryClient';
import { ApiOperation } from '../../../api';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../error-handlers/useOnError';
import { RoleMutationPropsInterface } from './RoleMutationProps.interface';
import useRoleNotFoundErrorHandler from '../../error-handlers/role/useRoleNotFoundError.handler';
import { deleteRoleMutationKey } from '../mutationKeys';
import { useAlert } from '../../../alert/Alert.provider';
import { RoleInterface } from '../../../../types/roles/Role.interface';

const useDeleteRoleMutation = ({ setOtherError }: RoleMutationPropsInterface = {}) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.DeleteRole);

  const notFoundErrorHandler = useRoleNotFoundErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([notFoundErrorHandler], generalErrorHandler);

  return useMutation(
    async ({ roleNumber }: RoleInterface) => {
      const res = await request({ urlParams: { id: roleNumber } });

      return res.data;
    },
    {
      mutationKey: deleteRoleMutationKey,
      onSuccess: (_, { name }: RoleInterface) => {
        queryClient.invalidateQueries(getRolesQueryKey);
        queryClient.invalidateQueries(getAllRolesQueryKey);

        showAlert(
          <Trans
            i18nKey="settings.sections.permission.subsections.role.deleteModal.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useDeleteRoleMutation;
