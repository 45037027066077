import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { SynchronizedInboxMutationPropsInterface } from './SynchronizedInboxMutationProps.interface';
import { ApiOperation } from '../../../../../api';
import useGeneralErrorHandler from '../../../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../../../error-handlers/useOnError';
import { queryClient } from '../../../../../queryClient';
import { getInProgressInvoicesQueryKey } from '../../../../queries/queryKeys';
import useSynchronizedInboxNotFoundErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/useSynchronizedInboxNotFoundError.handler';
import { useSyncMailboxMutationKey } from '../../../mutationKeys';
import { useAlert } from '../../../../../alert/Alert.provider';
import {
  NylasSynchronizedInboxInterface,
  SmtpSynchronizedInboxInterface,
} from '../../../../../../types/upload-hub/synchronized-inboxes/SynchronizedInbox.interface';
import { EmailProviderEnum } from '../../../../../../enums/upload-hub/settings/EmailProvider.enum';
import useNylasAccountNotFoundErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/nylas/useNylasAccountNotFoundError.handler';
import useNylasAccountNotAuthorizedErrorHandler from '../../../../error-handlers/plugin-settings/upload-hub/synchronized-inboxes/nylas/useNylasAccountNotAuthorizedError.handler';

const useSyncSynchronizedInboxMutation = (
  { setOtherError, provider }: SynchronizedInboxMutationPropsInterface = {
    provider: EmailProviderEnum.SMTP,
  },
) => {
  const { showAlert } = useAlert();

  const { request: smtpSync } = useRequest(ApiOperation.SyncMailbox);
  const { request: nylasSync } = useRequest(ApiOperation.NylasScheduleAccountMessagesProcessing);
  const request = provider === EmailProviderEnum.SMTP ? smtpSync : nylasSync;

  const generalErrorHandler = useGeneralErrorHandler(setOtherError);
  const notFoundSmtpErrorHandler = useSynchronizedInboxNotFoundErrorHandler(setOtherError);
  const notFoundNylasErrorHandler = useNylasAccountNotFoundErrorHandler(setOtherError);
  const notAuthorizedNylasErrorHandler = useNylasAccountNotAuthorizedErrorHandler(setOtherError);

  const { onError } = useOnError(
    [notFoundSmtpErrorHandler, notFoundNylasErrorHandler, notAuthorizedNylasErrorHandler],
    generalErrorHandler,
  );

  return useMutation(
    async (syncInbox: SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface) => {
      let data;

      if (provider === EmailProviderEnum.SMTP) {
        data = new FormData();
        data.append(
          'UploadHubEmailSettingId',
          (syncInbox as SmtpSynchronizedInboxInterface).uniqueNumber,
        );
      } else {
        data = {
          accountUniqueNumber: (syncInbox as NylasSynchronizedInboxInterface).accountUniqueNumber,
        };
      }

      const res = await request({ data });

      return res.data;
    },
    {
      mutationKey: useSyncMailboxMutationKey,
      onSuccess: (
        _,
        { email }: SmtpSynchronizedInboxInterface | NylasSynchronizedInboxInterface,
      ) => {
        queryClient.invalidateQueries(getInProgressInvoicesQueryKey);

        showAlert(
          <Trans
            i18nKey="pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.actions.sync.success"
            values={{ email }}
            components={{ bold: <strong /> }}
          />,
          'success',
        );
      },
      onError,
    },
  );
};

export default useSyncSynchronizedInboxMutation;
