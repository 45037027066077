import React, { ForwardedRef, forwardRef, PropsWithChildren, ReactElement } from 'react';
import './IconButton.component.scss';
import { IconButton as MatIconButton } from '@mui/material';
import classNames from 'classnames';
import { IconButtonPropsInterface } from './interfaces/IconButtonProps.interface';

const IconButton = forwardRef<HTMLButtonElement, IconButtonPropsInterface>(
  (
    props: PropsWithChildren<IconButtonPropsInterface>,
    ref: ForwardedRef<HTMLButtonElement>,
  ): ReactElement => {
    const { children, className, variant } = props;

    return (
      <MatIconButton
        {...props}
        className={classNames(
          'data-hub-icon-button',
          className,
          variant ? `data-hub-icon-button--${variant}` : '',
        )}
        ref={ref}
      >
        {children}
      </MatIconButton>
    );
  },
);

export { IconButton };
