import { useReducer } from 'react';
import { TableFiltersInterface } from '../interfaces/TableFilters.interface';

export enum TableFilterAction {
  UPDATE_FILTERS,
}

const defaultFilters: TableFiltersInterface = {
  PageNumber: 1,
  PageSize: 20,
  OrderBy: '',
  SearchKeyword: '',
};

const tableFiltersReducer = (
  state: TableFiltersInterface,
  action: {
    type: TableFilterAction;
    value: TableFiltersInterface;
  },
): TableFiltersInterface => {
  switch (action.type) {
    case TableFilterAction.UPDATE_FILTERS: {
      return {
        ...state,
        ...action.value,
      };
    }

    default: {
      return defaultFilters;
    }
  }
};

const useTableFilters = (filters?: TableFiltersInterface) => {
  const [tableFilters, dispatchTableFilters] = useReducer(tableFiltersReducer, {
    ...defaultFilters,
    ...filters,
  });

  return {
    filters: tableFilters,
    dispatchFilters: dispatchTableFilters,
  };
};

export default useTableFilters;
