import React, { FC, ReactElement, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import classNames from 'classnames';
import { AlignEnum } from '../../../../../../../../../../../../enums/Align.enum';
import classes from './MatchSection.module.scss';
import MatchTagComponent from '../match-tag/MatchTag.component';
import { MatchSectionPropsInterface } from './interfaces/MatchSectionProps.interface';
import { InvoiceFieldInterface } from '../../../../../../../../../../../../types/upload-hub/invoice/InvoiceField.interface';
import MatchSectionPaginatorComponent from './components/MatchSectionPaginator.component';
import { Input } from '../../../../../../../../../../../components/material';

const MatchSectionComponent: FC<MatchSectionPropsInterface> = ({
  title,
  fields,
  matchMap,
  getTag,
}): ReactElement => {
  const isMultiPageSection = fields.length > 0 && Array.isArray(fields[0]);
  const [page, setPage] = useState(0);
  const [currentFields, setCurrentFields] = useState(
    isMultiPageSection
      ? (fields[0] as InvoiceFieldInterface[])
      : (fields as InvoiceFieldInterface[]),
  );

  const onPreviousPageClickHandler = () => {
    setPage(page - 1);
    setCurrentFields(fields[page - 1] as InvoiceFieldInterface[]);
  };

  const onNextPageClickHandler = () => {
    setPage(page + 1);
    setCurrentFields(fields[page + 1] as InvoiceFieldInterface[]);
  };

  return (
    <div
      className={classes['match-section']}
      data-cy={`match-section${isMultiPageSection ? '-multi' : ''}`}
    >
      <div className={classes['match-section__title-container']}>
        <h6 className={classes['title-container__title']}>{title}</h6>
        {isMultiPageSection && (
          <MatchSectionPaginatorComponent
            page={page}
            pageAmount={fields.length}
            onPreviousPageClick={onPreviousPageClickHandler}
            onNextPageClick={onNextPageClickHandler}
          />
        )}
      </div>
      <TableContainer className={classes['match-section__table-container']}>
        <Table aria-label="invoice-data-table" className={classes['match-section__table']}>
          <TableBody>
            {currentFields.map(
              (field: InvoiceFieldInterface): ReactElement => (
                <TableRow key={field.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    align={AlignEnum.LEFT}
                    className={classNames(
                      classes['MuiTableCell-root'],
                      classes.table__cell,
                      classes['table__cell--color-indicator'],
                    )}
                  >
                    <div
                      className={classes.wrapper}
                      style={{ backgroundColor: field.color || 'black' }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    width="13.15rem"
                    align={AlignEnum.LEFT}
                    className={classNames(classes['MuiTableCell-root'], classes.table__cell)}
                  >
                    <Droppable droppableId={field.name} isDropDisabled={matchMap.has(field.name)}>
                      {(dropProvider: DroppableProvided, snapshot: DroppableStateSnapshot) => {
                        const tag = getTag(matchMap.get(field.name));

                        return (
                          <div
                            ref={dropProvider.innerRef}
                            data-cy={`match-slot-${field.name}`}
                            style={{
                              backgroundColor: snapshot.isDraggingOver ? '#edf8f7' : 'white',
                              height: '3.15rem',
                              width: '13.15rem',
                              borderRadius: '4px',
                              border: snapshot.isDraggingOver
                                ? '1px solid #309591'
                                : '1px dashed #C4C4C4',
                              transition:
                                'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            }}
                            {...dropProvider.droppableProps}
                          >
                            {matchMap.has(field.name) && tag ? (
                              <Draggable
                                draggableId={String(matchMap.get(field.name))}
                                index={0}
                                key={String(matchMap.get(field.name))}
                              >
                                {(
                                  provided: DraggableProvided,
                                  dragSnapshot: DraggableStateSnapshot,
                                ) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: 'none',
                                      margin: '0 0 8px 0',
                                      minHeight: '50px',
                                      borderColor: dragSnapshot.isDragging ? '#263B4A' : '#456C86',
                                      ...provided.draggableProps.style,
                                    }}
                                    data-cy="match-tag"
                                  >
                                    <MatchTagComponent label={tag.label} />
                                  </div>
                                )}
                              </Draggable>
                            ) : (
                              dropProvider.placeholder
                            )}
                          </div>
                        );
                      }}
                    </Droppable>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align={AlignEnum.LEFT}
                    className={classNames(classes['MuiTableCell-root'], classes.table__cell)}
                  >
                    <Input
                      disabled
                      multiline
                      fullWidth
                      value={field.valueText}
                      className={classes['table__cell--value']}
                    />
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MatchSectionComponent;
