import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { getConfigurationQueryKey } from '../queryKeys';
import { ApiOperation } from '../../../api';
import { ConfigurationResponseType } from '../../../../types/configuration/ConfigurationResponse.type';

const useGetConfigurationQuery = () => {
  const { request } = useRequest<ConfigurationResponseType>(ApiOperation.GetConfiguration);

  return useQuery(
    getConfigurationQueryKey,
    async () => {
      const res = await request();

      return res.data.responseModel;
    },
    {
      enabled: false,
    },
  );
};

export default useGetConfigurationQuery;
