import React, { FC, ReactElement } from 'react';
import { Card as MatCard, CardActionArea, CardActions } from '@mui/material';
import classNames from 'classnames';
import { CardPropsInterface } from './interfaces/CardProps.interface';
import { CardContent } from '..';
import classes from './Card.module.scss';

const Card: FC<CardPropsInterface> = ({
  children,
  link,
  image,
  imageAlt,
  actions,
  ...props
}): ReactElement => {
  const onOpenLinkHandler = () => {
    if (link && window) {
      window.open(link, '_blank')?.focus();
    }
  };

  return (
    <MatCard {...props} className={classNames(classes['data-hub-card'], props.className)}>
      {link && (
        <CardActionArea onClick={onOpenLinkHandler}>
          <CardContent
            image={image}
            imageAlt={imageAlt}
            className={classes['data-hub-card__content']}
          >
            {children}
          </CardContent>
        </CardActionArea>
      )}

      {!link && (
        <CardContent
          image={image}
          imageAlt={imageAlt}
          className={classes['data-hub-card__content']}
        >
          {children}
        </CardContent>
      )}

      {actions && (
        <CardActions className={classes['data-hub-card__actions']}>{actions}</CardActions>
      )}
    </MatCard>
  );
};

export { Card };
