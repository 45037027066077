import React, { FC, ReactElement } from 'react';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import HorizontalRuleSharpIcon from '@mui/icons-material/HorizontalRuleSharp';
import { useTranslation } from 'react-i18next';
import { InvoicesReportFormPropsInterface } from './interfaces/InvoicesReportFormProps.interface';
import classes from './InvoicesReportForm.module.scss';
import FormContainerComponent from '../../../../../../../../../components/form-container/FormContainer.component';
import DateController from '../../../../../to-verify/components/verification/components/verify-invoice-data/components/controllers/DateController.component';
import { InvoicesReportFormInterface } from './interfaces/InvoicesReportForm.interface';
import { FormControl } from '../../../../../../../../../components/material';

const InvoicesReportFormComponent: FC<InvoicesReportFormPropsInterface> = ({
  error,
}): ReactElement => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext<InvoicesReportFormInterface>();

  return (
    <FormContainerComponent className={classes['invoices-report-form']} fullWidth>
      <Grid
        container
        direction="row"
        columnSpacing={4}
        rowSpacing={4}
        className={classes['invoices-report-form__container']}
      >
        <Grid item xs={4}>
          <DateController
            name="fromDate"
            label={t('invoices.report.controls.fromDate.label')}
            error={errors.fromDate}
            required
          />
        </Grid>
        <Grid item xs={1}>
          <HorizontalRuleSharpIcon className={classes.container__separator} />
        </Grid>
        <Grid item xs={4}>
          <DateController
            name="toDate"
            label={t('invoices.report.controls.toDate.label')}
            error={errors.toDate}
            required
          />
        </Grid>

        {error && (
          <Grid item>
            <FormControl error={!!error}>
              <span role="alert">{error}</span>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </FormContainerComponent>
  );
};

export default InvoicesReportFormComponent;
