import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import { getResidentPropertiesQueryKey } from '../../queryKeys';
import { ResidentPropertiesResponseType } from '../../../../../types/upload-hub/resident/property/ResidentPropertiesResponse.type';

const useGetResidentPropertiesQuery = () => {
  const { request } = useRequest<ResidentPropertiesResponseType>(
    ApiOperation.GetResidentProperties,
  );

  return useQuery(
    getResidentPropertiesQueryKey,
    async () => {
      const res = await request();

      return res.data.responseModel.residentProperties;
    },
    {
      enabled: true,
    },
  );
};

export default useGetResidentPropertiesQuery;
