export enum OCRFieldsEnum {
  AMOUNT = 'Amount',
  DUE_DATE = 'DueDate',
  INVOICE_DATE = 'InvoiceDate',
  INVOICE_ID = 'InvoiceId',
  PRODUCT_CODE = 'ProductCode',
  SERVICE_END_DATE = 'ServiceEndDate',
  SERVICE_START_DATE = 'ServiceStartDate',
  TAX = 'Tax',
  INVOICE_TOTAL = 'InvoiceTotal',
  VENDOR_ADDRESS = 'VendorAddress',
  VENDOR_ADDRESS_RECIPIENT = 'VendorAddressRecipient',
  VENDOR_NAME = 'VendorName',
  DATE = 'Date',
}
