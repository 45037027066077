import React, { FC, lazy, ReactElement, Suspense, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import LoadingComponent from '../loading/Loading.component';
import useAuth from '../../../services/auth/Auth.provider';
import LoginSectionComponent from '../../landing-page/components/login-section/LoginSection.component';
import ForgotPasswordFormComponent from '../../landing-page/components/form/components/forgot-password-form/ForgotPasswordForm.component';
import { ElementInterface } from '../../../interfaces/Element.interface';
import GlobalSettingsComponent from '../../home-page/components/settings/components/global-settings/GlobalSettings.component';
import DataSourcesComponent from '../../data-source/DataSources.component';
import PluginSettingsComponent from '../../plugin-settings/PluginSettings.component';
import { PathEnum } from './enums/Path.enum';
import ConfirmInvitationComponent from '../../landing-page/components/confirm-invitation/ConfirmInvitation.component';
import InProgressInvoicesComponent from '../../home-page/components/invoices/components/in-progress/InProgressInvoices.component';
import ToVerifyInvoicesComponent from '../../home-page/components/invoices/components/to-verify/ToVerifyInvoices.component';
import ProcessedInvoicesComponent from '../../home-page/components/invoices/components/processed/ProcessedInvoices.component';
import UploadHubProvider from '../../home-page/components/invoices/providers/UploadHub.provider';
import DashboardComponent from '../../home-page/components/dashboard/Dashboard.component';
import { useSubscription } from '../../../services/subscription/Subscription.provider';
import { useUserPermissions } from '../../../services/user/User.provider';
import { PermissionTypeEnum } from '../../../enums/permission/PermissionType.enum';
import ToApproveInvoicesComponent from '../../home-page/components/invoices/components/to-approve/ToApproveInvoices.component';
import ApprovalElement from './upload-hub/Approval.element';
import ProcessingElement from './upload-hub/Processing.element';

const LandingPageView = lazy(() => import('../../landing-page/LandingPage.view'));
const HomePageView = lazy(() => import('../../home-page/HomePage.view'));

const SettingsComponent = lazy(
  () => import('../../home-page/components/settings/Settings.component'),
);

const InvoicesComponent = lazy(
  () => import('../../home-page/components/invoices/Invoices.component'),
);

const PublicElement: FC<ElementInterface> = ({ element, clearAuth }): ReactElement => {
  const [isAuthConfirmed, setIsAuthConfirmed] = useState(false);
  const { isAuthenticated, clearAuthInfo } = useAuth();

  useEffect(() => {
    if (clearAuth) {
      clearAuthInfo();
    }

    setIsAuthConfirmed(true);
  }, [clearAuth, clearAuthInfo, setIsAuthConfirmed]);

  if (!isAuthConfirmed) {
    return <LoadingComponent />;
  }

  // TODO: replace with "/" when home page is enabled
  return isAuthenticated && !clearAuth ? <Navigate to="/invoices/in-progress" /> : element;
};

const ProtectedElement: FC<ElementInterface> = ({
  element,
  allowedPermissions,
  disabled,
}): ReactElement => {
  const { isAuthenticated } = useAuth();
  const { hasPlugins } = useSubscription();
  const { hasPermission } = useUserPermissions();

  if (!isAuthenticated) {
    return <Navigate to={pathTo([PathEnum.LOGIN])} />;
  }

  // TODO: temporary solution for accessing dashboard when no plugins are available, refactor when dashboard and multi plugins are supported
  if (
    !hasPlugins ||
    (allowedPermissions !== undefined && !allowedPermissions.some(hasPermission)) ||
    disabled
  ) {
    return <Navigate to={pathTo([PathEnum.DASHBOARD])} />;
  }

  return element;
};

// TODO: temporary solution for auto load Upload Hub plugin when accessing dashboard, refactor when dashboard and multi plugins are supported
const DefaultPluginElement: FC<ElementInterface> = ({ element }): ReactElement => {
  const { isAuthenticated } = useAuth();
  const { hasPlugins } = useSubscription();
  const { hasAnyPermission } = useUserPermissions();

  if (!isAuthenticated) {
    return <Navigate to={pathTo([PathEnum.LOGIN])} />;
  }

  return hasPlugins && hasAnyPermission ? <Navigate to={pathTo([PathEnum.INVOICES])} /> : element;
};

export const pathTo = (paths: PathEnum[] | string[]): string => {
  return `/${paths.join('/')}`;
};

const routes = (isAuthenticated: boolean, arePluginsEnabled = true) => [
  {
    path: '/',
    element: isAuthenticated ? <HomePageView /> : <LandingPageView />,
    children: [
      {
        path: PathEnum.LOGIN,
        element: <PublicElement element={<LoginSectionComponent />} />,
      },
      {
        path: PathEnum.RESET_PASS,
        element: <PublicElement element={<ForgotPasswordFormComponent />} />,
      },
      {
        path: `${PathEnum.CONFIRM_INVITATION}/:id`,
        element: <PublicElement element={<ConfirmInvitationComponent />} clearAuth />,
      },
      {
        path: PathEnum.DASHBOARD,
        element: <DefaultPluginElement element={<DashboardComponent />} />,
      },
      {
        path: PathEnum.SETTINGS,
        element: <ProtectedElement element={<SettingsComponent />} />,
        children: [
          {
            path: PathEnum.GLOBAL,
            element: (
              <ProtectedElement
                element={<GlobalSettingsComponent />}
                allowedPermissions={[PermissionTypeEnum.ADMIN]}
              />
            ),
          },
          {
            path: PathEnum.DATA_SRC,
            element: (
              <ProtectedElement
                element={<DataSourcesComponent />}
                allowedPermissions={[PermissionTypeEnum.ADMIN]}
              />
            ),
          },
          {
            path: PathEnum.PLUGIN,
            element: (
              <ProtectedElement
                element={<PluginSettingsComponent />}
                disabled={!arePluginsEnabled}
                allowedPermissions={[PermissionTypeEnum.ADMIN, PermissionTypeEnum.UPLOAD_HUB_ADMIN]}
              />
            ),
          },
          {
            path: '',
            element: <Navigate replace to={pathTo([PathEnum.SETTINGS, PathEnum.PLUGIN])} />,
          },
        ],
      },
      // TODO: revert when home page is enabled
      {
        path: '',
        element: <Navigate replace to={pathTo([PathEnum.INVOICES, PathEnum.IN_PROGRESS])} />,
      },
      // {
      //   path: '',
      //   element: <ProtectedElement element={<HomeComponent />} />,
      // },
      {
        path: PathEnum.INVOICES,
        element: (
          <ProtectedElement
            element={
              <UploadHubProvider>
                <InvoicesComponent />
              </UploadHubProvider>
            }
            allowedPermissions={[
              PermissionTypeEnum.ADMIN,
              PermissionTypeEnum.UPLOAD_HUB_ADMIN,
              PermissionTypeEnum.UPLOAD_HUB,
            ]}
          />
        ),
        children: [
          {
            path: PathEnum.IN_PROGRESS,
            element: (
              <ProtectedElement
                element={<ProcessingElement element={<InProgressInvoicesComponent />} />}
              />
            ),
          },
          {
            path: PathEnum.TO_VERIFY,
            element: (
              <ProtectedElement
                element={<ProcessingElement element={<ToVerifyInvoicesComponent />} />}
              />
            ),
          },
          {
            path: PathEnum.TO_APPROVE,
            element: (
              <ProtectedElement
                element={<ApprovalElement element={<ToApproveInvoicesComponent />} />}
              />
            ),
          },
          {
            path: PathEnum.PROCESSED,
            element: <ProtectedElement element={<ProcessedInvoicesComponent />} />,
          },
          {
            path: '',
            element: <Navigate replace to={pathTo([PathEnum.INVOICES, PathEnum.IN_PROGRESS])} />,
          },
        ],
      },
    ],
  },
  // TODO: replace with "/" when home page is enabled
  {
    path: '*',
    element: <Navigate replace to={pathTo([PathEnum.INVOICES, PathEnum.IN_PROGRESS])} />,
  },
];

const RouterOutlet: FC = (): ReactElement => {
  const { isAuthenticated } = useAuth();
  const { pluginsTypes } = useSubscription();

  const router = useRoutes(routes(isAuthenticated, pluginsTypes.length > 0));

  return <Suspense fallback={<LoadingComponent />}>{router}</Suspense>;
};

export default RouterOutlet;
