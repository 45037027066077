import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import classes from './PluginSettings.module.scss';
import TabsComponent from '../components/tabs/Tabs.component';
import { TabInterface } from '../components/tabs/interfaces/Tab.interface';
import { useSubscription } from '../../services/subscription/Subscription.provider';
import { PluginInterface } from '../../types/plugin/Plugin.interface';

const PluginSettingsComponent: FC = () => {
  const { t } = useTranslation();
  const { pluginsTypes } = useSubscription();

  const pluginsTabs: TabInterface[] = pluginsTypes.map((plugin: PluginInterface): TabInterface => {
    return {
      ...plugin.settings,
      // @ts-ignore
      label: t(`${plugin.settings.label}`),
    };
  });

  return (
    <Grid className={classes['plugin-settings']}>
      <h5 className={classes['plugin-settings__title']}>{t('pluginSettings.title')}</h5>
      <TabsComponent tabs={pluginsTabs} cards />
    </Grid>
  );
};

export default PluginSettingsComponent;
