import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { getAllRolesQueryKey } from '../queryKeys';
import { ApiOperation } from '../../../api';
import { RolesResponseType } from '../../../../types/roles/RolesResponse.type';

const useGetAllRolesListQuery = () => {
  const { request } = useRequest<RolesResponseType>(ApiOperation.GetRoles);

  return useQuery(getAllRolesQueryKey, async () => {
    const res = await request();

    return res.data.responseModel;
  });
};

export default useGetAllRolesListQuery;
