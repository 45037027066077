import { useCallback, useMemo } from 'react';
import { FileMimeTypeEnum } from '../../../views/components/file-preview/enums/FileMimeTypeEnum';

const useFile = (
  fileLocalization: string = '',
  fileName: string = `${new Date().toISOString()}_data-hub.pdf`,
) => {
  const download = useCallback(
    (localization?: string, name?: string) => {
      const link = document.createElement('a');
      link.href = localization || fileLocalization;
      link.setAttribute('download', name || fileName);

      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    },
    [fileLocalization, fileName],
  );

  const fileType = useMemo(() => {
    const fileNameParts = fileName.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1];

    if (['pdf', 'PDF'].includes(fileExtension)) {
      return FileMimeTypeEnum.PDF;
    }

    if (['jpeg', 'JPEG', 'jpe', 'JPE', 'jif', 'JIF', 'jfif', 'JFIF'].includes(fileExtension)) {
      return FileMimeTypeEnum.JPEG;
    }

    if (['jpg', 'JPG'].includes(fileExtension)) {
      return FileMimeTypeEnum.JPG;
    }

    if (['png', 'PNG'].includes(fileExtension)) {
      return FileMimeTypeEnum.PNG;
    }

    return FileMimeTypeEnum.UNKNOWN;
  }, [fileName]);

  return {
    name: fileName,
    localization: fileLocalization,
    download,
    fileType,
  };
};

export default useFile;
