import { useTranslation } from 'react-i18next';
import React, { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { VerifyInvoiceDataFormInterface } from '../../interfaces/VerifyInvoiceDataForm.interface';
import AutocompleteSelect from '../../../../../../../../../../../components/material/autocomplete-select/AutocompleteSelect.component';
import { ResidentPropertyInterface } from '../../../../../../../../../../../../types/upload-hub/resident/property/ResidentProperty.interface';
import { FormControl } from '../../../../../../../../../../../components/material';
import { ResidentScheduleInterface } from '../../../../../../../../../../../../types/upload-hub/resident/schedule/ResidentSchedule.interface';
import useGetResidentSchedulesQuery from '../../../../../../../../../../../../services/react-query-hooks/queries/upload-hub/resident/useGetResidentSchedules.query';
import { ResidentInvoiceDataEnum } from '../../../../../../../../../../../../enums/upload-hub/ResidentInvoiceData.enum';

// @ts-ignore
const SchedulesControllerComponent = ({
  // @ts-ignore
  name,
  // @ts-ignore
  error,
  // @ts-ignore
  onChangeSchedule,
  disabled = false,
  'data-cy': dataCy = '',
}) => {
  const { t } = useTranslation();
  const { control, watch, getValues } = useFormContext<VerifyInvoiceDataFormInterface>();

  const propertyValue = getValues(String(ResidentInvoiceDataEnum.PROPERTY));
  const { refetch: fetchSchedules } = useGetResidentSchedulesQuery(
    propertyValue ? (propertyValue as ResidentPropertyInterface).value : '',
  );

  const currentSelectedSchedule = watch(name);

  const schedulesController = useMemo(
    () => (
      <Controller
        name={name}
        control={control}
        render={({ field: fieldProps }) => (
          <AutocompleteSelect
            {...fieldProps}
            value={fieldProps.value as ResidentScheduleInterface}
            fetch={fetchSchedules as () => Promise<any>}
            placeholder={t(
              'invoices.tabs.toVerify.verificationModal.steps.verification.sections.items.controls.schedule.label',
            )}
            error={!!error}
            disabled={disabled || propertyValue === null}
            onChange={(value) => {
              fieldProps.onChange(value);

              if (
                onChangeSchedule &&
                (value === null ||
                  (currentSelectedSchedule as ResidentScheduleInterface)?.value !==
                    (value as ResidentScheduleInterface)?.value)
              ) {
                onChangeSchedule(value as ResidentScheduleInterface);
              }
            }}
            data-cy={`${dataCy || 'schedule'}-input`}
          />
        )}
      />
    ),
    [
      control,
      currentSelectedSchedule,
      dataCy,
      disabled,
      error,
      fetchSchedules,
      name,
      onChangeSchedule,
      propertyValue,
      t,
    ],
  );

  return (
    <FormControl
      error={!!error}
      style={{ width: '50%' }}
      data-cy={`${dataCy || 'schedule'}-controller`}
    >
      {schedulesController}
      <span role="alert">{(error as any)?.message}</span>
    </FormControl>
  );
};

const SchedulesController = memo(SchedulesControllerComponent);

export default SchedulesController;
