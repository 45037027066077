import { useTranslation } from 'react-i18next';
import CommonSetErrorType from '../../CommonSetError.type';
import { useCommonErrorHandler } from '../../useErrorHandler';

const useThresholdOfAvailablePagesToProcessExceededErrorHandler = (
  setError?: CommonSetErrorType,
) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'THRESHOLD_OF_AVAILABLE_PAGES_TO_PROCESS_EXCEEDED',
    errorMessage: t('invoices.tabs.inProgress.actions.ocr.errors.limitExceeded'),
  });

  return handler;
};

export default useThresholdOfAvailablePagesToProcessExceededErrorHandler;
