import React, { FC, ReactElement } from 'react';
import { TablePaginationProps, TablePagination as MatTablePagination } from '@mui/material';
import classNames from 'classnames';
import './TablePagination.component.scss';
import { TablePaginationPropsInterface } from './interfaces/TablePaginationProps.interface';

export const TablePagination: FC<TablePaginationProps<any, TablePaginationPropsInterface>> = ({
  className,
  showRowsLabel = true,
  ...rest
}): ReactElement => {
  return (
    <MatTablePagination
      className={classNames(
        'data-hub-table-pagination',
        !showRowsLabel ? 'data-hub-table-pagination--no-rows-label' : '',
        className,
      )}
      component="div"
      {...rest}
    />
  );
};

export default TablePagination;
