import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteModalComponent from '../../../../../../../../../components/modal/delete-modal/DeleteModal.component';
import useDeleteUserMutation from '../../../../../../../../../../services/react-query-hooks/mutations/user/useDeleteUserMutation';
import { UserInterface } from '../../../../../../../../../../types/users/User.interface';

interface DeleteUserPropsInterface {
  user: UserInterface;
  close: () => void;
}

const DeleteUserComponent: FC<DeleteUserPropsInterface> = ({ user, close }): ReactElement => {
  const { t } = useTranslation();
  const { mutate: deleteUser, isLoading, isSuccess } = useDeleteUserMutation();

  return (
    <DeleteModalComponent
      text={t('settings.sections.permission.subsections.user.deleteModal.message')}
      close={close}
      isLoading={isLoading}
      isSuccess={isSuccess}
      mutate={() => deleteUser(user)}
    />
  );
};

export default DeleteUserComponent;
