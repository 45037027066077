import {
  ApiClientConfigInterface,
  ApiError,
  ApiRequestConfig,
  ApiResponse,
} from '@datahub/api-client';
import Cookie from 'js-cookie';
import { AuthCookieEnum } from '../auth/enums/AuthCookie.enum';
import { stagingDomain } from '../../domains';

export type restClients = 'authClient' | 'appClient' | 'nylasClient';

const requestInterceptor = async (config: ApiRequestConfig): Promise<ApiRequestConfig> => {
  const newConfig = { ...config };

  if (
    Cookie.get(AuthCookieEnum.TOKEN_TYPE) &&
    Cookie.get(AuthCookieEnum.ID_TOKEN) &&
    newConfig.url !== '/User/ConfirmInvitation'
  ) {
    // TODO: regenerate token if needed

    const headers = new Headers();

    headers.append(
      'Authorization',
      `${Cookie.get(AuthCookieEnum.TOKEN_TYPE)} ${Cookie.get(AuthCookieEnum.ID_TOKEN)}`,
    );

    newConfig.headers = newConfig.headers.concat({
      Authorization: `${Cookie.get(AuthCookieEnum.TOKEN_TYPE)} ${Cookie.get(
        AuthCookieEnum.ID_TOKEN,
      )}`,
    });
  }

  return newConfig;
};

const responseInterceptor = async (response: ApiResponse) => response;
const responseError = (error: ApiError) => {
  if (error.response?.status === 401) {
    // TODO: add toast error, redirect to login
  }

  return Promise.reject(error);
};

export const clients: ApiClientConfigInterface<restClients>[] = [
  {
    name: 'appClient',
    baseUrl:
      window.location.hostname === stagingDomain && process.env.REACT_APP_APP_API_URL_STAGING
        ? process.env.REACT_APP_APP_API_URL_STAGING
        : process.env.REACT_APP_APP_API_URL,
    interceptors: {
      request: {
        onFulfillment: requestInterceptor,
      },
      response: {
        onFulfillment: responseInterceptor,
        onError: responseError,
      },
    },
  },
  {
    name: 'authClient',
    baseUrl: process.env.REACT_APP_AUTH_API_URL,
  },
  {
    name: 'nylasClient',
    baseUrl: process.env.REACT_APP_NYLAS_API_URL,
  },
];
