import jwtDecode from 'jwt-decode';

const getTokenExpDateFromNow = (expTime: string | number): Date => {
  const accessTokenExp = new Date();
  accessTokenExp.setSeconds(accessTokenExp.getSeconds() + Number(expTime));

  return accessTokenExp;
};

const isTokenAuthenticated = (accessToken: string, idToken: string, expiresIn: string): boolean => {
  if (!accessToken || !idToken || !expiresIn) {
    return false;
  }

  const currentTime = new Date().getTime() / 1000;
  const accessTokenExp = getTokenExpDateFromNow(Number(expiresIn)).getTime() / 1000;
  // @ts-ignore
  const { exp } = jwtDecode(idToken);

  return [exp, accessTokenExp].every((e: number) => currentTime <= e);
};

export { getTokenExpDateFromNow, isTokenAuthenticated };
