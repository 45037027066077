import React from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceUploadStatusType from '../../../../../../../../types/invoice-upload/InvoiceUploadStatus.type';
import { Chip } from '../../../../../../../components/material';
import { ChipPropsInterface } from '../../../../../../../components/material/chip/interfaces/ChipProps.interface';

interface StatusChipComponentPropsInterface {
  status: InvoiceUploadStatusType;
}

const UploadStatusChipComponent = ({ status }: StatusChipComponentPropsInterface) => {
  const { t } = useTranslation();

  const chipColor = (): ChipPropsInterface => {
    switch (status) {
      case 'done':
        return {
          color: 'success',
          variant: 'filled',
        };
      case 'error':
        return {
          color: 'error',
        };
      case 'queue':
        return {
          color: 'transparent',
          variant: 'outlined',
        };
      default:
        return {
          color: 'default',
          variant: 'outlined',
        };
    }
  };

  return <Chip label={t(`invoices.invoiceUpload.statuses.${status}`)} {...chipColor()} />;
};

export default UploadStatusChipComponent;
