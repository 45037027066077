import React, { useMemo, useState } from 'react';
import InvoicesReportModalComponent from '../InvoicesReportModal.component';

const useInvoicesReport = () => {
  const [isInvoicesReportModalOpen, setIsInvoicesReportModalOpen] = useState(false);

  const invoicesReportModal = useMemo(() => {
    return (
      <InvoicesReportModalComponent
        open={isInvoicesReportModalOpen}
        setOpen={setIsInvoicesReportModalOpen}
      />
    );
  }, [isInvoicesReportModalOpen]);

  return { invoicesReportModal, setIsInvoicesReportModalOpen };
};

export default useInvoicesReport;
