import React from 'react';
import { useMutation } from 'react-query';
import { Trans } from 'react-i18next';
import { useRequest } from '@datahub/api-client';
import { getDataSourcesForGeneralTypeQuery, getDataSourcesQueryKey } from '../../queries/queryKeys';
import { queryClient } from '../../../queryClient';
import { ApiOperation } from '../../../api';
import useDataSourceNotFoundErrorHandler from '../../error-handlers/data-source/useDataSourceNotFoundError.handler';
import useGeneralErrorHandler from '../../error-handlers/useGeneralErrorHandler';
import useOnError from '../../error-handlers/useOnError';
import { DataSourceMutationPropsInterface } from './DataSourceMutationProps.interface';
import { useAlert } from '../../../alert/Alert.provider';
import { DataSourceInterface } from '../../../../types/data-source/DataSource.interface';

const useDeleteDataSourceMutation = ({ setOtherError }: DataSourceMutationPropsInterface = {}) => {
  const { showAlert } = useAlert();

  const { request } = useRequest(ApiOperation.DeleteDataSource);

  const notFoundErrorHandler = useDataSourceNotFoundErrorHandler(setOtherError);
  const generalErrorHandler = useGeneralErrorHandler(setOtherError);

  const { onError } = useOnError([notFoundErrorHandler], generalErrorHandler);

  return useMutation(
    async ({ uniqueNumber }: DataSourceInterface) => {
      const res = await request({ urlParams: { id: uniqueNumber } });

      return res.data;
    },
    {
      onSuccess: (_, { name }: DataSourceInterface) => {
        queryClient.invalidateQueries(getDataSourcesQueryKey);
        queryClient.invalidateQueries(getDataSourcesForGeneralTypeQuery);

        showAlert(
          <Trans
            i18nKey="dataSourcesSettings.sections.dataSources.subsections.supplierList.deleteDataSourceModal.success"
            values={{ name }}
            components={{ bold: <strong /> }}
          />,
          'info',
        );
      },
      onError,
    },
  );
};

export default useDeleteDataSourceMutation;
