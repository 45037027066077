import React, { ChangeEvent, ReactElement, useState, MouseEvent } from 'react';
import classNames from 'classnames';
import { Checkbox, Collapse, TableCell, TableRow } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import classes from '../../Table.module.scss';
import { TableColumnInterface } from '../../interfaces/TableColumn.interface';
import { AlignEnum } from '../../../../../enums/Align.enum';
import { TableColumnTypeEnum } from '../../enums/TableColumnType.enum';
import TableActionsComponent from '../table-actions/TableActions.component';
import { TableActionInterface } from '../../interfaces/TableProps.interface';
import { TableActionNameEnum } from '../../enums/TableActionName.enum';
import { TableRowPropsInterface } from './interfaces/TableRowProps.interface';
import { TableRowInterface } from '../../interfaces/TableRow.interface';
import { StatusEnum } from '../../../../../enums/Status.enum';
import { IconButton } from '../../../material';
import TableLoadingCircleComponent from '../table-loading-circle/TableLoadingCircle.component';
import useTableRowUtils from './hooks/TableRowUtils.hook';

const rowStatusClass = (row: TableRowInterface): string => {
  switch (row.status) {
    case StatusEnum.WARNING:
      return classes['table__row--warning'];
    case StatusEnum.ERROR:
      return classes['table__row--error'];
    case StatusEnum.SUCCESS:
      return classes['table__row--success'];
    default:
      return '';
  }
};

const rowStatusIcon = (row: TableRowInterface): ReactElement => {
  switch (row.status) {
    case StatusEnum.WARNING:
      return <WarningIcon />;
    case StatusEnum.ERROR:
      return <ReportIcon />;
    case StatusEnum.SUCCESS:
      return <CheckCircleIcon />;
    default:
      return <> </>;
  }
};

const columnWidth = (column: TableColumnInterface): string => {
  const explicitWidth = () => {
    if (column.width) {
      return typeof column.width === 'number' ? `${column.width}px` : column.width;
    }

    return 'auto';
  };

  return column.type === TableColumnTypeEnum.STATUS || column.type === TableColumnTypeEnum.ACTIONS
    ? '4rem'
    : explicitWidth();
};

const TableRowComponent = <T,>({
  row,
  isRowSelected,
  onSelectRow,
  onClickRow,
  columns,
  onActionSelected,
  multiselect,
  actions,
  isActionDisabled,
  expandable,
  disabled,
  loading,
}: TableRowPropsInterface<T>): ReactElement => {
  const [open, setOpen] = useState(false);
  const { activeActions } = useTableRowUtils(row, actions);

  const toggleRowHandler = (event?: MouseEvent<HTMLTableRowElement>) => {
    const isExpanded = expandable && row.expandedElement;

    if (!event && isExpanded) {
      setOpen((prevOpen: boolean) => !prevOpen);
      return;
    }

    const target = event?.target as Element;

    if (
      !target.classList.contains('MuiSvgIcon-root') &&
      !target.classList.contains('MuiBackdrop-root') &&
      !target.classList.contains('MuiButtonBase-root')
    ) {
      if (isExpanded) {
        setOpen((prevOpen: boolean) => !prevOpen);
      } else if (onClickRow) {
        onClickRow(String(row.id));
      }
    }
  };

  const cellContent = (column: TableColumnInterface, index: number) => {
    const lastColumnIndex = columns.length - 1;
    const showActions = index < lastColumnIndex || (index === lastColumnIndex && !open);

    return column.collapseIcon && open ? (
      <IconButton size="large" onClick={() => setOpen((prevOpen) => !prevOpen)}>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    ) : (
      <>
        {column.type === TableColumnTypeEnum.DEFAULT && row[column.name]}

        {column.type !== undefined &&
          [TableColumnTypeEnum.LOADING, TableColumnTypeEnum.ACTIONS].includes(column.type) &&
          loading && <TableLoadingCircleComponent />}

        {column.type === TableColumnTypeEnum.ACTIONS && showActions && !loading && (
          <TableActionsComponent
            row={row}
            actions={activeActions}
            onActionSelected={(action: TableActionInterface<T>) => {
              if (onActionSelected) {
                onActionSelected(action.name, row.id);
              }
            }}
          />
        )}

        {column.type === TableColumnTypeEnum.ACTIONS &&
          open &&
          index === lastColumnIndex &&
          !loading && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                toggleRowHandler();
              }}
            >
              <ExpandLessIcon />
            </IconButton>
          )}

        {column.type === TableColumnTypeEnum.CHECKBOX && (
          <Checkbox
            checked={row[column.name] as boolean}
            disabled={isActionDisabled?.actionType === 'checkbox' && isActionDisabled?.isDisabled}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (onActionSelected) {
                onActionSelected(
                  TableActionNameEnum.CHECKBOX,
                  row.id,
                  column.name,
                  event.target.checked,
                );
              }
            }}
            onClick={(event) => event.stopPropagation()}
          />
        )}

        {column.type === TableColumnTypeEnum.STATUS && rowStatusIcon(row)}
      </>
    );
  };

  return (
    <>
      <TableRow
        hover
        className={classNames(
          classes['MuiTableRow-root'],
          classes.table__row,
          rowStatusClass(row),
          open ? classes['table__row--expanded'] : '',
          disabled ? classes['table__row--disabled'] : '',
          loading ? classes['table__row--loading'] : '',
          onClickRow || expandable ? classes['table__row--clickable'] : '',
          isRowSelected(String(row.id)) ? classes['table__row--to-delete'] : '',
        )}
        onClick={toggleRowHandler}
        data-cy={`row-${row.id}`}
      >
        {multiselect && (
          <TableCell
            padding="checkbox"
            className={classNames(
              classes['MuiTableCell-root'],
              classes.table__cell,
              classes['table__cell--checkbox'],
            )}
            style={{ width: '4rem' }}
          >
            {!disabled && (
              <Checkbox
                color="primary"
                checked={isRowSelected(String(row.id))}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onSelectRow(event, String(row.id))
                }
                onClick={(event) => event.stopPropagation()}
              />
            )}
          </TableCell>
        )}

        {columns.map(
          (column: TableColumnInterface, index: number) =>
            row[column.name] !== 'undefined' && (
              <TableCell
                key={column.name}
                align={column.align || AlignEnum.CENTER}
                className={classNames(
                  classes['MuiTableCell-root'],
                  classes.table__cell,
                  column.type === TableColumnTypeEnum.STATUS ? classes['table__cell--status'] : '',
                )}
                style={{
                  width: columnWidth(column),
                  minWidth: columnWidth(column),
                }}
              >
                {cellContent(column, index)}
              </TableCell>
            ),
        )}
      </TableRow>

      {expandable && row.expandedElement && (
        <TableRow
          className={classNames(
            classes['MuiTableRow-root'],
            classes.table__row,
            classes['table__row--details'],
            open ? classes['table__row--expanded-details'] : '',
          )}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={12}
            className={classNames(classes['MuiTableCell-root'], classes.table__cell)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className={classes.row__details}>{row.expandedElement}</div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TableRowComponent;
