import { useTranslation } from 'react-i18next';
import CommonSetErrorType from '../../../../CommonSetError.type';
import { useCommonErrorHandler } from '../../../../useErrorHandler';

const useNylasAccountUniqueErrorHandler = (setError?: CommonSetErrorType) => {
  const { t } = useTranslation();

  const { handler } = useCommonErrorHandler({
    setError,
    errorCode: 'NYLAS_ACCOUNT_ALREADY_EXISTS',
    errorMessage: t(
      'pluginSettings.sections.uploadHub.subsections.synchronizedInboxes.controls.email.errors.duplicate',
    ),
  });

  return handler;
};

export default useNylasAccountUniqueErrorHandler;
