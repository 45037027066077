import React, { FC, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicesReportModalPropsInterface } from './interfaces/InvoicesReportModalProps.interface';
import ModalComponent from '../../../../../../../components/modal/modal/Modal.component';
import InvoicesReportView from './InvoicesReport.view';

const InvoicesReportModalComponent: FC<InvoicesReportModalPropsInterface> = ({
  open,
  setOpen,
}): ReactElement => {
  const { t } = useTranslation();
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      title={t('invoices.report.title')}
      content={<InvoicesReportView close={handleClose} />}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
      className="invoices-report-modal"
      actions
    />
  );
};

export default InvoicesReportModalComponent;
