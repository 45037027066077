import React, { ReactElement, FC, useState, memo } from 'react';
import classnames from 'classnames';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useTranslation } from 'react-i18next';
import classes from './NavigationMenu.module.scss';
import { PropsInterface } from '../../../interfaces/Props.interface';
import { IconButton } from '../material';
import NavigationLinks from './components/navigation-links/NavigationLinks.component';
import DrawerMenuComponent from './components/drawer-menu/DrawerMenu.component';

const NavigationMenuComponent: FC<PropsInterface> = ({ className }): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classnames(classes['navigation-menu'], className)}>
      <NavigationLinks className={classes['navigation-menu__menu']} />
      <IconButton
        size="large"
        color="secondary"
        aria-label={t('navigation.aria.button')}
        className={classnames([
          classes['navigation-menu__hamburger'],
          classes['MuiButtonBase-root'],
        ])}
        onClick={() => setIsOpen(true)}
      >
        <MenuOutlinedIcon />
      </IconButton>
      <DrawerMenuComponent isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

const NavigationMenu = memo(NavigationMenuComponent);

export default NavigationMenu;
