import React, { PropsWithChildren, ReactElement } from 'react';
import './Button.component.scss';
import { Button as MatButton } from '@mui/material';
import { LoadingButton as MatLoadingButton } from '@mui/lab';
import classNames from 'classnames';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsVariantOverrides } from '@mui/material/Button/Button';
import { ButtonProps } from './interfaces/ButtonProps.interface';

const Button = (props: PropsWithChildren<ButtonProps | any>): ReactElement => {
  const { loading, className, variant, startIcon, disabled } = props;
  const { colorText, ...restProps } = props;
  const buttonClassName = classNames(
    'data-hub-button',
    className,
    `MuiButton-${variant}`,
    colorText ? 'MuiButton-colorText' : '',
    loading ? `MuiButton-loading` : '',
    disabled ? `MuiButton-disabled` : '',
  );
  const buttonVariant = ['contained', 'text', 'outlined', 'link', 'toggle'].includes(variant)
    ? (variant as OverridableStringUnion<
        'text' | 'outlined' | 'contained' | 'link' | 'toggle',
        ButtonPropsVariantOverrides
      >)
    : 'contained';

  return loading !== undefined ? (
    <MatLoadingButton
      {...restProps}
      loading={loading}
      variant={buttonVariant}
      className={buttonClassName}
      startIcon={loading ? undefined : startIcon}
    />
  ) : (
    <MatButton {...restProps} variant={buttonVariant} className={buttonClassName} />
  );
};

export { Button };
