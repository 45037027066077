import { useQuery } from 'react-query';
import { useRequest } from '@datahub/api-client';
import { ApiOperation } from '../../../../api';
import { getResidentBankAccountsQueryKey } from '../../queryKeys';
import { ResidentBankAccountsResponseType } from '../../../../../types/upload-hub/resident/bank-account/ResidentBankAccountsResponse.type';

const useGetResidentBankAccountsQuery = (propertyId: string = '') => {
  const { request } = useRequest<ResidentBankAccountsResponseType>(
    ApiOperation.GetResidentBankAccounts,
  );

  return useQuery(
    [getResidentBankAccountsQueryKey, propertyId],
    async () => {
      const res = await request({
        params: {
          PropertyId: propertyId,
        },
      });

      return res.data.responseModel.residentBankAccounts;
    },
    {
      enabled: !!propertyId,
    },
  );
};

export default useGetResidentBankAccountsQuery;
