import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classes from '../material/card/Card.module.scss';
import { Card } from '../material';

const commercialWebsiteUrl = 'https://dhub.uk.com/';

const CommercialWebsiteCardComponent: FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      link={commercialWebsiteUrl}
      image="/static/commercial-website-background.jpg"
      imageAlt="Commercial website background"
    >
      <h6 className={classes['data-hub-card__title']}>{t('commercialWebsite.card.title')}</h6>
      <p className={classes['data-hub-card__content']}>{t('commercialWebsite.card.content')}</p>
    </Card>
  );
};

export default CommercialWebsiteCardComponent;
