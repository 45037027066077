import { useRequest } from '@datahub/api-client';
import { ProcessedInvoiceResponseType } from '../../../../../../types/upload-hub/invoice/processed/ProcessedInvoiceResponse.type';
import { ApiOperation } from '../../../../../api';
import usePaginatedListQuery from '../../../../pagination/usePaginatedList.query';
import { getProcessedInvoicesQueryKey } from '../../../queryKeys';
import { TableFiltersInterface } from '../../../../../../views/components/table/interfaces/TableFilters.interface';

export const useGetProcessedInvoicesListQuery = (filters?: TableFiltersInterface) => {
  const { request } = useRequest<ProcessedInvoiceResponseType>(ApiOperation.GetProcessedInvoices);
  const { data, ...rest } = usePaginatedListQuery<ProcessedInvoiceResponseType>(
    request,
    getProcessedInvoicesQueryKey,
    filters,
  );

  return {
    data: data?.responseModel,
    ...rest,
  };
};
