import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './NotificationsContent.module.scss';
import NotificationsHeaderComponent from '../notifications-header/NotificationsHeader.component';
import { TabInterface } from '../../../tabs/interfaces/Tab.interface';
import TabsComponent from '../../../tabs/Tabs.component';
import NotificationsListComponent from '../notifications-list/NotificationsList.component';
import { Button } from '../../../material';
import './NotificationsContent.component.scss';
import useMarkAllNotificationsAsReadMutation from '../../../../../services/react-query-hooks/mutations/notification/useMarkAllNotificationsAsRead.mutation';
import { useNotification } from '../../../../../services/notification/Notification.provider';
import { NotificationsContentPropsInterface } from './interfaces/NotificationsContentProps.interface';
import { TableFilterAction } from '../../../table/hooks/TableFilters.hook';
import NotificationsFooterComponent from '../notifications-footer/NotificationsFooter.component';

const NotificationsContentView: FC<NotificationsContentPropsInterface> = ({
  onClose,
}): ReactElement => {
  const { t } = useTranslation();
  const [, setError] = useState('');
  const [isInitialRender, setIsInitialRender] = useState(true);

  const {
    newNotifications,
    notifications,
    dispatchNotificationsFilters,
    selectedNotificationTabIndex,
    setSelectedNotificationTabIndex,
  } = useNotification();

  useEffect(() => {
    dispatchNotificationsFilters({
      type: TableFilterAction.UPDATE_FILTERS,
      value: {
        PageNumber: 1,
      },
    });
  }, [dispatchNotificationsFilters]);

  const notificationsTabs: TabInterface[] = [
    {
      name: 'newNotifications',
      label: t('notification.tabs.new.title'),
      element: (
        <NotificationsListComponent notifications={newNotifications} isNew onClose={onClose} />
      ),
    },
    {
      name: 'overallNotifications',
      label: t('notification.tabs.overall.title'),
      element: <NotificationsListComponent notifications={notifications || []} onClose={onClose} />,
    },
  ];

  const { mutate: markAllAsRead, isLoading: isMarkingAllAsRead } =
    useMarkAllNotificationsAsReadMutation({
      setOtherError: setError,
    });

  const onMarkAllAsReadClickHandler = useCallback(() => {
    setError('');
    markAllAsRead();
  }, [markAllAsRead]);

  const onClickNotificationTabHandler = (index: number | string): void => {
    const newNotificationTabIndex = Number(index);

    if (newNotificationTabIndex === 1 && selectedNotificationTabIndex === newNotificationTabIndex) {
      dispatchNotificationsFilters({
        type: TableFilterAction.UPDATE_FILTERS,
        value: {
          PageNumber: 1,
        },
      });
    }
  };

  useEffect(() => {
    const initRenderTimeout = setTimeout(() => {
      setIsInitialRender(false);
    }, 0);
    return () => {
      clearTimeout(initRenderTimeout);
    };
  }, []);

  useEffect(() => {
    if (isInitialRender && newNotifications.length === 0 && selectedNotificationTabIndex === 0) {
      setIsInitialRender(false);
      setSelectedNotificationTabIndex(1);
    }
  }, [
    isInitialRender,
    newNotifications.length,
    selectedNotificationTabIndex,
    setSelectedNotificationTabIndex,
  ]);

  return (
    <div className={classes['notifications-content']}>
      <NotificationsHeaderComponent onClose={onClose} />

      <TabsComponent
        initTabIndex={selectedNotificationTabIndex}
        tabs={notificationsTabs}
        onChangeTab={(index: number | string) => setSelectedNotificationTabIndex(Number(index))}
        onClickTab={onClickNotificationTabHandler}
        classNames={{
          root: classnames(classes['notifications-content__tabs'], 'notifications-content__tabs'),
          content: classes.tabs__content,
          panel: classes.tabs__panel,
        }}
        toolbar={
          <Button
            className={classes.tabs__button}
            variant="text"
            onClick={onMarkAllAsReadClickHandler}
            loading={isMarkingAllAsRead}
          >
            {t('notification.markAsRead.button')}
          </Button>
        }
        variant="standard"
        color="secondary"
        uppercase
      />

      <NotificationsFooterComponent />
    </div>
  );
};

export default NotificationsContentView;
